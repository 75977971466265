import { useEffect } from 'react'

const formatYmd = date => date.toISOString().slice(0, 10)

const Clock = () => {

    useEffect(() => {
        showTime();
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    function showTime(){
        try {
            var date = new Date();
            var h = date.getHours(); // 0 - 23
            var m = date.getMinutes(); // 0 - 59
            var s = date.getSeconds(); // 0 - 59
            var session = "AM";

            if(h === 0){
                h = 12;
            }

            if(h > 12){
                h = h - 12;
                session = "PM";
            }

            h = (h < 10) ? "0" + h : h;
            m = (m < 10) ? "0" + m : m;
            s = (s < 10) ? "0" + s : s;

            var time = h + ":" + m + ":" + s + " " + session;
            const timeClockElement = document.getElementById("timeclock");
            if(timeClockElement) {
                timeClockElement.innerText = time;
                timeClockElement.textContent = time;    
            }

            setTimeout(showTime, 1000);
        }
        catch {}
    }

    return (
        <>
            {formatYmd(new Date())}
        </>
    );
    }

export default Clock