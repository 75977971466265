import { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useMst } from 'app/store'
import { observer } from 'mobx-react-lite'
import Select from '../kit/Select'
import { AddButton } from '../kit/SmallWidthButton'
import { Paper } from '../Layout'
import { useLocation } from 'react-router-dom'
import { getRig } from '../../../utils/helpers'

const Settings = observer(() => {
  const {
    store: { CasingTally },
  } = useMst()

  const getLibraryPipes = gql`
    query getPipes($type: String!, $rigId: String!) {
      getPipes(
            type: $type,
            rigId: $rigId
      ) {
        id
        type
        name
        connection
        OD
        ID
        weight
        disp
        cap
        range
        mutMin
        mutMax
        tenStrength
        torStrength
        standard
        rigId
      }
    }
  `

  const [getFreshPipes, setGetFreshPipes] = useState(true)
  const [pipesPopulated, setPipesPopulated] = useState(false)
  const [pipeData, setPipeData] = useState([])
  const location = useLocation()
  const uri = location.pathname

  const { refetch, loading, error } = useQuery(getLibraryPipes, {
    variables: { type: "CSG", rigId: getRig() },
    skip: !getFreshPipes,
    onCompleted(data) {
      if (data) {
        setPipeData(data.getPipes)
      }
      setGetFreshPipes(false)
      setPipesPopulated(true)
    }
  })

  useEffect(() => {
    refreshPipes()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri])

  async function refreshPipes() {
    await setGetFreshPipes(true)
    await refetch()
  }

  if (loading) {
    // console.log('loading...')
  }
  if (error) console.log(`Error retrieving fresh pipes: ${error.message}`)

  useEffect(() => {
    if (!pipesPopulated)
      setGetFreshPipes(true)
  }, [pipesPopulated])

  function stringChange(event) {
    let update = new Promise(function(resolve, reject) {
      setPipesPopulated(false)
      CasingTally.setCurrentString(event.target.value)
      refetch()
    })
    update.then(
      function() {
        // ensurePipeSelected()
      },
      function(error) {
        console.log('apollo issue detected', error)
      }
    );
  }

  function nameChange(event) {
    CasingTally?.currentString.setPipeName(String(event.target.value))
  }

  // ensure defaults are set
  useEffect(() => {
    if (CasingTally && CasingTally.currentString) {
      if (CasingTally.currentString.pipeTypeId !== 'CSG') {
        CasingTally.currentString.setPipeTypeId('CSG')
      }
  
      if (CasingTally.currentString.name === undefined) {
        if (pipeData && pipeData.length > 0) {
          CasingTally.currentString.setPipeName(pipeData[0].id)
        }
      }
    }  
  })
  

  return (
    <Paper style={{ marginBottom: 16 }}>

      <h3 style={{ marginLeft: 8, marginBottom: 8, marginTop: 8 }}>
        String {CasingTally?.currentString.number}
      </h3>
      <div style={{ borderRadius: '8px', backgroundColor: 'rgb(58, 60, 86)', display: 'flex', alignItems: 'baseline' }}>

        <Select
          id="currentString"
          value={CasingTally?.currentString.id}
          onChange={stringChange}
          style={{ backgroundColor: '#2D2E44', fontWeight: 400, color: '#fff', margin: 8, width: 120 }}
          title="Current string"
        >
          {CasingTally?.strings.map(({ id, number }) => (
            <option key={id} value={id}>String {number}</option>
          ))}
        </Select>

        <Select
          id="currentPipeType"
          defaultValue="{CasingTally?.currentString.pipeTypeId}"
          style={{ backgroundColor: '#2D2E44', color: '#fff', margin: 8, width: 85 }}
          title="Pipe type"
        >
            <option key={'CSG'} value={'CSG'}>Casing</option>
        </Select>

        <Select
          id="currentPipeId"
          value={CasingTally?.currentString.name}
          onChange={nameChange}
          style={{ backgroundColor: '#2D2E44', color: '#ffffff', margin: 8, width: 400 }}
          title="Description"
        >
          {pipeData.map(({ id, name }) => <option key={id} value={id}>{name}</option>)}
        </Select>

        <Select
          id="currentStringLength"
          onChange={(e) => {
            CasingTally?.currentString.setStandLength(e.target.value)
          }}
          style={{ backgroundColor: '#2D2E44', color: '#ffffff', margin: 8, width: 125 }}
          value={CasingTally?.currentString.standLength}
          title="Joints per Stand"
        >
          <option key={'1'} value={'1'}>
            1
          </option>
          <option key={'2'} value={'2'}>
            2
          </option>
          <option key={'3'} value={'3'}>
            3
          </option>
          <option key={'4'} value={'4'}>
            4
          </option>
        </Select>

        <span id="addString">
        <AddButton
          onClick={() => CasingTally.createEmptyString()}
          style={{ margin: 8 }}
          title="Add string"
        />
      </span>

      </div>
    </Paper>
  )
})

export default Settings
