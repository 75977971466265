import { types, getParent } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { TripSheetInstance } from '../TripSheet/index'

// export type StringInstance = Instance<typeof TripSheetRow>
export type UpdateTripSheetInput = {
  name?: string
  // diameter?: string
  // standLength?: Instance<typeof StandLength>
}

const StandLength = types.enumeration(['1', '2', '3', '4'])
const PipeTypes = types.enumeration(['DP', 'HWDP', 'DC', 'LS', 'CSG', 'LIN'])

const TripSheetRow = types
  .model('TripSheetRow', {
    id: types.optional(types.identifier, uuid),
    index: types.integer,
    source: types.optional(types.string, '?'),
    sourceId: types.optional(types.string, ''),
    deleted: types.optional(types.boolean, false),
    name: types.maybe(types.string),
    pipeTypeId: types.optional(PipeTypes, 'DP'),
    pipeId: types.optional(types.string, ''),
    standLength: types.optional(StandLength, '3'),
    string: types.optional(types.number, 0),
    standNumber: types.optional(types.number, 0),
    length: types.optional(types.number, 0),
    serialNumber: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    partType: types.optional(types.string, ''),
    OD: types.optional(types.number, 0),
    ID: types.optional(types.number, 0),
    disp: types.optional(types.number, 0),
    cap: types.optional(types.number, 0),
    weight: types.optional(types.number, 0),
    emptyFill: types.optional(types.string, ''),
    tt1: types.optional(types.number, 0),
    tt2: types.optional(types.number, 0),
    openClosed: types.optional(types.string, 'open'),
    measHoleIncrem: types.optional(types.number, 0),
    measHoleAccum: types.optional(types.number, 0),
    calcIncrem: types.optional(types.number, 0),
    calcAccum: types.optional(types.number, 0),
    discIncrem: types.optional(types.number, 0),
    discAccum: types.optional(types.number, 0),
    comment: types.optional(types.string, ''),
    tsComment: types.optional(types.string, ''),
  })
  .views((self) => {
    return {
      // get pipeProperties() {
      //   return pipes[pipes.findIndex(({ ID }) => ID === self.pipeId)]
      // },
      get tripSheetStub() {
        const tripSheetStub: number = (getParent(getParent(self)) as TripSheetInstance).stub || 0
        return tripSheetStub
      },
      get totalLength() {
        return 999
      },
    }
  })
  .actions((self) => ({
    // update(data: UpdateTripSheetInput) {
    //   self = { ...self, ...data }
    // },
  }))

export default TripSheetRow
