import { useEffect, useState, useContext } from 'react'
import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { useLocation } from 'react-router-dom'
import icon_plus_circle from '../../../assets/images/icons/icon_plus_circle.svg'
import icon_minus_circle from '../../../assets/images/icons/icon_minus_circle.svg'
import icon_clear_circle from '../../../assets/images/icons/icon_clear_circle.svg'
import { unitConversion } from '../../../utils/helpers'
import { underscoreToSpace } from 'utils/helpers'
import { useLazyQuery, gql } from '@apollo/client'
import { isAlive } from 'mobx-state-tree'

interface Props {
  host?: string;
  wellName?: string
  wellSection?: string
}

const DataTable = observer (({host, wellName, wellSection}: Props) => {
  const {
    store: { WellM, WellSectionM, TrendSheet },
  } = useMst()
  const { locationState } = useContext(RouterContext)

  const [activeLiner, setActiveLiner] = useState(0)
  const [units, setUnits] = useState('imperial')
  const [tblDepth, setTblDepth] = useState(0)
  const [tblDepthTvd, setTblDepthTvd] = useState(0)
  const [isLoaded, setIsLoaded] = useState('')

  const location = useLocation()
  const uri = location.pathname

  useEffect(() => {
    if (isLoaded === '') {
      getUnits()
      prepVars()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wellName, uri, isLoaded, WellM, WellSectionM, wellName, wellSection, TrendSheet])

  useEffect(() => {
    if (isLoaded === '') {
      getUnits()
      prepVars()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoaded) {
      setLinerVisibility()
      prepVars()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded])

  function prepVars() {
    if (host === "section") {
      if (TrendSheet && isAlive(TrendSheet) && TrendSheet.rows.length > 0) {
        setTblDepth(unitConversion('lengthMedium', units, 'out', getLastTrendSheetRow()?.depth || 0, 15))
        setTblDepthTvd(unitConversion('lengthMedium', units, 'out', getLastTrendSheetRow()?.depthTvd || 0, 15))
      }
    }
  }

  let currentWell = wellName
  let currentWellSection = wellSection

  if (!wellName) {
    currentWell = uri.split('/')[2]
  }

  if (!wellSection) {
    currentWellSection = ''
  }

  const variables = {
    wellName: underscoreToSpace(currentWell || ''),
    wellSectionName: underscoreToSpace(currentWellSection || ''),
    companyId: JSON.parse(localStorage.getItem('context') || '').company.id,
    rigId: JSON.parse(localStorage.getItem('context') || '').rig.id,
  }

  const [getUnits] = useLazyQuery(
    gql`
        query wellUnits(
          $wellName: String!
          $companyId: String!
          $rigId: String!
        ) {
          wellByName(
            wellName: $wellName
            companyId: $companyId
            rigId: $rigId
          ) {
            id
            data
            units
            status
          }
        }
    `,
    {
        variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // fetchPolicy: "no-cache",
        onCompleted(data: any) {
          if (data.wellByName.units) {
            setUnits(data.wellByName.units)
          }
          setIsLoaded('loaded')
        },
        onError(err) {
            console.error(err)
        }
    }
  )

  useEffect(() => {
    if (locationState) {
      setUnits(locationState?.units || '')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState])

  useEffect(() => {
    determineLinerVisibility()
    setLinerVisibility()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    determineLinerVisibility()
    setLinerVisibility()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WellM, WellSectionM])

  useEffect(() => {
    setLinerVisibility()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLiner])

  function setInputFieldValue(field: string, value: number) {
    const elem = document.getElementById(field) as HTMLInputElement
    if (elem) {
      elem.value = value.toString()
    }
  }

  function determineLinerVisibility() {
    if (WellM) {
      if (WellM?.LinerSize5 + WellM?.LinerId5 + WellM?.LinerShoeMd5 + WellM?.LinerShoeTvd5 + WellM?.LinerTolMd5 + WellM?.LinerTolTvd5 > 0) {
        setActiveLiner(5)
      } else if (WellM?.LinerSize4 + WellM?.LinerId4 + WellM?.LinerShoeMd4 + WellM?.LinerShoeTvd4 + WellM?.LinerTolMd4 + WellM?.LinerTolTvd4 > 0) {
        setActiveLiner(4)
      } else if (WellM?.LinerSize3 + WellM?.LinerId3 + WellM?.LinerShoeMd3 + WellM?.LinerShoeTvd3 + WellM?.LinerTolMd3 + WellM?.LinerTolTvd3 > 0) {
        setActiveLiner(3)
      } else if (WellM?.LinerSize2 + WellM?.LinerId2 + WellM?.LinerShoeMd2 + WellM?.LinerShoeTvd2 + WellM?.LinerTolMd2 + WellM?.LinerTolTvd2 > 0) {
        setActiveLiner(2)
      } else if (WellM?.LinerSize1 + WellM?.LinerId1 + WellM?.LinerShoeMd1 + WellM?.LinerShoeTvd1 + WellM?.LinerTolMd1 + WellM?.LinerTolTvd1 > 0) {
        setActiveLiner(1)
      } else {
        setActiveLiner(1)
      }
    }
  }

  function setLinerVisibility() {
    if (isLoaded === 'loaded') {
      if (activeLiner === 1) {
        document.getElementById('liner1row')!.style.display = ''
        document.getElementById('liner2row')!.style.display = 'none'
        document.getElementById('liner3row')!.style.display = 'none'
        document.getElementById('liner4row')!.style.display = 'none'
        document.getElementById('liner5row')!.style.display = 'none'
        document.getElementById('linerAdd1')!.style.visibility = 'visible'
        document.getElementById('linerAdd2')!.style.visibility = 'hidden'
        document.getElementById('linerAdd3')!.style.visibility = 'hidden'
        document.getElementById('linerAdd4')!.style.visibility = 'hidden'
        document.getElementById('linerRemove2')!.style.visibility = 'hidden'
        document.getElementById('linerRemove3')!.style.visibility = 'hidden'
        document.getElementById('linerRemove4')!.style.visibility = 'hidden'
        document.getElementById('linerRemove5')!.style.visibility = 'hidden'
      } else if (activeLiner === 2) {
        document.getElementById('liner1row')!.style.display = ''
        document.getElementById('liner2row')!.style.display = ''
        document.getElementById('liner3row')!.style.display = 'none'
        document.getElementById('liner4row')!.style.display = 'none'
        document.getElementById('liner5row')!.style.display = 'none'
        document.getElementById('linerAdd1')!.style.visibility = 'hidden'
        document.getElementById('linerAdd2')!.style.visibility = 'visible'
        document.getElementById('linerAdd3')!.style.visibility = 'hidden'
        document.getElementById('linerAdd4')!.style.visibility = 'hidden'
        document.getElementById('linerRemove2')!.style.visibility = 'visible'
        document.getElementById('linerRemove3')!.style.visibility = 'hidden'
        document.getElementById('linerRemove4')!.style.visibility = 'hidden'
        document.getElementById('linerRemove5')!.style.visibility = 'hidden'
      } else if (activeLiner === 3) {
        document.getElementById('liner1row')!.style.display = ''
        document.getElementById('liner2row')!.style.display = ''
        document.getElementById('liner3row')!.style.display = ''
        document.getElementById('liner4row')!.style.display = 'none'
        document.getElementById('liner5row')!.style.display = 'none'
        document.getElementById('linerAdd1')!.style.visibility = 'hidden'
        document.getElementById('linerAdd2')!.style.visibility = 'hidden'
        document.getElementById('linerAdd3')!.style.visibility = 'visible'
        document.getElementById('linerAdd4')!.style.visibility = 'hidden'
        document.getElementById('linerRemove2')!.style.visibility = 'hidden'
        document.getElementById('linerRemove3')!.style.visibility = 'visible'
        document.getElementById('linerRemove4')!.style.visibility = 'hidden'
        document.getElementById('linerRemove5')!.style.visibility = 'hidden'
      } else if (activeLiner === 4) {
        document.getElementById('liner1row')!.style.display = ''
        document.getElementById('liner2row')!.style.display = ''
        document.getElementById('liner3row')!.style.display = ''
        document.getElementById('liner4row')!.style.display = ''
        document.getElementById('liner5row')!.style.display = 'none'
        document.getElementById('linerAdd1')!.style.visibility = 'hidden'
        document.getElementById('linerAdd2')!.style.visibility = 'hidden'
        document.getElementById('linerAdd3')!.style.visibility = 'hidden'
        document.getElementById('linerAdd4')!.style.visibility = 'visible'
        document.getElementById('linerRemove2')!.style.visibility = 'hidden'
        document.getElementById('linerRemove3')!.style.visibility = 'hidden'
        document.getElementById('linerRemove4')!.style.visibility = 'visible'
        document.getElementById('linerRemove5')!.style.visibility = 'hidden'
      } else if (activeLiner === 5) {
        document.getElementById('liner1row')!.style.display = ''
        document.getElementById('liner2row')!.style.display = ''
        document.getElementById('liner3row')!.style.display = ''
        document.getElementById('liner4row')!.style.display = ''
        document.getElementById('liner5row')!.style.display = ''
        document.getElementById('linerAdd1')!.style.visibility = 'hidden'
        document.getElementById('linerAdd2')!.style.visibility = 'hidden'
        document.getElementById('linerAdd3')!.style.visibility = 'hidden'
        document.getElementById('linerAdd4')!.style.visibility = 'hidden'
        document.getElementById('linerRemove2')!.style.visibility = 'hidden'
        document.getElementById('linerRemove3')!.style.visibility = 'hidden'
        document.getElementById('linerRemove4')!.style.visibility = 'hidden'
        document.getElementById('linerRemove5')!.style.visibility = 'visible'
      }
    }
  }

  function getLastTrendSheetRow() {
    if (TrendSheet) {
        if (TrendSheet.rows.length > 0) {
            return TrendSheet.rows[TrendSheet.rows.length - 1]
        }
    }
    return null
  }

  return (
    isLoaded === 'loaded' && WellM ? (
    <div className="wellDatatableWrapper">
      <div className="wellDatatableContainer">
        <div className="wellDatatablePanel">
          <div className="wellDatatable">
            <table className="metricTable">
              <thead>
                <tr>
                  <th style={{ width: '16%', textAlign: 'center' }}></th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Size</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>ID</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Shoe Depth MD</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Shoe Depth TVD</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>TOL MD</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>TOL TVD</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Casing</td>
                  <td>
                    <input
                      id="casingSize"
                      title="Casing Size"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.casingSize, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'casingSize',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('casingSize', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="casingId"
                      title="Casing ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.casingId, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'casingId',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('casingId', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                      />
                    </td>
                  <td>
                    <input
                      id="casingShoeMd"
                      title="Casing Shoe MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.casingShoeMd, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'casingShoeMd',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('casingShoeMd', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                  <input
                      id="casingShoeTvd"
                      title="Casing Shoe TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.casingShoeTvd, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'casingShoeTvd',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('casingShoeTvd', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>

                {/* Liner 1 */}

                <tr id="liner1row">
                  <td >Liner 1</td>
                  <td>
                    <input
                      id="LinerSize1"
                      title="Liner Size"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerSize1, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerSize1',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerSize1', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerId1"
                      title="Liner ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerId1, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerId1',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerId1', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeMd1"
                      title="Liner Shoe MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeMd1, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeMd1',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeMd1', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeTvd1"
                      title="Liner Shoe TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeTvd1, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeTvd1',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeTvd1', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolMd1"
                      title="Liner TOL MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolMd1, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolMd1',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolMd1', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolTvd1"
                      title="Liner TOL TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolTvd1, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolTvd1',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolTvd1', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <img id="linerClear1" alt="" src={icon_clear_circle} style={{ marginRight: '15px' }} />
                    <img
                      id="linerAdd1"
                      alt="add liner"
                      src={icon_plus_circle}
                      style={{ cursor: 'pointer', marginRight: '36px' }}
                      onClick={() => {
                        setActiveLiner(2)
                        WellM.updateWellData(WellM.id, 'LinerSize2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerId2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeMd2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeTvd2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolMd2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolTvd2', 'string', '0')
                        setInputFieldValue('LinerSize2', 0)
                        setInputFieldValue('LinerId2', 0)
                        setInputFieldValue('LinerShoeMd2', 0)
                        setInputFieldValue('LinerShoeTvd2', 0)
                        setInputFieldValue('LinerTolMd2', 0)
                        setInputFieldValue('LinerTolTvd2', 0)
                      }}
                    />
                  </td>
                </tr>

                {/* Liner 2 */}

                <tr id="liner2row">
                  <td>Liner 2</td>
                  <td>
                    <input
                      id="LinerSize2"
                      title="Liner Size"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerSize2, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerSize2',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerSize2', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerId2"
                      title="Liner ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerId2, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerId2',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerId2', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeMd2"
                      title="Liner Shoe MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeMd2, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeMd2',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeMd2', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeTvd2"
                      title="Liner Shoe TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeTvd2, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeTvd2',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeTvd2', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolMd2"
                      title="Liner TOL MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolMd2, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolMd2',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolMd2', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolTvd2"
                      title="Liner TOL TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolTvd2, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolTvd2',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolTvd2', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap'}}>
                    <img
                      id="linerAdd2"
                      alt="add liner"
                      src={icon_plus_circle}
                      style={{ cursor: 'pointer', marginRight: '0px' }}
                      onClick={() => {
                        setActiveLiner(3)
                        WellM.updateWellData(WellM.id, 'LinerSize3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerId3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeMd3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeTvd3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolMd3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolTvd3', 'string', '0')
                        setInputFieldValue('LinerSize3', 0)
                        setInputFieldValue('LinerId3', 0)
                        setInputFieldValue('LinerShoeMd3', 0)
                        setInputFieldValue('LinerShoeTvd3', 0)
                        setInputFieldValue('LinerTolMd3', 0)
                        setInputFieldValue('LinerTolTvd3', 0)
                      }}
                    />
                    <img
                      id="linerRemove2"
                      alt="remove liner"
                      src={icon_minus_circle}
                      style={{ cursor: 'pointer', marginRight: '0px', marginLeft: '15px' }}
                      onClick={() => {
                        setActiveLiner(1)
                        WellM.updateWellData(WellM.id, 'LinerSize2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerId2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeMd2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeTvd2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolMd2', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolTvd2', 'string', '0')
                        setInputFieldValue('LinerSize2', 0)
                        setInputFieldValue('LinerId2', 0)
                        setInputFieldValue('LinerShoeMd2', 0)
                        setInputFieldValue('LinerShoeTvd2', 0)
                        setInputFieldValue('LinerTolMd2', 0)
                        setInputFieldValue('LinerTolTvd2', 0)
                      }}
                    />
                  </td>
                </tr>

                {/* Liner 3 */}

                <tr id="liner3row">
                  <td>Liner 3</td>
                  <td>
                    <input
                      id="LinerSize3"
                      title="Liner Size"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerSize3, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerSize3',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerSize3', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerId3"
                      title="Liner ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerId3, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerId3',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerId3', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeMd3"
                      title="Liner Shoe MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeMd3, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeMd3',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeMd3', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeTvd3"
                      title="Liner Shoe TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeTvd3, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeTvd3',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeTvd3', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolMd3"
                      title="Liner TOL MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolMd3, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolMd3',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolMd3', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolTvd3"
                      title="Liner TOL TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolTvd3, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolTvd3',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolTvd3', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap'}}>
                    <img
                      id="linerAdd3"
                      alt="add liner"
                      src={icon_plus_circle}
                      style={{ cursor: 'pointer', marginRight: '0px' }}
                      onClick={() => {
                        setActiveLiner(4)
                        WellM.updateWellData(WellM.id, 'LinerSize4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerId4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeMd4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeTvd4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolMd4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolTvd4', 'string', '0')
                        setInputFieldValue('LinerSize4', 0)
                        setInputFieldValue('LinerId4', 0)
                        setInputFieldValue('LinerShoeMd4', 0)
                        setInputFieldValue('LinerShoeTvd4', 0)
                        setInputFieldValue('LinerTolMd4', 0)
                        setInputFieldValue('LinerTolTvd4', 0)
                      }}
                    />
                    <img
                      id="linerRemove3"
                      alt="remove liner"
                      src={icon_minus_circle}
                      style={{ cursor: 'pointer', marginRight: '0px', marginLeft: '15px' }}
                      onClick={() => {
                        setActiveLiner(2)
                        WellM.updateWellData(WellM.id, 'LinerSize3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerId3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeMd3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeTvd3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolMd3', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolTvd3', 'string', '0')
                        setInputFieldValue('LinerSize3', 0)
                        setInputFieldValue('LinerId3', 0)
                        setInputFieldValue('LinerShoeMd3', 0)
                        setInputFieldValue('LinerShoeTvd3', 0)
                        setInputFieldValue('LinerTolMd3', 0)
                        setInputFieldValue('LinerTolTvd3', 0)
                      }}
                    />
                  </td>
                </tr>

                {/* Liner 4 */}

                <tr id="liner4row">
                  <td>Liner 4</td>
                  <td>
                    <input
                      id="LinerSize4"
                      title="Liner Size"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerSize4, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerSize4',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerSize4', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerId4"
                      title="Liner ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerId4, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerId4',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerId4', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeMd4"
                      title="Liner Shoe MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeMd4, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeMd4',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeMd4', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeTvd4"
                      title="Liner Shoe TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeTvd4, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeTvd4',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeTvd4', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolMd4"
                      title="Liner TOL MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolMd4, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolMd4',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolMd4', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolTvd4"
                      title="Liner TOL TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolTvd4, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolTvd4',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolTvd4', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap'}}>
                    <img
                      id="linerAdd4"
                      alt="add liner"
                      src={icon_plus_circle}
                      style={{ cursor: 'pointer', marginRight: '0px' }}
                      onClick={() => {
                        setActiveLiner(5)
                        WellM.updateWellData(WellM.id, 'LinerSize5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerId5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeMd5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeTvd5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolMd5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolTvd5', 'string', '0')
                        setInputFieldValue('LinerSize5', 0)
                        setInputFieldValue('LinerId5', 0)
                        setInputFieldValue('LinerShoeMd5', 0)
                        setInputFieldValue('LinerShoeTvd5', 0)
                        setInputFieldValue('LinerTolMd5', 0)
                        setInputFieldValue('LinerTolTvd5', 0)
                      }}
                    />
                    <img
                      id="linerRemove4"
                      alt="remove liner"
                      src={icon_minus_circle}
                      style={{ cursor: 'pointer', marginRight: '0px', marginLeft: '15px' }}
                      onClick={() => {
                        setActiveLiner(3)
                        WellM.updateWellData(WellM.id, 'LinerSize4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerId4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeMd4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeTvd4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolMd4', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolTvd4', 'string', '0')
                        setInputFieldValue('LinerSize4', 0)
                        setInputFieldValue('LinerId4', 0)
                        setInputFieldValue('LinerShoeMd4', 0)
                        setInputFieldValue('LinerShoeTvd4', 0)
                        setInputFieldValue('LinerTolMd4', 0)
                        setInputFieldValue('LinerTolTvd4', 0)
                      }}
                    />
                  </td>
                </tr>

                {/* Liner 5 */}

                <tr id="liner5row">
                  <td>Liner 5</td>
                  <td>
                    <input
                      id="LinerSize5"
                      title="Liner Size"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerSize5, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerSize5',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerSize5', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerId5"
                      title="Liner ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.LinerId5, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerId5',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerId5', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeMd5"
                      title="Liner Shoe MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeMd5, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeMd5',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeMd5', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerShoeTvd5"
                      title="Liner Shoe TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerShoeTvd5, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeTvd5',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeTvd5', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolMd5"
                      title="Liner TOL MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolMd5, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolMd5',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolMd5', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="LinerTolTvd5"
                      title="Liner TOL TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM?.LinerTolTvd5, 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolTvd5',
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolTvd5', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap'}}>
                    <img
                      id="linerRemove5"
                      alt="remove liner"
                      src={icon_minus_circle}
                      style={{ cursor: 'pointer', marginRight: '0px' }}
                      onClick={() => {
                        setActiveLiner(4)
                        WellM.updateWellData(WellM.id, 'LinerSize5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerId5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeMd5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerShoeTvd5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolMd5', 'string', '0')
                        WellM.updateWellData(WellM.id, 'LinerTolTvd5', 'string', '0')
                        setInputFieldValue('LinerSize5', 0)
                        setInputFieldValue('LinerId5', 0)
                        setInputFieldValue('LinerShoeMd5', 0)
                        setInputFieldValue('LinerShoeTvd5', 0)
                        setInputFieldValue('LinerTolMd5', 0)
                        setInputFieldValue('LinerTolTvd5', 0)
                      }}
                    />
                  </td>
                </tr>

                {/* BHA */}

                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>Open Hole</td>
                  <td>
                    <input
                      id="BhaSize"
                      title="BHA Size"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.BhaSize, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'BhaSize',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('BhaSize', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="BhaId"
                      title="BHA ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.BhaId, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'BhaId',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('BhaId', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="BhaShoeMd"
                      title="BHA Shoe MD"
                      type="number"
                      step="0.1"
                      value={tblDepth.toFixed(1)}
                      className={host === "well" ? 'invisible' : 'metricItemValueInputTableDisabled'}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <input
                      id="BhaShoeTvd"
                      title="BHA Shoe TVD"
                      type="number"
                      step="0.1"
                      value={tblDepthTvd.toFixed(1)}
                      className={host === "well" ? 'invisible' : 'metricItemValueInputTableDisabled'}
                      disabled={true}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    ) : (<>'loading...'</>)
  );
})

export default DataTable
