import { destroy, types, Instance, clone } from 'mobx-state-tree'
//import { v4 as uuid } from 'uuid'
import { BOPPart, createFreshPart } from './BOPPart'
import { undoManager } from 'app/store'

// export type PartProperties = {
//   name: string
//   top?: number
//   bottom?: number
//   middle?: number
// }

// type AddPartOptions = {
//   partType: BOPPartTypeEnum
//   properties: PartProperties
// }

// type UpdatePartOptions = {
//   partType: BOPPartTypeEnum
//   id: string
//   properties: PartProperties
// }

export const BOP = types
  .model('BOP', {
    //id: types.optional(types.identifier, uuid),
    parts: types.array(BOPPart),
  });

  export const BOPExtra = types
  .model('BOPExtra', {
    bop: BOP,
    newPart: BOPPart,
    bitDepth: types.union(types.number, types.null, types.undefined),
    wellId: types.string,
    tide: types.maybeNull(types.number),
  })
  .views((self) => ({
    partLength() {
      return self.bop.parts.length;
    },
  }))
  .actions((self) => ({
    setBitDepth(val: number | null) {undoManager.withoutUndo(() => {
      self.bitDepth = val;
    })
    },
    setTide(val: number | null) {undoManager.withoutUndo(() => {
      self.tide = val;
    })
    },
    addPart() {
      self.bop.parts.unshift(clone(self.newPart));
      self.newPart = createFreshPart();
    },

    removePart(idx: number) {
      destroy(self.bop.parts[idx])
    },

    movePart(fromIndex: number, toIndex: number) {
      let element = clone(self.bop.parts[fromIndex]);
      self.bop.parts.splice(fromIndex, 1);
      self.bop.parts.splice(toIndex, 0, element);      
    },
  }))
  //.actions((self) => ({
  //}))

  export default BOPExtra
  export type BOPInstance = Instance<typeof BOPExtra>
  