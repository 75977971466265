import { types, Instance } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

export type POIInstance = Instance<typeof POI>
export type UpdatePOIInput = {
  comment?: string
  depth?: number
}

const POI = types
  .model('POI', {
    id: types.optional(types.identifier, uuid),
    number: types.integer,
    comment: types.optional(types.string, ''),
    depth: types.number,
  })
  .actions((self) => ({
    update(data: UpdatePOIInput) {
      self = { ...self, ...data }
    },
  }))

export type CreatePOIInput = Omit<POIInstance, 'number'>

const PointsOfInterest = types
  .model('PointsOfInterest', {
    id: types.optional(types.identifier, uuid),
    data: types.array(POI),
  })
  .actions((self) => ({
    create(poi: CreatePOIInput) {
      self.data.push({ ...poi, number: self.data.length + 1 })
    },
    update(id: string, data: UpdatePOIInput) {
      const index = self.data.findIndex(({ id: ID }) => ID === id)
      if (index !== -1) self.data[index].update(data)
    },
    delete(id: string) {
      const index = self.data.findIndex(({ id: ID }) => ID === id)
      if (index !== -1) self.data.splice(index, 1)
    },
  }))

export default PointsOfInterest
