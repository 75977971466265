const Error = () => {
    const uri = window.location.href
    const d = new Date();

    return (
        <div style={{ padding: '25px 50px', color: '#fff' }}>
            <h2 style={{ color: 'silver'}}>
                Oops
            </h2>
            <div style={{ fontSize: '14px', marginBottom: '30px' }}>
                There has been an issue serving you this content. Please refer the following to your support contact:
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '14px', marginTop: '15px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#3e3f5b', color: '#fff', padding: '15px'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                        <div>
                            Event:
                        </div>
                        <div style={{ marginLeft: '6px' }}>
                            {uri.split('/')[3].replace('error?message=', ' ')}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                        <div>
                            From:
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                        {document.referrer}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                        <div>
                            Time:
                        </div>
                        <div style={{ marginLeft: '12px' }}>
                        {d.toUTCString()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error
