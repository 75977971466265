import { ReactElement, useState, useEffect } from "react";
import { useMst } from 'app/store'
import { isAlive } from 'mobx-state-tree'

export function MudType(): ReactElement {
  const {
    store: { WellSectionM },
} = useMst()

  const [mudBase, setMudBase] = useState('')

  useEffect(() => {
    if (WellSectionM && isAlive(WellSectionM)) {
      setMudBase(WellSectionM.mudBase)
    }
  }, [mudBase, WellSectionM])

  return (
    <div className="wellSectionHeaderDropdownWrapper" style={{ color: "#fff" }}>
      <select
        id="unitSelection"
        className="formFieldSelect wellSectionHeaderDropdown"
        value={mudBase}
        onChange={(e) => {
          setMudBase(e.target.value)
            WellSectionM?.updateWellSectionData(
            WellSectionM.id,
            'mudBase',
            'string',
            e.target.value
            )
            e.target.blur()
          }}
        style={{
          textAlign: "center",
          fontSize: "12pt",
          color: "yellow",
          whiteSpace: "nowrap",
          fontFamily: "sans-serif",
          fontWeight: 400,
        }}
      >
        <option value="oil" style={{ color: "orange" }}>
          Oil-based Mud
        </option>
        <option value="water" style={{ color: "orange" }}>
          Water-based Mud
        </option>
      </select>
    </div>
  );
}
