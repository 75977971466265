import { ReactComponent as Crossover } from 'app/components/kit/images/parts/crossover.svg'
import { ReactComponent as HoleOpener } from 'app/components/kit/images/parts/holeopener.svg'
import { ReactComponent as Accelerator } from 'app/components/kit/images/parts/accelerator.svg'
import { ReactComponent as DP } from 'app/components/kit/images/parts/dp.svg'
import { ReactComponent as HWDP } from 'app/components/kit/images/parts/hwdp.svg'
import { ReactComponent as Jar } from 'app/components/kit/images/parts/jar.svg'
import { ReactComponent as Stabilizer } from 'app/components/kit/images/parts/stabilizer.svg'
import { ReactComponent as SpiralDC } from 'app/components/kit/images/parts/spiraldc.svg'
import { ReactComponent as SlickDC } from 'app/components/kit/images/parts/slickdc.svg'
import { ReactComponent as MWDCollar } from 'app/components/kit/images/parts/mwdcollar.svg'
import { ReactComponent as RollerCone } from 'app/components/kit/images/parts/rollercone.svg'
import { ReactComponent as PDC } from 'app/components/kit/images/parts/pdc.svg'
import { ReactComponent as Custom } from 'app/components/kit/images/parts/custom.svg'

export const images = {
  DP,
  HWDP,
  SpiralDC,
  SlickDC,
  MWDCollar,
  Stabilizer,
  Crossover,
  HoleOpener,
  Jar,
  Accelerator,
  RollerCone,
  PDC,
  Custom,
}
