import { useContext } from 'react'
import { RouterContext } from '../Router'

const Badges = () => {
  const { locationState } = useContext(RouterContext)

  return (
      <div className="textGeneric wellSectionHeaderStatus">
      <span className="uiContainerStatusMessage">
        {locationState.rigType === 'floater' ? 'SUBSEA' : 'SURFACE'}
      </span>
      <span className="uiContainerStatusWarning" style={{ marginLeft: '8px' }}>
        {locationState.units}
      </span>
    </div>
  );
}

export default Badges