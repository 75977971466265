import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'App'
import { useQuery, gql } from '@apollo/client'
import { UserFields } from 'api/fragments'
import endeavorLogo from '../kit/icons/EndeavorLogo.svg'

export type Company = {
    id: string
    name: string
}

export type User = {
    id: string
    email: string
    password: string
    firstName: string | null
    secondName: string | null
    companyId: string | null
    rigId: string | null
    createdAt: Date
    permissions: string[]
    scope: string[]
    company: {
        id: string
        name: string
    }
    rig: {
        id: string
        name: string
        type: string
    }
} | null

const SplashScreen = () => (
    <div
        style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
        }}
    >
        <img src={endeavorLogo} style={{ height: 60 }} alt="website logo" />
    </div>
)

const Profile = () => {

    const { user, setUser } = useContext(UserContext)
    const [role, setRole] = useState('')

    const { loading } = useQuery<{ me: User }>(
        gql`
            ${UserFields}
            query me {
            me {
                ...UserFields
                company {
                    name
                }
            }
        }
        `,
        {
            onCompleted(data) {
                setUser && setUser(data.me)
            },
            onError(err) {
                console.error(err)
            },
        }
    )

    // const [isOnline, setNetwork] = useState(window.navigator.onLine)
    // const updateNetwork = () => {
    //    setNetwork(window.navigator.onLine)
    // }

    // useEffect(() => {
    //    window.addEventListener("offline", updateNetwork)
    //    window.addEventListener("online", updateNetwork)
    //    return () => {
    //       window.removeEventListener("offline", updateNetwork)
    //       window.removeEventListener("online", updateNetwork)
    //    }
    // })

    useEffect(() => {
        const viewer: Array<string> = ['read']
        const complete: Array<string> = ['read', 'full']
        const driller: Array<string> = ['read', 'write']
        const supervisor: Array<string> = ['read', 'owner']
        const support: Array<string> = ['read', 'write', 'owner']

            switch (JSON.stringify(user?.permissions)) {
            case JSON.stringify(support):
                setRole('Support')
                break
            case JSON.stringify(complete):
                setRole('Complete')
                break
            case JSON.stringify(viewer):
                setRole('Viewer')
                break
            case JSON.stringify(driller):
                setRole('Driller')
                break
            case JSON.stringify(supervisor):
                setRole('Supervisor')
                break
            default:
                setRole('Viewer')
                break
        }
    }, [user?.permissions])

    function changeTheme(theme: string) {
        var element = document.getElementById('htmlContainer');
        if (element) {
          element.className = ''
          element.className = theme
        }
    }

    return !loading ? (
        <div className="uiContainer uiContainerShallow">
        <div className="uiContainerTitle">Profile</div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Name: </span>
                <span className="dashboardSelect">{user?.firstName} {user?.secondName}</span>
            </div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Email: </span>
                <span className="dashboardSelect">{user?.email}</span>
            </div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Role: </span>
                <span className="dashboardSelect">{role} <span style={{ color: 'transparent' }}>{user?.scope}</span></span>
            </div>
            {/* <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Status: </span>
                <span className={isOnline ? 'uiContainerTextStatusActive' : 'uiContainerTextStatusInactive'}>{isOnline ? 'ONLINE' : 'OFFLINE'}</span>
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="themeSwapTarget" style={{ marginLeft: '-15px', marginTop: '35px' }}>
                    <div style={{ padding: '0px', filter: 'none' }}>
                        <button onClick={() => changeTheme('theme_lightest')} style={{ backgroundColor: '#2b2c41', color: 'ivory', padding: '4px', border: '1px solid ivory', borderRadius: '6px', filter: 'none', cursor: 'pointer', marginRight: '7px' }}>
                            light
                        </button>
                    </div>
                    <div style={{ padding: '0px', filter: 'none' }}>
                        <button onClick={() => changeTheme('theme_darker')} style={{ backgroundColor: 'ivory', color: '#2b2c41', padding: '4px', border: '1px solid #2b2c41', borderRadius: '7px', filter: 'none', cursor: 'pointer' }}>
                            dark
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <SplashScreen />
    )
}

export default Profile
