import { gql } from '@apollo/client'
import { UserFields } from 'api/fragments'

export const LOGIN = gql`
  ${UserFields}
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      session
      user {
        ...UserFields
      }
    }
  }
`
