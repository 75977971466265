import Table from './Table'
import PrintInfo from 'app/components/kit/PrintInfo'
import Settings from './Settings'
import { SectionTabs } from '../WellSection/SectionTabs'
import './pipeTally.css'

const PipeTally = () => {
  return (
    <>
      <SectionTabs />
      <PrintInfo />
      <Settings />
      <Table />
    </>
  )
}

export default PipeTally
