import { KeyboardEvent, MouseEventHandler, useContext } from 'react';
import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { getStatistic, validateTwoDecimals } from 'utils/helpers';

type TripTableRowProps = {
    calcAccum: number
    calcIncrem: number
    cap: number
    currentString: number
    description: string
    discAccum: number
    discIncrem: number
    disp: number
    emptyFill: string
    id: string
    index: number
    measHoleAccum: number
    measHoleIncrem: number
    openClosed: string
    partType: string
    source: string
    sourceId: string
    standNumber: number
    tsComment: string
    tt1: number
    tt2: number
    bhaLength: number
    discrepancyAlarm: number
    onClick: MouseEventHandler<HTMLDivElement>
}

function onPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
        (event.target as HTMLInputElement).blur()
    }
}

const TripTableRow = observer(({ ...props }: TripTableRowProps) => {
    const {
        id,
        index,
        calcAccum,
        calcIncrem,
        cap,
        currentString,
        description,
        disp,
        discAccum,
        discIncrem,
        emptyFill,
        measHoleAccum,
        measHoleIncrem,
        openClosed,
        partType,
        source,
        sourceId,
        standNumber,
        tsComment,
        tt1,
        tt2,
        discrepancyAlarm,
} = props
const { locationState } = useContext(RouterContext)
const {
    store: { TripSheet },
} = useMst()

function roundDown(number: number, decimals: number) {
    decimals = decimals || 0;
    return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
}

function saveTsComment(id: string, tsComment: string) {
    TripSheet?.setTsComment(sourceId, tsComment)
}

function saveTripTanks(id: string, tt1: number, tt2: number) {
    if (TripSheet) {
        TripSheet.setTripTanks(id, tt1, tt2)
    }
}

function saveOpenClosed(id: string, openClosed: string) {
    if (TripSheet) {
        if (TripSheet.rows.length > 0) {
            for (let i = 0; i < TripSheet.rows.length; i++) {
                if (TripSheet.rows[i].id === id) {
                    TripSheet.setOpenClosedWaterfall(
                        locationState,
                        index,
                        openClosed,
                    )
                }
            }
        }
    }
}

function saveEmptyFill(elemId: string, id: string, emptyFill: string) {
    let elem = document.getElementById(elemId) as HTMLInputElement
    if (elem != null) {
        if (emptyFill === 'true') {
            elem.checked = true
        } else {
            elem.checked = false
        }
    }
    TripSheet?.setEmptyFill(id, emptyFill)
}

return (
    <div style={{ display: 'table-row' }}>
        <div className="tripTableRow">
            <div className="form_radio" style={{ gridColumn: '1/2', gridRow: '1/2',
                margin: 'auto', width: '100%', height: '100%',
                borderRight: '1px solid #3d3d3d', borderBottom: '1px solid #3d3d3d'
            }}>
                <input
                    type="checkbox"
                    key={id}
                    id={`${id}-emptyFill`}
                    name={id}
                    title="Selected"
                    checked={emptyFill.length > 0 ? JSON.parse(emptyFill.toLowerCase()) === true ? true : false : false}
                    onClick={(e) => {saveEmptyFill(
                        `${id}-emptyFill`,
                        (e.target as HTMLInputElement).name,
                        (e.target as HTMLInputElement).checked ? 'true' : 'false')
                    }}
                    onChange={(e) => {saveEmptyFill(
                        `${id}-emptyFill`,
                        (e.target as HTMLInputElement).name,
                        e.target.checked ? 'true' : 'false')
                    }}
                />
            </div>
            <div className="tripTableRowCell"  style={{ gridColumn: '2/3', gridRow: '1/2', whiteSpace: 'nowrap', textAlign: 'left', paddingLeft: '4px' }}>
                <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                        data-tooltip={`${partType} - ${description}`}
                        data-tooltip-position="right"
                        className={
                            source === 'bha' ? 'dataTooltipHost source source1' :
                            source === 'casing' ? 'dataTooltipHost source source2' :
                            source === 'liner' ? 'dataTooltipHost source source3' :
                            source === 'pipe' ? 'dataTooltipHost source source4' : ''
                    }>
                        {source === 'bha' ? source.toUpperCase() : source.toUpperCase()}
                    </span>
                    <span>&nbsp;</span>
                    {/* <!-- tooltip className: tripTableDescHover --> */}
                    <span className="" title={`${partType} - ${description}`} data-title={`${partType} - ${description}`}>
                        {partType} - {description}
                    </span>
                </span>
            </div>
            <div
                className="tripTableRowCell"  style={{ gridColumn: '3/4', gridRow: '1/2', backgroundColor: '#2e2f42' }}>
                {roundDown(standNumber, 0).toFixed(0)}
            </div>
            <div className="tripTableRowCell tripTableRowCellEditable"  style={{ gridColumn: '4/5', gridRow: '1/2' }}>
                <input
                    key={id}
                    id={`${id}-tt1`}
                    name={id}
                    style={{
                        minWidth: '100%', width: '100%', color: '#fff', backgroundColor: '#3e4056', fontSize: '12px', paddingLeft: '6px', paddingRight: '4px', borderRadius: '8px', textAlign: 'right' }}
                    title="Trip Tank 1"
                    type="number"
                    maxLength={500}
                    value={tt1}
                    onFocus={e => e.target.select()}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    className="editableDataCellInput"
                    onKeyDown={onPressEnter}
                    onChange={(e) => {
                        validateTwoDecimals(e.target)
                        saveTripTanks(e.target.name, 1, parseFloat(e.target.value))
                    }}
                />
            </div>
            <div className="tripTableRowCell tripTableRowCellEditable"  style={{ gridColumn: '5/6', gridRow: '1/2', backgroundColor: '#2e2f42' }}>
            <input
                    key={id}
                    id={`${id}-tt2`}
                    name={id}
                    style={{ minWidth: '100%', width: '100%', color: '#fff', backgroundColor: '#3e4056', fontSize: '12px', paddingLeft: '6px', paddingRight: '8px', borderRadius: '8px', marginLeft: '4px', textAlign: 'right' }}
                    title="Trip Tank 2"
                    type="number"
                    maxLength={500}
                    value={tt2}
                    onFocus={e => e.target.select()}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    pattern="[0-9]+([\.,][0-9]+)?"
                    className="editableDataCellInput"
                    inputMode="numeric"
                    onKeyDown={onPressEnter}
                    onChange={(e) => {
                        validateTwoDecimals(e.target)
                        saveTripTanks(e.target.name, 2, parseFloat(e.target.value))
                    }}
                />
            </div>
            <div className="tripTableRowCell tripTableRowCellEditable"  style={{ gridColumn: '6/7', gridRow: '1/2', backgroundColor: '#2e2f42' }}>
                <select
                    key={id}
                    id={`openClosed-${index}-${currentString}`}
                    name={id}
                    title="Open/Closed"
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={(e) => {
                        saveOpenClosed(
                            (e.target as HTMLSelectElement).name,
                            e.target.value,
                        )
                    }}
                    className="editableDataCellSelect"
                    style={{ fontSize: '12px', backgroundColor: '#3e4056', paddingLeft: '6px', borderRadius: '8px', marginLeft: '8px' }}
                    value={openClosed || 'open'}
                >
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                </select>
            </div>
            <div className="tripTableRowCell"  style={{ gridColumn: '7/8', gridRow: '1/2' }}>
                {locationState?.units !== 'hybrid' ?
                    disp.toFixed(4)
                    : (disp * Number(getStatistic('tripDispCap', locationState))).toFixed(4)
                }
            </div>
            <div className="tripTableRowCell"  style={{ gridColumn: '8/9', gridRow: '1/2' }}>
                {locationState?.units !== 'hybrid' ?
                    (disp + cap).toFixed(4)
                    : ((disp * Number(getStatistic('tripDispCap', locationState))) + (cap * Number(getStatistic('tripDispCap', locationState)))).toFixed(4)
                }
            </div>
            <div className="tripTableRowCell"  style={{ gridColumn: '9/10', gridRow: '1/2' }}>
                {measHoleIncrem.toFixed(2)}
            </div>
            <div className="tripTableRowCell"  style={{ gridColumn: '10/11', gridRow: '1/2' }}>
                {measHoleAccum.toFixed(2)}
            </div>
            <div
                id={`ciDiv-${index}-${currentString}`}
                className="tripTableRowCell"
                style={{ color: '#9FE2BF', gridColumn: '11/12', gridRow: '1/2' }}
            >
                <input
                    key={id}
                    id={`ci-${index}-${currentString}`}
                    name={id}
                    style={{
                        minWidth: '100%', border: 'none', width: '100%', color: 'rgb(115, 116, 150)', backgroundColor: 'transparent', fontSize: '12px', paddingLeft: '6px', paddingRight: '4px', borderRadius: '8px', textAlign: 'right' }}
                    title="calcIncrem"
                    type="text"
                    maxLength={10}
                    value={calcIncrem.toFixed(2)}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    className="tripTableRowCellEditable"
                    onChange={(e) => {
                        return null
                    }}
                    disabled={true}
                />

            </div>
            <div id={`ca-${index}-${currentString}`} className="tripTableRowCell"  style={{ color: '#40B5AD', gridColumn: '12/13', gridRow: '1/2' }}>
                <input
                    key={id}
                    id={`${id}-ca`}
                    name={id}
                    style={{
                        minWidth: '100%', border: 'none', width: '100%', color: 'rgb(115, 116, 150)', backgroundColor: 'transparent', fontSize: '12px', paddingLeft: '6px', paddingRight: '4px', borderRadius: '8px', textAlign: 'right' }}
                    title="calcAccum"
                    type="text"
                    maxLength={10}
                    value={calcAccum.toFixed(2)}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    className="tripTableRowCellEditable"
                    onChange={(e) => {
                        return null
                    }}
                    disabled={true}
                />
            </div>
            <div className="tripTableRowCell"  style={{ gridColumn: '13/14', gridRow: '1/2' }}>
                {discIncrem.toFixed(2)}
            </div>
            <div
                className={(((discAccum > discrepancyAlarm) || (discAccum < -discrepancyAlarm)) && discrepancyAlarm !== 0) ? 'tripTableRowCellAlarm' : 'tripTableRowCell'}
                style={{ gridColumn: '14/15', gridRow: '1/2' }}
            >
                {discAccum.toFixed(2)}
            </div>
            <div className="tripTableRowCell"  style={{ width: '100%', gridColumn: '15/16', gridRow: '1/2' }}>
                <input
                    key={id}
                    id={`${id}-tsComment`}
                    name={id}
                    style={{ minWidth: '100%', width: '100%', color: '#fff', backgroundColor: '#3e4056', fontSize: '12px', paddingLeft: '6px', borderRadius: '8px' }}
                    title={tsComment || ''}
                    type="text"
                    maxLength={500}
                    defaultValue={tsComment || ''}
                    onFocus={e => e.target.select()}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    className="editableDataCellInput"
                    onKeyDown={onPressEnter}
                    onMouseLeave={(e) => {
                        saveTsComment((e.target as HTMLInputElement).name, (e.target as HTMLInputElement).value)
                    }}
                    onBlur={(e) => {
                        saveTsComment(e.target.name, e.target.value)
                    }}
                />
            </div>
        </div>
    </div>
)
})

export default TripTableRow
