// import { Link } from 'react-router-dom'
import { RESET_PASSWORD_LINK } from '../../constants/common'

const Help = () => {

    return (
        <div className="uiContainer">
        <div className="uiContainerTitle">Help</div>
            {/* <Link to="/all-wells" className="uiContainerLink">Online Help</Link><br/> */}
            <a href={RESET_PASSWORD_LINK} className="uiContainerSecondaryLink">Support Requests</a>
        </div>
    );
}

export default Help
