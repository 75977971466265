import { types, Instance, getParent, getRoot } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { STAND_LITERALS, LAST_STAND } from '../../constants/common'
import { PipeTallyInstance, EditingModeEnum } from './index'
import PipeList from './PipeList'
import Big from 'big.js'
import { isNumeric } from 'utils/helpers'
import pipes from '../../store/Pipes.json'
import { MainStoreInstance } from 'app/store'

export type StringInstance = Instance<typeof TallyString>
export type UpdateTallyStringInput = {
  name?: string
  diameter?: string
  standLength?: Instance<typeof StandLength>
}

export class StandNumberIterator implements IterableIterator<string> {
  private stand = 0
  // private standLiterals = ['S', 'D', 'T']
  private standLiterals = STAND_LITERALS
  private lastStand: number
  protected standLength: number
  constructor(props: { standLength: number; lastStand: number }) {
    const { standLength, lastStand } = props
    if (standLength > 4 || standLength < 1) {
      throw new Error('stand length can only be between 1 and 4')
    } else {
      this.lastStand = lastStand
      this.standLength = standLength
    }
  }

  get mod() {
    return this.stand % this.standLength
  }

  public next(): IteratorResult<string> {
    this.stand++
    const value =
      this.mod === 0
        ? (this.stand / this.standLength).toString()
        : this.standLiterals[this.mod - 1]
    return {
      done: this.stand === this.lastStand,
      value,
    }
  }

  [Symbol.iterator](): IterableIterator<string> {
    return this
  }

  toArray() {
    return Array.from(this)
  }
}

function createReferenceList(standLength: string) {
  return new StandNumberIterator({
    standLength: Number(standLength),
    lastStand: LAST_STAND,
  }).toArray()
}

const StandLength = types.enumeration(['1', '2', '3', '4'])
const PipeTypes = types.enumeration(['DP', 'HWDP', 'DC', 'LS', 'CSG', 'LIN'])

const TallyString = types
  .model('TallyString', {
    id: types.optional(types.identifier, uuid),
    number: types.integer,
    name: types.maybe(types.string),
    pipeTypeId: types.optional(PipeTypes, 'DP'),
    standLength: types.optional(StandLength, '3'),
    shoeDepthMd: types.optional(types.number, 0),
    shoeDepthTvd: types.optional(types.number, 0),
    TolMd: types.optional(types.number, 0),
    TolTvd: types.optional(types.number, 0),
    list: types.optional(PipeList, {}),
  })
  .volatile((self) => ({
    referenceStandList: createReferenceList(self.standLength),
  }))
  .views((self) => {
    return {
      getReferenceStandList() {
        return createReferenceList(self.standLength)
      },
      get pipeProperties() {
        return pipes[pipes.findIndex(({ ID }) => ID === self.pipeTypeId)]
      },
      get $standLength() {
        return Number(self.standLength)
      },
      get length() {
        return self.list.data.length
      },
      get tripSheetStub() {
        const tripSheetStub: number = (getParent(getParent(self)) as PipeTallyInstance).tripSheetStub || 0
        return tripSheetStub
      },
      getPipe(pipeId: string) {
        const mem: any = (getRoot(self) as MainStoreInstance)
        if (mem.Tubulars && mem.Tubulars?.tubulars.length > 0) {
          const pipe = mem.Tubulars.tubulars[mem.Tubulars.tubulars.findIndex((tubular: any) => tubular.id === pipeId)]
          return pipe
        } else {
          return {}
        }
      },
      get totalLength() {
        let totalLengthAcc = new Big(0)

        self.list.data.forEach((pipe) => {
          totalLengthAcc = new Big(totalLengthAcc.add(pipe.length))
        })
        return totalLengthAcc.toFixed(3)
      },
      get editingMode() {
        const editingMode = (getParent(self) as PipeTallyInstance)
          .editingMode as EditingModeEnum
        return editingMode
      },
      get tally() {
        let standLengthAcc = new Big(0)
        let totalLengthAcc = new Big(0)

        const mem = (getRoot(self) as MainStoreInstance)

        let bhaStandLength = 0
        if (mem.BHA && mem.BHA?.parts.length > 0) {
          for (let i = 0; i < mem.BHA.parts.length; i++) {
            bhaStandLength = bhaStandLength + mem.BHA.parts[i].length
          }
        }

        let pipeTallyLength = 0
        if (mem.PipeTally && mem.PipeTally?.strings.length > 0) {
          for (let i = 0; i < mem.PipeTally.strings.length; i++) {
            if (mem.PipeTally?.strings[i].totalLength) {
              pipeTallyLength = pipeTallyLength + parseFloat(mem.PipeTally.strings[i].totalLength)
            }
          }
        }

        let casingLength = 0
        if (mem.CasingTally && mem.CasingTally?.strings.length > 0) {
          for (let i = 0; i < mem.CasingTally.strings.length; i++) {
            if (mem.CasingTally?.strings[i].totalLength) {
              casingLength = casingLength + parseFloat(mem.CasingTally.strings[i].totalLength)
            }
          }
        }

        let linerLength = 0
        if (mem.LinerTally && mem.LinerTally?.strings.length > 0) {
          for (let i = 0; i < mem.LinerTally.strings.length; i++) {
            if (mem.LinerTally?.strings[i].totalLength) {
              linerLength = linerLength + parseFloat(mem.LinerTally.strings[i].totalLength)
            }
          }
        }

        return self.list.data.map((pipe, index) => {

          const standNumber =
            self.referenceStandList[(pipe.standNumber as number) - 1]

          let standLength: string
          let totalLength: string
          standLengthAcc = new Big(standLengthAcc.add(pipe.length))
          totalLengthAcc = new Big(totalLengthAcc.add(pipe.length))
          totalLength = totalLengthAcc.toFixed(3)

          if (isNumeric(standNumber)) {
            standLength = standLengthAcc.toFixed(3)
            standLengthAcc = new Big(0)
          } else standLength = ''

          return {
            ...pipe,
            length: pipe.length.toFixed(3).toString(),
            standNumber,
            standLength,
            totalLength,
            bhaLength: bhaStandLength,
          }
        })
      },
    }
  })
  .actions((self) => ({
    update(data: UpdateTallyStringInput) {
      self = { ...self, ...data }
    },
    setStandLength(standLength: '1' | '2' | '3' | '4') {
      if (standLength !== self.standLength) {
        self.referenceStandList.clear()
        self.referenceStandList = createReferenceList(standLength)
      }
      self.standLength = standLength
    },
    setPipeTypeId(id: any) {
      self.pipeTypeId = id
    },
    setPipeName(name: string) {
      self.name = name
    },
    setEditingMode(editingMode: EditingModeEnum) {
      const tally = (getParent(self) as PipeTallyInstance)
      tally.editingMode = editingMode
    },
    setComment(index: number, comment: string) {
      self.list.data[index].comment = comment
    },
  }))

export default TallyString
