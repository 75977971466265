import React from 'react'
import ReactDOM from 'react-dom'
import { ChildrenProps } from 'utils/helpers'

export default function Portal({
  children,
  parent,
  className,
}: ChildrenProps & {
  className: string
  parent?: Node | HTMLElement | undefined
}) {
  const el = React.useMemo(() => document.createElement('div'), [])
  React.useEffect(() => {
    const target = parent ? parent : document.body
    const classList = ['portal-container']
    if (className) className.split(' ').forEach((item) => classList.push(item))
    classList.forEach((item) => el.classList.add(item))
    target.appendChild(el)
    return () => {
      target.removeChild(el)
    }
  }, [el, parent, className])
  return ReactDOM.createPortal(children, el)
}
