import { HTMLProps } from 'react'
import { ChildrenProps } from 'utils/helpers'
import './Input.css'

const Select = ({
  title,
  helperText,
  placeholder,
  errorHelperText,
  id,
  children,
  ...props
}: InputProps) => {
  return (
    <div className="field">
      <select id={id} {...props}>
        {children}
      </select>
      <label
        htmlFor={id}
        className="placeholder"
        style={{ pointerEvents: 'none' }}
      >
        {title}
      </label>
    </div>
  )
}

type InputProps = {
  title?: string
  helperText?: string
  placeholder?: string
  errorHelperText?: string
} & HTMLProps<HTMLSelectElement> &
  ChildrenProps

export default Select
