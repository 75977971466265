import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { useContext } from 'react'
import { RouterContext } from '../Router'
import { MetricSpacer } from "./MetricSpacer";
import { fixNumber, getLabel, conversion, unitConversion } from '../../../utils/helpers'

const Metrics = observer (() => {
  const {
    store: { WellM },
} = useMst()
  const { locationState } = useContext(RouterContext)

  function setInputFieldValue(field: string, value: number) {
    const elem = document.getElementById(field) as HTMLInputElement
    if (elem) {
      elem.value = value.toString()
    }
  }

  function getInputFieldValue(field: string) {
    const elem = document.getElementById(field) as HTMLInputElement
    if (elem) {
      return parseFloat(elem.value)
    }
    return 0
  }

  function updateMetric(id: string, fieldName: string, fieldType: string, value: string) {
    if (WellM) {
      WellM.updateWellData(id, fieldName, fieldType, value.toString())
    }
  }

  function getSetRkbToMudLine(airGap = 0, waterDepth = 0) {
    if (WellM && locationState) {
      updateMetric(
        WellM.id,
        'rkbToMudLine',
        'string',
        locationState?.units === 'hybrid' ?
          (fixNumber(conversion('lengthMedium', 'metToImp', airGap) + conversion('lengthMedium', 'metToImp', waterDepth), 15)).toString()
          : fixNumber(airGap + waterDepth, 15).toString()
      )
      setInputFieldValue('rkbToMudLine',  fixNumber(airGap + waterDepth, 15))
      return fixNumber(airGap + waterDepth, 15)
    }
      return 0
  }

  function getSetRkbToWellHead() {
    if (WellM && locationState) {
      locationState.units === 'hybrid' ?
        updateMetric(WellM.id, 'rkbToDatum', 'string', fixNumber((conversion('lengthMedium', 'metToImp', WellM.airGap) + conversion('lengthMedium', 'metToImp', WellM.waterDepth) - conversion('lengthMedium', 'metToImp', WellM.mudLineToWellHead)), 15).toString())
        : updateMetric(WellM.id, 'rkbToDatum', 'string', fixNumber((WellM.airGap + WellM.waterDepth - WellM.mudLineToWellHead), 15).toString())
      setInputFieldValue('rkbToDatum',  locationState.units === 'hybrid' ?
        fixNumber(conversion('lengthMedium', 'metToImp', WellM.airGap) + conversion('lengthMedium', 'metToImp', WellM.waterDepth) - conversion('lengthMedium', 'metToImp', WellM.mudLineToWellHead), 15)
        : fixNumber(WellM.airGap + WellM.waterDepth - WellM.mudLineToWellHead, 15))
      return locationState.units === 'hybrid' ?
        fixNumber(conversion('lengthMedium', 'metToImp', WellM.airGap) + conversion('lengthMedium', 'metToImp', WellM.waterDepth) - conversion('lengthMedium', 'metToImp', WellM.mudLineToWellHead), 15)
        : fixNumber(WellM.airGap + WellM.waterDepth - WellM.mudLineToWellHead, 15)
    }
      return 0
  }

  function getSetRkbToWellHeadQuick(airGap = 0, waterDepth = 0, mudLineToWellHead = 0) {
    if (WellM && locationState) {
      updateMetric(
        WellM.id,
        'rkbToDatum',
        'string',
        fixNumber(
          locationState?.units === 'hybrid' ?
            conversion('lengthMedium', 'metToImp', airGap ) + conversion('lengthMedium', 'metToImp', waterDepth ) - conversion('lengthMedium', 'metToImp', mudLineToWellHead)
            : airGap + waterDepth - mudLineToWellHead
            , 15).toString()
      )
      setInputFieldValue('rkbToDatum', fixNumber(airGap + waterDepth - mudLineToWellHead, 15))
      return fixNumber(airGap + waterDepth - mudLineToWellHead, 15)
    }
      return 0
  }

  return (
      <div className="metricsWrapper">
        <div className="metricsContainer">
          <div className="metricsPanel">

            <div className="gridTableOneRowOnly">

              <div className="gridTableItemWrapper">
                  <div className="gridTableItemContainer">
                    <div className="metricItemProminentValue">
                      <input
                        id="emptyBlockWeight"
                        title="Empty Block Weight"
                        type="number"
                        step="0.1"
                        defaultValue={fixNumber(WellM?.emptyBlockWeight || 0, 15).toFixed(1)}
                        onFocus={e => e.target.select()}
                        onBlur={(e) => {
                          updateMetric(WellM?.id || '', 'emptyBlockWeight', 'string', e.target.value)
                          setInputFieldValue('emptyBlockWeight', parseFloat(e.target.value))
                        }}
                        className="metricItemValueInput"
                      />
                    </div>
                  </div>
                  <div className="gridTableItemContainer">
                    <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                      Empty Block Weight
                      <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                        ({getLabel('weightLarge', locationState)})
                      </div>
                    </div>
                    <div />
                  </div>
                </div>

                <MetricSpacer direction="gridTableSpacerVertical" />

                <div className="gridTableItemWrapper">
                  <div className="gridTableItemContainer">
                    <div className="metricItemProminentValue">
                      <input
                        id="mudLineToWellHead"
                        title="Mud Line To Well Head"
                        type="number"
                        step="0.1"
                        defaultValue={unitConversion('lengthMedium', locationState?.units, 'out', WellM?.mudLineToWellHead, 10).toFixed(1)}
                        onFocus={e => e.target.select()}
                        onBlur={(e) => {
                          updateMetric(
                            WellM?.id || '',
                            'mudLineToWellHead',
                            'string',
                            locationState?.units === 'hybrid' ? fixNumber(conversion('lengthMedium', 'metToImp', parseFloat(e.target.value)), 15).toString() : e.target.value.toString()
                          )
                          setInputFieldValue('mudLineToWellHead', parseFloat(e.target.value))
                          if (locationState?.rigType === 'floater') {
                            getSetRkbToWellHeadQuick(getInputFieldValue('airGap'), getInputFieldValue('waterDepth'), parseFloat(e.target.value))
                          }
                        }}
                        className="metricItemValueInput"
                      />
                    </div>
                  </div>
                  <div className="gridTableItemContainer">
                    <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                      {locationState?.rigType === 'land' ? 'Ground' : 'Mud Line'} to Well Head
                      <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                        ({getLabel('lengthMedium', locationState)})
                      </div>
                    </div>
                    <div />
                  </div>
                </div>

                <MetricSpacer direction="gridTableSpacerVertical" />

                <div className="gridTableItemWrapper">
                  <div className="gridTableItemContainer">
                    <div className="metricItemProminentValue">
                      <input
                        id="rkbToDatum"
                        title="RKB to Well Head"
                        type="number"
                        step="0.1"
                        disabled={locationState?.rigType === 'floater' ? true : false}
                        defaultValue={unitConversion('lengthMedium', locationState?.units, 'out', WellM?.rkbToDatum, 10).toFixed(1)}
                        onFocus={e => e.target.select()}
                        onBlur={(e) => {
                          if (locationState?.rigType !== 'floater') {
                            locationState?.units === 'hybrid' ?
                              updateMetric(
                                WellM?.id || '',
                                'rkbToDatum',
                                'string',
                                fixNumber(conversion('lengthMedium', 'metToImp', parseFloat(e.target.value)), 15).toString()
                              )
                              : updateMetric(WellM?.id || '', 'rkbToDatum', 'string', e.target.value)
                          }
                          setInputFieldValue(
                            'rkbToDatum',
                            locationState?.rigType === 'floater' ?
                              getSetRkbToWellHead() :
                              parseFloat(e.target.value)
                          )
                        }}
                        className="metricItemValueInput"
                      />
                    </div>
                  </div>
                  <div className="gridTableItemContainer">
                    <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                      RKB to Well Head
                      <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                        ({getLabel('lengthMedium', locationState)})
                      </div>
                    </div>
                    <div />
                  </div>
                </div>

                <MetricSpacer />
                <div></div>
                <MetricSpacer />
                <div></div>
                <MetricSpacer />

              </div>

              {locationState?.rigType === 'land' ? (<></>) : (

              <div className="gridTableOneRowOnly" style={{ marginTop: '25px' }}>

                <div className="gridTableItemWrapper">
                  <div className="gridTableItemContainer">
                    <div className="metricItemProminentValue">
                      <input
                        id="airGap"
                        title="Air Gap"
                        type="number"
                        step="0.1"
                        defaultValue={unitConversion('lengthMedium', locationState?.units, 'out', WellM?.airGap, 10).toFixed(1)}
                        onFocus={e => e.target.select()}
                        onBlur={(e) => {
                          updateMetric(
                            WellM?.id || '',
                            'airGap',
                            'string',
                            locationState?.units === 'hybrid' ? fixNumber(conversion('lengthMedium', 'metToImp', parseFloat(e.target.value)), 15).toString() : e.target.value.toString()
                          )
                          setInputFieldValue('airGap', parseFloat(e.target.value))
                          getSetRkbToMudLine(parseFloat(e.target.value), getInputFieldValue('waterDepth'))
                          getSetRkbToWellHeadQuick(parseFloat(e.target.value), getInputFieldValue('waterDepth'), getInputFieldValue('mudLineToWellHead'))

                        }}
                        className="metricItemValueInput"
                      />
                    </div>
                  </div>
                  <div className="gridTableItemContainer">
                    <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                      Air Gap
                      <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px', textTransform: 'none' }}>
                        ({getLabel('lengthMedium', locationState)})
                      </div>
                    </div>
                    <div />
                  </div>
                </div>

                <MetricSpacer direction="gridTableSpacerVertical" />

                <div className="gridTableItemWrapper">
                  <div className="gridTableItemWContainer">
                    <div className="metricItemProminentValue">
                      <input
                        id="waterDepth"
                        title="Water Depth"
                        style={{ marginLeft: '-10px'}}
                        type="number"
                        step="0.1"
                        defaultValue={unitConversion('lengthMedium', locationState?.units, 'out', WellM?.waterDepth, 10).toFixed(1)}
                        onFocus={e => e.target.select()}
                        onBlur={(e) => {
                          updateMetric(
                            WellM?.id || '',
                            'waterDepth',
                            'string',
                            locationState?.units === 'hybrid' ? fixNumber(conversion('lengthMedium', 'metToImp', parseFloat(e.target.value)), 15).toString() : e.target.value.toString()
                          )
                          setInputFieldValue('waterDepth', parseFloat(e.target.value))
                          getSetRkbToMudLine(getInputFieldValue('airGap'), parseFloat(e.target.value))
                          getSetRkbToWellHeadQuick(getInputFieldValue('airGap'), parseFloat(e.target.value), getInputFieldValue('mudLineToWellHead'))
                        }}
                        className="metricItemValueInput"
                      />
                    </div>
                  </div>
                  <div className="gridTableItemWContainer">
                    <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row', marginLeft: '28px' }}>
                      Water Depth
                      <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                        ({getLabel('lengthMedium', locationState)})
                      </div>
                    </div>
                    <div />
                  </div>
                </div>

                <MetricSpacer direction="gridTableSpacerVertical" />

                <div className="gridTableItemWrapper">
                  <div className="gridTableItemContainer">
                    <div className="metricItemProminentValue">
                      <input
                        id="rkbToMudLine"
                        title="RKB to Mud Line"
                        type="number"
                        step="0.1"
                        disabled={true}
                        defaultValue={unitConversion('lengthMedium', locationState?.units, 'out', WellM?.rkbToMudLine, 10).toFixed(1)}
                        className="metricItemValueInput"
                      />
                    </div>
                  </div>
                  <div className="gridTableItemContainer">
                    <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                      RKB to {locationState?.rigType === 'land' ? 'Ground' : 'Mud Line'}
                      <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                        ({getLabel('lengthMedium', locationState)})
                      </div>
                    </div>
                    <div />
                  </div>
                </div>
              </div>
              )}
          </div>
        </div>
    </div>
  );
})

export default Metrics