import { useDrag, useDrop, DndProvider } from 'react-dnd'
import { useRef } from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Part from './Part'

import { observer } from 'mobx-react-lite'
import { useMst } from 'app/store'

const ItemTypes = {
  PARENT: 'parent',
  PART: 'part',
}

const TowerPart = ({ id, title, image, index, movePart, partType, historyIndex }) => {
  const ref = useRef(null)
  const [, /* { handlerId } */ drop] = useDrop({
    accept: ItemTypes.PART,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      movePart(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
    drop(item) {
      // const dragIndex = item.index
      // const hoverIndex = index
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.PART,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
    end() {},
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <Part
      ref={ref}
      image={image}
      labelTitle={title}
      style={{
        opacity,
        pointerEvents:
          historyIndex !== 0 || partType === 'RollerCone' || partType === 'PDC' ? 'none' : 'inherit',
      }}
    />
  )
}

const DraggableTower = observer(({historyIndex}) => {
  const {
    store: { BHA },
  } = useMst()

  const movePart = (dragIndex, hoverIndex) => {
    BHA.movePart(dragIndex, hoverIndex)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '16px'
      }}
    >
      {(historyIndex === 0 ? BHA.parts : BHA.history[historyIndex-1].parts).map(({ id, description, image, partType }, i) => {
        return (
          <TowerPart
            key={id}
            id={id}
            title={description}
            image={image}
            index={i}
            movePart={movePart}
            partType={partType}
            historyIndex={historyIndex}
          />
        )
      })}
    </div>
  )
})

const Tower = ({historyIndex}) => {
  return (
        <DndProvider backend={HTML5Backend}>
          <DraggableTower historyIndex={historyIndex} />
        </DndProvider>
  )
}

export default Tower
