import { makeStyles } from '@mui/styles'
import { ReactComponent as UndoIcon } from './icons/undo.svg'
import { ReactComponent as RedoIcon } from './icons/redo.svg'
import { DetailedHTMLProps, HTMLAttributes, MouseEventHandler } from 'react'
import { theme } from './styles/theme'

const useStyles = makeStyles({
  undoButtonsBox: {
    width: '96px',
    height: '48px',
    background: '#34354F',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 5,
  },
  undoButton: ({ undoDisabled }: JSSProps) => ({
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '14px',
    paddingRight: '6px',
    width: '100%',
    height: '100%',
    opacity: undoDisabled ? 0.15 : 1,
    pointerEvents: undoDisabled ? 'none' : 'auto',
    borderRadius: '16px 0px 0px 16px',
    transition: 'background-color ease-out 0.1s',
    '&:hover': {
      backgroundColor: theme.colors.well.hover,
    },
  }),
  redoButton: ({ redoDisabled }: JSSProps) => ({
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '9px',
    paddingRight: '12px',
    width: '100%',
    height: '100%',
    opacity: redoDisabled ? 0.15 : 1,
    pointerEvents: redoDisabled ? 'none' : 'auto',
    borderRadius: '0px 16px 16px 0px',
    transition: 'background-color ease-out 0.1s',
    '&:hover': {
      backgroundColor: theme.colors.well.hover,
    },
  }),
  divider: {
    width: '1px',
    height: '24px',
    backgroundColor: '#737496',
    opacity: 0.4,
    borderRadius: '16px',
  },
})

export const UndoButtons = (props: UndoButtonsProps) => {
  const {
    onUndo,
    onRedo,
    undoDisabled = false,
    redoDisabled = false,
    ...p
  } = props

  const cn = useStyles({ undoDisabled, redoDisabled })

  return (
    <div {...p} className={cn.undoButtonsBox}>
      <button
        onClick={onUndo}
        disabled={undoDisabled}
        className={cn.undoButton}
      >
        <UndoIcon />
      </button>
      <div className={cn.divider} />
      <button
        onClick={onRedo}
        disabled={redoDisabled}
        className={cn.redoButton}
      >
        <RedoIcon />
      </button>
    </div>
  )
}

type JSSProps = {
  undoDisabled: boolean
  redoDisabled: boolean
}

type JSXButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

interface UndoButtonsProps extends JSXButtonProps {
  undoDisabled?: boolean
  redoDisabled?: boolean
  onUndo: MouseEventHandler<HTMLButtonElement>
  onRedo: MouseEventHandler<HTMLButtonElement>
}
