import { Instance, types, SnapshotIn } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

export type WellSectionMInstance = Instance<typeof WellSectionM>
export type WellSectionMSetAttributes = SnapshotIn<typeof WellSectionM>

const WellSectionM = types
  .model('WellSectionM', {
    id: types.optional(types.identifier, uuid),
    mudBase: types.optional(types.string, 'oil'),
    mudWeight: types.optional(types.number, 0),
    yieldPoint: types.optional(types.number, 0),
    outD: types.optional(types.number, 0),
    inD: types.optional(types.number, 0),
    bitSize: types.optional(types.number, 0),
    maasp: types.optional(types.number, 0),
    cp: types.optional(types.number, 0),
  })
  .views((self) => ({
    // getKillSheetRow(id: string) {
    //   return self.rows.find(({ id: ID }) => ID === id)
    // },
    // getFirstKillSheetRow() {
    //   return self.rows[0]
    // },
    // getLastCheckedRow() {
    //   self.rows.filter(e => e['emptyFill'] === 'true')
    //   return 0
    // }
  }))
  .actions((self) => {
    return {
      updateWellSectionData(id: string, field: any, fieldType: string, value: string) {
        const updateFieldType: string = fieldType
        const updateField: typeof updateFieldType = field
        const updateValue = value

        if (updateField === 'mudBase') {
          self.mudBase = updateValue
        } else if (updateField === 'mudWeight') {
          self.mudWeight = parseFloat(updateValue)
        } else if (updateField === 'yieldPoint') {
          self.yieldPoint = parseFloat(updateValue)
        } else if (updateField === 'outD') {
          self.outD = parseFloat(updateValue)
        } else if (updateField === 'inD') {
          self.inD = parseFloat(updateValue)
        } else if (updateField === 'bitSize') {
          self.bitSize = parseFloat(updateValue)
        } else if (updateField === 'maasp') {
          self.maasp = parseFloat(updateValue)
        } else if (updateField === 'cp') {
          self.cp = parseFloat(updateValue)
        }
      }
    }
  })

export default WellSectionM
