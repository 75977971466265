import { isMatch } from 'micromatch'
import { TallySheet, RawTallyRow, TallyRowInput } from 'types'
import { conversion } from '../utils/helpers'

const serialNumberMatcher = ['*snr*', '*ser no*', '*serial number*']
const singleLengthMatcher = [
  '*single*',
  '*single length*',
  '*joint*',
  '*joint length*',
]

export const wiseTrim = (
  s: string,
  opts?: { hard?: boolean; case?: 'lower' | 'upper' }
): string => {
  s = s.replace('\n', opts?.hard ? '' : ' ').trim()
  s = s.replace(/\s+/g, opts?.hard ? '' : ' ')
  return opts?.case === 'upper' ? s.toUpperCase() : s.toLowerCase()
}

export const jsonPrint = (obj: any): string => JSON.stringify(obj, undefined, 2)

export const getTallySheet = (
  arr: any[],
  units?: string,
  opts?: {
    log?: boolean
  }
) =>
  new Promise<TallySheet>((resolve, reject) => {
    let serials: (string | number | undefined)[] = []
    let errors: string[] = []

    const tallySheet = arr.map((obj, index) => {
      let decoded: RawTallyRow = {}
      try {
        for (let k in obj as any) {
          let key = wiseTrim(k)

          if (isMatch(key, serialNumberMatcher)) {
            if (!decoded?.serialNumber) {
              if (serials.includes(obj[k])) {
                // errors.push(`Duplicate serialNumber in row ${index + 2}\n`)
                errors.length = 0
                errors.push('\nThe file you are importing contains duplicate serial numbers. Please check your file and ensure serial numbers are unique and re-import.')
              } else {
                decoded.serialNumber = obj[k]
              }
            } else if (decoded.serialNumber !== obj[k]) {
              throw new Error(`Duplicate "serialNumber" key in element:${index}. \n ${jsonPrint(obj)}`
              )
            }
          }

          if (isMatch(key, singleLengthMatcher)) {
            if (!decoded?.length) {
              if (units && units === 'hybrid') {
                decoded.length = conversion('lengthMedium', 'metToImp', obj[k])
              } else {
                decoded.length = obj[k]
              }
            } else if (decoded.length !== obj[k]) {
              throw new Error(`Duplicate "singleLength" key in element:${index}. \n ${jsonPrint(obj)}`)
            }
          }
        }

        if (decoded?.length) {
          if (typeof decoded.standNumber === 'number')
            decoded.standNumber = decoded.standNumber.toString()

            serials.push(decoded.serialNumber)
            return decoded as TallyRowInput

        } else
          throw new Error(
            `Not enough properties to form a model.\n Object: ${jsonPrint(
              obj
            )}, Possible model: ${jsonPrint(decoded)}, Index: ${index} `
          )
      } catch (err) {
        reject(err)
        return err
      }
    })

    if (errors.length > 0) {
      reject(errors)
      return errors
    }
    resolve(tallySheet as TallySheet)
  })
