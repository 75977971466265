import { DetailedHTMLProps, HTMLAttributes } from 'react'
import './RadioButton.css'

import { v4 } from 'uuid'

export const CheckMarkButton = (props: JSXInputProps) => {
  const id = v4()
  return (
    <div className='form_radio'>
      <input id={id} type='radio' {...props} checked={props.checked} />
      <label htmlFor={id} />
    </div>
  )
}

export const RadioButton = ({ style, ...props }: JSXInputProps) => {
  return (
    <div style={style} className='radio-button'>
      <input id={props.id} type='radio' {...props} checked={props.checked} />
      <label htmlFor={props.id} />
    </div>
  )
}

type JSXInputProps = DetailedHTMLProps<
  HTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { checked: boolean }
