import { ChangeEvent, KeyboardEvent } from 'react';
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { validateTwoDecimals } from 'utils/helpers';

const StubRow = observer(() => {
const {
    store: { TripSheet },
} = useMst()

function saveStub(event: ChangeEvent<HTMLInputElement>) {
    TripSheet?.setTripSheetStub(parseFloat(event.target.value) || 0)
}

function onPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
        (event.target as HTMLInputElement).blur()
    }
}

return (
    <div className="tripTableHeader">
        <div className="tripTableHeaderCell" style={{ gridColumn: '1/2', gridRow: '1/2', margin: 'auto', width: '100%', height: '100%', borderRight: '1px solid #3d3d3d', borderBottom: '1px solid #3d3d3d' }} />
        <div className="tripTableHeaderCell" style={{ gridColumn: '2/3', gridRow: '1/2', margin: 'auto', width: '100%', height: '100%', borderRight: '1px solid #3d3d3d', borderBottom: '1px solid #3d3d3d', textAlign: 'left', color: '#737496', paddingTop: '4px', paddingLeft: '4px' }}>
            START
        </div>
        <div className="tripTableHeaderCell" style={{ gridColumn: '3/4', gridRow: '1/2', margin: 'auto', width: '100%', height: '100%', borderRight: '1px solid #3d3d3d', borderBottom: '1px solid #3d3d3d', color: '#737496', paddingTop: '4px' }}>
            0
        </div>
        <div className="tripTableRowCell tripTableRowCellEditable"  style={{ gridColumn: '4/5', gridRow: '1/2' }}>
            <input
                key="stub"
                id="stub"
                name="stub"
                style={{ minWidth: '100%', width: '100%', color: '#fff', backgroundColor: '#3e4056', fontSize: '12px', paddingLeft: '6px', paddingRight: '4px', borderRadius: '8px', textAlign: 'right' }}
                title="stub"
                type="number"
                inputMode='numeric'
                step="0.01"
                maxLength={12}
                defaultValue={TripSheet?.stub || 0}
                onClick={(e) => {
                    e.stopPropagation()
                }}
                className="editableDataCellInput"
                onKeyDown={onPressEnter}
                onChange={(e) => {
                    validateTwoDecimals(e.target)
                    saveStub(e)
                }}
                onBlur={(e) => {
                    validateTwoDecimals(e.target)
                    saveStub(e)
                }}
            />
        </div>
        <div className="tripTableHeaderCell" style={{ gridColumn: '5/16', gridRow: '1/2', margin: 'auto', width: '100%', height: '100%', borderRight: '1px solid #3d3d3d', borderBottom: '1px solid #3d3d3d' }} />
    </div>
)
})

export default StubRow
