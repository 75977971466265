import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import './utils/arrayClear'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { configure } from 'mobx'
import { Provider, store, undoManager } from 'app/store'

configure({ enforceActions: 'observed' })
// configure({ enforceActions: 'never' })

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  //<React.StrictMode>
    <Provider value={{ store, undoManager }}>
      <App />
    </Provider>
  //</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
