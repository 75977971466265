import { useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import AddPipe from './AddPipe'
import { ImportButton } from '../kit/SmallWidthButton'
import {
  AddActionButton,
  StashActionButton,
  TrashActionButton,
} from '../kit/IconButtons'
import { ChangeEventHandler, MouseEventHandler, useState, useContext } from 'react'
import { getTallySheet } from 'utils/getTallySheet'
import { unitConversion } from '../../../utils/helpers'
import { useMst } from 'app/store'
import * as xlsx from 'xlsx'
import { RouterContext } from '../Router'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import { cleanUpRogueCharacters } from '../../../utils/helpers'
import excel from '../../../assets/images/icons/icon_excel.svg'
import download from '../../../assets/images/icons/icon_export.svg'
import warning from '../../../assets/images/icons/icon_warning.svg'
import React from 'react'
import { createRoot, Root } from 'react-dom/client'

const TableToolbar = observer(() => {
  const {
    store: { PipeTally, CasingTally, getTubulars },
  } = useMst()

  const [open, setOpen] = useState(false)
  const [csvData, setCsvData] = useState<any>([])
  const [tubularsRefreshed, setTubularsRefreshed] = useState(false)
  const { locationState } = useContext(RouterContext)
  const location = useLocation()
  const uri = location.pathname
  let msgRoot : Root | null = null

  const handleClose = () => {
    setOpen(false)
  }

  const handleSpreadsheet: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files
    if (!files) return
    if (files[0] instanceof Blob) {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target?.result instanceof ArrayBuffer) {
          const data = new Uint8Array(e.target.result)
          const book = xlsx.read(data, { type: 'buffer' })
          const processError = (err: any) => {
            const stdErr = 'There is a problem with the contents of the file being imported. The first worksheet tab should contain two columns - serial numbers in the first column, and numeric lengths in the second.'

            if (err && err.message && err.message.length > 0) {
              err[0] = stdErr
            }

            const errMsg = React.createElement('div', { id: 'tableToolbarMsg' }, changeDirection.render(err[0], () => {}))
            if(!msgRoot) {
              const elem = document.getElementById('importFileMessage') as HTMLInputElement
              msgRoot = createRoot(elem)  
            }
            if(msgRoot) msgRoot.render(errMsg)
          }

          for (let key in book.Sheets) {
            const rawJson = xlsx.utils.sheet_to_json(book.Sheets[key])
            getTallySheet(rawJson, locationState?.units)
              .then((sheet) => {
                CasingTally?.currentString.list.setData(sheet)
              })
              .catch(processError)
            break
          }
        }
      }
      reader.readAsArrayBuffer(files[0])
    }
    const elem = document.getElementById('icon-button-file') as HTMLInputElement
    if (elem) { elem.value = '' }
  }

    const changeDirection = {
      closeOnOverlayClick: true,
      render: (message: string, onCancel: any) => {
          return (
              <div id="importFileMessage" className="centeredDiv" style={{ backgroundColor: 'firebrick' }}>
                  <div style={{ width: '600px', margin: '20px', padding: '20px', marginBottom: '15px' }}>
                      <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                          <img alt="warning" src={warning} style={{ marginTop: '10px', width: '75px', height: '75px', marginRight: '10px' }} />
                      </div>
                      <div style={{ lineHeight: '30px', color: '#FFFFFF', fontWeight: 500, fontSize: '14pt', marginBottom: '30px' }}>
                          {message}
                      </div>
                      <button onClick={closeModal} className="popupAlertButton"> OK </button>
                  </div>
              </div>
          )
      }
    }

    function closeModal() {
      if (msgRoot) {
        msgRoot.unmount()
      }
  }

  const handleTrash: MouseEventHandler<HTMLButtonElement> = () => {
    CasingTally?.storePipe({
      direction: 'trash',
    })
  }

  const handleLayOut: MouseEventHandler<HTMLButtonElement> = () => {
    CasingTally?.storePipe({
      direction: 'stash',
    })
  }

  const handleAdd: MouseEventHandler<HTMLButtonElement> = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (!tubularsRefreshed) {
      refreshPipes()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tubularsRefreshed) {
      refreshPipes()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri])

  async function refreshPipes() {
    await getTubulars({ rigId: JSON.parse(localStorage.getItem('context') || '').rig.id })
    await setTubularsRefreshed(true)
  }

  function generateDownload() {
    let download:any = []

    if (CasingTally && CasingTally?.strings.length > 0) {
      if (!tubularsRefreshed) {
        refreshPipes()
      }
      download.push([
        'Serial Number',
        'Single Length',
        'String',
        'Pipe Name',
        'Stand Number',
      ])
      for (let s = 0; s < CasingTally!.strings.length; s++) {
        let pipe = CasingTally.strings[CasingTally.strings[s].number-1].getPipe(CasingTally.strings[CasingTally.strings[s].number-1].name || '')
        if (pipe) {
          for (let i = 0; i < CasingTally.strings[s].list.data.length; i++) {
            download.push([
                CasingTally.strings[s].list.data[i].serialNumber,
                unitConversion('lengthMedium', locationState?.units, 'out', CasingTally.strings[s].list.data[i].length, 10).toFixed(3),
                s + 1,
                pipe.name,
                CasingTally.strings[s].getReferenceStandList()[(CasingTally.strings[s].list.data[i].standNumber as number) - 1],
            ])
          }
        }
      }
    }
    setCsvData(download)
  }

  const csvLinkRef = useRef<any>(null)

  async function GetData() {
    await refreshPipes()
    await generateDownload()

    if (csvLinkRef?.current) {
      csvLinkRef.current.link.click();
    }
  }

  return (
    <div id="tableToolbar" >
      <div className="table-toolbar">
        <AddPipe onClose={handleClose} open={open} />
        <div
          style={{
            marginLeft: '12px',
            display: 'flex',
            alignItems: 'center',
            width: '180px',
          }}
        >
          <Link to="/assets/file/template/tally/TallyImport.xls" target="_blank">
            <img alt="excel" src={excel} style={{ marginTop: '6px', padding: '0px', width: '17px', height: '17px' }} />
          </Link>

          <input
            style={{ display: 'none' }}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="icon-button-file"
            type="file"
            multiple={false}
            onChange={handleSpreadsheet}
          />
          <label htmlFor="icon-button-file">
            <ImportButton />
          </label>
          <div className="exportButton" style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', marginTop: '-2px', marginLeft: '10px' }}>
            <button onClick={GetData}>
            <span style={{ color: 'dodgerblue', fontSize: '12px' }}>Export</span>
              <span>
                <img
                  alt="download"
                  src={download}
                  style={{ marginTop: '0px', marginLeft: '7px', padding: '0px', width: '17px', height: '17px' }}
                />
              </span>
            </button>
            <CSVLink
              data={csvData}
              ref={csvLinkRef}
              className="hidden"
              filename={`${cleanUpRogueCharacters(window.location.href.split('/')[5])}-CasingTally-${new Date().toISOString()}_UTC.csv`}>
            </CSVLink>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '180px',
            justifyContent: 'center',
          }}
        >
          <AddActionButton
            disabled={
              CasingTally?.currentString.length !== 0 &&
              (PipeTally?.editingMode === 'disabled' ||
              CasingTally?.currentString.list.selected.length !== 1)
            }
            style={{ margin: '0px 4px 0px 4px' }}
            onClick={handleAdd}
          />
          <StashActionButton
            disabled={
              PipeTally?.editingMode === 'disabled' ||
              CasingTally?.currentString.list.selected.length === 0
            }
            style={{ margin: '0px 4px 0px 4px' }}
            onClick={handleLayOut}
          />
          <TrashActionButton
            disabled={
              PipeTally?.editingMode === 'disabled' ||
              CasingTally?.currentString.list.selected.length === 0
            }
            style={{ margin: '0px 4px 0px 4px' }}
            onClick={handleTrash}
          />
        </div>
      </div>
      <div id="importFileMessage" className="popupAlert" />
    </div>
  )
})

export default TableToolbar
