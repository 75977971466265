import { types, getParent, getRoot } from 'mobx-state-tree'
// import { Instance, types, SnapshotIn, destroy, getRoot, isAlive } from 'mobx-state-tree'
import { MainStoreInstance } from 'app/store'

import { v4 as uuid } from 'uuid'
// import { MainStoreInstance } from 'app/store'
import { KickCalculatorInstance } from '../KickCalculator/index'

export type UpdateKickCalculatorColumnInput = {
    id?: string
  }

function roundDown(number: number, decimals: number) {
    decimals = decimals || 0;
    return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
}

function nan(value: number) {
    return isNaN(value) ? 0 : value
}

const KickCalculatorColumn = types
    .model('KickCalculatorColumn', {
        id: types.optional(types.identifier, uuid),
        index: types.integer,
        A1: types.optional(types.number, 0, [undefined, null]),
        A2: types.optional(types.number, 0, [undefined, null]),
        A3: types.optional(types.number, 0, [undefined, null]),
        A4: types.optional(types.number, 0, [undefined, null]),
        A5: types.optional(types.number, 0, [undefined, null]),
        A6: types.optional(types.number, 0, [undefined, null]),
        A7: types.optional(types.number, 0, [undefined, null]),
        A8: types.optional(types.number, 0, [undefined, null]),
        A9: types.optional(types.number, 0, [undefined, null]),
        A10: types.optional(types.number, 0, [undefined, null]),
        B1: types.optional(types.number, 0, [undefined, null]),
        B2: types.optional(types.number, 0, [undefined, null]),
        B3: types.optional(types.number, 0, [undefined, null]),
        B4: types.optional(types.number, 0, [undefined, null]),
        B5: types.optional(types.number, 0, [undefined, null]),
        B6: types.optional(types.number, 0, [undefined, null]),
        B7: types.optional(types.number, 0, [undefined, null]),
        B8: types.optional(types.number, 0, [undefined, null]),
        B9: types.optional(types.number, 0, [undefined, null]),
        C1: types.optional(types.number, 0, [undefined, null]),
        C2: types.optional(types.number, 0, [undefined, null]),
        C3: types.optional(types.number, 0, [undefined, null]),
        C4: types.optional(types.number, 0, [undefined, null]),
        C5: types.optional(types.number, 0, [undefined, null]),
        C6: types.optional(types.number, 0, [undefined, null]),
        C7: types.optional(types.number, 0, [undefined, null]),
        C8: types.optional(types.number, 0, [undefined, null]),
        C9: types.optional(types.number, 0, [undefined, null]),
        D1: types.optional(types.number, 0, [undefined, null]),
        D2: types.optional(types.number, 0, [undefined, null]),
        D3: types.optional(types.number, 0, [undefined, null]),
        D4: types.optional(types.number, 0, [undefined, null]),
        DD1: types.optional(types.number, 0, [undefined, null]),
        DD2: types.optional(types.number, 0, [undefined, null]),
        DD3: types.optional(types.number, 0, [undefined, null]),
        DD4: types.optional(types.number, 0, [undefined, null]),
        E: types.optional(types.number, 0, [undefined, null]),
    })
    .views(self => ({
        // get mainStore(): MainStoreInstance {
        //     return getRoot(self)
        // }
    }))
    .actions((self) => {
        return {
            recalculateColumn(measurement?: string) {
                let multiplier = 0
                let converter = 0

                if (measurement) {
                    multiplier = measurement === 'imperial' ? 0.052 : measurement === 'metric' ? 0.0981 : 0.170603674540676
                    converter = measurement === 'imperial' ? 1029.4 : measurement === 'metric' ? 1973.616 : 313.761120000011797
                } else {
                    const mem = (getRoot(self) as MainStoreInstance)
                    multiplier = mem.KickCalculator?.units === 'imperial' ? 0.052 : mem.KickCalculator?.units === 'metric' ? 0.0981 : 0.170603674540676
                    converter = mem.KickCalculator?.units === 'imperial' ? 1029.4 : mem.KickCalculator?.units === 'metric' ? 1973.616 : 313.761120000011797
                }

                self.B5 = nan(self.B2 + self.B3)
                self.C1 = nan(roundDown(((self.B4 - self.B3) * multiplier * self.A5), 1) - self.B7)
                self.C2 = nan((self.A3 * self.B5 * multiplier) - (self.A3 * self.B6 * multiplier))
                self.C3 = nan((self.A3 * self.B5 * multiplier) - (self.B9/100 * self.A3 * self.B6 * multiplier) - (1 - self.B9/100) * self.B3 * self.A3 * multiplier)
                self.C4 = nan(((self.B4 + self.B8) * self.A5 * multiplier) - (self.A5 * self.B6 * multiplier))
                self.C5 = nan((self.A3 * self.B5 * multiplier) - (self.A3 - (getParent(getParent(self)) as KickCalculatorInstance).waterDepth - (getParent(getParent(self)) as KickCalculatorInstance).rkbToWater) * self.B6 * multiplier)
                self.C6 = nan((self.A3 * self.B5 * multiplier) - (self.B9/100 * (self.A3 - (getParent(getParent(self)) as KickCalculatorInstance).waterDepth - (getParent(getParent(self)) as KickCalculatorInstance).rkbToWater)) * self.B6 * multiplier - (1 - self.B9/100) * self.B3 * (self.A3 - (getParent(getParent(self)) as KickCalculatorInstance).waterDepth - (getParent(getParent(self)) as KickCalculatorInstance).rkbToWater) * multiplier)
                self.C7 = nan((self.B4 + self.B8) * self.A5 * multiplier - (self.A5 - (getParent(getParent(self)) as KickCalculatorInstance).waterDepth - (getParent(getParent(self)) as KickCalculatorInstance).rkbToWater) * self.B6 * multiplier)
                self.C8 = nan(Math.min(self.C2, self.C3, self.C4))
                self.C9 = nan(Math.min(self.C5, self.C6, self.C7))
                self.D1 = nan((self.C1 - (0 * multiplier * self.A3)) / ((self.B3 - self.B6) * multiplier))
                self.D2 = nan(self.A8 < (self.D1 / Math.cos(self.A7 * Math.PI / 180)) ?
                    (self.D1 / Math.cos(self.A7 * Math.PI / 180) - self.A8) * (((self.A1 * self.A1) - (self.A10 * self.A10)) / converter) + ((((self.A1 * self.A1) - (self.A9 * self.A9))/converter) * self.A8)
                    : (((self.A1 * self.A1 - self.A9 * self.A9) / converter) * (self.D1 / Math.cos(self.A7 * Math.PI / 180))))
                self.D3 = nan(((((self.A1 * self.A1 - self.A10 * self.A10) / converter * self.D1) / Math.cos ( self.A6 * Math.PI / 180)) * self.B4 * multiplier * self.A5) / (self.B5 * self.A3 * multiplier))
                self.D4 = nan(Math.min(self.D2, self.D3))
                self.DD1 = nan((self.C1 - (self.B2 * multiplier * self.A3)) / ((self.B3 - self.B6) * multiplier))
                self.DD2 = nan(self.A8 < (self.DD1 / Math.cos(self.A7 * Math.PI / 180)) ?
                    ((self.DD1) / Math.cos(self.A7 * Math.PI / 180) - self.A8) * (((self.A1 * self.A1) - (self.A10 * self.A10)) / converter) + ((((self.A1 * self.A1) - (self.A9 * self.A9)) / converter) * self.A8)
                    : (((self.A1 * self.A1 - self.A9 * self.A9) / converter * self.DD1) / Math.cos(self.A7 * Math.PI / 180)))
                self.DD3 = nan((((self.A1 * self.A1 - self.A10 * self.A10) / converter * self.DD1) / Math.cos(self.A6 * Math.PI / 180)) * self.B4 * multiplier * self.A5 / (self.B5 * self.A3 * multiplier))
                self.DD4 = nan(Math.min(self.DD2, self.DD3))
                self.E = nan(self.B4 - self.B3)
                // console.log('parent',getParent(getParent(self)))
            },

          updateKickCalculatorColumn(id: string, field: any, fieldType: string, value: string) {
            const updateFieldType: string = fieldType
            const updateField: typeof updateFieldType = field
            const updateValue = value

            if (updateField === 'id') {
                self.id = updateValue
            } else if (updateField === 'index') {
                self.index = parseFloat(updateValue)
            } else if (updateField === 'A1') {
                self.A1 = parseFloat(updateValue)
            } else if (updateField === 'A2') {
                self.A2 = parseFloat(updateValue)
            } else if (updateField === 'A3') {
                self.A3 = parseFloat(updateValue)
            } else if (updateField === 'A4') {
                self.A4 = parseFloat(updateValue)
            } else if (updateField === 'A5') {
                self.A5 = parseFloat(updateValue)
            } else if (updateField === 'A6') {
                self.A6 = parseFloat(updateValue)
            } else if (updateField === 'A7') {
                self.A7 = parseFloat(updateValue)
            } else if (updateField === 'A8') {
                self.A8 = parseFloat(updateValue)
            } else if (updateField === 'A9') {
                self.A9 = parseFloat(updateValue)
            } else if (updateField === 'A10') {
                self.A10 = parseFloat(updateValue)
            } else if (updateField === 'B1') {
                self.B1 = parseFloat(updateValue)
            } else if (updateField === 'B2') {
                self.B2 = parseFloat(updateValue)
                self.B5 = self.B2 + self.B3
            } else if (updateField === 'B3') {
                self.B3 = parseFloat(updateValue)
                self.B5 = self.B2 + self.B3
            } else if (updateField === 'B4') {
                self.B4 = parseFloat(updateValue)
            } else if (updateField === 'B5') {
                self.B5 = parseFloat(updateValue)
            } else if (updateField === 'B6') {
                self.B6 = parseFloat(updateValue)
            } else if (updateField === 'B7') {
                self.B7 = parseFloat(updateValue)
            } else if (updateField === 'B8') {
                self.B8 = parseFloat(updateValue)
            } else if (updateField === 'B9') {
                self.B9 = parseFloat(updateValue)
            } else if (updateField === 'C1') {
                self.C1 = parseFloat(updateValue)
            } else if (updateField === 'C2') {
                self.C2 = parseFloat(updateValue)
            } else if (updateField === 'C3') {
                self.C3 = parseFloat(updateValue)
            } else if (updateField === 'C4') {
                self.C4 = parseFloat(updateValue)
            } else if (updateField === 'C5') {
                self.C5 = parseFloat(updateValue)
            } else if (updateField === 'C6') {
                self.C6 = parseFloat(updateValue)
            } else if (updateField === 'C7') {
                self.C7 = parseFloat(updateValue)
            } else if (updateField === 'C8') {
                self.C8 = parseFloat(updateValue)
            } else if (updateField === 'C9') {
                self.C9 = parseFloat(updateValue)
            } else if (updateField === 'D1') {
                self.D1 = parseFloat(updateValue)
            } else if (updateField === 'D2') {
                self.D2 = parseFloat(updateValue)
            } else if (updateField === 'D3') {
                self.D3 = parseFloat(updateValue)
            } else if (updateField === 'D4') {
                self.D4 = parseFloat(updateValue)
            } else if (updateField === 'DD1') {
                self.DD1 = parseFloat(updateValue)
            } else if (updateField === 'DD2') {
                self.DD2 = parseFloat(updateValue)
            } else if (updateField === 'DD3') {
                self.DD3 = parseFloat(updateValue)
            } else if (updateField === 'DD4') {
                self.DD4 = parseFloat(updateValue)
            } else if (updateField === 'E') {
                self.E = parseFloat(updateValue)
            }
          }
        }
      })

export default KickCalculatorColumn
