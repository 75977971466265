import { types, Instance, getParent, SnapshotIn } from 'mobx-state-tree'
import {
  adjectives,
  colors,
  uniqueNamesGenerator,
  // NumberDictionary,
  Config,
} from 'unique-names-generator'
import { v4 as uuid } from 'uuid'
import Big from 'big.js'
import { PipeListInstance } from './PipeList'

export type PipeInstance = Instance<typeof Pipe>
export type PipeCreationAttributes = SnapshotIn<typeof Pipe>

export interface TallyPipe extends PipeInstance {
  standLength?: Big
  totalLength: Big
  bhaTotalLength: Big
}

const pipeNameGeneratorConfig: Config = {
  dictionaries: [adjectives, colors, ['pipe']],
  separator: '-',
  style: 'lowerCase',
}

export const pipeNameGen = () => uniqueNamesGenerator(pipeNameGeneratorConfig)

const Pipe = types
  .model('Pipe', {
    id: types.optional(types.identifier, uuid),
    length: types.number,
    serialNumber: types.optional(types.string, pipeNameGen),
    standNumber: types.maybe(types.maybeNull(types.number)),
    comment: types.optional(types.string, ''),
    // pipeList: types.late(
    //   (): IReferenceType<IAnyModelType> => types.reference(PipeList)
    // ),
  })
  .views((self) => ({
    get selected() {
      const list = getParent(self) as PipeListInstance
      const selected = (list.selected.indexOf(self.id) !== -1) as boolean
      return selected
    },
  }))

export default Pipe
