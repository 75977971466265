import { ReactElement } from 'react';

interface Props {
    direction?: string;
  }
  
  export function MetricSpacer({
    direction = 'gridTableSpacerHorizontal',
  }: Props): ReactElement {
    return (
        <div className={direction} />
    );
  }
  