import { ReactElement, useContext, MouseEventHandler } from 'react';
import { RouterContext } from '../Router'
import { useMst } from 'app/store'
import { getLabel } from '../../../utils/helpers'
import { AddActionButton } from '../kit/IconButtons'
import arrow from '../../../assets/images/icons/icon_arrow.svg'

interface TableProps {
  firstName: string | null
  lastName: string | null
  role: string
}

export function TableHeader(props: TableProps): ReactElement {
  const {
    store: { TrendSheet, PipeTally },
  } = useMst()
  const { locationState } = useContext(RouterContext)

  const handleAdd: MouseEventHandler<HTMLButtonElement> = () => {
    TrendSheet?.createEmptyRow((props.firstName || '').concat(' ').concat(props.lastName || ''))
  }


  function slide(direction: string){
    const wrapper = document.getElementById('trendTableWrapper')
    const btn1 = document.getElementById('trendTableScrollControlButton1')
    const btn2 = document.getElementById('trendTableScrollControlButton2')

    let scrollCompleted = 0
    var slideVar = setInterval(function(){
        if(direction === 'left'){
            wrapper!.scrollLeft -= 1000
            btn1!.style.position = 'relative'
            btn1!.style.left = '0px'
            btn2!.style.position = 'relative'
            btn2!.style.left = '0px'
        } else {
            wrapper!.scrollLeft += 1000
            btn1!.style.position = 'relative'
            btn1!.style.left = '473px'
            btn2!.style.position = 'relative'
            btn2!.style.left = '473px'
        }
        scrollCompleted += 100;
        if(scrollCompleted >= 100){
            window.clearInterval(slideVar)
        }
    }, 50)
}

  return (
    <div id="trendTableHeader" className="grid-header" style={{
      position: 'sticky',
      top: '40',
      overflow: 'hidden',
    }}>

      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <div id="trendTableScrollControls" style={{
          width: '100%',
          backgroundColor: '#42435c',
        }}>
          <span id="doNotPrintWrapper">
            <div id="trendTableScrollControlButtons" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <button
                  id="trendTableScrollControlButton1"
                  className="scrollButtons"
                  type="button"
                  onClick={() => slide('left')}
                  >
                    <img alt="scroll left" src={arrow} className="flipImage" style={{ height: '15px', width: '40px' }} />
                </button>
              </div>
              <div style={{ textAlign: 'center', padding: '10px', paddingRight: '15px' }}>
                <button
                  id="trendTableScrollControlButton2"
                  className="scrollButtons"
                  type="button"
                  onClick={() => slide('right')}
                >
                  <img alt="scroll right" src={arrow} style={{ height: '15px', width: '40px' }} />
                </button>
              </div>
            </div>
          </span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="grid-header-container">
            <div className="grid-item grid-item--header grid-first-column" style={{
              gridColumn: '1/2',
              gridRow: '1/3',
              left: '0px',
              top: 0,
              position: 'sticky',
              backgroundColor: '#42435c',
              color: '#fff',
              zIndex: 2,
              textAlign: 'center'
            }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'left',
                  justifyContent: 'left',
                }}
              >
                {(PipeTally?.editingMode === 'RIH' && (props.role === 'Driller' || props.role === 'Support' || props.role === 'Complete')) ? (
                    <AddActionButton
                      style={{ margin: '-3px 4px 0px 12px' }}
                      onClick={handleAdd}
                    />
                  ) : ' '
                }
              </div>
            </div>
            <div className="grid-item grid-item--header grid-first-column" style={{
              gridColumn: '2/3',
              gridRow: '1/3',
              left: '30px',
              top: 0,
              position: 'sticky',
              backgroundColor: '#42435c',
              color: '#fff',
              zIndex: 2
            }}>
              Time
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '3/4', gridRow: '1/3' }}>
              User
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '4/5', gridRow: '1/3' }}>
              Depth<br/>&nbsp;MD ({getLabel('lengthMedium', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '5/6', gridRow: '1/3' }}>
              Depth TVD ({getLabel('lengthMedium', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '6/7', gridRow: '1/3' }}>
              ROP <br />({getLabel('distanceTime', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '7/8', gridRow: '1/3' }}>
              WOB <br />({getLabel('weightLarge', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '8/9', gridRow: '1/3' }}>
              RPM
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '9/10', gridRow: '1/3' }}>
              Torq. ({getLabel('torque', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '10/11', gridRow: '1/3' }}>
              Press. On ({getLabel('pressure', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '11/12', gridRow: '1/3' }}>
              Press. Off ({getLabel('pressure', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '12/13', gridRow: '1/3' }}>
              SPM
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '13/14', gridRow: '1/3' }}>
              Flow In ({getLabel('volumeTime', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '14/15', gridRow: '1/3' }}>
              Flow Out (%)
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '15/16', gridRow: '1/3', whiteSpace: 'nowrap' }}>
              Active<br/>({getLabel('volume', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '16/17', gridRow: '1/3' }}>
              MW In ({getLabel('weightVolumeSg', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '17/18', gridRow: '1/3' }}>
              MW Out ({getLabel('weightVolumeSg', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '18/19', gridRow: '1/3' }}>
              ECD<br/>({getLabel('weightVolumeSg', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '19/20', gridRow: '1/3' }}>
              Gas<br />(%)
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '20/21', gridRow: '2/3', whiteSpace: 'nowrap' }}>
              Up<br /> ({getLabel('weightLarge', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '21/22', gridRow: '2/3' }}>
              Free Rot. ({getLabel('weightLarge', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '22/23', gridRow: '2/3' }}>
              Down ({getLabel('weightLarge', locationState)})
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '23/24', gridRow: '1/3' }}>
              Azimuth (deg)
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '24/25', gridRow: '1/3' }}>
              Inclination (deg)
            </div>
            <div className="grid-item grid-item--header"  style={{ gridColumn: '25/26', gridRow: '1/3' }}>
              Comment
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
