import {Instance,types,SnapshotIn,destroy} from "mobx-state-tree"
import { v4 as uuid } from "uuid"
import KickCalculatorColumn from "./KickCalculatorColumn"

export type KickCalculatorInstance = Instance<typeof KickCalculator>;
export type KickCalculatorSetAttributes = SnapshotIn<typeof KickCalculator>;
export type KickCalculatorColumnCreationArgs = SnapshotIn<typeof KickCalculatorColumn>;

export type CreateKickCalculatortInput = {
  id: string;
}

export const KickCalculator = types
  .model("KickCalculator", {
    id: types.optional(types.identifier, uuid),
    well: types.optional(types.string, ""),
    wellNumber: types.optional(types.string, ""),
    location: types.optional(types.string, ""),
    type: types.optional(types.string, ""),
    units: types.optional(types.string, ""),
    ramBop: types.optional(types.number, 0),
    waterDepth: types.optional(types.number, 0),
    rkbToWater: types.optional(types.number, 0),
    client: types.optional(types.string, ""),
    preparedBy: types.optional(types.string, ""),
    checkedBy: types.optional(types.string, ""),
    columns: types.optional(types.array(KickCalculatorColumn), [{ index: 1 }]),
  })
  .views((self) => ({
    getKickCalculatorColumn(id: string) {
      return self.columns.find(({ id: ID }) => ID === id)
    },

    getKickCalculatorColumnByIndex(index: number) {
      return self.columns.find(({ index: INDEX }) => INDEX === index)
    },
  }))
  .actions((self) => {
    return {
      updateKickCalculator(id: string, field: any, fieldType: string, value: string) {
          const updateFieldType: string = fieldType
          const updateField: typeof updateFieldType = field
          const updateValue = value

          if (updateField === 'id') {
              self.id = updateValue
          } else if (updateField === 'well') {
              self.well = updateValue
          } else if (updateField === 'wellNumber') {
              self.wellNumber = updateValue
          } else if (updateField === 'location') {
              self.location = updateValue
          } else if (updateField === 'type') {
              self.type = updateValue
          } else if (updateField === 'units') {
              self.units = updateValue
          } else if (updateField === 'ramBop') {
              self.ramBop = parseFloat(updateValue)
          } else if (updateField === 'waterDepth') {
              self.waterDepth = parseFloat(updateValue)
          } else if (updateField === 'rkbToWater') {
              self.rkbToWater = parseFloat(updateValue)
            } else if (updateField === 'client') {
              self.client = updateValue
          } else if (updateField === 'preparedBy') {
              self.preparedBy = updateValue
          } else if (updateField === 'checkedBy') {
              self.checkedBy = updateValue
          }

          // column.recalculateColumn()
      },

      updateKickCalculatorModel(id: string, field: any, fieldType: string, value: string, units: string) {
        const column: any = self.columns.find(({ id: ID }) => ID === id)
        if (column) {

          const updateFieldType: string = fieldType
          const updateField: typeof updateFieldType = field
          const updateValue = value

          if (updateFieldType === 'string') {
            column[updateField] = updateValue
          } else if (updateFieldType === 'number') {
            column[updateField] = Number(updateValue)
          } else if (updateFieldType === 'boolean') {
            column[updateField] = updateValue === 'true'
          } else if (updateFieldType === 'array') {
            column[updateField] = updateValue.split(',')
          } else if (updateFieldType === 'object') {
            column[updateField] = JSON.parse(updateValue)
          } else if (updateFieldType === 'date') {
            column[updateField] = new Date(updateValue)
          } else if (updateFieldType === 'enum') {
            column[updateField] = updateValue
          } else if (updateFieldType === 'reference') {
            column[updateField] = updateValue
          } else {
            column[updateField] = updateValue
          }

          column.recalculateColumn(units)
          // console.log('units', units)
        }
      },

      clearKickCalculatorColumns() {
        destroy(self.columns)
      },

      createEmptyColumn() {
        self.columns.push(
          KickCalculatorColumn.create({
            index: self.columns[self.columns.length - 1].index + 1,
          })
        )
      },

      deleteFirstColumn() {
        self.columns.shift()
      },

    }
  })

export type KickCalculatorColumnInstance = Instance<typeof KickCalculatorColumn>