import Table from './Table'
import Settings from './Settings'
import PrintInfo from 'app/components/kit/PrintInfo'
import { SectionTabs } from '../WellSection/SectionTabs'
import './pipeTally.css'

const LinerTally = () => {
  return (
    <>
      <SectionTabs />
      <PrintInfo />
      <Settings />
      <Table />
    </>
  )
}

export default LinerTally
