import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { ChildrenProps } from 'utils/helpers'
import { ReactComponent as AddIcon } from './icons/PlusCircleBlue.svg'
import './SmallWidthButton.css'

export const ImportButton = (props: JSXButtonProps & { title?: string }) => {
  return (
    <span {...props} className='btn import' style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginRight: '6px' }}>
        {props.title ? props.title : 'Import'}
      </div>
      <div><AddIcon width='17px' /></div>
    </span>
  )
}

export const AddButton = ({
  title,
  ...props
}: JSXButtonProps & { title: string }) => {
  return (
    <button {...props} className='btn import'>
      <div style={{ marginRight: '6px' }}>{title}</div>
      <AddIcon width='17px' />
    </button>
  )
}

export const Button = ({
  children,
  small = false,
  secondary = false,
  ...props
}: JSXButtonProps & ChildrenProps) => {
  return (
    <button
      {...props}
      className={`button ${small ? 'small' : ''} ${
        secondary ? 'secondary' : ''
      }`}
    >
      {children}
    </button>
  )
}

type JSXButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  small?: boolean
  secondary?: boolean
  disabled?: boolean
}
