/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  no-extend-native*/
declare global {
  interface Array<T> {
    clear: () => void
  }
}

Array.prototype.clear = function () {
  this.length = 0
}

export {}
