import { Instance, types, SnapshotIn, destroy, getRoot, isAlive } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import TripSheetRow from './TripSheetRow'
import { MainStoreInstance } from 'app/store'
import { getStatistic } from 'utils/helpers'

export type TripSheetInstance = Instance<typeof TripSheet>
export type TripSheetSetAttributes = SnapshotIn<typeof TripSheet>
export type TripSheetRowCreationArgs = SnapshotIn<typeof TripSheetRow>

export type CreateTripSheetInput = {
  name?: string;
  diameter?: string
}
const TripSheet = types
  .model('TripSheet', {
    id: types.optional(types.identifier, uuid),
    stub: types.optional(types.number, 0),
    rows: types.optional(types.array(TripSheetRow), [{ index: 1 }]),
    volslug: types.optional(types.number, 0),
    mwslug: types.optional(types.number, 0),
    mw: types.optional(types.number, 0),
    discrepancyAlarm: types.optional(types.number, 0),
  })
  .views((self) => ({
    getTripSheetRow(id: string) {
      return self.rows.find(({ sourceId: SOURCEID }) => SOURCEID === id)
    },

    getTripSheetRowByIndex(index: number) {
      return self.rows.find(({ index: INDEX }) => INDEX === index)
    },
    // getLastCheckedRow() {
    //   self.rows.filter(e => e['emptyFill'] === 'true')
    //   return 0
    // }
  }))
  .actions((self) => {
    return {
      createTripSheetRow(args: TripSheetRowCreationArgs) {
        self.rows.push(TripSheetRow.create(args))
      },

      markDeleted(id: string) {
        const row = self.rows.find(({ id: ID }) => ID === id)
        if (row) {
          if (isAlive(row)) {
            row.deleted = true
          }
        }
      },

      updateTripSheetRow(
        id: string,
        index: number,
        tt1: number,
        tt2: number,
        openClosed: string,
        tsComment: string,
      ) {
        const row = self.rows.find(({ id: ID }) => ID === id)
        if (row) {
          row.id = id
          row.index = index
          row.tt1 = tt1
          row.tt2 = tt2
          row.openClosed = openClosed
          row.tsComment = tsComment
        }
      },

      deleteTripSheetRow(id: string) {
        const deleteKey = self.rows.findIndex(({ id: ID }) => ID === id)
        if (deleteKey !== -1) {
          self.rows.splice(deleteKey, 1)
        } else {
          console.log('deleteTripSheetRow: row not found')
        }
      },

      deleteTripSheetRows(category: string) {
        const deleteKey = self.rows.find(({ source: CATEGORY }) => CATEGORY === category)
        if (deleteKey) {
          self.rows.splice(deleteKey.index)
        } else {
          console.log('deleteTripSheetRow: category not found')
        }
      },

      clearTripSheetRows() {
        self.stub = 0
        destroy(self.rows)
      },

      setTripSheetStub(quantity: number) {
        if (quantity !== self.stub) {
          self.stub = quantity
          self.rows.forEach((row) => {
            row.tt1 = quantity
            row.tt2 = 0
            row.measHoleIncrem = 0
            row.measHoleAccum = 0
          })
        }
      },

      updateVolSlug(newVal: number) {
        self.volslug = newVal
      },

      updateMwSlug(newVal: number) {
        self.mwslug = newVal
      },

      updateDiscrepancyAlarm(newVal: number) {
        self.discrepancyAlarm = newVal
      },

      updateMw(newVal: number) {
        self.mw = newVal
      },

      createEmptyRow() {
        self.rows.push(
          TripSheetRow.create({
            index: self.rows[self.rows.length - 1].index + 1,
          })
        )
      },

      setTsComment(sourceId: string, comment: string) {
        const row = self.rows.find(({ sourceId: SOURCEID }) => SOURCEID === sourceId)
        if (row) {
          row.tsComment = comment
        }
      },

      setEmptyFill(id: string, emptyFill: string) {
        const row = self.rows.find(({ id: ID }) => ID === id)
        if (row) {
          row.emptyFill = emptyFill
        }
      },

      setTripTanks(id: string, tank: number, quantity: number) {
        const row = self.rows.find(({ id: ID }) => ID === id)
        let priorTankValue = 0
        let originalAlternateTank = 0

        if (isNaN(quantity)) {
          quantity = 0
        }

        if (row) {
          // set this rows value
          if (tank === 1) {
            row.tt1 = quantity
            if (isNaN(row.tt2)) {
              row.tt2 = 0
            }
          } else if (tank === 2) {
            row.tt2 = quantity
            if (isNaN(row.tt1)) {
              row.tt1 = 0
            }
          }

          const mem = (getRoot(self) as MainStoreInstance)

          for (let i = 0; i < self.rows.length; i++) {

            if (self.rows[i].index === row.index) {
              // this is the edited row

              // record orginal alternate tank for use on following row
              if (tank === 1) {
                originalAlternateTank = self.rows[i].tt2
              } else if (tank === 2) {
                originalAlternateTank = self.rows[i].tt1
              }

              if (i === 0) {
                if (originalAlternateTank === 0) {
                  self.rows[i].measHoleIncrem = quantity - self.stub
                  self.rows[i].measHoleAccum = quantity - self.stub
                }
                self.rows[i].discIncrem = quantity - self.stub - self.rows[i].calcIncrem
                self.rows[i].discAccum = quantity - self.stub - self.rows[i].calcIncrem
              } else {
                if (tank === 1) {
                  if (self.rows[i - 1].tt1 !== 0) {
                    self.rows[i].measHoleIncrem = quantity - self.rows[i - 1].tt1
                    self.rows[i].measHoleAccum = quantity - self.rows[i - 1].tt1 + self.rows[i - 1].measHoleAccum
                    self.rows[i].discIncrem = quantity - self.rows[i - 1].tt1 - self.rows[i].calcIncrem
                    self.rows[i].discAccum = quantity - self.rows[i - 1].tt1 - self.rows[i].calcIncrem + self.rows[i - 1].discAccum
                  }
                  // TODO: what if editing current row tt1 and prior row tt1 value is 0 - recalc MHI (possible use case?)
                } else if (tank === 2) {
                  if (self.rows[i - 1].tt2 !== 0) {
                    self.rows[i].measHoleIncrem = quantity - self.rows[i - 1].tt2
                    self.rows[i].measHoleAccum = quantity - self.rows[i - 1].tt2 + self.rows[i - 1].measHoleAccum
                    self.rows[i].discIncrem = quantity - self.rows[i - 1].tt2 - self.rows[i].calcIncrem
                    self.rows[i].discAccum = quantity - self.rows[i - 1].tt2 - self.rows[i].calcIncrem + self.rows[i - 1].discAccum
                  }
                  // TODO: what if editing current row tt2 and prior row tt2 value is 0 - recalc MHI (possible use case?)
                }
              }
            } else if (
              mem.PipeTally?.editingMode === 'RIH' ?
                self.rows[i].index > row.index :
                self.rows[i].index < row.index
              ) {
              // following rows - waterfall
              if (tank === 1) {

                // set primary tank
                self.rows[i].tt1 = quantity

                // set alternate tank
                if (self.rows[i].tt1 === 0) {
                  self.rows[i].tt2 = originalAlternateTank
                } else if (priorTankValue === 0) {
                  self.rows[i].tt2 = 0
                }

                if (self.rows[i - 1].tt1 !== 0) {
                  self.rows[i].measHoleIncrem = quantity - self.rows[i - 1].tt1
                  self.rows[i].measHoleAccum = quantity - self.rows[i - 1].tt1 + self.rows[i - 1].measHoleAccum
                  self.rows[i].discIncrem = quantity - self.rows[i - 1].tt1 - self.rows[i].calcIncrem
                  self.rows[i].discAccum = quantity - self.rows[i - 1].tt1 - self.rows[i].calcIncrem + self.rows[i - 1].discAccum
                } else {
                  self.rows[i].measHoleIncrem = self.rows[i].tt1 - self.rows[i - 1].tt1
                  self.rows[i].measHoleAccum = self.rows[i].tt1 - self.rows[i - 1].tt1 + self.rows[i - 1].measHoleAccum
                  self.rows[i].discIncrem = self.rows[i].tt1 - self.rows[i - 1].tt1 - self.rows[i].calcIncrem
                  self.rows[i].discAccum = self.rows[i].tt1 - self.rows[i - 1].tt1 - self.rows[i].calcIncrem + self.rows[i - 1].discAccum
                }
              } else if (tank === 2) {

                // set primary tank
                self.rows[i].tt2 = quantity

                // set alternate tank
                if (self.rows[i].tt2 === 0) {
                  self.rows[i].tt1 = originalAlternateTank
                } else if (priorTankValue === 0) {
                  self.rows[i].tt1 = 0
                }

                if (self.rows[i - 1].tt2 !== 0) {
                  self.rows[i].measHoleIncrem = quantity - self.rows[i - 1].tt2
                  self.rows[i].measHoleAccum = quantity - self.rows[i - 1].tt2 + self.rows[i - 1].measHoleAccum
                  self.rows[i].discIncrem = quantity - self.rows[i - 1].tt2 - self.rows[i].calcIncrem
                  self.rows[i].discAccum = quantity - self.rows[i - 1].tt2 - self.rows[i].calcIncrem + self.rows[i - 1].discAccum
                } else {
                  self.rows[i].measHoleIncrem = self.rows[i].tt2 - self.rows[i - 1].tt2
                  self.rows[i].measHoleAccum = self.rows[i].tt2 - self.rows[i - 1].tt2 + self.rows[i - 1].measHoleAccum
                  self.rows[i].discIncrem = self.rows[i].tt2 - self.rows[i - 1].tt2 - self.rows[i].calcIncrem
                  self.rows[i].discAccum = self.rows[i].tt2 - self.rows[i - 1].tt2 - self.rows[i].calcIncrem + self.rows[i - 1].discAccum
                }
              }
            }
          }
        }
      },

      setOpenClosedWaterfall(
        locationState: any,
        index: number,
        openClosed: string,
      ) {
        let currentStandLength = 0

        let priorRow = TripSheetRow.create({
          index: 9999999,
          tt1: self.stub,
          tt2: 0,
          measHoleIncrem: 0,
          measHoleAccum: 0,
          calcIncrem: 0,
          calcAccum: 0,
          discIncrem: 0,
          discAccum: 0
        })

        const mem = (getRoot(self) as MainStoreInstance)

        for (let i = 0; i < self.rows.length; i++) {
          // only this row and waterfall forward
          if (
              mem.PipeTally?.editingMode === 'RIH' ?
              index <= self.rows[i].index :
              index >= self.rows[i].index
          ) {
              // if no prior row use the default above
              if (i > 0) {
                priorRow = {...self.rows[i - 1]}
              }

              self.rows[i].openClosed = openClosed

              if (self.rows[i].index < 2000000) {
                // bha only
                currentStandLength = self.rows[i].length

                self.rows[i].calcIncrem = currentStandLength
                * (self.rows[i].openClosed === 'open' ?
                  self.rows[i].weight / Number(getStatistic('bhaDispMultiplier', locationState))
                  : (self.rows[i].ID * self.rows[i].ID) /  Number(getStatistic('bhaCapMultiplierBha', locationState))
                )
                * (mem.PipeTally?.editingMode === 'POOH' ? -1 : 1)

              } else {
                // tallies
                currentStandLength = parseFloat(self.rows[i].comment.split(',')[6])

                self.rows[i].calcIncrem = currentStandLength
                * (self.rows[i].openClosed === 'open' ?
                  self.rows[i].disp * Number(getStatistic('hybridizeVolumeImpToMet', locationState))
                  : (self.rows[i].disp + self.rows[i].cap) * Number(getStatistic('hybridizeVolumeImpToMet', locationState))
                )
                * (mem.PipeTally?.editingMode === 'POOH' ? -1 : 1)
              }

              self.rows[i].calcAccum = self.rows[i].calcIncrem + priorRow.calcAccum
              self.rows[i].discIncrem = self.rows[i].measHoleIncrem - self.rows[i].calcIncrem
              self.rows[i].discAccum = self.rows[i].measHoleAccum - self.rows[i].calcAccum
          }
        }
      },

      calculateInitialCalcIncrems(locationState: any) {
        let currentStandLength = 0

        let priorRow = TripSheetRow.create({
          index: 9999999,
          tt1: self.stub,
          tt2: 0,
          measHoleIncrem: 0,
          measHoleAccum: 0,
          calcIncrem: 0,
          calcAccum: 0,
          discIncrem: 0,
          discAccum: 0
        })

        const mem = (getRoot(self) as MainStoreInstance)

        for (let i = 0; i < self.rows.length; i++) {
          // if no prior row use the default above
          if (i > 0) {
            priorRow = {...self.rows[i - 1]}
          }

          if (self.rows[i].index < 2000000) {
            // bha only
            currentStandLength = self.rows[i].length

            self.rows[i].calcIncrem = currentStandLength
            * (self.rows[i].openClosed === 'open' ?
              self.rows[i].weight / Number(getStatistic('bhaDispMultiplier', locationState))
              : (self.rows[i].ID * self.rows[i].ID) /  Number(getStatistic('bhaCapMultiplierBha', locationState))
            )
            * (mem.PipeTally?.editingMode === 'POOH' ? -1 : 1)

          } else {
            // tallies
            currentStandLength = parseFloat(self.rows[i].comment.split(',')[6])

            self.rows[i].calcIncrem = currentStandLength
            * (self.rows[i].openClosed === 'open' ?
              self.rows[i].disp * Number(getStatistic('hybridizeVolumeImpToMet', locationState))
              : (self.rows[i].disp + self.rows[i].cap) * Number(getStatistic('hybridizeVolumeImpToMet', locationState))
            )
            * (mem.PipeTally?.editingMode === 'POOH' ? -1 : 1)
          }

          self.rows[i].calcAccum = self.rows[i].calcIncrem + priorRow.calcAccum
          self.rows[i].discIncrem = self.rows[i].measHoleIncrem - self.rows[i].calcIncrem
          self.rows[i].discAccum = self.rows[i].measHoleAccum - self.rows[i].calcAccum
        }
      },

      reverseTripSheet() {
        self.rows.reverse()
      },

      reverseTripSheetTallyRows(type: string) {
        self.rows.splice(
          self.rows.findIndex(row => row.source=== type),  //start
          self.rows.filter(e => e['source'] === type).length, // number to affect
          ...self.rows.slice(self.rows.findIndex(row => row.source=== type), self.rows.findIndex(row => row.source=== type) + self.rows.filter(e => e['source'] === type).length).reverse() // start, end
        )
      },

      recalculateTripSheet() {
        for (let i = 0; i < self.rows.length; i++) {
          if (i > 0) {
            self.rows[i].measHoleIncrem = (
              self.rows[i].tt1 >= self.rows[i].tt2 ?
                self.rows[i].tt1 :
                  self.rows[i].tt2) - (self.rows[i - 1].tt1 >= self.rows[i - 1].tt2 ?
                  self.rows[i - 1].tt1 :
                self.rows[i - 1].tt2
            )
          }
        }
        for (let i = 0; i < self.rows.length; i++) {
          if (i > 0) {
            self.rows[i].measHoleAccum = self.rows[i - 1].measHoleIncrem +
              (self.rows[i].tt1 >= self.rows[i].tt2 ?
                self.rows[i].tt1 :
                self.rows[i].tt2
              )
              - (self.rows[i - 1].tt1 >= self.rows[i - 1].tt2 ?
                self.rows[i - 1].tt1 :
                self.rows[i - 1].tt2
              )
            self.rows[i].calcAccum = self.rows[i].discIncrem + self.rows[i - 1].discAccum
          }
        }
      },

      setIncrementals(
        index: number,
        measHoleIncrem: number,
        discIncrem: number,
      ) {
        const row = self.rows.find(({ index: INDEX }) => INDEX === index)
        if (row) {
          row.measHoleIncrem = measHoleIncrem
          row.discIncrem = discIncrem
        }
      },

      setAccumulations(
        index: number,
        measHoleAccum: number,
        calcAccum: number,
        discAccum: number
      ) {
        const row = self.rows.find(({ index: INDEX }) => INDEX === index)
        if (row) {
          row.measHoleAccum = measHoleAccum
          row.calcAccum = calcAccum
          row.discAccum = discAccum
        }
      }
    }
  })

export type TripSheetRowInstance = Instance<typeof TripSheetRow>

export default TripSheet
