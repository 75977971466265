import Units from '../../../app/store/Units.json'

type KickCalculatorToolHeaderProps = {
    settingsType: any
    settingsUnits: any
}

const KickCalculatorToolHeader = ((props:KickCalculatorToolHeaderProps) => {

    function getLabel(key: string, measure: string) {
        const conversion = Units[Units.findIndex(({ handle }) => handle === key)]
        if (conversion) {
            if (measure=== 'imperial') {
              return conversion.impLabel
            }
            else if (measure === 'metric') {
              return conversion.metLabel
            }
            else if (measure === 'hybrid') {
              return conversion.hybLabel
            }
        }
        return ''
      }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '300px', maxWidth: '300px', color: 'silver', fontSize: '13px' }}>
                <div className="kickCalculatorHeader">DATA</div>
                <div className="kickCalculatorSubHeader">Well Geometry and Tubulars</div>
                <div className="kickCalculatorColumnHeadings">Open Hole Diameter</div>
                <div className="kickCalculatorColumnHeadings">Total Measured Depth - TMD ({getLabel('lengthMedium', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Total True Vertical Depth ({getLabel('lengthMedium', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Casing Shoe Measured Depth ({getLabel('lengthMedium', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Casing Shoe True Vertical Depth ({getLabel('lengthMedium', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Inclination at Weak Point (deg)</div>
                <div className="kickCalculatorColumnHeadings">Inclination at Reservoir (deg)</div>
                <div className="kickCalculatorColumnHeadings">BHA Length ({getLabel('lengthMedium', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">BHA OD (in)</div>
                <div className="kickCalculatorColumnHeadings">Drill Pipe OD (in)</div>

                <div className="kickCalculatorSubHeader">Pressure Data</div>
                <div className="kickCalculatorColumnHeadings">Expected Hydrocarbon Zone</div>
                <div className="kickCalculatorColumnHeadings">Kick Intensity - above mud weight ({getLabel('weightVolume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Planned Mud Weight at TD ({getLabel('weightVolume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Fracture Pressure Gradient at Shoe ({getLabel('weightVolume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Maximum Pore Pressure Gradient at TD ({getLabel('weightVolume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Assumed Gradient of the Influx ({getLabel('weightVolume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">MAASP Margin ({getLabel('pressure', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">MAASP Margin, above Fracture Gradient ({getLabel('weightVolume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Ratio influx/mud for partial evacuation (%)</div>

                <div className="kickCalculatorHeader" style={{ marginTop: '10px'}}>RESULTS</div>
                <div className="kickCalculatorSubHeader">MAASP and MASP/MAWHP</div>
                <div className="kickCalculatorColumnHeadings">MAASP ({getLabel('pressure', props.settingsUnits)})</div>
                <div className={props.settingsType === 'floater' ? 'invisible' : 'kickCalculatorColumnHeadings' }>MASP, based on full evacuation ({getLabel('pressure', props.settingsUnits)})</div>
                <div className={props.settingsType === 'floater' ? 'invisible' : 'kickCalculatorColumnHeadings' }>MASP, based on partial evacuation ({getLabel('pressure', props.settingsUnits)})</div>
                <div className={props.settingsType === 'floater' ? 'invisible' : 'kickCalculatorColumnHeadings' }>MASP, based on fracture at shoe ({getLabel('pressure', props.settingsUnits)})</div>
                <div className={props.settingsType === 'floater' ? 'kickCalculatorColumnHeadings' : 'invisible' }>MAWHP, based on full evacuation ({getLabel('pressure', props.settingsUnits)})</div>
                <div className={props.settingsType === 'floater' ? 'kickCalculatorColumnHeadings' : 'invisible' }>MAWHP, based on partial evacuation ({getLabel('pressure', props.settingsUnits)})</div>
                <div className={props.settingsType === 'floater' ? 'kickCalculatorColumnHeadings' : 'invisible' }>MAWHP, based on fracture at shoe ({getLabel('pressure', props.settingsUnits)})</div>
                <div className={props.settingsType === 'floater' ? 'invisible' : 'kickCalculatorColumnHeadings' }>MASP retained ({getLabel('pressure', props.settingsUnits)})</div>
                <div className={props.settingsType === 'floater' ? 'kickCalculatorColumnHeadings' : 'invisible' }>MAWHP retained ({getLabel('pressure', props.settingsUnits)})</div>

                <div className="kickCalculatorSubHeader">Kick Tolerance</div>
                <div className="kickCalculatorSectionHeader">At Kick Intensity = 0</div>
                <div className="kickCalculatorColumnHeadings">Maximum Influx Height - H max ({getLabel('lengthMedium', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Kick Tolerance at Initial Shut In ({getLabel('volume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Kick Tolerance when influx is at shoe ({getLabel('volume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Kick Tolerance - Lesser of above values ({getLabel('volume', props.settingsUnits)})</div>

                <div className="kickCalculatorSectionHeader">Assumed Kick Intensity ({getLabel('volume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Maximum Influx Height - H max ({getLabel('lengthMedium', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Kick Tolerance at Initial Shut In ({getLabel('volume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Kick Tolerance when influx is at shoe ({getLabel('volume', props.settingsUnits)})</div>
                <div className="kickCalculatorColumnHeadings">Kick Tolerance - Lesser of above values ({getLabel('volume', props.settingsUnits)})</div>

                <div className="kickCalculatorSectionHeader">Safe Drilling Margin</div>
                <div className="kickCalculatorColumnHeadings">Planned Safe Drilling Margin ({getLabel('weightVolume', props.settingsUnits)})</div>
            </div>
        </div>
    )
})

export default KickCalculatorToolHeader