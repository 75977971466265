import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'
import { LOGOUT } from 'api'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { makeStyles } from '@mui/styles'
import { theme } from '../kit/styles/theme'
import { UndoButtons } from '../kit/UndoButtons'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import {
  ChildrenProps,
  JSXElementProps,
  underscoreToSpace,
} from 'utils/helpers'
import endeavorLogo from '../kit/icons/EndeavorLogo.svg'
import { getCompany, getRig } from 'utils/helpers'
// import { clearCache } from 'utils/cacheClear'
import { useContext, useState, useEffect, Fragment, forwardRef } from 'react'
import { UserContext } from 'App'
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserFields } from 'api/fragments'
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'
import Menu from './Menu'
import { ChipTab } from '../kit/Tabs'
import { ReactComponent as SaveIcon } from '../kit/icons/Save.svg'
import icon_home from '../../../assets/images/icons/icon_home.svg'
import icon_print from '../../../assets/images/icons/icon_print.svg'
import icon_wifi_on from '../../../assets/images/icons/icon_wifi_on.svg'
import icon_wifi_off from '../../../assets/images/icons/icon_wifi_off.svg'
// import logoOperator from '../../../assets/images/logos/logoOperatorGrey.png'
import { Toast } from '../kit/Toast'
import { AlertProps } from '@mui/material/Alert';
import { HEALTH_CHECK, HEALTH_CHECK_INTERVAL, STATUS_CHECK, STATUS_INTERVAL } from 'app/constants/common'

const useStyles = makeStyles({
  Breadcrumbs: {
    fontWeight: 600,
    fontSize: 'min(max(16px, 1.5vw), 22px)',
    color: theme.colors.text.primary,
    lineHeight: '46px',
    width: '100%',
  },
  contentBox: {
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 72px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: theme.colors.text.primary,
  },
  paper: {
    backgroundColor: theme.colors.well.bg.secondary,
    padding: theme.spacing(1),
    borderRadius: '24px',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignContent: 'center',
    width: 250,
  },
})

const routes: BreadcrumbsRoute[] = [
  {
    path: '/all-wells',
    breadcrumb: function AllWellsBread() {
      const router = useNavigate()
      return (
        <span
          className="clickable_breadcrump"
          onClick={() => router(`/all-wells`)}
        >
          / All Wells
        </span>
      )
    },
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/pipe-tally',
    breadcrumb: function PipeTallyBC() {
      return (
        <span>
            Pipe Tally
        </span>
      )
    },
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/liner-tally',
    breadcrumb: 'Liner Tally',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/casing-tally',
    breadcrumb: 'Casing Tally',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/bha',
    breadcrumb: 'BHA',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/bop',
    breadcrumb: 'BOP',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/trip-sheet',
    breadcrumb: 'Trip Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/trend-sheet',
    breadcrumb: 'Trend Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/overpull-sheet',
    breadcrumb: 'Overpull Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/kill-sheet',
    breadcrumb: 'Kill Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/kill-graph',
    breadcrumb: 'Kill Chart',
  },
  { path: '/', breadcrumb: null },
  {
    path: '/all-wells/:wellId',
    breadcrumb: function Bready({ match, id }: any) {
      const router = useNavigate()
      return (
        <span
          className="clickable_breadcrump"
          onClick={() => router(`/all-wells/${match.params.wellId}`)}
          title={underscoreToSpace(match.params.wellId)}
        >
          {trimString(underscoreToSpace(match.params.wellId), 14)}
        </span>
      )
    },
  },
  {
    path: '/all-wells/:wellId/:wellSectionId',
    breadcrumb: ({ match }: any) => (
      <span title={underscoreToSpace(match.params.wellSectionId)}>{trimString(underscoreToSpace(match.params.wellSectionId), 14)}</span>
    ),
  },
]

function trimString(string: string, chars: number) {
  if (string.length > chars) {
    return string.substring(0, chars) + '...'
  } else {
    return string
  }
}

export const Breadcrumbs = ({fname = ''}, props: JSXElementProps<HTMLDivElement>) => {
  const location = useLocation()
  const uri = location.pathname
  const cn = useStyles()
  const breadcrumbs = useBreadcrumbs(routes)
  const router = useNavigate()

  return (
    <div {...props} className={cn.Breadcrumbs}>
      {breadcrumbs.map(({ breadcrumb, match }, i, arr) => {
        const dash =
          arr.length > 2
            ? i > 1 && i <= arr.length - 1
              ? ' / '
              : null
            : i > 0 && i <= arr.length - 1
            ? ' / '
            : null

        if (arr.length === 3 && i === 0) {
          return (
            <span
              key={i}
              style={{ cursor: 'pointer' }}
              onClick={() => router(match.pathname)}
            >
              ... /
            </span>
          )
        }

        if (arr.length === 4 && i === 1) {
          return (
            <span
              key={i}
              style={{ cursor: 'pointer' }}
              onClick={() => router(match.pathname)}
            >
              ... /
            </span>
          )
        }

        if (arr.length === 4 && i === 0) {
          return (
            <span
              key={i}
              style={{ cursor: 'pointer' }}
              onClick={() => router(match.pathname)}
            >
              ... /
            </span>
          )
        }

        if (arr.length === 4 && i === 2) {
          return (
            <span
              key={i}
              style={{ cursor: 'pointer' }}
              onClick={() => router(match.pathname)}
            >
              {breadcrumb}
            </span>
          )
        }

        if (match.pathname === '/all-wells' && arr.length > 2) {
          return null
        }

        if (match.pathname === '/dashboard') {
          return (
            <span key={i}>
              <span
                className="dashboardHeaderWelcome"
              >
                Welcome back
              </span>
              <span
                className="dashboardHeaderName"
              >
                {fname}
              </span>
            </span>
          )
        }

        if (i === arr.length - 1) {
          return (
            <span key={i}>
              {uri !== '/all-wells' ? <span> / </span> : null}
              <span
                style={{ color: 'dodgerblue' }}
              >
                {breadcrumb}
              </span>
            </span>
          )
        }

        else
        return (
            <Fragment key={i}>
              <span key={i}>
                {dash}
                {breadcrumb}
                {/* {trimString((breadcrumb?.valueOf().toString() || '').valueOf(), 20)} */}
              </span>
            </Fragment>
          )
      })}
    </div>
  )
}

export const Paper = forwardRef<
  HTMLDivElement,
  JSXElementProps<HTMLDivElement> & ChildrenProps
>(({ children, ...props }, ref) => {
  const cn = useStyles()
  return (
    <div ref={ref} {...props} className={cn.paper}>
      {children}
    </div>
  )
})

const SplashScreen = () => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    }}
  >
    <img src={endeavorLogo} style={{ height: 60 }} alt="website logo" />
  </div>
)

export const UserInfo = ({
  user,
  ...props
}: JSXElementProps<HTMLDivElement> & { user?: User }) => {
  const cn = useStyles()
  return user ? (
    <div {...props} className={cn.userInfo}>
      <h5>
        {user.firstName} {user.secondName}
      </h5>
    </div>
  ) : null
}

export type User = {
  id: string
  email: string
  password: string
  firstName: string | null
  secondName: string | null
  companyId: string | null
  rigId: string | null
  createdAt: Date
  permissions: string[]
  scope: string[]
  company: {
    id: string
    name: string
  }
  rig: {
    id: string
    name: string
    type: string
  }
} | null

type LayoutProps = { sort?: string } & ChildrenProps;

const Layout = observer(({ children, sort }: LayoutProps) => {
  const {
    undoManager,
    store: { updateTally, updateBha, updateBop, updateLinerTally, updateCasingTally, updateTripSheet, updateTrendSheet, updateKillSheet, updateWellM, updateWellSectionM },
  } = useMst()

  const location = useLocation()
  const uri = location.pathname
  const router = useNavigate()

  const { user, setUser } = useContext(UserContext)
  const [msg, setMsg]= useState('')
  const [showMsg, setShowMsg] = useState(false)
  const [variant, setVariant] = useState<AlertProps["color"]>("success")

  const { loading } = useQuery<{ me: User }>(
    gql`
      ${UserFields}
      query me {
        me {
          ...UserFields
        }
      }
    `,
    {
      onCompleted(data) {
        setUser && setUser(data.me)
      },
      onError(err) {
        console.error(err)
        window.localStorage.removeItem('session')
        router('/login')
      },
    }
  )

  const [getStatus] = useLazyQuery(
    gql` query { status } `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
          if (data.status === false) {
            logout({ variables: { session: localStorage.getItem('session') } })
          }
      },
      onError(error) {
          console.log('fetch-error', error.message)

          if (error.message === 'Not authorized') {
            console.log('Not authorized')
            logout({ variables: { session: localStorage.getItem('session') } })
          }

          if (error.message === 'Network error: Failed to fetch') {
            console.log('There is a network error')
          }
        }
    }
  )

  const terminateSession = () => {
    window.localStorage.removeItem('session')
    setUser && setUser(null)
    router('/login')
  }

  const [logout] =
  useMutation(LOGOUT, {
    onCompleted(data) {
      terminateSession()
      localStorage.removeItem('context')
    },
    onError(error) {
      terminateSession()
      localStorage.removeItem('context')
      console.log('error', error)
    },
  })

  const matches = useMediaQuery('(min-width:1280px)')
  const [role, setRole] = useState('')
  const [userCompanyId, setUserCompanyId] = useState('')
  const [isOnline, setNetwork] = useState(true)

  useEffect(() => {
    const viewer: Array<string> = ['read']
    const complete: Array<string> = ['read', 'full']
    const driller: Array<string> = ['read', 'write']
    const supervisor: Array<string> = ['read', 'owner']
    const support: Array<string> = ['read', 'write', 'owner']

        switch (JSON.stringify(user?.permissions)) {
        case JSON.stringify(support):
            setRole('Support')
            break
        case JSON.stringify(complete):
            setRole('Complete')
            break
        case JSON.stringify(viewer):
            setRole('Viewer')
            break
        case JSON.stringify(driller):
            setRole('Driller')
            break
        case JSON.stringify(supervisor):
            setRole('Supervisor')
            break
        default:
            setRole('Viewer')
            break
    }
  }, [user?.permissions])

  useEffect(() => {
    if (showMsg === true) setShowMsg(false)
  }, [showMsg])

  useEffect(() => {
    setUserCompanyId(getCompany())
  }, [uri])

  useEffect(() => {
    if (HEALTH_CHECK) {
      setInterval(connection, HEALTH_CHECK_INTERVAL)
    }
    if (STATUS_CHECK) {
      setInterval(session, STATUS_INTERVAL)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isOnline) {
      setMsg('Internet connection is online')
      setVariant('success')
      setShowMsg(true)
    } else {
      setMsg('Internet connection is offline')
      setVariant('error')
      setShowMsg(true)
    }
  }, [isOnline])

  function session() {
    getStatus()
  }

  function connection() {
    try {
    fetch(process.env.REACT_APP_BASEURL as string + '?' + new Date().getTime(), { cache: 'no-store'})
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          console.log('Health check success')
          setNetwork(true)
        } else {
          setNetwork(false)
          console.log('Health check failure: invalid status code', response.status, response.statusText)
        }
      })
      .catch((error) => {
        setNetwork(false)
        console.log('Health check failure: general exception', error)
      })
    } catch (error) {
        setNetwork(false)
        console.log('Health check general failure', error)
    }
  }

  return !loading ? (
      <div className="pageWrapper">
        {matches ? (
        <div className="pageSidebar">
          <div className="pageSidebarHeader">
            <Menu />
          </div>
          <div className="pageSiderbarBranding">
            <a target="_blank" rel="noreferrer" href="https://endeavor.one">
              <img src={endeavorLogo} className="endeavorLogo" alt="Endeavor" />
            </a>
          </div>
        </div>
        ) : (
          <div style={{ position: 'absolute', top: 26, left: 8, color: '#fff' }}>
            <Link className="" title="Home" to="/dashboard">
              <img alt="" src={icon_home} style={{ height: 20, width: 20 }} />
            </Link>
          </div>
        )}
        <div className="pageBody">
          <div className="pageHeader">
            <Breadcrumbs fname={user?.firstName || ''} />
            <UndoButtons
              undoDisabled={!undoManager.canUndo}
              redoDisabled={!undoManager.canRedo}
              onRedo={undoManager.redo}
              onUndo={undoManager.undo}
            />
            <ChipTab
              onClick={() => {
                window.print();
              }}
            >
              <img alt="print" src={icon_print} />
            </ChipTab>

            { function() : JSX.Element {
              switch(sort) {
                case 'wellName':
                  if(role === 'Support' ||
                  (
                    user?.companyId === userCompanyId &&
                    (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                    user?.scope?.includes('rig')
                  )) return (                <div>
                    <ChipTab
                      onClick={() => {
                        updateWellM({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          company: getCompany(),
                          rig: getRig(),
                        })
                        setMsg('Well saved!')
                        setShowMsg(true)
                      }}
                      >
                      <SaveIcon />
                    </ChipTab>
                  </div>
                  )
                  else return <></>
                case 'wellSectionName':
                  if(role === 'Support' ||
                  (
                    user?.companyId === userCompanyId &&
                    (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                    user?.scope?.includes('rig')
                  ))
                  return (
                    <div>
                      <ChipTab
                        onClick={() => {
                          updateWellM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            company: getCompany(),
                            rig: getRig(),
                          })
                          updateWellSectionM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                              ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                              ),
                            company: getCompany(),
                            rig: getRig(),
                          })
                          setMsg('Well Section saved!')
                          setShowMsg(true)
                          }
                        }
                        >
                        <SaveIcon />
                      </ChipTab>
                    </div>)
                  else return <></>
                case 'pipe-tally':
                  if(role === 'Support' ||
                  (
                    user?.companyId === userCompanyId &&
                    (role === 'Supervisor' || role === 'Complete') &&
                    user?.scope?.includes('rig')
                  ))
                  return (
                    <div>
                      <ChipTab
                        onClick={() => {
                          updateTally({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                            ),
                            company: getCompany(),
                            rig: getRig(),
                            })
                            setMsg('Pipe Tally saved!')
                            setShowMsg(true)
                          }
                        }
                        >
                        <SaveIcon />
                      </ChipTab>
                    </div>)
                  else return <></>
                case 'bop':
                  if(role === 'Support' ||
                  (
                    user?.companyId === userCompanyId &&
                    (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                    user?.scope?.includes('rig')
                  ))
                  return (
                    <div>
                      <ChipTab
                        onClick={() => {
                          updateBop({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            company: getCompany(),
                            rig: getRig()
                          })
                          setMsg('BOP saved!')
                          setShowMsg(true)
                        }
                      }
                      >
                        <SaveIcon />
                      </ChipTab>
                    </div>)
                  else return <></>
                  case 'bha':
                    if(role === 'Support' ||
                    (
                      user?.companyId === userCompanyId &&
                      (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                      user?.scope?.includes('rig')
                    ))
                    return (
                      <div>
                        <ChipTab
                          onClick={() => {
                            updateBha({
                              wellName: underscoreToSpace(
                                location.pathname.split('/')[2]
                              ),
                              wellSectionName: underscoreToSpace(
                                location.pathname.split('/')[3]
                              ),
                              company: getCompany(),
                              rig: getRig()
                            })
                            setMsg('BHA saved!')
                            setShowMsg(true)
                          }
                        }
                        >
                          <SaveIcon />
                        </ChipTab>
                      </div>)
                    else return <></>
                    case 'casing-tally':
                      if(role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      ))
                      return (
                        <div>
                          <ChipTab
                            onClick={() => {
                              updateCasingTally({
                                wellName: underscoreToSpace(
                                  location.pathname.split('/')[2]
                                ),
                                wellSectionName: underscoreToSpace(
                                  location.pathname.split('/')[3]
                                ),
                                company: getCompany(),
                                rig: getRig()
                                })
                                setMsg('Casing Tally saved!')
                                setShowMsg(true)
                              }
                            }
                            >
                            <SaveIcon />
                          </ChipTab>
                        </div>)
                      else return <></>
                      case 'liner-tally':
                        if(role === 'Support' ||
                        (
                          user?.companyId === userCompanyId &&
                          (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                          user?.scope?.includes('rig')
                        ))
                        return (
                          <div>
                            <ChipTab
                              onClick={() => {
                                updateLinerTally({
                                  wellName: underscoreToSpace(
                                    location.pathname.split('/')[2]
                                  ),
                                  wellSectionName: underscoreToSpace(
                                    location.pathname.split('/')[3]
                                  ),
                                  company: getCompany(),
                                  rig: getRig()
                                  })
                                  setMsg('Liner Tally saved!')
                                  setShowMsg(true)
                                }
                              }
                              >
                              <SaveIcon />
                            </ChipTab>
                          </div>)
                        else return <></>
                        case 'trip-sheet':
                          if(role === 'Support' ||
                          (
                            user?.companyId === userCompanyId &&
                            (role === 'Driller' || role === 'Complete') &&
                            user?.scope?.includes('rig')
                          ))
                          return (
                            <div>
                              <ChipTab
                                onClick={() => {
                                  updateTripSheet({
                                    wellName: underscoreToSpace(
                                      location.pathname.split('/')[2]
                                    ),
                                    wellSectionName: underscoreToSpace(
                                      location.pathname.split('/')[3]
                                  ),
                                  company: getCompany(),
                                  rig: getRig()
                                  })
                                  setMsg('Trip Sheet saved!')
                                  setShowMsg(true)
                                }
                              }
                              >
                                <SaveIcon />
                              </ChipTab>
                            </div>)
                          else return <></>
                          case 'trend-sheet':
                            if(role === 'Support' ||
                            (
                              user?.companyId === userCompanyId &&
                              (role === 'Driller' || role === 'Complete') &&
                              user?.scope?.includes('rig')
                            ))
                            return (
                              <div>
                                <ChipTab
                                  id="btnSaveTrendSheet"
                                  onClick={() => {
                                    updateTrendSheet({
                                      wellName: underscoreToSpace(
                                        location.pathname.split('/')[2]
                                      ),
                                      wellSectionName: underscoreToSpace(
                                        location.pathname.split('/')[3]
                                      ),
                                      company: getCompany(),
                                      rig: getRig()
                                      }).then(
                                        () => {
                                          setMsg('Trend Sheet saved!')
                                          setShowMsg(true)
                                        }
                                      )
                                    }
                                  }
                                  >
                                  <SaveIcon />
                                </ChipTab>
                              </div>)
                            else return <></>
                            case 'kill-sheet':
                              if(role === 'Support' ||
                              (
                                user?.companyId === userCompanyId &&
                                (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                                user?.scope?.includes('rig')
                              ))
                              return (
                                <div>
                                  <ChipTab
                                    onClick={() => {
                                      updateKillSheet({
                                        wellName: underscoreToSpace(
                                          location.pathname.split('/')[2]
                                        ),
                                        wellSectionName: underscoreToSpace(
                                          location.pathname.split('/')[3]
                                        ),
                                        company: getCompany(),
                                        rig: getRig()
                                        })
                                        setMsg('Kill Sheet saved!')
                                        setShowMsg(true)
                                      }
                                    }
                                    >
                                    <SaveIcon />
                                  </ChipTab>
                                </div>)
                              else return <></>
                default:
                  return <></>
              }
            }()
            }

            <UserInfo user={user} />
            {HEALTH_CHECK ? (
              <div className={isOnline ? 'online' : 'offline'}>
                  <img src={isOnline ? icon_wifi_on : icon_wifi_off} alt="online status" style={{ height: '25px', width: '25px' }} />
                  <img src={icon_wifi_off} alt="loading status" style={{ height: '1px', width: '1px' }} />
              </div>)
            : null}
            <Link
              className="headerLink"
              title="logout"
              to="/login"
              onClick={() => {
                logout({ variables: { session: localStorage.getItem('session') } })
                // clearCache();
              }}>logout
            </Link>
            <div style={{ marginLeft: 'auto'}}>
            { /* <img alt="Operator" style={{ marginLeft: '-20px', paddingTop: '15px', paddingRight: '25px', height: '75px' }} src={logoOperator} />
            */ }
            </div>
            <Toast
              variant={variant}
              // variant={'success'}
              message={`${msg}`}
              open={showMsg}
            />
          </div>
          <div className="pageBodyContent">
            <div>
              {children}
            </div>
          </div>
        </div>
      </div>
  ) : (
    <SplashScreen />
  )
})

export default Layout
