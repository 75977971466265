import { useContext } from 'react'
import './Menu.css'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useNavigate, useLocation } from 'react-router'
import { RouterContext } from '../Router'
import { Link } from 'react-router-dom'
import { FEEDBACK_LINK } from '../../constants/common'
import logoSeadrill from '../../../assets/images/logos/logoSeadrillWhite.svg'
import logoAcme from '../../../assets/images/logos/logoAcme.svg'
import logoValaris from '../../../assets/images/logos/logoValarisDark.svg'
import logoZenith from '../../../assets/images/logos/logoZenith.svg'
// import logoGlobalCo from '../../../assets/images/logos/logoGlobalCo.png'
import logoGooding from '../../../assets/images/logos/logoGooding.png'
import logoInterWell from '../../../assets/images/logos/logoInterWell.png'
import logoContractorGrey from '../../../assets/images/logos/logoContractorGrey.png'
import { spaceToUnderscore } from 'utils/helpers'

const Menu = () => {
  const location = useLocation()
  const uri = location.pathname
  const breadcrumbs = useBreadcrumbs()
  const router = useNavigate()
  const path = breadcrumbs[3]?.match.url
  // const [selected, setSelected] = useState('')
  const { locationState } = useContext(RouterContext)
  const isOpenHole = locationState?.wellSectionType === 'OH'

  // useEffect(() => {
  //   path && setSelected(path.split('/')[3])
  // }, [path, setSelected])

  const handleClick = (value) => {
    let newPathArray = path.split('/')
    // newPathArray.splice(-1, 1, value)
    newPathArray.push(value)
    router(newPathArray.join('/'))
  }

  const handleLibraryClick = (value) => {
    router(`/library/${value}`)
  }

  const navigationDepth = uri.split('/').length
  const activeItem = uri.split('/')[uri.split('/').length - 1]

  const branding = [
    {
      'Default': {'img': logoContractorGrey},
      'Seadrill': {'img': logoSeadrill},
      'Acme': {'img': logoAcme},
      'Zenith': {'img': logoZenith},
      'GlobalCo': {'img': logoContractorGrey},
      'Gooding': {'img': logoGooding},
      'InterWell': {'img': logoInterWell},
      'Valaris': {'img': logoValaris},
    }
  ]

  let currentWell = ''
  try {
    currentWell = uri.split('/')[2].toString().replace(/-/g, ' ')
  }
  catch{}

  function changeTheme(theme) {
    var element = document.getElementById('htmlContainer');
    if (element) {
      element.className = ''
      element.className = theme
    }
  }

  function getBrand() {
    const company = branding[0][JSON.parse(localStorage.getItem('context')).company.name]
    try {
      if (company.img.includes('/static/media/')) {
        return company.img
      }
    }
    catch {
      return branding[0]['Default'].img
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <div style={{
        marginTop: '-20px',
        marginBottom: '5px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <img alt="Contractor" style={{ width: '140px', marginTop: '30px', marginRight: '10px' }} src={getBrand()} />
        </Link>
      </div>

      <nav className="" style={{ marginTop: '0px' }}>
        <ul>
          <li
            onClick={() => router('/dashboard')}
            className={uri === '/dashboard' ? 'menu_item active' : 'menu_item'}
          >
            <span style={{ marginLeft: '6px' }} className="menuTextPad">Home</span>
          </li>
          {(uri !== '/dashboard'
            || uri !== '/'
            || uri.includes('/library')
            ? (
          <li
            onClick={() => router('/all-wells')}
            className={uri === '/all-wells' ? 'menu_item active' : 'menu_item'}
          >
            <span style={{ marginLeft: '6px' }} className="menuTextPad">Wells</span>
          </li>
          ) : '')}
          {(uri !== '/dashboard'
            && uri !== '/'
            && uri !== '/all-wells'
            & !uri.includes('/library')
            & !uri.includes('/RotatingHours')
            ? (
            <li className="section_header" style={{ marginTop: '-16px' }}>Well Plan</li>
          ) : '')}
          {(uri !== '/dashboard'
            && uri !== '/'
            && uri !== '/all-wells'
            & !uri.includes('/library')
            & !uri.includes('/RotatingHours')
            ? (
            <li
              style={{ cursor: 'pointer'}}
              onClick={() => {
                router(`/all-wells/${spaceToUnderscore(uri.split('/')[2])}`)
            }}
              className={
                uri.split('/').length === 3
                ?
                'menu_item active'
                :
                  currentWell.length > 0 && navigationDepth >= 3
                  ?
                  'menu_item'
                  :
                  'menu_item_hidden'
            }>
              <span className="menuTextPad">Well Info</span>
            </li>
          ) : '')}
        </ul>
      </nav>
      <nav className={!path ? 'disabled' : ''} style={{ marginTop: '-15px' }}>
        <ul style={{ marginBottom: '20px' }}>
          <li className="section_header">Drill String</li>
          <li
            onClick={() => router(`/all-wells/${uri.split('/')[2]}/${spaceToUnderscore(uri.split('/')[3])}`)}
            className={
              (
                (uri.split('/').length === 4) ||
                (uri.split('/')[4] === '')
              )
                ? 'menu_item_wellSection active' : 'menu_item_wellSection'
            }
          >
            Summary
          </li>
          <li
            onClick={() => handleClick('pipe-tally')}
            className={
              activeItem === 'pipe-tally' ? 'menu_item_wellSection active' : 'menu_item_wellSection'
            }
          >
            Pipe Tally
          </li>
          {!isOpenHole ? (
            <li
              onClick={() => handleClick('casing-tally')}
              className={
                activeItem === 'casing-tally' ? 'menu_item_wellSection active' : 'menu_item_wellSection'
              }
            >
              Casing Tally
            </li>
          ) : ''}
          {isOpenHole ? (
          <li
            onClick={() => handleClick('bha')}
            className={
              activeItem === 'bha'
                ? 'menu_item_wellSection active'
                : 'menu_item_wellSection'
            }
          >
            BHA
          </li>
          ) : ''}
          {!isOpenHole ? (
          <li
            onClick={() => handleClick('liner-tally')}
            className={
              activeItem === 'liner-tally'
                ? 'menu_item_wellSection active'
                : 'menu_item_wellSection'
            }
          >
            Liner Tally
          </li>
          ) : ''}
          {/* <li
            onClick={() => handleClick('bop')}
            className={activeItem === 'bop' ? 'menu_item_wellSection active' : 'menu_item_wellSection'}
          >
            {'BOP space out'}
          </li> */}
          <li
            onClick={() => handleClick('trip-sheet')}
            className={activeItem === 'trip-sheet' ? 'menu_item_wellSection active' : 'menu_item_wellSection'}
          >
            {'Trip Sheet'}
          </li>
          <li
            onClick={() => handleClick('trend-sheet')}
            className={activeItem === 'trend-sheet' ? 'menu_item_wellSection active' : 'menu_item_wellSection'}
          >
            {'Trend Sheet'}
          </li>
          {/* <li
            onClick={() => handleClick('overpull-sheet')}
            className={activeItem === 'overpull-sheet' ? 'menu_item_wellSection active' : 'menu_item_wellSection'}
          >
            {'Overpull Sheet'}
          </li> */}
          <li
            onClick={() => handleClick('kill-sheet')}
            className={activeItem === 'kill-sheet' ? 'menu_item_wellSection active' : 'menu_item_wellSection'}
          >
            {'Kill Sheet'}
          </li>
          <li
            onClick={() => handleClick('kill-graph')}
            className={activeItem === 'kill-graph' ? 'menu_item_wellSection active' : 'menu_item_wellSection'}
          >
            {'Kill Chart'}
          </li>
          <li
            onClick={() => handleClick('bop')}
            className={
              activeItem === 'bop'
                ? 'menu_item_wellSection active'
                : 'menu_item_wellSection'
            }
          >
            BOP
          </li>
        </ul>
      </nav>
      <nav>
        <ul>
          <li style={{ marginTop: '-20px' }} className="section_header">Library</li>
          <li
            onClick={() => handleLibraryClick('tubulars')}
            className={uri === '/library/tubulars' ? 'menu_item active' : 'menu_item'}
          >
            Tubulars
          </li>
          <li
            onClick={() => router(`/RotatingHours`)}
            className={uri === '/RotatingHours' ? 'menu_item active' : 'menu_item'}
          >
            Rotating Hours
          </li>
          <li className="menu_item_static">
            <a href={FEEDBACK_LINK}
              className="menu_item_static_link" style={{
              textDecoration: 'none',
              textAlign: 'center',
              width: '200px',
              padding: '0px 2px',
              color: '#737496',
              transition: 'all ease-out 0.1s',
              cursor: 'pointer',
              // -webkit-user-select: 'none',
              userSelect: 'none',
              fontStyle: 'normal',
              fontSize: '14px',
              marginLeft: '-1px',
              marginTop: '-6px',
              }}>
                Feedback
              </a>
          </li>
        </ul>
      </nav>

      <br />

      <div className="themeSwapTarget">
        <div style={{ padding: '0px', filter: 'none' }}>
          <button onClick={() => changeTheme('theme_lightest')} style={{ padding: '0px', filter: 'none' }}>
            <div style={{ height: '22px', width: '22px', backgroundColor: '#00000000', filter: 'none', cursor: 'pointer'  }}></div>
          </button>
        </div>
        <div style={{ padding: '0px', filter: 'none' }}>
          <button onClick={() => changeTheme('theme_darker')} style={{ padding: '0px', filter: 'none' }}>
          <div style={{ height: '22px', width: '22px', backgroundColor: '#00008b75', filter: 'none', cursor: 'pointer'  }}></div>
          </button>
        </div>
      </div>

      <br /><br />
    </div>
  )
}

export default Menu
