import { Routes, Route, Navigate } from 'react-router-dom'
import Auth from './Auth'
import Error from './Error'
import DashboardLayout from './Dashboard/DashboardLayout'
import BHA from './BHA'
import Layout from './Layout'
import PipeTally from './PipeTally'
import LinerTally from './LinerTally'
import CasingTally from './CasingTally'
import TripSheetUi from './TripSheet'
import TrendSheetUi from './TrendSheet'
import KillSheetUi from './KillSheet'
import KillChart from './KillChart'
import StateFetcher from './StateFetcher'
import RotatingHours from './RotatingHours'
import Wells from './Wells'
import Well from './Well'
import WellSection from './WellSection'
import { useState, createContext, Dispatch, SetStateAction } from 'react'
import PipesLibrary from './Library/Tubulars'
import BOP from './BOP'

type LocationState = {
  wellId?: string
  wellSectionId?: string
  wellSectionType?: 'CSG' | 'OH'
  rigId?: string
  rigType?: string
  units?: 'imperial' | 'metric' | 'hybrid'
  status?: 'completed' | 'inprogress'
}

interface RouterContext {
  locationState?: LocationState
  setLocationState?: Dispatch<SetStateAction<LocationState>>
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RouterContext = createContext<RouterContext>({})

export default function MainContents() {
  const [locationState, setLocationState] = useState<LocationState>({
    wellId: '',
    wellSectionId: '',
    wellSectionType: 'OH',
    rigId: '',
    rigType: '',
    units: 'imperial',
    status: 'inprogress',
  })
  // const [trendSaved, setTrendSaved] = useState<boolean>(false)

  if (!window.localStorage.getItem('session')) {
    return (
      // <Layout>
        <Auth />
      // </Layout>
    )
  }

  return (
    <RouterContext.Provider value={{ locationState, setLocationState }}>
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/" element={<Navigate to="/dashboard" />}/>
        <Route path='/error' element= {
          <Layout>
            <Error />
          </Layout>
        } />
        <Route path="/dashboard/*" element={
          <Layout>
            <DashboardLayout />
          </Layout>
        } />
        <Route path="/all-wells/*" element={
          <Layout>
             <Wells />
          </Layout>
        } />
        <Route path="/all-wells/:wellName/*" element={
          <Layout sort='wellName'>
             <StateFetcher>
               <Well />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/*" element={
          <Layout sort='wellSectionName'>
             <StateFetcher>
               <WellSection />
             </StateFetcher>
          </Layout>
        } />
        <Route path='/all-wells/:wellName/:wellSectionName/pipe-tally/*' element={
          <Layout sort='pipe-tally'>
             <StateFetcher>
               <PipeTally />
             </StateFetcher>
          </Layout>
        } />
        <Route path='/all-wells/:wellName/:wellSectionName/liner-tally/*' element={
          <Layout sort='liner-tally'>
             <StateFetcher>
               <LinerTally />
             </StateFetcher>
          </Layout>
        } />
        <Route path='/all-wells/:wellName/:wellSectionName/casing-tally/*' element={
          <Layout  sort='casing-tally'>
             <StateFetcher>
               <CasingTally />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/bha/*" element={
          <Layout sort='bha'>
             <StateFetcher>
               <BHA />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/trip-sheet/*" element={
          <Layout sort='trip-sheet'>
             <StateFetcher>
               <TripSheetUi />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/trend-sheet/*" element={
          <Layout sort='trend-sheet'>
             <StateFetcher>
               <TrendSheetUi />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/kill-sheet/*" element={
          <Layout sort='kill-sheet'>
             <StateFetcher>
               <KillSheetUi />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/kill-graph/*" element={
          <Layout>
             <StateFetcher>
               <KillChart />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/bop/*" element={
          <Layout sort='bop'>
             <StateFetcher>
               <BOP />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/liner/*" element={
          <Layout>
             <StateFetcher>
               <LinerTally />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/library/tubulars/*" element={
          <Layout>
             <PipesLibrary />
          </Layout>
        } />
        <Route path="/RotatingHours/*" element={
          <Layout>
             <RotatingHours />
          </Layout>
        } />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </RouterContext.Provider>
  )
}
