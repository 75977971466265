import { ReactElement, useContext } from 'react';
import { RouterContext } from '../Router'
import { getLabel } from '../../../utils/helpers'

export function TableHeader(): ReactElement {
  const { locationState } = useContext(RouterContext)

  return (
    <div style={{
      display: 'table-header-group',
      position: 'sticky',
      overflow: 'hidden',
      top: 0
    }}
    >
      <div id="tripTableHeader" className="tripTableHeader" style={{ backgroundColor: 'rgb(62, 64, 86)' }}>
        <div className="tripTableHeaderCell" style={{ gridColumn: '1/2', gridRow: '1/4' }}></div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '2/3', gridRow: '1/4' }}>
        &nbsp;<br />&nbsp;<br />Pipe Name
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '3/4', gridRow: '1/4' }}>
        &nbsp;<br />Stand<br />No.
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '4/6', gridRow: '1/2' }}>
          Trip Tank
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '4/5', gridRow: '2/3' }}>
          1
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '5/6', gridRow: '2/3' }}>
          2
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '4/5', gridRow: '3/4' }}>
          {getLabel('volume', locationState)}
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '5/6', gridRow: '3/4' }}>
          {getLabel('volume', locationState)}
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '6/7', gridRow: '1/4' }}>
        &nbsp;<br />Open / Closed End
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '7/9', gridRow: '1/3' }}>
        &nbsp;<br />Displacement
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '7/8', gridRow: '3/4' }}>
          Open End
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '8/9', gridRow: '3/4' }}>
          Closed End
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '9/11', gridRow: '1/2' }}>
          Measured Hole
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '9/10', gridRow: '2/3', whiteSpace: 'nowrap' }}>
          Fill / Disp
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '10/11', gridRow: '2/3' }}>
          {getLabel('volume', locationState)}
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '9/10', gridRow: '3/4' }}>
          Increm
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '10/11', gridRow: '3/4' }}>
          Accum
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '11/13', gridRow: '1/2' }}>
          Calculated
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '11/12', gridRow: '2/3', whiteSpace: 'nowrap' }}>
          Fill / Disp
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '12/13', gridRow: '2/3' }}>
          {getLabel('volume', locationState)}
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '11/12', gridRow: '3/4' }}>
          Increm
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '12/13', gridRow: '3/4' }}>
          Accum
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '13/15', gridRow: '1/2' }}>
          Discrepency
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '13/14', gridRow: '2/3', whiteSpace: 'nowrap' }}>
          Fill / Disp
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '14/15', gridRow: '2/3' }}>
          {getLabel('volume', locationState)}
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '13/14', gridRow: '3/4' }}>
          Increm
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '14/15', gridRow: '3/4' }}>
          Accum
        </div>
        <div className="tripTableHeaderCell"  style={{ gridColumn: '15/16', gridRow: '3/4' }}>
          Comments
        </div>
      </div>
    </div>
  );
}
