import { useQuery, gql, useMutation } from '@apollo/client'
import { produce } from 'immer'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { spaceToUnderscore, removeNonAlphaNumericExceptSpacePeriodHyphen, getCompany, getRig } from 'utils/helpers'
import Dialog from './kit/Dialog'
import Input from './kit/Input'
import { Button, ImportButton } from './kit/SmallWidthButton'
import { RadioButton } from './kit/RadioButton'
import { Paper } from './Layout'
import './Wells.css'

const Head = () => (
  <thead>
    <tr>
      <th style={{ width: 400, fontSize: '14px', fontWeight: 600 }}>Well Name</th>
      <th style={{ width: 373, fontSize: '14px', fontWeight: 600 }}>Location</th>
      <th style={{ width: 233, fontSize: '14px', fontWeight: 600 }}>Date Created</th>
      <th style={{ width: 100, fontSize: '14px', fontWeight: 600 }}>Units</th>
      <th style={{ width: 166, fontSize: '14px', fontWeight: 600 }}>Status</th>
    </tr>
  </thead>
)

const CreateWell = ({ refetch, open, onClose, companyId }) => {
  const [form, setForm] = useState({ name: '' })
  const [unit, setUnit] = useState('')

  const [createWell] = useMutation(
    gql`
      mutation createWell($name: String!, $loacation: String!, $companyId: String!, $rigId: String!, $units: String!) {
        createWell(
            name: $name
            loacation: $loacation
            company: $companyId
            rig: $rigId
            units: $units
        ) {
          id
        }
      }
    `,
    {
      variables: {
        rigId: JSON.parse(localStorage.getItem('context')).rig.id,
    },
      onCompleted() {
        refetch()
        setForm({ name: '' })
        onClose()
      },
    }
  )

  const handleName = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.name = sanitizeInput(e.target.value)
      })
    )
  }

  const handleLoacation = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.loacation = e.target.value
      })
    )
  }

  const handleUnits = {
    imperial() {
      setUnit('imperial')
      setForm(
        produce(form, (draft) => {
          if (draft.units !== 'imperial') draft.units = 'imperial'
        })
      )
    },
    metric() {
      setUnit('metric')
      setForm(
        produce(form, (draft) => {
          if (draft.units !== 'metric') draft.units = 'metric'
        })
      )
    },
    hybrid() {
      setUnit('hybrid')
      setForm(
        produce(form, (draft) => {
          if (draft.units !== 'hybrid') draft.units = 'hybrid'
        })
      )
    },
  }

  function sanitizeInput(str) {
    return removeNonAlphaNumericExceptSpacePeriodHyphen(str)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 32,
          marginTop: 12,
          marginLeft: 20,
          marginRight: 20,
          flexDirection: 'column',
        }}
      >
        <h3>Create new well</h3>
        <div style={{ margin: 16, paddingBottom: '0px', marginTop: '-10px' }}>
          <Input
            id="wellName"
            title="Name"
            onChange={handleName}
            onKeyUp={(e) => {
              e.target.value = sanitizeInput(e.target.value)
            }}
            className="inputModalField"
            labelClassName="placeholder"
            wrapperClassName="field"
          />
        </div>
        <div style={{ fontSize: '8pt', color: 'silver', marginTop: '-20px', marginBottom: '20px' }}>* Alphanumeric, period, hyphen and spaces only</div>
        <div style={{ margin: 16, paddingBottom: '20px', marginTop: '-10px' }}>
        <Input
            id="loacation"
            title="Location"
            onChange={handleLoacation}
            className="inputModalField"
            labelClassName="placeholder"
            wrapperClassName="field"
          />
        </div>

        <h4 className="createWellSectionTitle">
          Units
        </h4>
        <div className="createWellSectionContainer" style={{ flexDirection: 'column' }}>
          <div className="direction-picker" onClick={handleUnits.imperial}>
            <RadioButton
              id="select-before"
              style={{ marginLeft: 30 }}
              checked={unit === 'imperial'}
              onChange={() => {}}
            />
            <p className="direction-label">Imperial</p>
          </div>
          <div className="direction-picker" onClick={handleUnits.metric}>
            <RadioButton
              id="select-after"
              style={{ marginLeft: 30 }}
              checked={unit === 'metric'}
              onChange={() => {}}
            />
            <p className="direction-label">Metric</p>
          </div>
          <div className="direction-picker" onClick={handleUnits.hybrid}>
            <RadioButton
              id="select-hybrid"
              style={{ marginLeft: 30 }}
              checked={unit === 'hybrid'}
              onChange={() => {}}
            />
            <p className="direction-label">Hybrid</p>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            marginRight: 34,
          }}
        >
          <Button
            small
            disabled={!form.name || !form.units || !form.loacation}
            onClick={() => {
              createWell({ variables: { ...form, companyId } })
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const Wells = () => {
  const [open, setOpen] = useState(false)
  const router = useNavigate()
  useEffect(() => {
    // console.log('user', user)
  })

  // # where: {limit: 10, offset: 0, rig: {id: $rigId}, company: {id: $companyId}}

  const { data, loading, refetch } = useQuery(
    gql`
      query getWells($companyId: String!, $rigId: String!, $take: String!) {
        getWells(
            companyId: $companyId,
            rigId: $rigId,
            take: $take,
          ) {
          id
          rigId
          name
          loacation
          units
          status
          createdAt
        }
      }
    `,
    {
      variables: {
        companyId: getCompany(),
        rigId: getRig(),
        take: '500',
      },
      onCompleted(data) {
        // console.log('Wells', data)
      },
    }
  )

  return (
    <>
      <div style={{
          display: 'flex',
          flexDirection: 'row',
          color: '#FFFFFF',
          fontSize: '28px',
          fontWeight: 600,
          lineHeight: '46px',
          marginTop: '5px',
          marginBottom: '-10px',
      }}>
        <CreateWell
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        refetch={refetch}
        companyId={JSON.parse(localStorage.getItem('context')).company.id}
      />
      </div>

      <div
        style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}
      >
        <ImportButton
          style={{ width: 100, margin: 8 }}
          title="Create well"
          onClick={() => setOpen(true)}
        />
      </div>
      <Paper style={{ padding: '8px 16px' }}>
        <table className="table well">
          <Head />
          <tbody>
            {!loading &&
              data?.getWells.map(({ id, name, units, loacation, status, createdAt }) => (
                <tr
                  key={id}
                  onClick={() => {
                    router(`/all-wells/${spaceToUnderscore(name)}`)
                  }}
                >
                  <td style={{ width: 473, fontSize: '14px', fontWeight: 500, height: '64px' }} className="hl">
                    {name}
                  </td>
                  <td style={{ width: 473, fontSize: '14px', fontWeight: 500, height: '64px' }} className="hl">
                    {loacation}
                  </td>
                  <td style={{ width: 233, fontSize: '14px', fontWeight: 500, height: '64px' }} className="hl">
                    {new Date(createdAt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})}
                  </td>
                  <td style={{ width: 100, fontSize: '14px', fontWeight: 500, height: '64px' }} className="hl">
                    {units}
                  </td>
                  <td style={{ width: 166, fontSize: '14px', fontWeight: 500, height: '64px' }}>
                    <span style={{ fontSize: '10pt' }} className={status === 'inprogress' ? 'uiContainerStatusWarning' : 'uiContainerStatusActive'}>
                      {status === 'inprogress' ? 'In Progress' : 'Completed'}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Paper>
    </>
  )
}

export default Wells
