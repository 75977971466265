import { ReactElement, useState, useEffect, useRef } from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
interface ToastProps {
    message: string;
    onClose?: () => void;
    variant: AlertProps["color"];
    open: boolean;
}

export function Toast({ message, onClose, variant, open }: ToastProps): ReactElement {
    const [isOpen, setIsOpen] = useState(false);
    const nodeRef = useRef(null);
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpen(false);
    };

    useEffect(() => {
        if (open) {
            setIsOpen(true);
        }
    }, [open]);

    return (
        <div>
            <Snackbar
                open={isOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}
                // action={action}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                ref={nodeRef}
            >
                <MuiAlert severity={variant} variant="filled">{message}</MuiAlert>
            </Snackbar>
        </div>
    )
}
