import { useState, useEffect } from 'react';
import Clock from '../components/Well/Clock'
import Metrics from '../components/WellSection/Metrics'
import DataTable from '../components/Well/DataTable'
import Badges from '../components/WellSection/Badges'
// import Direction from './WellSection/Direction'
import PrintInfo from 'app/components/kit/PrintInfo'
import { SectionTabs } from './WellSection/SectionTabs'
import ChartSelect from './WellSection/ChartSelect'
import { useLocation } from 'react-router-dom'
import { underscoreToSpace } from 'utils/helpers'

const WellSection = () => {
  const [currentWell, setCurrentWell] = useState('')
  const [currentWellSection, setCurrentWellSection] = useState('')
  const location = useLocation()
  const uri = location.pathname

  useEffect(() => {
    setCurrentWell(underscoreToSpace(uri.split('/')[2]))
    setCurrentWellSection(underscoreToSpace(uri.split('/')[3]))
  }, [uri])

  useEffect(() => {
    document.body.style.cursor='auto'
  },[])

  return (
    <>
      <SectionTabs />
      <PrintInfo />
      <div className="wellSectionBody" style={{ marginTop: '-15px' }}>
        <div className="wellSectionHeaderWrapper">
          <div className="wellSectionHeaderContainer">
            <Badges />
            {/* <Direction /> */}

            <div className="wellInfoTimeClockWrapper">
              <div className="timeclock wellInfoTimeClock"><Clock /></div>
              <div id="timeclock" className="timeclock"></div>
            </div>

          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div className="uiContainer" style={{ width: '50%' }}>
                <div id="dashboardChartROPContainer" className="uiContainerTitle">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px', width: '100%' }}>
                        <ChartSelect chartNumber={1} />
                    </div>
                </div>
            </div>
            <div className="uiContainerLimited" style={{ width: '50%', maxWidth: '1000px' }}>
                <div id="dashboardChartWOBContainer" className="uiContainerTitle">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px', width: '100%' }}>
                        <ChartSelect chartNumber={2} />
                    </div>
                </div>
            </div>
          </div>
        <Metrics
          wellName={currentWell}
          wellSection={currentWellSection}
        />
        <DataTable
          host="section"
          wellName={currentWell}
          wellSection={currentWellSection}
        />
      </div>
    </>
  )
}

export default WellSection
