import { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client'
import Rig from './Rig'
import Company from './Company'
import { getCompany } from '../../../utils/helpers'

const Summary  = ({
    currentCompanyId,
    setCurrentCompanyId,
    currentRigId,
    setCurrentRigId,
}) => {
    const [isLoading, setIsLoading] = useState('')
    const [summary, setSummary] = useState([])
    const { data, loading, refetch } = useQuery(
        gql`
            query getWellSections($companyId: String!, $rigId: String!, $take: String!) {
                getWellSections(
                    companyId: $companyId,
                    rigId: $rigId,
                    take: $take,
                ) {
                    id
                    name
                    type
                    createdAt
                    updatedAt
                    well {
                        id
                        name
                        status
                    }
                }
            }
        `,
        {
            variables: {
                companyId: currentCompanyId.length > 0 ? currentCompanyId : '-',
                rigId: currentRigId.length > 0 ? currentRigId : '-',
                take: '1',
            },
            onCompleted(data) {
                setIsLoading(true)
            },
        }
    )

    useEffect(() => {
        if (getCompany() !== currentCompanyId) {
            setCurrentCompanyId(getCompany())
        }
    }, [currentCompanyId, setCurrentCompanyId])

    useEffect(() => {
        if (isLoading && data) {
            if (data.getWellSections) {
                setSummary([...data.getWellSections]) // ES6 way, better than .slice()
            }
        }

        return () => {
            setIsLoading(false)
        }
    }, [isLoading, data])

    useEffect(() => {
        if (currentCompanyId !== '') {
            refetch()
        }
    }, [currentCompanyId, refetch])

    return (
        <div className="uiContainer uiContainerShallow">
        <div className="uiContainerTitle">Summary</div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Company: </span>
                <span className="uiContainerLinkText">
                    <Company
                        currentCompanyId={currentCompanyId}
                        setCurrentCompanyId={setCurrentCompanyId}
                    />
                </span>
            </div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Current Rig: </span>
                <span className="uiContainerLinkText">
                    <Rig
                        currentCompanyId={currentCompanyId}
                        currentRigId={currentRigId}
                        setCurrentRigId={setCurrentRigId}
                    />
                </span>
            </div>
            {!loading && summary.map(({ id, name, type, status, createdAt, updatedAt, well }) => (
            <div key={id}>
                <div className="uiContainerText">
                    <div style={{ display: 'flex', flexDirection: 'row', height: '22px'  }}>
                        <div>
                            <span className="uiContainerLinkLabel">Current Well: </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '22px'  }}>
                            <div>
                                <span className="dashboardSelect">
                                    {
                                        well.name.length > 15 ? well.name.substring(0, 15) + '...' : well.name
                                    }
                                </span>
                            </div>
                            <div style={{ marginTop: '2px', marginLeft: '-6px' }}>
                                <span className="">
                                    <span className={well.status === 'complete' ? 'dashboardStatusComplete' : 'dashboardStatusInProgress'} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uiContainerText" style={{ marginTop: '3px'}}>
                    <span className="uiContainerLinkLabel" style={{ whiteSpace: 'nowrap'}}>Current Section: </span>
                    <span className="dashboardSelect">{
                        name.length > 13 ? name.substring(0, 13) + '...' : name
                    }</span>
                </div>
            </div>
            ))}
        </div>
    );
}

export default Summary
