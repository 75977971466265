import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useNavigate } from 'react-router'
import { useMst } from 'app/store'
import { spaceToUnderscore } from 'utils/helpers'
import { underscoreToSpace } from 'utils/helpers'

const Head = () => (
    <thead>
        <tr>
            <th style={{ borderBottom: '1px solid #696969' }}><span style={{ marginLeft: '5px' }}>Well</span></th>
            <th style={{ borderBottom: '1px solid #696969' }}><span style={{ marginLeft: '6px' }}>Section</span></th>
            <th style={{ borderBottom: '1px solid #696969' }}>Location</th>
            <th style={{ borderBottom: '1px solid #696969' }}>Date Created</th>
            <th style={{ borderBottom: '1px solid #696969' }}>Date Updated</th>
        </tr>
    </thead>
    )

const Activity = ({currentRigId, currentCompanyId}) => {
    const {
        store: { getWellM, getWellSectionM, getTrendSheet, getKillSheet },
      } = useMst()
    const router = useNavigate()
    const [isLoading, setIsLoading] = useState('')
    const [activity, setActivity] = useState([])
    const { data, loading, refetch } = useQuery(
        gql`
            query getWellSections($companyId: String!, $rigId: String!, $take: String!) {
                getWellSections(
                    companyId: $companyId,
                    rigId: $rigId,
                    take: $take,
                ) {
                    id
                    name
                    type
                    createdAt
                    updatedAt
                    well {
                        id
                        name
                    }
                }
            }
        `,
        {
            variables: {
                companyId: currentCompanyId.length > 0 ? currentCompanyId : '-',
                rigId: currentRigId.length > 0 ? currentRigId : '-',
                take: '5',
            },
            onCompleted(data) {
                setIsLoading(true)
            },
        }
    )

    useEffect(() => {
        if (isLoading && data) {
            if (data.getWellSections) {
                setActivity([...data.getWellSections])
            }
        }

        return () => {
            setIsLoading(false)
        }
    }, [isLoading, data])

    useEffect(() => {
        if (currentCompanyId !== '') {
            refetch()
        }
    }, [currentCompanyId, refetch])

    async function takeShortcut(well, section) {
        const variables = {
            wellName: underscoreToSpace(well),
            wellSectionName: underscoreToSpace(section),
            companyId: JSON.parse(localStorage.getItem('context') || '').company.id,
            rigId: JSON.parse(localStorage.getItem('context') || '').rig.id,
        }

        document.body.style.cursor='wait'
        try {
            await getWellM(variables)
            await getWellSectionM(variables)
            await getTrendSheet(variables)
            await getKillSheet(variables)
            setTimeout(() => {
                router(`/all-wells/${well}/${section}`)
            }, 1500)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="uiContainer uiContainerWide">
            <div className="uiContainerTitle" style={{ marginLeft: '15px' }}>Recent Activity</div>

            <table className="well dashboardRecentActivity" style={{ width: '97%', marginLeft: '15px' }}>
                <Head />
                <tbody>
                    {!loading && activity.map(({ id, name, type, createdAt, updatedAt, well }) => (
                    <tr
                        style={{ height: '30px !important', backgroundColor: 'transparent' }}
                        key={id}
                        onClick={(e) => {
                            takeShortcut(spaceToUnderscore(well.name), spaceToUnderscore(name))
                        }}
                    >
                        <td style={{ borderBottom: '1px solid transparent', width: 473, fontSize: '13px', fheight: '30px' }} className="hl">
                            <span className="hl" style={{ fontSize: '14px', fontWeight: 400, marginLeft: '7px' }}>{well.name}</span>
                        </td>
                        <td style={{ borderBottom: '1px solid transparent', width: 473, fontSize: '13px', fheight: '30px' }} className="hl">
                            <span className="hl" style={{ fontSize: '14px', fontWeight: 400, marginLeft: '7px' }}>{name}</span>
                        </td>
                        <td style={{ borderBottom: '1px solid transparent', width: 473, fontWeight: 400, fontSize: '13px', height: '30px' }} className="hl">
                            {type === 'CSG' ? 'Casing' : 'Open hole'}
                        </td>
                        <td style={{ borderBottom: '1px solid transparent', width: 333, fontWeight: 400, fontSize: '13px', height: '30px' }} className="hl">
                            {new Date(createdAt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})}
                        </td>
                        <td style={{ borderBottom: '1px solid transparent', width: 266, fontWeight: 400, fontSize: '14px', height: '30px' }} className="hl">
                            {new Date(updatedAt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})}
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>

        </div>
    );
}

export default Activity
