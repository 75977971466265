import { Instance, types, SnapshotIn } from 'mobx-state-tree'

import { v4 as uuid } from 'uuid'
import PipeList from './PipeList'
import TallyString from './TallyString'
import PointsOfInterest from './PointsOfInterest'
import { undoManager } from 'app/store'

export type PipeTallyInstance = Instance<typeof PipeTally>
export type PipeTallySetAttributes = SnapshotIn<typeof PipeTally>

export type StoreDirection = 'stash' | 'trash'
export type EditingModeEnum = 'RIH' | 'POOH' | 'disabled'

export type CreateTallyStringInput = { name?: string; diameter?: string }
export type DeleteTallyStringInput = string | undefined
export type StorePipeInput = { ids?: string[]; direction: StoreDirection }

const PipeTally = types
  .model('PipeTally', {
    id: types.optional(types.identifier, uuid),
    name: types.optional(types.string, uuid),
    tripSheetStub: types.optional(types.number, 0),
    strings: types.optional(types.array(TallyString), [{ number: 1 }]),
    stash: types.optional(PipeList, {}),
    trash: types.optional(PipeList, {}),
    currentStringIndex: types.optional(types.number, 0),
    pointsOfInterests: types.optional(PointsOfInterest, { data: [] }),
    editingMode: types.optional(
      types.enumeration(['RIH', 'POOH', 'disabled']),
      'RIH'
    ),
  })
  .views((self) => ({
    get currentString() {
      return self.strings[self.currentStringIndex]
    },
  }))
  .actions((self) => {
    return {
      setCurrentString(id: string) {
        self.currentStringIndex = self.strings.findIndex(
          ({ id: ID }) => ID === id
        )
      },

      createTallyString(data: CreateTallyStringInput = {}, setCurrent = true) {
        self.strings.push({
          ...data,
          number: self.strings.length + 1,
        })

        if (setCurrent)
          this.setCurrentString(self.strings[self.strings.length - 1].id)
      },

      deleteTallyString(id: DeleteTallyStringInput) {
        const index = id
          ? self.strings.findIndex(({ id: ID }) => ID === id)
          : self.currentStringIndex

        return self.strings.remove(self.strings[index])
      },

      setTripSheetStub(val: number) {
        undoManager.withoutUndo(() => {
            self.tripSheetStub = val
        })
      },

      setEditingMode(mode: EditingModeEnum) {
        undoManager.withoutUndo(() => {
          self.editingMode = mode
          self.currentString.list.selected.clear()
        })
      },

      storePipe(opts: StorePipeInput) {
        if (self.editingMode !== 'disabled') {
          const { ids, direction } = opts
          self[direction].addData(
            self.currentString.list.removeData({
              mode: self.editingMode as 'RIH' | 'POOH',
              ids,
            })
          )
        }
      },

      createEmptyString() {
        self.strings.push(
          TallyString.create({
            number: self.strings[self.strings.length - 1].number + 1,
          })
        )
        self.currentStringIndex = self.strings.length - 1
      },
    }
  })

export default PipeTally
