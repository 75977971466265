import {  useContext, useRef, useEffect } from 'react'
import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import KickCalculatorToolHeader from './KickCalculatorToolHeader'
import KickCalculatorToolColumn from './KickCalculatorToolColumn'
import Clock from '../Well/Clock'
import { getCompanyName, getRigName } from '../../../utils/helpers'

const KickCalculatorTool = observer(() => {
    const { locationState } = useContext(RouterContext)
    const {store: {  WellM }} = useMst()
    const cols = 9

    useEffect(() => {
        const existingCalculator =  (WellM && (
            (WellM?.KickCalculator === undefined) ||
            (WellM?.KickCalculator === null) ||
            (WellM?.KickCalculator.columns.length < cols)
        )) ? false : true

        if (!existingCalculator) {
            WellM?.createKickCalculator()
        }
    }, [WellM])

    const calculator = useRef(WellM?.KickCalculator)

    if (calculator && calculator.current && calculator.current.columns.length < cols) {
        for (let i = 0; i < cols; i++) {
            calculator.current.createEmptyColumn()
        }
    }

    if (calculator && calculator.current && calculator.current.type === '') {
        calculator.current.updateKickCalculator(calculator.current.id, 'type', 'string', locationState?.rigType || 'surface')
        document.getElementById('wellType')?.setAttribute('value', locationState?.rigType || 'surface')
    }

    if (calculator && calculator.current && calculator.current.units === '') {
        calculator.current.updateKickCalculator(calculator.current.id, 'units', 'string', locationState?.units || 'imperial')
        document.getElementById('units')?.setAttribute('value', locationState?.units || 'imperial')
    }

    function updateKickCalculator(id='', fieldName='A1', fieldType='string', value='') {
        if (calculator && calculator.current) {
            calculator.current.updateKickCalculator(id, fieldName, fieldType, value)
        }
    }

    function unfocus() {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }

    function recalculateAllColumns() {
        if (calculator && calculator.current) {
            calculator.current.columns.forEach((col) => {
                col.recalculateColumn(calculator.current?.units || '')
            })
        }
    }

    // console.log('KickCalculator', JSON.stringify(calculator.current))
    // console.log('kc-meta', calculator.current?.type, calculator.current?.units)

    return (
        (calculator.current && calculator.current.type.length > 0) ? (
        <div className="">
            <h2 style={{ color: 'dodgerblue', marginLeft: '8px' }}>Kick Calculator</h2>
            <div className="" style={{ backgroundColor: 'transparent', width: '100%' }}>

                <div style={{ backgroundColor: 'transparent', width: '100%' }}>
                    <div className="kickCalculatorSettings">
                        <div className="kickCalculatorContextLabels">
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Company</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Rig</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Well Description</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Well Number</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Location</div>
                        </div>
                        <div className="kickCalculatorContextValues">
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>{getCompanyName()}</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>{getRigName()}</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}><input className="kickCalculatorInput" value={calculator.current.well} onChange={(e) => {updateKickCalculator(calculator.current!.id, 'well', 'string', e.target.value)}} /></div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}><input className="kickCalculatorInput" value={calculator.current.wellNumber} onChange={(e) => {updateKickCalculator(calculator.current!.id, 'wellNumber', 'string', e.target.value)}} /></div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}><input className="kickCalculatorInput" value={calculator.current.location} onChange={(e) => {updateKickCalculator(calculator.current!.id, 'location', 'string', e.target.value)}} /></div>
                        </div>
                        <div className="kickCalculatorTypeLables">
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Type</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Units</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>RAM BOP Rated W.P.</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Water Depth</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>RKB to Water (Air Gap)</div>
                        </div>
                        <div className="kickCalculatorTypeValues">
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>
                                <select
                                    id="wellType"
                                    className="formFieldSelect wellSectionHeaderDropdown kickCalculatorSelect"
                                    onChange={(e) => {updateKickCalculator(calculator.current!.id, 'type', 'string', e.target.value)}}
                                    value={locationState?.rigType === 'floater' ? 'subsea' : 'surface'}
                                    style={{
                                        marginTop: '0px',
                                        textAlign: "center",
                                        fontSize: "12pt",
                                        whiteSpace: "nowrap",
                                        fontFamily: "sans-serif",
                                        fontWeight: 400,
                                    }}
                                    onBlur={(e) => {unfocus()}}
                                >
                                    <option value="subsea" className={locationState?.rigType === 'floater' ? 'subsea' : 'invisible'} style={{ color: "orange" }}>Subsea</option>
                                    <option value="surface" className={locationState?.rigType === 'floater' ? 'invisible' : 'surface'} style={{ color: "orange" }}>Surface</option>
                                </select>
                            </div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>
                                <select
                                    id="units"
                                    className="formFieldSelect wellSectionHeaderDropdown kickCalculatorSelect"
                                    onChange={(e) => {
                                        updateKickCalculator(calculator.current!.id, 'units', 'string', e.target.value)
                                        recalculateAllColumns()
                                    }}
                                    value={locationState?.units}
                                    style={{
                                        marginTop: '0px',
                                        textAlign: "center",
                                        fontSize: "12pt",
                                        whiteSpace: "nowrap",
                                        fontFamily: "sans-serif",
                                        fontWeight: 400,
                                    }}
                                    onBlur={(e) => {unfocus()}}
                                >
                                    <option value="imperial" className={locationState?.units === 'imperial' ? '' : 'invisible'} style={{ color: "orange" }}>Imperial</option>
                                    <option value="metric" className={locationState?.units === 'metric' ? '' : 'invisible'} style={{ color: "orange" }}>Metric</option>
                                    <option value="hybrid" className={locationState?.units === 'hybrid' ? '' : 'invisible'} style={{ color: "orange" }}>Hybrid</option>
                                </select>
                            </div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>
                            <input
                                    id="ramBop"
                                    onFocus={e => e.target.select()}
                                    onClick={(e) => {e.stopPropagation()}}
                                    type="number"
                                    value={calculator.current!.ramBop}
                                    className="kickCalculatorInput"
                                    onChange={(e) => {
                                        updateKickCalculator(
                                            calculator.current!.id,
                                            'ramBop',
                                            'number',
                                            e.target.value === "" ? "0" : e.target.value
                                        )
                                        recalculateAllColumns()
                                    }}
                                />
                            </div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>
                                <input
                                    id="waterDepth"
                                    onFocus={e => e.target.select()}
                                    onClick={(e) => {e.stopPropagation()}}
                                    type="number"
                                    value={calculator.current!.waterDepth}
                                    className="kickCalculatorInput"
                                    onChange={(e) => {
                                        updateKickCalculator(
                                            calculator.current!.id,
                                            'waterDepth',
                                            'number',
                                            e.target.value === "" ? "0" : e.target.value
                                        )
                                        recalculateAllColumns()
                                    }}
                                />
                            </div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>
                                <input
                                    id="rkbToWater"
                                    onFocus={e => e.target.select()}
                                    onClick={(e) => {e.stopPropagation()}}
                                    type="number"
                                    value={calculator.current!.rkbToWater}
                                    className="kickCalculatorInput"
                                    onChange={(e) => {
                                        updateKickCalculator(
                                            calculator.current!.id,
                                            'rkbToWater',
                                            'number',
                                            e.target.value === "" ? "0" : e.target.value
                                        )
                                        recalculateAllColumns()
                                    }}
                                />
                            </div>
                        </div>
                        <div className="kickCalculatorUserLabels">
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Client</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Prepared By</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Checked By</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Date: </div>
                        </div>
                        <div className="kickCalculatorUserValues">
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}><input className="kickCalculatorInput" value={calculator.current!.client} onChange={(e) => {updateKickCalculator(calculator.current!.id, 'client', 'string', e.target.value)}} /></div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}><input className="kickCalculatorInput" value={calculator.current!.preparedBy} onChange={(e) => {updateKickCalculator(calculator.current!.id, 'preparedBy', 'string', e.target.value)}} /></div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}><input className="kickCalculatorInput" value={calculator.current!.checkedBy} onChange={(e) => {updateKickCalculator(calculator.current!.id, 'checkedBy', 'string', e.target.value)}} /></div>
                            <div className="wellInfoTimeClockWrapper nowrap h25"
                                style={{ marginTop: '-23px' }}
                            >
                                <div style={{ fontSize: '14px', color: 'silver' }} className="timeclock wellInfoTimeClock"><Clock /></div>
                                <div style={{ fontSize: '14px', color: 'silver' }} id="timeclock" className="timeclock"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '120px' }}>
                    <KickCalculatorToolHeader settingsType={calculator.current!.type} settingsUnits={calculator.current!.units} />

                    {calculator.current ? (calculator.current!.columns.map(({ id, index, ...props }, idx) => (
                        <KickCalculatorToolColumn
                            {...props}
                            key={id}
                            id={id}
                            index={index}
                            kc={calculator.current!}
                            wellType={locationState?.rigType || 'surface'}
                            units={locationState?.units || 'imperial'}
                        />
                        ))) : ('')
                    }
                </div>

                <div style={{
                    textAlign: 'center',
                    marginLeft: '0px',
                    marginTop: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    borderTop: '2px solid dimgray',
                    paddingTop: '25px',
                }}>
                    <div style={{
                    margin: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '600px',
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: 700,
                    border: '1px solid white',
                    borderRadius: '8px',
                    padding: '20px',
                    backgroundColor: 'dimgray',
                    color: 'black'
                    }}>
                        <span style={{ color: 'black', fontWeight: 400, marginRight: '10px' }}>Color Code Legend:</span> &nbsp;
                        <span style={{ backgroundColor: 'red', color: '#fff', padding: '5px' }}>Not Acceptable</span>
                        <span style={{ backgroundColor: 'yellow', color: '#000000', padding: '5px' }}>MoC Required</span>
                        <span style={{ backgroundColor: 'green', color: '#fff', padding: '5px' }}>Acceptable</span>
                        <span style={{ backgroundColor: '#a4bbc7', color: '#fff', padding: '5px' }}>Description</span>
                    </div>
                </div>

                <div style={{ height: '50px' }}>&nbsp;</div>

            </div>
        </div>
        ) : <></>
    )
})

export default KickCalculatorTool