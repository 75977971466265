import withApollo from 'app/withApollo'
import { createContext, useState, SetStateAction } from 'react'
import { ThemeProvider, withTheme } from '@mui/styles'
import { unstable_createMuiStrictModeTheme } from '@mui/material/styles'
import MainContents from 'app/components/Router'
import 'app/components/kit/fonts/stylesheet.css'
import { User } from 'app/components/Layout'
import { BrowserRouter } from 'react-router-dom'

export const UserContext = createContext<{
  user: User
  setUser: ((arg: SetStateAction<User>) => void) | null
}>({ user: null, setUser: null })

const App = () => {
  const [user, setUser] = useState<User>(null)
  // const theme = createMuiTheme({
  const theme = unstable_createMuiStrictModeTheme({
    palette: {
      secondary: { main: '#5C85F5' },
      primary: { main: '#5C85F5' },
      text: { secondary: '#737496', primary: '#fffff' },
    },
    // overrides: {
    //   MuiStepIcon: {
    //     root: {
    //       color: '#353750',
    //     },
    //     text: {
    //       color: '#737496',
    //       fill: 'unset',
    //     },
    //   },
    // },
  })

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <UserContext.Provider value={{ user, setUser }}>
            <MainContents />
          </UserContext.Provider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default withTheme(withApollo(App))
