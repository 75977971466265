import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import StubRow from './StubRow';
import { TableHeader } from './TableHeader';
import TripTableRow from './TripTableRow';
import warning from '../../../assets/images/icons/icon_warning.svg';
import './TripSheet.css'

const TripTable = observer (() => {
  const {
      store: { PipeTally, TripSheet },
  } = useMst()

  const TripSheetRowsReversed = TripSheet?.rows.slice(0).sort((a, b) => b.index - a.index)

  return (
    (TripSheet?.rows.length || 0) > 0 ? (
      <div className="tripTable">
        <div className="" style={{
            display: 'table',
            borderTop: '1px solid #3d3d3d',
            borderLeft: '1px solid #3d3d3d',
            borderRadius: '8px',
            width: '101.5%',
            marginBottom: '10px',
            marginLeft: '-8px'
        }}>
            <TableHeader />
            <StubRow />
            {TripSheet && TripSheetRowsReversed ? (
              PipeTally?.editingMode !== 'disabled' ? (
                  TripSheet.rows.map(({ id, ...props }, idx) => (
                    <TripTableRow
                      {...props}
                      key={id}
                      id={id}
                      bhaLength={0}
                      currentString={0}
                      description={TripSheet.rows[idx].description}
                      standNumber={(TripSheet.rows[idx].standNumber / parseInt(TripSheet.rows[idx].standLength))}
                      discrepancyAlarm={TripSheet.discrepancyAlarm}
                      onClick={function (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
                        throw new Error('Function not implemented.');
                      }}
                    />
                  ))
              ) : ('')
            ) : ('')
          }
        </div>
    </div>
    ) : (
      <div style={{ color: '#fff', padding: '20px', display: 'flex', flexDirection: 'row' }}>
        <div><img alt="warning" src={warning} className="filter-blue" style={{ marginTop: '10px', width: '25px', height: '25px', marginRight: '20px' }} /></div>
        <div style={{ marginTop: '11px', color: '#0096FF' }}>To view a Trip Sheet you must have a Trend Sheet with depth set in at least one row</div>
      </div>
    )
  );
})

export default TripTable
