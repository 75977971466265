import { useEffect, useState } from 'react';
import { observer } from 'mobx-react'
import { useLocation } from 'react-router'
import { useMst } from 'app/store'
import { validateTwoDecimals } from 'utils/helpers';

const TableToolbar = observer(() => {
    const {
        store: { PipeTally, TripSheet },
    } = useMst()

    const [mwSlug, setMwSlug] = useState(0)
    const [volSlug, setVolSlug] = useState(0)
    const [mw, setMw] = useState(0)
    const [flowbackVolume, setFlowbackVolume] = useState(0)

    const location = useLocation()
    const uri = location.pathname

    useEffect(() => {
        setVolSlug(TripSheet?.volslug || 0)
        setMwSlug(TripSheet?.mwslug || 0)
        setMw(TripSheet?.mw || 0)
        setFlowbackVolume((mwSlug - mw)/mw*volSlug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setVolSlug(TripSheet?.volslug || 0)
        setMwSlug(TripSheet?.mwslug || 0)
        setMw(TripSheet?.mw || 0)
        setFlowbackVolume((mwSlug - mw)/mw*volSlug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mwSlug, volSlug, mw, uri, location, TripSheet])

    return (
        <div className={PipeTally?.editingMode === 'RIH' ? 'invisible' : ''} style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div style={{ backgroundColor: 'transparent', width: '100%' }}></div>
            <div className="tripToolbar">
                <div className="tripToolbarCenter">
                    <div>
                        <table style={{ width : '100%' }}>
                            <tbody>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr>
                                    <td style={{ width: '75px', textAlign: 'right' }}>VOLSLUG</td>
                                    <td>
                                        <input
                                            style={{ width: 120, fontSize: '12pt', borderBottom: '1px solid dimgry', marginRight: 16 }}
                                            id="volslug"
                                            title="VOLSLUG"
                                            type="number"
                                            step="0.01"
                                            value={volSlug}
                                            onChange={(e) => {
                                                validateTwoDecimals(e.target)
                                                setVolSlug(parseFloat(e.target.value))
                                                TripSheet?.updateVolSlug(parseFloat(e.target.value))
                                            }}
                                            className="editableInput"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                <td style={{ width: '75px', textAlign: 'right' }}>MWSLUG</td>
                                    <td>
                                        <input
                                            style={{ width: 120, fontSize: '12pt', borderBottom: '1px solid dimgry', marginRight: 16 }}
                                            id="mwslug"
                                            title="MWSLUG"
                                            type="number"
                                            step="0.01"
                                            value={mwSlug}
                                            onChange={(e) => {
                                                validateTwoDecimals(e.target)
                                                setMwSlug(parseFloat(e.target.value))
                                                TripSheet?.updateMwSlug(parseFloat(e.target.value))
                                            }}
                                            className="editableInput"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                <td style={{ width: '75px', textAlign: 'right' }}>MW</td>
                                    <td>
                                    <input
                                            style={{ width: 120, fontSize: '12pt', marginRight: 16 }}
                                            id="mw"
                                            title="MW"
                                            type="number"
                                            inputMode='numeric'
                                            pattern="[0-9]+([,\.][0-9]+)?"
                                            step="0.01"
                                            value={mw}
                                            onChange={(e) => {
                                                validateTwoDecimals(e.target)
                                                setMw(parseFloat(e.target.value))
                                                TripSheet?.updateMw(parseFloat(e.target.value))
                                            }}
                                            className="editableInput"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="tripToolbarRight">
                    <table style={{ marginTop: '10px', fontStyle: 'italic', fontFamily: 'cursive'}}>
                        <tbody>
                            <tr>
                                <td>
                                <div style={{ paddingTop: '2px', marginRight: '80px' }}>
                                    {/* <img alt="formula" src={formulaFlowbackVolume} style={{ width: '375px' }} /><br /> */}
                                    <div style={{ width: '375px', paddingTop: '2px', marginRight: '20px', marginLeft: '2px' }}>
                                        <div>
                                            <span style={{ color: 'dodgerblue', fontSize: '19px', fontStyle: 'normal', fontFamily: 'Times New Roman, serif' }}>Flowback Volume</span>
                                            <span style={{ color: '#fff', fontSize: '19px', fontStyle: 'italic', fontFamily: 'Times New Roman, serif' }}>
                                                = Volslug *
                                                <span style={{ marginLeft: '8px', borderBottom: '1px solid #fff', fontSize: '19px', fontStyle: 'italic', fontFamily: 'Times New Roman, serif' }}>MWslug - MW</span>
                                            </span>
                                        </div>
                                        <div style={{
                                            color: '#fff',
                                            fontSize: '19px',
                                            fontStyle: 'italic',
                                            fontFamily: 'Times New Roman, serif',
                                            textAlign: 'right',
                                            marginRight: '50px'
                                        }}>
                                            MW
                                        </div>
                                    </div>
                                </div>
                                </td>
                                <td style={{ width: '80px' }} />
                                <td>
                                    <table style={{ fontSize: '14pt', marginTop: '0px' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ color: 'dodgerblue' }}>{(!isNaN(isFinite(flowbackVolume) ? flowbackVolume : 0) ? isFinite(flowbackVolume) ? flowbackVolume : 0 : 0).toFixed(2)}</td>
                                                <td>=</td>
                                                <td>{volSlug.toFixed(2)}</td>
                                                <td>x</td>
                                                <td>
                                                    <table style={{ borderCollapse: 'collapse' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ borderBottom: '1px solid silver' }}>{mwSlug.toFixed(2)}</td>
                                                                <td style={{ borderBottom: '1px solid silver' }}>-</td>
                                                                <td style={{ borderBottom: '1px solid silver' }}>{mw.toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'center' }} colSpan={3}>{mw.toFixed(2)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ paddingTop: '2px', marginRight: '20px', marginLeft: '2px' }}>
                                        <span style={{ color: 'dodgerblue', fontSize: '19px', fontStyle: 'normal', fontFamily: 'Times New Roman, serif' }}>Length of Dry Pipe</span>
                                        <span style={{ color: '#fff', fontSize: '19px', fontStyle: 'italic', fontFamily: 'Times New Roman, serif' }}> = Flowback Volume / Capacity</span>
                                    </div>
                                </td>
                                <td style={{ width: '80px' }} />
                                <td>
                                    <div style={{ fontSize: '14pt', marginTop: '12px', marginLeft: '2px' }}>
                                        <span style={{ color: 'dodgerblue' }}>
                                            {
                                                ((!isNaN(isFinite(flowbackVolume) ? flowbackVolume : 0) ? isFinite(flowbackVolume) ? flowbackVolume : 0 : 0)
                                                / (TripSheet && TripSheet.rows.length > 0 ? TripSheet.rows[0].cap : 0)).toFixed(2)
                                            }
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
})

export default TableToolbar
