import { Component, ReactNode } from 'react'
import { ThemeProvider, useTheme as useThemeGeneric } from '@mui/styles'

export const theme = {
  colors: {
    well: {
      bg: {
        primary: '#2B2C41',
        secondary: '#2D2E44',
        tertiary: '#34354F',
      },
      hover: '#414361',
      primary: '#5C85F5',
      primaryHover: '#4260B2',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#737496',
    },
    additional: {
      green: '#1ADE8F',
      yellow: '#E8C752',
      red: '#FD4444',
    },
  },
  spacing: (n: number) => `${n * 4}px`,
}

export type Theme = typeof theme

export function withTheme(children: ReactNode) {
  return class extends Component {
    render() {
      return <ThemeProvider theme={theme}>{children}</ThemeProvider>
    }
  }
}

export const useTheme = () => useThemeGeneric<typeof theme>()
