import { Link } from 'react-router-dom'

const Links = () => {
    return (
        <div className="uiContainer uiContainerShallow" style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="uiContainerTitle">Quick Links</div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{
                    marginTop: '35px',
                    marginBottom: '35px',
                    marginLeft: '5px',
                    marginRight: '5px',
                }}>
                    <Link to="/all-wells" style={{
                        padding: '20px',
                        border: '1px dotted dodgerblue',
                        color: 'white',
                        borderRadius: '8px',
                    }} className="uiContainerLinkProminent">
                        Wells
                    </Link>
                </div>
                <div style={{
                        marginTop: '35px',
                        marginBottom: '35px',
                        marginLeft: '5px',
                        marginRight: '5px',
                    }}>
                    <Link to="/library/tubulars" style={{
                        padding: '20px',
                        border: '1px dotted dodgerblue',
                        color: 'white',
                        borderRadius: '8px',
                    }}  className="uiContainerLinkProminent">
                        Tubulars
                    </Link>
                </div>
                <div style={{
                        marginTop: '35px',
                        marginBottom: '35px',
                        marginLeft: '5px',
                        marginRight: '35px',
                    }}>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{
                        marginTop: '15px',
                        marginBottom: '35px',
                        marginLeft: '5px',
                        marginRight: '5px',
                    }}>
                    <Link to="/RotatingHours" style={{
                        padding: '20px',
                        border: '1px dotted dodgerblue',
                        color: 'white',
                        borderRadius: '8px',
                    }}  className="uiContainerLinkProminent">
                        Rotating Hours
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Links
