import { useEffect, useContext } from 'react';
import { RouterContext } from '../Router'
import Clock from '../../components/Well/Clock'
import { SectionTabs } from '../WellSection/SectionTabs'
import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import { getLabel, getStatistic, unitConversion, checkIsNumber } from '../../../utils/helpers'
import TextField from '@mui/material/TextField';
import Units from '../../store/Units.json'
import PrintInfo from 'app/components/kit/PrintInfo'
import kill_clear from '../../../assets/images/misc/kill_clear.webp'
import kill_land from '../../../assets/images/misc/kill_land.webp'
import icon_tooltip from '../../../assets/images/icons/icon_tooltip.svg'
//import { event } from 'wonka/dist/types/src/shims/Dom.shim';
import warning from '../../../assets/images/icons/icon_warning.svg'
import './KillSheet.css'

export type User = {
  id: string
  email: string
  password: string
  firstName: string | null
  secondName: string | null
  companyId: string | null
  rigId: string | null
  createdAt: Date
  permissions: string[]
  scope: string[]
  company: {
      id: string
      name: string
  }
  rig: {
      id: string
      name: string
      type: string
  }
} | null

const KillSheetUi = observer(() => {
    const {
        store: { PipeTally, BHA, CasingTally, LinerTally, KillSheet, TrendSheet, WellM, WellSectionM },
    } = useMst()
    const { locationState } = useContext(RouterContext)
    const sqInchToBblFt = 1029.4

    let pipeTallyLength = 0
    PipeTally?.strings.forEach((string:any) => {
      pipeTallyLength += parseFloat(string.totalLength)
    })

    let casingTallyLength = 0
    CasingTally?.strings.forEach((string:any) => {
      casingTallyLength += parseFloat(string.totalLength)
    })

    let linerTallyLength = 0
    LinerTally?.strings.forEach((string:any) => {
      linerTallyLength += parseFloat(string.totalLength)
    })

    let bhaTotalLength = 0
    BHA?.tally(0).map((part:any, i) => {
      return bhaTotalLength += parseFloat(part.length)
    })

    let preReqs = bhaTotalLength + casingTallyLength + linerTallyLength > 0 ? true : false
    preReqs = locationState?.rigType !== 'land' ? pipeTallyLength === 0 ? false : preReqs : preReqs

    useEffect(() => {
      if (KillSheet) {
        // if it is the first use configure one
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [KillSheet])

    useEffect(() => {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }, []);
    const alertUser = (e: Event) => {
      e.preventDefault();
      console.log('moving...')
      alert('moving...')
      window.location.href = '/'
    };

    function getConversionStatistic(key: string) {
      const conversion = Units[Units.findIndex(({ handle }) => handle === key)]
      if (conversion && locationState) {
        return conversion
      }
    }

    function convertUTCDateToLocalDate(date:any) {
      var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    }

    function convertDateToMuiString(date:any) {
      if (date) {
          return date.getFullYear()+ "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" +("00" + date.getDate()).slice(-2)+"T"+ ("00" + date.getHours()).slice(-2) + ":" +("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2)
        }
      return date.toLocaleString()
    }

    function matrixMetric(value: number) {
      if (locationState) {
        return value * (locationState?.units === 'metric' ? parseFloat(getConversionStatistic('volumeDistance')!.impToMet) : 1)
      }
      return 0
    }

    function getLastTrendSheetRow() {
        if (TrendSheet) {
            if (TrendSheet.rows.length > 0) {
                return TrendSheet.rows[TrendSheet.rows.length - 1]
            }
        }
        return null
    }

    function getCurrentDSDepth() {
      const lastRow = getLastTrendSheetRow();
      return lastRow?.depth || 0;
    }

    function roundDown(number: number, decimals: number) {
      decimals = decimals || 0;
      return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
    }

    function getMaxLinerShoeTvd() {
      if (WellM) {
          if (WellM.LinerShoeTvd5 > 0) {
              return WellM.LinerShoeTvd5
          } else if (WellM.LinerShoeTvd4 > 0) {
              return WellM.LinerShoeTvd4
          } else if (WellM.LinerShoeTvd3 > 0) {
              return WellM.LinerShoeTvd3
          } else if (WellM.LinerShoeTvd2 > 0) {
              return WellM.LinerShoeTvd2
          } else {
              return WellM.LinerShoeTvd1
          }
      }
      return 0
    }

    function doubleInputUpdater(val: string) {
      KillSheet?.updateKillSheetData(KillSheet.id, 'sidpp', 'number', val.toString())
      KillSheet?.updateKillSheetData(KillSheet.id, 'currentSbp', 'number', val.toString())
      const input1 = (document.getElementById('sidpp')! as HTMLInputElement)
      const input2 = (document.getElementById('currentSbp')! as HTMLInputElement)
      if (input1 && input2) {
        input1.value = val
        input2.value = val
      }
    }

    function getMaximumAllowableDrillFluidDensity(units: string) {
      let val = 0

      if (WellM) {
        let A = 0
        let B = 0
        let C = 0
        let result = 0

        A = KillSheet?.surfaceLeakOffPressure || 0
        B = KillSheet?.drillingFluidDensity || 0
        if (getMaxLinerShoeTvd() > WellM.casingShoeTvd) {
          C = getMaxLinerShoeTvd()
        } else {
          C = WellM.casingShoeTvd
        }
        result = B + A / (C * (units === 'metric' ?
          parseFloat(getConversionStatistic('densityPressure')!.metRate) :
          parseFloat(getConversionStatistic('densityPressure')!.impRate))
        )
        val = roundDown(result, units === 'metric' ? 2 : 1)
        if (isNaN(val)) { val = 0 }
        if (!isFinite(val)) { val = 0 }
        return val
      }
      return 0
    }

    function getInitialMaasp(units: string) {
      let val = 0

      if (WellM) {
        let A = 0
        let B = 0
        let C = 0
        let D = 0
        let E = 0
        let F = 0
        let result = 0

        A = KillSheet?.surfaceLeakOffPressure || 0
        B = KillSheet?.drillingFluidDensity || 0
        D = WellM!.casingShoeTvd || 0
        E = getLastTrendSheetRow()?.mwOut || 0

        if (getMaxLinerShoeTvd() > WellM.casingShoeTvd) {
          C = getMaxLinerShoeTvd()
          F = roundDown(B + A / (C * (units === 'metric' ?
              parseFloat(getConversionStatistic('densityPressure')!.metRate) :
              parseFloat(getConversionStatistic('densityPressure')!.impRate)
          )), 1)
          result = (F - E) * (C * (units === 'metric' ?
              parseFloat(getConversionStatistic('densityPressure')!.metRate) :
              parseFloat(getConversionStatistic('densityPressure')!.impRate)
          ))
      } else {
          C = WellM.casingShoeTvd
          F = roundDown(B + A / (C * (units === 'metric' ?
              parseFloat(getConversionStatistic('densityPressure')!.metRate) :
              parseFloat(getConversionStatistic('densityPressure')!.impRate)
          )), 1)
          result = (F - E) * (D * (units === 'metric' ?
              parseFloat(getConversionStatistic('densityPressure')!.metRate) :
              parseFloat(getConversionStatistic('densityPressure')!.impRate)
          ))
      }
        val = roundDown(result, 0)
        if (isNaN(val)) { val = 0 }
        if (!isFinite(val)) { val = 0 }
        return val
      }
      return 0
    }

    function getLastStringOd() {
      if (PipeTally) {
        if (PipeTally.strings.length > 0 && parseFloat(PipeTally.strings[0].totalLength) > 0) {
          return PipeTally.strings[PipeTally.strings.length - 1].getPipe(PipeTally.strings[PipeTally.strings.length - 1].name || '').OD
        }
      }
      return 0
    }

    function getDensityMultiplier(units: string) {
      if (units === 'metric') {
        return '0.0981'
      } else if (units === 'imperial') {
        return '0.052'
      } else if (units === 'hybrid') {
        return '0.170612'
      }
      return '0'
    }

    function getMarineRiserLength() {
      if (WellM && locationState && locationState.rigType === 'floater') {
        return WellM!.airGap + WellM.waterDepth
      }
      return 0
    }

    function getRiserSpm() {
      if (KillSheet) {
        if(KillSheet.icpOverride) {
          return KillSheet.icpOverride - KillSheet.sidpp;
        } else if (KillSheet.pump === 1) {
          if (KillSheet.spm === 20) {
            return KillSheet.riser120
          } else if (KillSheet.spm === 30) {
            return KillSheet.riser130
          } else if (KillSheet.spm === 40) {
            return KillSheet.riser140
          } else if (KillSheet.spm === 50) {
            return KillSheet.riser150
          }
        } else if (KillSheet.pump === 2) {
          if (KillSheet.spm === 20) {
            return KillSheet.riser220
          } else if (KillSheet.spm === 30) {
            return KillSheet.riser230
          } else if (KillSheet.spm === 40) {
            return KillSheet.riser240
          } else if (KillSheet.spm === 50) {
            return KillSheet.riser250
          }
        }
      }
      return 0
    }

    function getChokeSpm() {
      if (KillSheet) {
        if (KillSheet.pump === 1) {
          if (KillSheet.spm === 20) {
            return KillSheet.choke120 - KillSheet.riser120
          } else if (KillSheet.spm === 30) {
            return KillSheet.choke130 - KillSheet.riser130
          } else if (KillSheet.spm === 40) {
            return KillSheet.choke140 - KillSheet.riser140
          } else if (KillSheet.spm === 50) {
            return KillSheet.choke150 - KillSheet.riser150
          }
        } else if (KillSheet.pump === 2) {
          if (KillSheet.spm === 20) {
            return KillSheet.choke220 - KillSheet.riser220
          } else if (KillSheet.spm === 30) {
            return KillSheet.choke230 - KillSheet.riser230
          } else if (KillSheet.spm === 40) {
            return KillSheet.choke240 - KillSheet.riser240
          } else if (KillSheet.spm === 50) {
            return KillSheet.choke250 - KillSheet.riser250
          }
        }
      }
      return 0;
    }

    function getInfluxHeight() {
      const result = (
        (
          getInitialMaasp(locationState?.units || "") -
          (
            (KillSheet?.kickIntensity || 0)
            * Number(getStatistic('constants', locationState))
            * unitConversion("lengthMedium", locationState?.units, "out", (KillSheet?.holeTvd || 0), 15)
          )
        )
        /
        (
          (
            (getLastTrendSheetRow()?.mwOut || 0)
            * Number(getStatistic('constants', locationState))
          )
          -
          (KillSheet?.influxGradientGas || 0)
        )
      )

      return result
    }

    function getUsableVolume(matrix: any, distance: number) {
      // walk up pipes consuming lengths until distance is expired
      // keep an accumulator of length * capacity
      let usableVolume = 0
      let pipeLengthProcessed = 0

      for (let i = 0; i < matrix.length; i++) {
        const pipe = matrix[i]
        const pipeLength = unitConversion("lengthMedium", locationState?.units, "out", pipe[3], 15)
        const pipeCapacity = unitConversion("lengthMedium", locationState?.units, "in", pipe[4], 15)

        if (distance > (pipeLengthProcessed + pipeLength)) {
          usableVolume += pipeLength * pipeCapacity
        } else {
          usableVolume += (distance - pipeLengthProcessed) * pipeCapacity
          break
        }
        pipeLengthProcessed += pipeLength
      }
      return usableVolume
    }

    function calculatePipeInfluxBhaVolume() {
      // const distance = getInfluxHeight() - bhaTotalLength > 0 ? getInfluxHeight() - bhaTotalLength : 0
      const distance = getInfluxHeight() - unitConversion("lengthMedium", locationState?.units, "out", bhaTotalLength, 15) > 0 ? getInfluxHeight() - unitConversion("lengthMedium", locationState?.units, "out", bhaTotalLength, 15) : 0
      if (killSheetMatrix) {
        let openHolePipeMatrix = killSheetMatrix
        openHolePipeMatrix = openHolePipeMatrix?.filter((row) => row[1] === 'openhole-block')
        openHolePipeMatrix.pop()
        openHolePipeMatrix.pop()
        openHolePipeMatrix = openHolePipeMatrix.reverse()
        return (unitConversion("lengthMedium", locationState?.units, "in", getMatrixValue(killSheetMatrix, 'openhole-block', 'BHA x Open Hole', 4), 15) * unitConversion("lengthMedium", locationState?.units, "out", bhaTotalLength, 15)) + getUsableVolume(openHolePipeMatrix, distance)
      }
      return 0
    }

    function calculatePipeInfluxCasingLinerVolume() {
      const distance = getInfluxHeight() // - casingTallyLength - linerTallyLength > 0 ? getInfluxHeight() - casingTallyLength - linerTallyLength : 0
      if (killSheetMatrix) {
        let openHolePipeMatrix = killSheetMatrix
        openHolePipeMatrix = openHolePipeMatrix?.filter((row) => row[1] === 'openhole-block')
        openHolePipeMatrix.pop()
        openHolePipeMatrix.pop()
        openHolePipeMatrix = openHolePipeMatrix?.filter((row) => row[8] === 'casing' || row[8] === 'liner')
        openHolePipeMatrix = openHolePipeMatrix.reverse()
        return getUsableVolume(openHolePipeMatrix, distance)
      }
      return 0
    }

    function calculatePipeInfluxVolumeAtBottomOfHole() {
      if (locationState?.wellSectionType === 'OH') {
        return calculatePipeInfluxBhaVolume()
      } else {
        return calculatePipeInfluxCasingLinerVolume()
      }
    }

    function calculatePipeInfluxVolumeAtShoeBase() {
      const distance = getInfluxHeight()
      if (killSheetMatrix) {
        let openHolePipeMatrix = killSheetMatrix
        openHolePipeMatrix = openHolePipeMatrix?.filter((row) => row[1] === 'openhole-block')
        // do not remove the BHA, pass it in as well as the pipes
        // openHolePipeMatrix.pop()
        openHolePipeMatrix.pop()
        return getUsableVolume(openHolePipeMatrix, distance)
      }
      return 0
    }

    function calculateInfluxVolumeAtBottom() {
      const distance = unitConversion("lengthMedium", locationState?.units, "out", getMaxLinerShoeTvd() > (WellM?.casingShoeTvd || 0) ? getMaxLinerShoeTvd() : (WellM?.casingShoeTvd || 0), 15)
      const shoeInflux = calculatePipeInfluxVolumeAtShoeBase()
      const holeTvd = unitConversion("lengthMedium", locationState?.units, "out", (KillSheet?.holeTvd || 0), 15)
      const convertAt = Number(getStatistic('constants', locationState))
      const madfd = getMaximumAllowableDrillFluidDensity(locationState?.units || '')
      const density = getLastTrendSheetRow()?.mwOut
      const leakOffPressure = convertAt * madfd * distance
      const formationPressure = convertAt * ((density || 0) + (KillSheet?.kickIntensity || 0)) * holeTvd

      return (shoeInflux * leakOffPressure) / formationPressure
    }

    function getOpenHoleLength() {
      const distance = unitConversion("lengthMedium", locationState?.units, "out", getRealHoleTVD(), 15)
      const holeTvd = unitConversion("lengthMedium", locationState?.units, "out", (unitConversion("lengthMedium", locationState?.units, "out", getLinerCasingTVD(), 15)), 15)
      return distance - holeTvd
    }

    function getBhaTotalLength() {
      if (BHA && BHA.parts.length > 0) {
        let bhaTotalLength = 0
        // eslint-disable-next-line array-callback-return
        BHA?.tally(0).map((part: any, i) => {
          bhaTotalLength += part.length
          if (i === BHA.parts.length - 1) {
            return bhaTotalLength
          }
        })
        return bhaTotalLength
      }
      return 0
    }

    function getBhaTotalLengthCapacity() {
      if (BHA && BHA.parts.length > 0 && WellM) {
        let bhaTotalLengthCapacity = 0
        // eslint-disable-next-line array-callback-return
        BHA?.tally(0).map((part: any, i) => {
          bhaTotalLengthCapacity += part.length * (part.ID * part.ID) / (locationState?.units === 'metric' ? 1973.616 : sqInchToBblFt)
          if (i === BHA.parts.length - 1) {
            return bhaTotalLengthCapacity
          }
        })
        return bhaTotalLengthCapacity
      }
      return 0
    }

    function getPreRecordedFlightStrokes(volume: number) {
      let val = 0
      if (KillSheet) {
        KillSheet && KillSheet.pump === 1 ?
        val  = (volume / KillSheet.displacementPump1) :
        val = (volume / KillSheet.displacementPump2)
      }
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getOpenHoleFlightStrokes(totalVolume: number) {
      let val = 0
      if (KillSheet) {
        KillSheet && KillSheet.pump === 1 ?
        val  = (totalVolume / KillSheet.displacementPump1) :
        val = (totalVolume / KillSheet!.displacementPump2)
      }
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getPreRecordedTime(volume: number) {
      let val = 0
      if (KillSheet) {
        KillSheet && KillSheet.pump === 1 ?
        val = (volume / KillSheet.displacementPump1 / KillSheet.spm) :
        val = (volume / KillSheet.displacementPump2 / KillSheet.spm)
      }
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getOpenHoleTime(totalVolume: number) {
      let val = 0
      if (KillSheet) {
        KillSheet && KillSheet.pump === 1 ?
        val = (totalVolume / KillSheet.displacementPump1 / KillSheet.spm) :
        val = (totalVolume / KillSheet.displacementPump2 / KillSheet.spm)
      }
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getCasingLinerStrokes(totalVolume: number) {
      let val = 0
      if (KillSheet) {
        KillSheet && KillSheet.pump === 1 ?
        val  = (totalVolume / KillSheet.displacementPump1) :
        val = (totalVolume / KillSheet!.displacementPump2)
      }
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getCasingLinerMinutes(totalVolume: number) {
      let val = 0
      if (KillSheet) {
        KillSheet && KillSheet.pump === 1 ?
        val = (totalVolume / KillSheet.displacementPump1 / KillSheet.spm) :
        val = (totalVolume / KillSheet.displacementPump2 / KillSheet.spm)
      }
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getKillFluidDensityMinutes() {
      let val = 0
      val = (getLastTrendSheetRow()?.mwOut || 0) + (KillSheet?.sidpp || 0) / (getRealHoleTVD() * (locationState?.units === 'metric' ? 0.0981 : 0.052))
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getOpenHoleMdLength() {
      return Math.max(0, getRealHoleDepth() - getLinerCasingDepth());
    }

    function getLinerCasingDepth() {
      return Math.max(WellM?.casingShoeMd ?? 0, 
        WellM?.LinerShoeMd1 ?? 0, WellM?.LinerShoeMd2 ?? 0, WellM?.LinerShoeMd3 ?? 0, WellM?.LinerShoeMd4 ?? 0, WellM?.LinerShoeMd5 ?? 0);
    }

    function getRealHoleDepth() {
      const depthFromTrip = TrendSheet?.rows ? TrendSheet.rows.reduce((accum, val) => Math.max(accum, val.depth), 0) : 0;
      return Math.max(depthFromTrip, getLinerCasingDepth());
    }

    function getLinerCasingTVD() {
      return Math.max(WellM?.casingShoeTvd ?? 0, 
        WellM?.LinerShoeTvd1 ?? 0, WellM?.LinerShoeTvd2 ?? 0, WellM?.LinerShoeTvd3 ?? 0, WellM?.LinerShoeTvd4 ?? 0, WellM?.LinerShoeTvd5 ?? 0);
    }

    function getRealHoleTVD() {
      const depthFromTrip = TrendSheet?.rows ? TrendSheet.rows.reduce((accum, val) => Math.max(accum, val.depthTvd), 0) : 0;
      return Math.max(depthFromTrip, getLinerCasingTVD());
    }

    function getLinerCount() {
      if (WellM) {
        if (WellM.LinerShoeMd5 > 0) {
          return 5
        } else if (WellM.LinerShoeMd4 > 0) {
          return 4
        } else if (WellM.LinerShoeMd3 > 0) {
          return 3
        } else if (WellM.LinerShoeMd2 > 0) {
          return 2
        } else {
          return 1
        }
      }
      return 0
    }

    function getBhaXOpenHoleLength() {
      if (KillSheet) {
        return Math.min(getBhaTotalLength(), Math.max(0, getOpenHoleMdLength() - Math.max(0, (getRealHoleDepth() - getCurrentDSDepth()))))
      }
      return 0
    }

    function getBhaXOpenHoleCapacity() {
      let bhaWeight = 0
      let bhaLength = 0

      if (BHA && BHA.parts.length > 0 && WellM) {
        BHA?.tally(0).forEach((part, i) => {
            if(parseFloat(part.totalWeight) > bhaWeight) bhaWeight = parseFloat(part.totalWeight);
            if(parseFloat(part.totalLength) > bhaLength)  bhaLength = parseFloat(part.totalLength);
        })
        // this value's conversion is always handled downstream by the caller
        // so imperial values sqInchToBblFt, 2747 and getBhaTotalLengthCapacity are accurate here
        return locationState?.units === 'metric' ?
        ((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - ((getBhaTotalLengthCapacity() / getBhaTotalLength() * 1.91714116) + (bhaWeight / 7900 / bhaLength * 1.91714116))
          : ((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - ((getBhaTotalLengthCapacity() / getBhaTotalLength()) + (bhaWeight / 2747 / bhaLength))
      }
      return 0
    }

    function getOpenHoleCapacity() {
      if (WellM) {
        // this value's conversion is always handled downstream by the caller
        // so imperial values sqInchToBblFt
        return (WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt
      }
      return 0
    }

    function getBhaXOpenHoleVolume() {
      if (KillSheet && WellM && WellSectionM) {
        return getBhaXOpenHoleLength() * matrixMetric(getBhaXOpenHoleCapacity())
      }
      return 0
    }

    function getBhaXLinerCapacity() {
      let bhaWeight = 0
      let bhaLength = 0

      if (BHA && BHA.parts.length > 0 && WellM) {
        BHA?.tally(0).map((part, i) => {
          return (
            parseFloat(part.totalWeight) > bhaWeight ? bhaWeight = parseFloat(part.totalWeight) : null,
            parseFloat(part.totalLength) > bhaLength ? bhaLength = parseFloat(part.totalLength) : null
          )
        })
        // this value's conversion is always handled downstream by the caller
        // so imperial values sqInchToBblFt, 2747 and getBhaTotalLengthCapacity are accurate here
        return locationState?.units === 'metric' ?
        ((WellM.BhaId * WellM.BhaId) / sqInchToBblFt) - ((getBhaTotalLengthCapacity() / getBhaTotalLength() * 1.91714116) + (bhaWeight / 7900 / bhaLength * 1.91714116))
          : ((WellM.BhaId * WellM.BhaId) / sqInchToBblFt) - ((getBhaTotalLengthCapacity() / getBhaTotalLength()) + (bhaWeight / 2747 / bhaLength))
      }
      return 0
    }

    function getNextKillSheetMatrixId(matrix: typeof killSheetMatrix) {
      return matrix!.reduce(function(max: number, arr: any) {
        return Math.max(max, arr[0]) + 1
      }, -Infinity)
    }

    function getMarkup(key: string) {
      if (key === 'D') {
        return '<span className="blueCircularEquationLabel">D</span>'
      }
    }

    function getOpenHoleTotalVolume(matrix: typeof killSheetMatrix) {
      if (matrix && WellM) {
        const offBottom = Math.max(0, getRealHoleDepth() - getCurrentDSDepth());
        let totalOpenHolePipeVolume = 0
        let OpenHoleAccumulator = Math.max(0, getOpenHoleMdLength() - offBottom);

        // remove bha
        OpenHoleAccumulator -= Math.min(getOpenHoleMdLength(), getMatrixValue(matrix, 'string-block', 'BHA', 3));
        if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;

        if (LinerTally && LinerTally?.strings.length > 0 && LinerTally.strings[0].list.data.length > 0) {
          for( let string of LinerTally?.strings) {
              const pipe = string.getPipe(string.name || '')
              const currentLength = Math.min(OpenHoleAccumulator, getMatrixValue(matrix, 'string-block', pipe.name, 3));
              totalOpenHolePipeVolume += currentLength
                * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp));
              OpenHoleAccumulator -= currentLength
              if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;
          }
        }

        if (CasingTally && CasingTally?.strings.length > 0 && CasingTally.strings[0].list.data.length > 0) {
          for(let string of CasingTally?.strings) {
            const pipe = string.getPipe(string.name || '')
            const currentLength = Math.min(OpenHoleAccumulator, getMatrixValue(matrix, 'string-block', pipe.name, 3));
            totalOpenHolePipeVolume += currentLength * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp));
            OpenHoleAccumulator -= currentLength
            if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;
          }
        }

        if (PipeTally && PipeTally?.strings.length > 0 && PipeTally.strings[0].list.data.length > 0) {
          for(let string of PipeTally?.strings) {
            const pipe = string.getPipe(string.name || '')
            const currentLength = Math.min(OpenHoleAccumulator, getMatrixValue(matrix, 'string-block', pipe.name, 3));
              totalOpenHolePipeVolume += currentLength * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp));
              OpenHoleAccumulator -= currentLength
              if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;
            }
        }

        // conversion to metric always handled downstream
        return getBhaXOpenHoleVolume() + totalOpenHolePipeVolume
      }
      return 0
    }

    function getMatrixValue(
      matrix: typeof killSheetMatrix,
      section: string,
      key: string,
      col: number,
      string = 9999
    ) {
      let val:any = 0
      try {
        if (matrix && matrix.length > 0) {
          if (string !== 9999) {
            val = matrix.filter(e => e[1] === section && e[2] === key && e[10] === string)[0][col]
          } else {
            val = matrix.filter(e => e[1] === section && e[2] === key)[0][col]
          }
          if (typeof val === 'string') {
            return val
          } else if (typeof val === 'number') {
            return Number(val)
          } else {
            return val
          }
        }
      } catch {
        return 0
      }
      return 0
    }

    function trimLongLabel(label: string, length: number, additionalContent: string) {
      return (label + ' ' + additionalContent).length > length ?
      (label + ' ' + additionalContent).substring(0, length) + ' ...' :
      label + ' ' + additionalContent
    }

    function createKillSheetMatrix(): any[][] | undefined {
      if (WellM && BHA && PipeTally && PipeTally.strings.length > 0 && TrendSheet && TrendSheet?.rows.length > 0 && preReqs) {
        let killSheetMatrix : any[][] = []

        for (let i = 0; i < PipeTally.strings.length; i++) {
          killSheetMatrix.shift()
        }

        if (killSheetMatrix) {
          const realDSDepth = getCurrentDSDepth();
          let remainingDSLength = realDSDepth;


          //const lastPipe = getLastDrillStringRow()
          //const lastSection = lastPipe.split('|')[0]
          //const lastString = parseInt(lastPipe.split('|')[1])
          //let depthAcc = 0
          let volumeAcc = 0
          let totalVolume = 0

          /* --- string block -------------------------------------- */

          // string block: add bha
          const bhaTotalLength = getBhaTotalLength();
          const bhaTotalCap = getBhaTotalLengthCapacity();
          const bhaLength = Math.min(bhaTotalLength, remainingDSLength);
          const bhaCapPerLength = bhaTotalLength > 0 ? bhaTotalCap / bhaTotalLength : 0;

          if (bhaLength > 0) {
            killSheetMatrix.push([
              getNextKillSheetMatrixId(killSheetMatrix),
              'string-block',
              'BHA',
              // length
              bhaLength,
              // capacity
              matrixMetric(bhaCapPerLength),
              // volume
              matrixMetric(bhaCapPerLength * bhaLength),
              '',
              '',
              'bha',
              '',
              '',
              ''
            ])
          }

          remainingDSLength -= bhaLength;
          volumeAcc += matrixMetric(bhaCapPerLength * bhaLength);

          // string block: add liner tally strings
          if ((Number(LinerTally?.strings[0].totalLength) || 0) > 0) {
            for(let index = 0; index < (LinerTally?.strings.length || 0); index++) {
              const string = LinerTally?.strings[index];
              if(!string) continue;
              const pipe = string.getPipe(string.name || '');
              const fullStringLength = parseFloat(string.totalLength);
              const stringCapPerLength = pipe.cap;
              const stringLength = Math.min(fullStringLength, remainingDSLength);


                 if(stringLength > 0) {
                   killSheetMatrix.push([
                    getNextKillSheetMatrixId(killSheetMatrix),
                    'string-block',
                    pipe.name,
                    // length
                    stringLength,
                    // capacity
                    matrixMetric(stringCapPerLength),
                    // volume
                    matrixMetric(stringCapPerLength * stringLength),
                    '',
                    '',
                    'liner',
                    '',
                    index
                  ])
                }

                  remainingDSLength -= stringLength;
                  volumeAcc += matrixMetric(stringCapPerLength * stringLength);
            }
          }
          

          // string block: add casing tally strings
          if ((Number(CasingTally?.strings[0].totalLength) || 0) > 0) {
            for(let index = 0; index < (CasingTally?.strings.length || 0); index++) {
                const string = CasingTally?.strings[index];
                if(!string) continue;
                const pipe = string.getPipe(string.name || '');
                const fullStringLength = parseFloat(string.totalLength);
                const stringCapPerLength = pipe.cap;
                const stringLength = Math.min(fullStringLength, remainingDSLength);
                
                if(stringLength > 0) {
                  killSheetMatrix.push([
                    getNextKillSheetMatrixId(killSheetMatrix),
                    'string-block',
                    pipe.name,
                    // length
                    stringLength,
                    // capacity
                    matrixMetric(stringCapPerLength),
                    // volume
                    matrixMetric(stringCapPerLength * stringLength),
                    '',
                    '',
                    'casing',
                    '',
                    index
                  ]);

                  remainingDSLength -= stringLength;
                  volumeAcc += matrixMetric(stringCapPerLength * stringLength);
                }
            }
          }

          // string block: add pipe tally strings
          if ((Number(PipeTally?.strings[0].totalLength) || 0) > 0) {
            for(let index = 0; index < PipeTally?.strings.length; index++) {
                  const string = PipeTally?.strings[index];
                  if(!string) continue;
                  const pipe = string.getPipe(string.name || '');
                  const fullStringLength = parseFloat(string.totalLength);
                  const stringCapPerLength = pipe.cap;
                  const stringLength = Math.min(fullStringLength, remainingDSLength);
  
                  if(stringLength > 0) {
                    killSheetMatrix.push([
                      getNextKillSheetMatrixId(killSheetMatrix),
                      'string-block',
                      pipe.name,
                      // length
                      stringLength,
                      // capacity
                      matrixMetric(stringCapPerLength),
                      // volume
                      matrixMetric(stringCapPerLength * stringLength),
                      '',
                      '',
                      'pipe',
                      pipe.type,
                      index
                    ])
                  }

                  remainingDSLength -= stringLength;
                  volumeAcc += matrixMetric(stringCapPerLength * stringLength);
            }
          }

          for (let i = 0; i < killSheetMatrix.length; i++) {
            killSheetMatrix[i][0] = killSheetMatrix.length - i
          }
          killSheetMatrix.reverse()

          // string block: add totals
          killSheetMatrix.push([
            getNextKillSheetMatrixId(killSheetMatrix),
            'string-block',
            'Drill String Volume',
            '',
            getMarkup('D'),
            volumeAcc,
            getPreRecordedFlightStrokes(volumeAcc),
            getPreRecordedTime(volumeAcc),
            '',
            'bold',
            ''
          ])

          /* --- open hole  -------------------------------------- */

          // process in reverse order as accumulations work upwards not downwards
          // then flip it when calculations complete and push it onto killSheetMatrix

          const currentDSDepth = getCurrentDSDepth();
          const offBottom = Math.max(0, getRealHoleDepth() - currentDSDepth);

          let openHoleMatrix : any[][] = []
          let OpenHoleAccumulator = 0
          volumeAcc = 0

          // open hole block: add totals
          openHoleMatrix.push([
            getNextKillSheetMatrixId(killSheetMatrix),
            'openhole-block',
            'Open Hole Volume',
            '',
            '(F)',
            getOpenHoleTotalVolume(killSheetMatrix),
            getOpenHoleFlightStrokes(getOpenHoleTotalVolume(killSheetMatrix)),
            getOpenHoleTime(getOpenHoleTotalVolume(killSheetMatrix)),
            '',
            'bold',
            ''
          ])

          // set accumulator to open hole volume
          OpenHoleAccumulator = getOpenHoleMdLength();

          const openHoleOpenLength = Math.min(offBottom, OpenHoleAccumulator);
          // open hole: off bottom
          openHoleMatrix.push([
            getNextKillSheetMatrixId(openHoleMatrix),
            'openhole-block',
            'Open x Open Hole',
            openHoleOpenLength,
            matrixMetric(getOpenHoleCapacity()),
            matrixMetric(openHoleOpenLength * getOpenHoleCapacity()),
            '',
            '',
            '',
            '',
            ''
          ])

          volumeAcc += matrixMetric(openHoleOpenLength * getOpenHoleCapacity());

          // remove oh from accumulator
          OpenHoleAccumulator -= offBottom
          if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;

          // open hole block: add bha
          const openHoleBhaLength = Math.min(OpenHoleAccumulator, getMatrixValue(killSheetMatrix, 'string-block', 'BHA', 3));
          openHoleMatrix.push([
            getNextKillSheetMatrixId(openHoleMatrix),
            'openhole-block',
            'BHA x Open Hole',
            openHoleBhaLength,
            matrixMetric(getBhaXOpenHoleCapacity()),
            matrixMetric(openHoleBhaLength * getBhaXOpenHoleCapacity()),
            '',
            '',
            'bha',
            '',
            ''
          ])

          volumeAcc += matrixMetric(openHoleBhaLength * getBhaXOpenHoleCapacity());

          // remove string-block bha from accumulator
          OpenHoleAccumulator -= openHoleBhaLength
          if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;

          // open hole block: add liner tally strings
          if ((Number(LinerTally?.strings[0].totalLength) || 0) > 0) {
            for(let index = 0; index < (LinerTally?.strings.length || 0); index++) {
                const string = LinerTally?.strings[index];
                const pipe = string?.getPipe(string.name || '');
                const currentLength = Math.min(OpenHoleAccumulator, getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, index));
                openHoleMatrix.push([
                  getNextKillSheetMatrixId(openHoleMatrix),
                  'openhole-block',
                  pipe.name + (' x Open Hole'),
                  currentLength,
                  matrixMetric(((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp)),
                  matrixMetric(currentLength
                    * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp))),
                  '',
                  '',
                  'liner',
                  '',
                  index
                ]);
                volumeAcc += matrixMetric(currentLength
                  * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp)));
                // depreciate accumulator by this string length
                OpenHoleAccumulator -= currentLength;
                if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;
              }
          }

          // open hole block: add casing tally strings
          if ((Number(CasingTally?.strings[0].totalLength) || 0) > 0) {
            for(let index = 0; index < (CasingTally?.strings.length || 0); index++) {
              const string = CasingTally?.strings[index];
              const pipe = string?.getPipe(string.name || '');
              const currentLength = Math.min(OpenHoleAccumulator, getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, index));
              openHoleMatrix.push([
                  getNextKillSheetMatrixId(openHoleMatrix),
                  'openhole-block',
                  pipe.name + (' x Open Hole'),
                  currentLength,
                  matrixMetric(((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp)),
                  matrixMetric(currentLength
                    * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp))),
                  '',
                  '',
                  'casing',
                  '',
                  index
                ]);
                volumeAcc += matrixMetric(currentLength
                  * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp)));
                // depreciate accumulator by this string length
                OpenHoleAccumulator -= currentLength;
                if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;
            }
          }

          // open hole block: add pipe tally strings
          if ((Number(PipeTally?.strings[0].totalLength) || 0) > 0) {
            for(let index = 0; index < PipeTally?.strings.length; index++) {
              const string = PipeTally?.strings[index];
              const pipe = string?.getPipe(string.name || '');
              const currentLength = Math.min(OpenHoleAccumulator, getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, index));
                openHoleMatrix.push([
                  getNextKillSheetMatrixId(openHoleMatrix),
                  'openhole-block',
                  pipe.name + (' x Open Hole'),
                  currentLength,
                  matrixMetric(((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp)),
                  matrixMetric(currentLength
                    * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp))),
                  '',
                  '',
                  'pipe',
                  pipe.type,
                  index
                ]);
                volumeAcc += matrixMetric(currentLength
                  * (((WellM.BhaSize * WellM.BhaSize) / sqInchToBblFt) - (pipe.cap + pipe.disp)));
                // depreciate accumulator by this string length
                OpenHoleAccumulator -= currentLength;
                if(OpenHoleAccumulator < 0) OpenHoleAccumulator = 0;
            }
          }

          // update the totalling row
          openHoleMatrix[0][5] = volumeAcc
          openHoleMatrix[0][6] = getOpenHoleFlightStrokes(volumeAcc)
          openHoleMatrix[0][7] = getOpenHoleTime(volumeAcc)

          // flip openHole block back
          openHoleMatrix.reverse()

          // flip index key
          const minOh = openHoleMatrix.reduce(function(min, obj) {
            return obj[0] < min ? obj[0] : min;
         }, Infinity)

          openHoleMatrix.map((row, index) => {
            return (
              row[0] = minOh + index
            )
          })

          // add openHole block to KillSheetMatrix
          killSheetMatrix = killSheetMatrix.concat(openHoleMatrix)

          /* --- liner  -------------------------------------- */

          // process in reverse order as accumulations work upwards not downwards
          // then flip it when calculations complete and push it onto killSheetMatrix

          let casingLinerMatrix : any[][] = []


          let hasLinerBlock = false;

          // getLinerCount()
          if (killSheetMatrix) {
            //console.log('°')

            let linerVolumeAcc = 0

            // for (let i = 0; i < getLinerCount(); i++) {
            for (let i = getLinerCount() - 1; i >= 0; i--) {

              let linerLengthAcc = 0;


              const linerBottom = i === getLinerCount()-1 ?
                WellM['LinerShoeMd'.concat((i+1).toString()) as keyof typeof WellM] :
                WellM['LinerTolMd'.concat((i+2).toString()) as keyof typeof WellM];

              const linerLength = Math.max(0, linerBottom - WellM['LinerTolMd'.concat((i+1).toString()) as keyof typeof WellM]);

              // liner: open
              const linerOpenLength = Math.min(linerLength, Math.max(0, linerBottom - currentDSDepth));

              const linerOpenCap = 
                      WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] 
                      * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]
                     / sqInchToBblFt
              ;
              
              casingLinerMatrix.push([
                isFinite(getNextKillSheetMatrixId(i === getLinerCount() + 1 ? killSheetMatrix : casingLinerMatrix)) ?
                  getNextKillSheetMatrixId(i === getLinerCount() + 1 ? killSheetMatrix : casingLinerMatrix) :
                  getNextKillSheetMatrixId(killSheetMatrix) + 1,
                'casingliner-block',
                'Open x Liner ' + (i + 1),
                linerOpenLength,
                matrixMetric(linerOpenCap),
                matrixMetric(linerOpenLength * linerOpenCap),
                '',
                '',
                '',
                '',
                '',
                (!hasLinerBlock && linerOpenLength > 0) ? 'subtotal' : ''
              ])

              if(!hasLinerBlock && linerOpenLength > 0) {
                hasLinerBlock = true;
              }

              linerLengthAcc += linerOpenLength;
              linerVolumeAcc += matrixMetric(linerOpenLength * linerOpenCap)


              // liner: bha
              const bhaRemaining = Math.max(0, getMatrixValue(killSheetMatrix, 'string-block', 'BHA', 3)
                - getMatrixValue(killSheetMatrix, 'openhole-block', 'BHA x Open Hole', 3)
                - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 1', 3)
                - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 2', 3)
                - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 3', 3)
                - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 4', 3)
                - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 5', 3))

              const bhaLength = Math.min(bhaRemaining, Math.max(0, linerLength - linerLengthAcc));

              casingLinerMatrix.push([
                getNextKillSheetMatrixId(casingLinerMatrix),
                'casingliner-block',
                'BHA x Liner ' + (i + 1),
                bhaLength,
                matrixMetric(getBhaXLinerCapacity()),
                matrixMetric(bhaLength * getBhaXLinerCapacity()),
                '',
                '',
                'bha',
                '',
                '',
                (!hasLinerBlock && bhaLength > 0) ? 'subtotal' : ''
              ])

              if(!hasLinerBlock && bhaLength > 0) {
                hasLinerBlock = true;
              }


              linerLengthAcc += bhaLength;
              linerVolumeAcc += matrixMetric(bhaLength * getBhaXLinerCapacity())

              // liner: liner
              if ((Number(LinerTally?.strings[0].totalLength) || 0) > 0) {
                for(let _index = 0; _index < (LinerTally?.strings?.length || 0); _index++) {
                    const string = LinerTally?.strings[_index];
                    const pipe = string?.getPipe(string?.name || '');

                    const lineLengthRemaining = getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, _index)
                    - getMatrixValue(killSheetMatrix, 'openhole-block', pipe.name + ' x Open Hole' || '', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 1', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 2', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 3', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 4', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 5', 3, _index);
                  
                    const lineLength = Math.min(lineLengthRemaining, Math.max(0, linerLength - linerLengthAcc));
    
                    casingLinerMatrix.push([
                      getNextKillSheetMatrixId(casingLinerMatrix),
                      'casingliner-block',
                      pipe.name + ' x Liner ' + (i + 1),
                      lineLength,
                      matrixMetric((((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                      matrixMetric(lineLength * (((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                      '',
                      '',
                      'liner',
                      i === getLinerCount() - i ?
                        'subtotal' :
                        '',
                      _index,
                      (!hasLinerBlock && lineLength > 0) ? 'subtotal' : ''
                    ]);

                    if(!hasLinerBlock && lineLength > 0) {
                      hasLinerBlock = true;
                    }      

                    linerLengthAcc += lineLength;
                    linerVolumeAcc += matrixMetric(lineLength * (((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap)));

                }
              }

              // liner: casing
              if ((Number(CasingTally?.strings[0].totalLength) || 0) > 0) {
                for(let _index = 0; _index < (CasingTally?.strings?.length || 0); _index++) {
                  const string = CasingTally?.strings[_index];
                  const pipe = string?.getPipe(string?.name || '');

                  const caseLengthRemaining = getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, _index)
                    - getMatrixValue(killSheetMatrix, 'openhole-block', pipe.name + ' x Open Hole' || '', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 1', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 2', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 3', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 4', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 5', 3, _index);

                  
                    const caseLength = Math.min(caseLengthRemaining, Math.max(0, linerLength - linerLengthAcc));

                    casingLinerMatrix.push([
                      getNextKillSheetMatrixId(casingLinerMatrix),
                      'casingliner-block',
                      pipe.name + ' x Liner ' + (i + 1),
                      caseLength,
                      matrixMetric((((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                      matrixMetric(caseLength * (((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                      '',
                      '',
                      'casing',
                      '',
                      _index,
                      (!hasLinerBlock && caseLength > 0) ? 'subtotal' : ''
                    ]);

                    if(!hasLinerBlock && caseLength > 0) {
                      hasLinerBlock = true;
                    }      

                    linerLengthAcc += caseLength;
                    linerVolumeAcc += matrixMetric(caseLength * (((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap)))

                }
              }

              // liner: pipe
              if ((Number(PipeTally?.strings[0].totalLength) || 0) > 0) {
                for(let _index = 0; _index < (PipeTally?.strings?.length || 0); _index++) {
                  const string = PipeTally?.strings[_index];
                  const pipe = string?.getPipe(string?.name || '');

                  const pipeRemainingLength = getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, _index)
                    - getMatrixValue(killSheetMatrix, 'openhole-block', pipe.name + ' x Open Hole' || '', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 1', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 2', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 3', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 4', 3, _index)
                    - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 5', 3, _index);


                    const pipeLength = Math.min(pipeRemainingLength, Math.max(0, linerLength - linerLengthAcc));

                    casingLinerMatrix.push([
                      getNextKillSheetMatrixId(casingLinerMatrix),
                      'casingliner-block',
                      pipe.name + ' x Liner ' + (i + 1),
                      pipeLength,
                      matrixMetric((((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                      matrixMetric(pipeLength * (((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                      '',
                      '',
                      'pipe',
                      pipe.type,
                      _index,
                      (!hasLinerBlock && pipeLength > 0) ? 'subtotal' : ''
                    ]);

                    if(!hasLinerBlock && pipeLength > 0) {
                      hasLinerBlock = true;
                    }      

                    linerLengthAcc += pipeLength;
                    linerVolumeAcc += matrixMetric(pipeLength * (((WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM] * WellM['LinerId'.concat((i+1).toString()) as keyof typeof WellM]) / sqInchToBblFt) - (pipe.disp + pipe.cap)))

                }
              }
            }

            /* --- casing ---------------------------------------- */

            let casingLengthAcc = 0;
            // casing: open
            const casingBottom = WellM.LinerTolMd1 > 0 ? WellM.LinerTolMd1 : WellM.casingShoeMd;

            const casingLength = casingBottom - getMarineRiserLength();

            const casingOpenLength = Math.min(casingLength, Math.max(0, casingBottom - currentDSDepth));

            const casingOpenCap = WellM.casingId * WellM.casingId / sqInchToBblFt;

            casingLinerMatrix.push([
              getNextKillSheetMatrixId(casingLinerMatrix),
              'casing-block',
              'Open x CSG',
              casingOpenLength,
              matrixMetric(casingOpenCap),
              matrixMetric(casingOpenLength * casingOpenCap),
              '',
              '',
              '',
              '',
              '',
              (!hasLinerBlock && casingOpenLength > 0) ? 'subtotal' : ''
            ])

            if(!hasLinerBlock && casingOpenLength > 0) {
              hasLinerBlock = true;
            }      

            casingLengthAcc += casingOpenLength;
            linerVolumeAcc += matrixMetric(casingOpenLength * casingOpenCap)

            // casing: bha
            const casingBhaRemaining = getMatrixValue(killSheetMatrix, 'string-block', 'BHA', 3)
              - getMatrixValue(killSheetMatrix, 'openhole-block', 'BHA x Open Hole', 3)
              - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 1', 3)
              - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 2', 3)
              - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 3', 3)
              - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 4', 3)
              - getMatrixValue(casingLinerMatrix, 'casingliner-block', 'BHA x Liner 5', 3);

            const casingBhaLength = Math.min(casingBhaRemaining, Math.max(0, casingLength - casingLengthAcc));

            casingLinerMatrix.push([
              getNextKillSheetMatrixId(casingLinerMatrix),
              'casing-block',
              'BHA x CSG',
              casingBhaLength,
              matrixMetric(getBhaXLinerCapacity()),
              matrixMetric(casingBhaLength * getBhaXLinerCapacity()),
              '',
              '',
              'bha',
              '',
              '',
              (!hasLinerBlock && casingBhaLength > 0) ? 'subtotal' : ''
            ])

            if(!hasLinerBlock && casingBhaLength > 0) {
              hasLinerBlock = true;
            }      

            casingLengthAcc += casingBhaLength;
            linerVolumeAcc += matrixMetric(casingBhaLength * getBhaXLinerCapacity())

            // casing: liner
            if ((Number(LinerTally?.strings[0].totalLength) || 0) > 0) {
              for(let _index = 0; _index < (LinerTally?.strings?.length || 0 ); _index++) {
                const string = LinerTally?.strings[_index];
                const pipe = string?.getPipe(string?.name || '');

                const lineRemaining = getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, _index)
                  - getMatrixValue(killSheetMatrix, 'openhole-block', pipe.name + ' x Open Hole' || '', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 1', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 2', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 3', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 4', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 5', 3, _index);

                const lineLength = Math.min(lineRemaining, Math.max(0, casingLength - casingLengthAcc));

                  casingLinerMatrix.push([
                    getNextKillSheetMatrixId(casingLinerMatrix),
                    'casing-block',
                    pipe.name + ' x CSG',
                    lineLength,
                    matrixMetric((((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                    matrixMetric(lineLength * (((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                    '',
                    '',
                    'liner',
                    '',
                    _index,
                    (!hasLinerBlock && lineLength > 0) ? 'subtotal' : ''
                  ]);

                  if(!hasLinerBlock && lineLength > 0) {
                    hasLinerBlock = true;
                  }            

                  casingLengthAcc += lineLength;
                  linerVolumeAcc += matrixMetric(lineLength * (((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap)))
              }
            }

            // casing: casing
            if ((Number(CasingTally?.strings[0].totalLength) || 0) > 0) {

              for(let _index = 0; _index < (CasingTally?.strings?.length || 0); _index++) {
                  const string = CasingTally?.strings[_index];
                  const pipe = string?.getPipe(string?.name || '');

                  const caseRemaining = getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, _index)
                  - getMatrixValue(killSheetMatrix, 'openhole-block', pipe.name + ' x Open Hole' || '', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 1', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 2', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 3', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 4', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 5', 3, _index);

                  const caseLength = Math.min(caseRemaining, Math.max(0, casingLength - casingLengthAcc));

                  casingLinerMatrix.push([
                    getNextKillSheetMatrixId(casingLinerMatrix),
                    'casing-block',
                    pipe.name + ' x CSG',
                    caseLength,
                    matrixMetric((((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                    matrixMetric(caseLength * (((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                    '',
                    '',
                    'casing',
                    '',
                    _index,
                    (!hasLinerBlock && caseLength > 0) ? 'subtotal' : ''
                  ]);

                  if(!hasLinerBlock && caseLength > 0) {
                    hasLinerBlock = true;
                  }            

                  casingLengthAcc += caseLength;
                  linerVolumeAcc += matrixMetric(caseLength * (((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap)))
              }
            }

            // casing: pipe
            if ((Number(PipeTally?.strings[0].totalLength) || 0) > 0) {

              for(let _index = 0; _index < (PipeTally?.strings?.length || 0); _index++) {
                const string = PipeTally?.strings[_index];
                const pipe = string?.getPipe(string?.name || '');

                const pipeRemaining = getMatrixValue(killSheetMatrix, 'string-block', pipe.name, 3, _index)
                  - getMatrixValue(killSheetMatrix, 'openhole-block', pipe.name + ' x Open Hole' || '', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 1', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 2', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 3', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 4', 3, _index)
                  - getMatrixValue(casingLinerMatrix, 'casingliner-block', pipe.name + ' x Liner 5', 3, _index);

                const pipeLength = Math.min(pipeRemaining, Math.max(0, casingLength - casingLengthAcc));

                  casingLinerMatrix.push([
                    getNextKillSheetMatrixId(casingLinerMatrix),
                    'casing-block',
                    pipe.name + ' x CSG',
                    pipeLength,
                    matrixMetric((((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                    matrixMetric(pipeLength * (((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap))),
                    '',
                    '',
                    'pipe',
                    pipe.type,
                    _index,
                    (!hasLinerBlock && pipeLength > 0) ? 'subtotal' : ''
                  ]);

                  if(!hasLinerBlock && pipeLength > 0) {
                    hasLinerBlock = true;
                  }            

                  casingLengthAcc += pipeLength;
                  linerVolumeAcc += matrixMetric(pipeLength * (((WellM.casingId * WellM.casingId) / sqInchToBblFt) - (pipe.disp + pipe.cap)))
              }
            }
            totalVolume = linerVolumeAcc
          }

          /* --- casing/liner total ---------------------------------------- */

          // flip index key
          const arrMax = casingLinerMatrix[0][0]
          for (let i = 0; i < (casingLinerMatrix[casingLinerMatrix.length - 1][0] - arrMax + 1); i++) {
            casingLinerMatrix[i][0] = casingLinerMatrix[casingLinerMatrix.length - 1][0] - i
          }

          // string block: add totals
          casingLinerMatrix.push([
            9999,
            'total-block',
            'DP/HWDP/DC x Casing/Liner Volume',
            '',
            '',
            totalVolume,
            getCasingLinerStrokes(totalVolume),
            getCasingLinerMinutes(totalVolume),
            '',
            'bold',
            ''
          ])

          // flip openHole block back
          casingLinerMatrix.sort((a, b) => a[0] - b[0])

          // add openHole block to KillSheetMatrix
          killSheetMatrix = killSheetMatrix.concat(casingLinerMatrix)

          return killSheetMatrix
        }
      }
    }

    // let data=window.performance.getEntriesByType("navigation")[0].toJSON()
    // let jdata = JSON.parse(JSON.stringify(data))
    // if (jdata.type === "reload") {
    //   window.location.href = '/dashboard'
    // }

    // let killSheetDataMatrix = createKillSheetDataMatrix()
    // if (killSheetDataMatrix) {
    //   console.log('killSheetDataMatrix', killSheetDataMatrix.slice().reverse())
    // }

    let killSheetMatrix = createKillSheetMatrix()
    // if (killSheetMatrix) {
    //   console.log('killSheetMatrix', killSheetMatrix)
    // }

    return (
      preReqs && KillSheet && killSheetMatrix ?
        <div>
          <SectionTabs />
          <PrintInfo />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="tripStatsData" style={{ width: '100%', textAlign: 'right', marginTop: '0px', marginRight: '10px', marginBottom: '10px', fontSize: '13px' }}>
              <div className="killSheetTimeClockWrapper" style={{ textAlign: 'right', flexDirection: 'row-reverse', marginTop: '-40px',  }}>
                <div id="timeclock" className="timeclock"></div>
                <div className="timeclock wellInfoTimeClock"><Clock /></div>
              </div>
            </div>
          </div>
          <div id="killSheetWrapper" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px', color: '#737496', fontSize: '14px' }}>

            <div id="killSheetFormationStrength" className="" style={{ gridColumn: '1/2', gridRow: '1/2', backgroundColor: '#E5E4E2', fontSize: '13px', borderRadius: '8px', border: '1px solid #515270', margin: 'auto', padding: '8px', width: '100%', height: '100%' }}>
              <div style={{ color: '#000000', margin: '10px 10px 0px 10px', fontWeight: 700 }}>FORMATION STRENGTH DATA</div>
              <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'transparent',  width: '100%', lineHeight: '40px' }}>
                <div style={{ backgroundColor: 'transparent', width: '100%', padding: '10px' }}>
                  <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>Surface leak-off pressure from formation strength test</div>
                  <div>Drilling fluid density at test</div>
                  <div>Maximum allowable drilling fluid density</div>
                  <div style={{ marginTop: '-20px', fontStyle: 'italic', color: 'dodgerblue' }}>
                    <span style={{
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      // -moz-border-radius: '50%',
                      borderRadius: '50%',
                      border: 'solid 1px #1464F4',
                      backgroundColor: 'transparent',
                      color: '#000000',
                      textAlign: 'center',
                      display: 'inline-block',
                      fontWeight: 800
                    }}>
                      B
                    </span> +
                    <span style={{
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      // -moz-border-radius: '50%',
                      borderRadius: '50%',
                      border: 'solid 1px #1464F4',
                      backgroundColor: 'transparent',
                      color: '#000000',
                      textAlign: 'center',
                      display: 'inline-block',
                      fontWeight: 800
                    }}>
                      A
                    </span> / <span className="redBrackets">(</span>Shoe T.V. Depth x {getDensityMultiplier(locationState?.units || '')}<span className="redBrackets">)</span>
                  </div>
                  <div>Initial MAASP</div>
                  <div style={{ marginTop: '-20px', fontStyle: 'italic', color: 'dodgerblue' }}><span className="redBrackets">(</span>
                    <span style={{
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      // -moz-border-radius: '50%',
                      borderRadius: '50%',
                      border: 'solid 1px #1464F4',
                      backgroundColor: 'transparent',
                      color: '#000000',
                      textAlign: 'center',
                      display: 'inline-block',
                      fontWeight: 800
                    }}>
                      C
                    </span> - Current Density<span className="redBrackets">)</span> x Shoe TV Depth x {getDensityMultiplier(locationState?.units || '')}
                    </div>
                </div>
                <div style={{ width: '200px', backgroundColor: 'transparent', padding: '10px' }}>
                  <div style={{ whiteSpace: 'nowrap' }}><input id="A" type="number" defaultValue={(KillSheet?.surfaceLeakOffPressure || 0).toFixed(0)}
                    onFocus={e => e.target.select()}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'surfaceLeakOffPressure', 'number', e.target.value)
                    })}
                  /> &nbsp; {getLabel('pressure', locationState)}</div>
                  <div style={{ whiteSpace: 'nowrap' }}><input id="B" type="number" defaultValue={(KillSheet?.drillingFluidDensity || 0).toFixed(2)}
                    onFocus={e => e.target.select()}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'drillingFluidDensity', 'number', e.target.value)
                    })}
                  /> &nbsp; {getLabel('weightVolumeSg', locationState)}</div>
                  <div className={(locationState?.units || '') === 'metric' ? 'madfdShiftMetric' : 'madfdShiftImperial'} style={{ textAlign: 'right', marginRight: '5px !important' }}>
                    {getMaximumAllowableDrillFluidDensity(locationState?.units || '').toFixed((locationState?.units || '') === 'metric' || 'hybrid' ? 2 : 1).toString()} &nbsp;
                    {getLabel('weightVolumeSg', locationState)}
                  </div>
                  <div>&nbsp;</div>
                  <div className="killSheetDplViewOnly" style={{ textAlign: 'right' }}>{getInitialMaasp(locationState?.units || '').toFixed(0).toString()} &nbsp; {getLabel('pressure', locationState)}</div>
                </div>
                <div style={{ width: '25px', backgroundColor: 'transparent', padding: '10px 20px 10px 12px', textAlign: 'left' }}>
                  <div>
                    <span style={{
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      // -moz-border-radius: '50%',
                      borderRadius: '50%',
                      border: 'solid 1px #1464F4',
                      backgroundColor: 'dodgerblue',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'inline-block',
                      fontWeight: 800
                    }}>
                      A
                    </span>
                  </div>
                  <div>
                    <span style={{
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      // -moz-border-radius: '50%',
                      borderRadius: '50%',
                      border: 'solid 1px #1464F4',
                      backgroundColor: 'dodgerblue',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'inline-block',
                      fontWeight: 800
                    }}>
                      B
                    </span>
                  </div>
                  <div>
                    <span style={{
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      // -moz-border-radius: '50%',
                      borderRadius: '50%',
                      border: 'solid 1px #1464F4',
                      backgroundColor: 'dodgerblue',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'inline-block',
                      fontWeight: 800
                    }}>
                      C
                    </span>
                  </div>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>

            <div id="killSheetDisplacement" className="" style={{ gridColumn: '1/2', gridRow: '2/3', backgroundColor: '#E5E4E2', borderRadius: '8px', border: '1px solid #515270', margin: 'auto', padding: '8px', width: '100%', height: '100%' }}>
              <div style={{ backgroundColor: 'transparent', width: '100%', padding: '10px', color: '#000000', fontWeight: 700 }}>DISPLACEMENT</div>
              <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', alignItems: 'center', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '40px', fontSize: '13px' }}>
                <div style={{ width: '45%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '50px', marginLeft: '10px', marginRight: '10px', textAlign: 'left' }}>Pump 1</div>
                  <div style={{width: '95px', textAlign: 'right', marginRight: '10px' }}>
                    <input id="displacementPump1" type="number" style={{ width: '100px' }} defaultValue={KillSheet?.displacementPump1 || 0}
                      onFocus={e => e.target.select()}
                      onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'displacementPump1', 'number', e.target.value)
                      })}
                    />
                  </div>
                  <div style={{ textAlign: 'left' }}>{getLabel('volume', locationState) === 'm3' ? <>m&sup3;</> : getLabel('volume', locationState)}/stk</div>
                </div>
                <div style={{ width:'10%' }}>&nbsp;</div>
                <div style={{ width: '45%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '50px', marginLeft: '0px', marginRight: '10px', textAlign: 'right' }}>Pump 2</div>
                  <div style={{ width: '68px', textAlign: 'right', marginRight: '40px' }}>
                    <input id="displacementPump2" type="number" style={{ width: '100px' }} defaultValue={KillSheet?.displacementPump2 || 0}
                      onFocus={e => e.target.select()}
                      onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'displacementPump2', 'number', e.target.value)
                      })} />
                  </div>
                  <div style={{ textAlign: 'left', backgroundColor: 'transparent' }}>{getLabel('volume', locationState) === 'm3' ? <>m&sup3;</> : getLabel('volume', locationState)}/stk</div>
                </div>
              </div>
            </div>

            <div id="killSheetDynamicPressureLoss" className="" style={{ gridColumn: '1/2', gridRow: '3/4', backgroundColor: '#E5E4E2', borderRadius: '8px', border: '1px solid #2b2c41', margin: 'auto', padding: '8px', width: '100%', height: '100%' }}>
              <div id="killSlowPumpRate" style={{
                display: 'grid',
                gridTemplateColumns: '2fr repeat(6, 1fr)',
                gridGap: '0px',
                width: '100%',
                backgroundColor: '#34354f',
                borderRadius: '8px',
                border: '2px solid #2b2c41',
                color: '#737496',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                lineHeight: '23px',
                fontSize: '13px',
                paddingBottom: '15px',
                marginBottom: '-20px'
              }}>

                <div style={{ gridColumn: '1/2', gridRow: '1/2' }}><br /></div>
                <div style={{ gridColumn: '2/8', gridRow: '1/2', textAlign: 'center', color: '#fff' }}>(PL) DYNAMIC PRESSURE LOSS (psi)</div>

                <div style={{ gridColumn: '1/2', gridRow: '2/3' }}><br /></div>
                <div style={{ gridColumn: '2/5', gridRow: '2/3', textAlign: 'center', border: '1px solid #D3D3D3', marginRight: '10px', marginBottom: '15px' }}>
                  <input type="radio" value="1" name="pump" id="pump1"
                    style={{ cursor: 'pointer' }}
                    checked={KillSheet?.pump === 1}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'pump', 'number', e.target.value)
                    })}
                  />
                  PUMP NO. 1
                </div>
                <div style={{ gridColumn: '5/8', gridRow: '2/3', textAlign: 'center', border: '1px solid #D3D3D3', marginRight: '10px', marginBottom: '15px' }}>
                <input type="radio" value="2" name="pump"
                    style={{ cursor: 'pointer' }}
                    checked={KillSheet?.pump === 2}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'pump', 'number', e.target.value)
                    })}
                  />
                  PUMP NO. 2
                </div>

                <div style={{ gridColumn: '1/2', gridRow: '3/4', lineHeight: '18px' }}><br />Slow Pump Rate</div>
                <div style={{ gridColumn: '2/3', gridRow: '3/4', lineHeight: '18px' }}><br />{locationState?.rigType === 'floater' ? 'Riser' : ''}</div>
                <div style={{ gridColumn: '3/4', gridRow: '3/4', lineHeight: '18px' }}><br />{locationState?.rigType === 'floater' ? 'Choke Line' : ''}</div>
                <div style={{ gridColumn: '4/5', gridRow: '3/4', lineHeight: '18px' }}>{locationState?.rigType === 'floater' ? 'Choke Line Friction' : ''}</div>
                <div style={{ gridColumn: '5/6', gridRow: '3/4', lineHeight: '18px' }}><br />{locationState?.rigType === 'floater' ? 'Riser' : ''}</div>
                <div style={{ gridColumn: '6/7', gridRow: '3/4', lineHeight: '18px' }}><br />{locationState?.rigType === 'floater' ? 'Choke Line' : ''}</div>
                <div style={{ gridColumn: '7/8', gridRow: '3/4', lineHeight: '18px' }}>{locationState?.rigType === 'floater' ? 'Choke Line Friction' : ''}</div>

                <div style={{ gridColumn: '1/2', gridRow: '4/5', border: '1px solid rgb(211, 211, 211)', marginLeft: '5px', marginRight: '5px', marginTop: '3px'  }}>
                  <input type="radio" value="20" name="spm" style={{ cursor: 'pointer' }}
                    checked={KillSheet?.spm === 20}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'spm', 'number', e.target.value)
                    })}
                  />
                  <TextField
                    sx={{ color: '#fff' }}
                    id="spmLabel1"
                    style={{ width: '25px', marginLeft: '2px', marginRight: '2px' }}
                    defaultValue={KillSheet?.spmLabel1 || '20'}
                    onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'spmLabel1', 'string', e.target.value)
                    })}
                    /> SPM
                </div>
                <div style={{ gridColumn: '2/3', gridRow: '4/5', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.riser120 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'riser120', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '3/4', gridRow: '4/5', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.choke120 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'choke120', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '4/5', gridRow: '4/5', marginTop: '3px' }}>{((KillSheet?.choke120 || 0) - (KillSheet?.riser120 || 0)).toFixed(2)}</div>
                <div style={{ gridColumn: '5/6', gridRow: '4/5', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.riser220 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'riser220', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '6/7', gridRow: '4/5', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.choke220 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'choke220', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '7/8', gridRow: '4/5', marginTop: '3px' }}>{((KillSheet?.choke220 || 0) - (KillSheet?.riser220 || 0)).toFixed(2)}</div>

                <div style={{ gridColumn: '1/2', gridRow: '5/6', border: '1px solid rgb(211, 211, 211)', marginLeft: '5px', marginRight: '5px', marginTop: '3px'  }}>
                  <input type="radio" value="30" name="spm" style={{ cursor: 'pointer' }}
                    checked={KillSheet?.spm === 30}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'spm', 'number', e.target.value)
                    })}
                  />
                  <TextField
                    id="spmLabel2"
                    style={{ width: '25px', marginLeft: '2px', marginRight: '2px' }}
                    defaultValue={KillSheet?.spmLabel2 || '30'}
                    onFocus={e => e.target.select()}
                    onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'spmLabel2', 'string', e.target.value)
                    })}
                    /> SPM
                </div>
                <div style={{ gridColumn: '2/3', gridRow: '5/6', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.riser130 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'riser130', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '3/4', gridRow: '5/6', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.choke130 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'choke130', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '4/5', gridRow: '5/6', marginTop: '3px' }}>{((KillSheet?.choke130 || 0) - (KillSheet?.riser130 || 0)).toFixed(2)}</div>
                <div style={{ gridColumn: '5/6', gridRow: '5/6', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.riser230 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'riser230', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '6/7', gridRow: '5/6', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.choke230 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'choke230', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '7/8', gridRow: '5/6', marginTop: '3px' }}>{((KillSheet?.choke230 || 0) - (KillSheet?.riser230 || 0)).toFixed(2)}</div>

                <div style={{ gridColumn: '1/2', gridRow: '6/7', border: '1px solid rgb(211, 211, 211)', marginLeft: '5px', marginRight: '5px', marginTop: '3px'  }}>
                  <input type="radio" value="40" name="spm" style={{ cursor: 'pointer' }}
                    checked={KillSheet?.spm === 40}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'spm', 'number', e.target.value)
                    })}
                  />
                  <TextField
                    id="spmLabel3"
                    style={{ width: '25px', marginLeft: '2px', marginRight: '2px' }}
                    defaultValue={KillSheet?.spmLabel3 || '40'}
                    onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'spmLabel3', 'string', e.target.value)
                    })}
                    /> SPM
                </div>
                <div style={{ gridColumn: '2/3', gridRow: '6/7', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.riser140 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'riser140', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '3/4', gridRow: '6/7', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.choke140 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'choke140', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '4/5', gridRow: '6/7', marginTop: '3px' }}>{((KillSheet?.choke140 || 0) - (KillSheet?.riser140 || 0)).toFixed(2)}</div>
                <div style={{ gridColumn: '5/6', gridRow: '6/7', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.riser240 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'riser240', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '6/7', gridRow: '6/7', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.choke240 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'choke240', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '7/8', gridRow: '6/7', marginTop: '3px' }}>{((KillSheet?.choke240 || 0) - (KillSheet?.riser240 || 0)).toFixed(2)}</div>

                <div style={{ gridColumn: '1/2', gridRow: '7/8', border: '1px solid rgb(211, 211, 211)', marginLeft: '5px', marginRight: '5px', marginTop: '3px' }}>
                  <input type="radio" value="50" name="spm" style={{ cursor: 'pointer' }}
                    checked={KillSheet?.spm === 50}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'spm', 'number', e.target.value)
                    })}
                  />
                  <TextField
                    id="spmLabel4"
                    style={{ width: '25px', marginLeft: '2px', marginRight: '2px' }}
                    defaultValue={KillSheet?.spmLabel4 || '50'}
                    onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'spmLabel4', 'string', e.target.value)
                    })}
                    /> SPM
                </div>
                <div style={{ gridColumn: '2/3', gridRow: '7/8', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.riser150 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'riser150', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '3/4', gridRow: '7/8', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.choke150 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'choke150', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '4/5', gridRow: '7/8', marginTop: '3px' }}>{((KillSheet?.choke150 || 0) - (KillSheet?.riser150 || 0)).toFixed(2)}</div>
                <div style={{ gridColumn: '5/6', gridRow: '7/8', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.riser250 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'riser250', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '6/7', gridRow: '7/8', marginTop: '3px' }}><input className="killSheetDplCell" type="number" defaultValue={KillSheet?.choke250 || 0}
                  onFocus={e => e.target.select()}
                  onChange={((e) => {
                    KillSheet?.updateKillSheetData(KillSheet.id, 'choke250', 'number', e.target.value)
                  })}
                /></div>
                <div className={locationState?.rigType === 'floater' ? '' : 'invisible'} style={{ gridColumn: '7/8', gridRow: '7/8', marginTop: '3px' }}>{((KillSheet?.choke250 || 0) - (KillSheet?.riser250 || 0)).toFixed(2)}</div>
                <div className="plDts" style={{ gridColumn: '1/8', gridRow: '8/9', paddingTop: '7px', marginTop: '13px', marginBottom: '0px', borderTop: '1px solid silver',  color: '#FFFFFF', textAlign: 'center', backgroundColor: 'transparent' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', width: '250px', marginLeft: '15px', backgroundColor: 'transparent' }}>
                      <span style={{ color: '#1fb4ff', fontWeight: 700 }}>Depth</span>:
                      <TextField
                        // required
                        id="pressureLossDepth"
                        style={{ width: '75px', marginLeft: '10px', marginRight: '2px' }}
                        // label="Required"
                        defaultValue={KillSheet?.pressureLossDepth}
                        onChange={((e) => {
                          KillSheet?.updateKillSheetData(KillSheet.id, 'pressureLossDepth', 'number', e.target.value)
                        })}
                      />
                      {getLabel('lengthMedium', locationState)}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', backgroundColor: 'transparent' }}>
                      <span style={{ color: '#1fb4ff', fontWeight: 700 }}>Date & Time</span>:
                      <TextField
                        id="pressureLossDate"
                        type="datetime-local"
                        style={{ fontSize: '10px', width: '200px', marginRight: '10px' }}
                        defaultValue={KillSheet?.pressureLossDate ? convertDateToMuiString(convertUTCDateToLocalDate(new Date(KillSheet?.pressureLossDate.replace('Z','')))) : ''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onFocus={e => e.target.select()}
                        onChange={((e) => {
                          try {
                            KillSheet?.updateKillSheetData(KillSheet.id, 'pressureLossDate', 'string', new Date(e.target.value).toISOString())
                          } catch (e) {}
                        })}
                        size="small"
                        />
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="killSheetCasingLiner" className="" style={{ gridColumn: '2/3', gridRow: '1/4', backgroundColor: '#E5E4E2', borderRadius: '8px', border: '1px solid #515270', margin: 'auto', padding: '0px 8px 8px 8px', width: '100%', height: '100%', fontSize: '13px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'transparent', margin: 'auto' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '15fr 10fr 1fr', lineHeight: '20px', width: '100%', backgroundColor: 'transparent', color: '#737496', margin: '10px', padding: '10px' }}>
                  <div style={{ gridColumn: '1/4', gridRow: '1/2', fontWeight: 700, color: '#000000' }}>CURRENT DRILLING FLUID</div>
                  <div style={{ gridColumn: '1/2', gridRow: '2/3' }}>Density</div>
                  <div style={{ gridColumn: '2/3', gridRow: '2/3', textAlign: 'right', marginRight: '5px'  }}>{getLastTrendSheetRow()?.mwOut}</div>
                  <div style={{ whiteSpace: 'nowrap', gridColumn: '3/4', gridRow: '2/3' }}>{getLabel('weightVolumeSg', locationState)}
                    &nbsp;
                    <span style={{
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      // -moz-border-radius: '50%',
                      borderRadius: '50%',
                      border: 'solid 1px #1464F4',
                      backgroundColor: 'dodgerblue',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'inline-block',
                      fontWeight: 800,
                      marginRight: '10px',
                      marginLeft: '4px'
                    }}>
                      M
                    </span>
                  </div>

                  <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''} style={{ gridColumn: '1/3', gridRow: '3/4', fontWeight: 700, color: '#000000' }}>SUBSEA BOP DATA</div>

                  <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''} style={{ gridColumn: '1/2', gridRow: '4/5' }}>Marine Riser Length</div>
                  <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''} style={{ gridColumn: '2/3', gridRow: '4/5', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', getMarineRiserLength(), 15).toFixed(1)}</div>
                  <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''} style={{ gridColumn: '3/4', gridRow: '4/5' }}>{getLabel('lengthMedium', locationState)}</div>

                  <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''} style={{ gridColumn: '1/2', gridRow: '5/6' }}>Chokeline Length</div>
                  <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''} style={{ gridColumn: '2/3', gridRow: '5/6', textAlign: 'right', marginRight: '6px' }}>
                    <input
                      type="number" style={{ width: '85px' }}
                      value={unitConversion('lengthMedium', locationState?.units, 'out', KillSheet?.chokelineLength || 0, 15).toFixed(0)}
                      onFocus={e => e.target.select()}
                      onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'chokelineLength', 'number', unitConversion('lengthMedium', locationState?.units, 'in', parseFloat(e.target.value), 15).toString())
                      })}
                    />
                  </div>
                  <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''} style={{ gridColumn: '3/4', gridRow: '5/6' }}>{getLabel('lengthMedium', locationState)}</div>

                  <div style={{ gridColumn: '1/3', gridRow: '6/7', fontWeight: 700, color: '#000000' }}>CASING LINER DATA</div>

                  <div style={{ gridColumn: '1/2', gridRow: '7/8' }}>Casing Size</div>
                  <div style={{ gridColumn: '2/3', gridRow: '7/8', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.casingSize : 0}</div>
                  <div style={{ gridColumn: '3/4', gridRow: '7/8' }}>in</div>

                  <div style={{ gridColumn: '1/2', gridRow: '8/9' }}>Casing ID</div>
                  <div style={{ gridColumn: '2/3', gridRow: '8/9', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.casingId : 0}</div>
                  <div style={{ gridColumn: '3/4', gridRow: '8/9' }}>in</div>

                  <div style={{ gridColumn: '1/2', gridRow: '9/10' }}>Casing Shoe MD</div>
                  <div style={{ gridColumn: '2/3', gridRow: '9/10', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.casingShoeMd : 0, 15).toFixed(1)}</div>
                  <div style={{ gridColumn: '3/4', gridRow: '9/10' }}>{getLabel('lengthMedium', locationState)}</div>

                  <div style={{ gridColumn: '1/2', gridRow: '10/11' }}>Casing Shoe TVD</div>
                  <div style={{ gridColumn: '2/3', gridRow: '10/11', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.casingShoeTvd : 0, 15).toFixed(1)}</div>
                  <div style={{ gridColumn: '3/4', gridRow: '10/11' }}>{getLabel('lengthMedium', locationState)}</div>

                  <div id="killSheetCasingLiner" style={{
                    gridColumn: '1/3',
                    gridRow: '11/12',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    height: '180px',
                    scrollbarColor: 'rebeccapurple green',
                    scrollbarWidth: 'auto',
                    border: '2px solid silver',
                    borderRadius: '9px',
                    margin: '0px 0px 0px -10px',
                    padding: '10px 10px 0px 10px',
                    backgroundColor: '#34354f',
                    minWidth: '225px'
                  }}>

                    <div style={{ color: '#fff' }}>LINER 1</div>
                    <div style={{ display: 'grid', gridTemplateColumns: '15fr 10fr 1fr', lineHeight: '20px', width: '106%', backgroundColor: 'transparent', color: '#D3D3D3', margin: '0px 0px 10px -10px', padding: '10px' }}>
                      <div style={{ gridColumn: '1/2', gridRow: '1/2' }}>Liner Size</div>
                      <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerSize1 : 0}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '1/2', textAlign: 'right' }}>in</div>
                      <div style={{ gridColumn: '1/2', gridRow: '2/3' }}>Liner ID</div>
                      <div style={{ gridColumn: '2/3', gridRow: '2/3', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerId1 : 0}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '2/3', textAlign: 'right' }}>in</div>
                      <div style={{ gridColumn: '1/2', gridRow: '3/4', whiteSpace: 'nowrap' }}>Liner Hanger MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '3/4', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolMd1 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '3/4', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '4/5', whiteSpace: 'nowrap' }}>Liner Hanger TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '4/5', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolTvd1 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '4/5', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '5/6' }}>Liner Shoe MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '5/6', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeMd1 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '5/6', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '6/7' }}>Liner Shoe TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '6/7', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeTvd1 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '6/7', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                    </div>

                  {WellM &&
                    (WellM.LinerSize2 + WellM.LinerId2 + WellM.LinerTolMd2 + WellM.LinerTolTvd2 + WellM.LinerShoeMd2 + WellM.LinerShoeTvd2 ) > 0 ? (
                    <div>
                      <div style={{ color: '#fff' }}>LINER 2</div>
                      <div style={{ display: 'grid', gridTemplateColumns: '15fr 10fr 1fr', lineHeight: '20px', width: '106%', backgroundColor: 'transparent', color: '#D3D3D3', margin: '0px 0px 10px -10px', padding: '10px' }}>
                        <div style={{ gridColumn: '1/2', gridRow: '1/2' }}>Liner Size</div>
                        <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerSize2 : 0}</div>
                        <div style={{ gridColumn: '3/4', gridRow: '1/2', textAlign: 'right' }}>in</div>
                        <div style={{ gridColumn: '1/2', gridRow: '2/3' }}>Liner ID</div>
                        <div style={{ gridColumn: '2/3', gridRow: '2/3', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerId2 : 0}</div>
                        <div style={{ gridColumn: '3/4', gridRow: '2/3', textAlign: 'right' }}>in</div>
                        <div style={{ gridColumn: '1/2', gridRow: '3/4' }}>Liner Hanger MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '3/4', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolMd2 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '3/4', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '4/5' }}>Liner Hanger TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '4/5', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolTvd2 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '4/5', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '5/6' }}>Liner Shoe MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '5/6', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeMd2 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '5/6', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '6/7' }}>Liner Shoe TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '6/7', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeTvd2 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '6/7', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      </div>
                    </div>) : null}

                    {WellM &&
                    (WellM.LinerSize3 + WellM.LinerId3 + WellM.LinerTolMd3 + WellM.LinerTolTvd3 + WellM.LinerShoeMd3 + WellM.LinerShoeTvd3 ) > 0 ? (
                    <div>
                      <div style={{ color: '#fff' }}>LINER 3</div>
                      <div style={{ display: 'grid', gridTemplateColumns: '15fr 10fr 1fr', lineHeight: '20px', width: '106%', backgroundColor: 'transparent', color: '#D3D3D3', margin: '0px 0px 10px -10px', padding: '10px' }}>
                        <div style={{ gridColumn: '1/2', gridRow: '1/2' }}>Liner Size</div>
                        <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerSize3 : 0}</div>
                        <div style={{ gridColumn: '3/4', gridRow: '1/2', textAlign: 'right' }}>in</div>
                        <div style={{ gridColumn: '1/2', gridRow: '2/3' }}>Liner ID</div>
                        <div style={{ gridColumn: '2/3', gridRow: '2/3', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerId3 : 0}</div>
                        <div style={{ gridColumn: '3/4', gridRow: '2/3', textAlign: 'right' }}>in</div>
                        <div style={{ gridColumn: '1/2', gridRow: '3/4' }}>Liner Hanger MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '3/4', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolMd3 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '3/4', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '4/5' }}>Liner Hanger TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '4/5', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolTvd3 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '4/5', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '5/6' }}>Liner Shoe MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '5/6', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeMd3 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '5/6', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '6/7' }}>Liner Shoe TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '6/7', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeTvd3 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '6/7', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      </div>
                    </div>) : null}

                    {WellM &&
                    (WellM.LinerSize4 + WellM.LinerId4 + WellM.LinerTolMd4 + WellM.LinerTolTvd4 + WellM.LinerShoeMd4 + WellM.LinerShoeTvd4 ) > 0 ? (
                    <div>
                      <div style={{ color: '#fff' }}>LINER 4</div>
                      <div style={{ display: 'grid', gridTemplateColumns: '15fr 10fr 1fr', lineHeight: '20px', width: '106%', backgroundColor: 'transparent', color: '#D3D3D3', margin: '0px 0px 10px -10px', padding: '10px' }}>
                        <div style={{ gridColumn: '1/2', gridRow: '1/2' }}>Liner Size</div>
                        <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerSize4 : 0}</div>
                        <div style={{ gridColumn: '3/4', gridRow: '1/2', textAlign: 'right' }}>in</div>
                        <div style={{ gridColumn: '1/2', gridRow: '2/3' }}>Liner ID</div>
                        <div style={{ gridColumn: '2/3', gridRow: '2/3', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerId4 : 0}</div>
                        <div style={{ gridColumn: '3/4', gridRow: '2/3', textAlign: 'right' }}>in</div>
                        <div style={{ gridColumn: '1/2', gridRow: '3/4' }}>Liner Hanger MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '3/4', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolMd4 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '3/4', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '4/5' }}>Liner Hanger TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '4/5', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolTvd4 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '4/5', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '5/6' }}>Liner Shoe MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '5/6', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeMd4 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '5/6', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '6/7' }}>Liner Shoe TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '6/7', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeTvd4 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '6/7', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      </div>
                    </div>) : null}

                    {WellM &&
                    (WellM.LinerSize5 + WellM.LinerId5 + WellM.LinerTolMd5 + WellM.LinerTolTvd5 + WellM.LinerShoeMd5 + WellM.LinerShoeTvd5 ) > 0 ? (
                    <div>
                      <div style={{ color: '#fff' }}>LINER 5</div>
                      <div style={{ display: 'grid', gridTemplateColumns: '15fr 10fr 1fr', lineHeight: '20px', width: '106%', backgroundColor: 'transparent', color: '#D3D3D3', margin: '0px 0px 10px -10px', padding: '10px' }}>
                        <div style={{ gridColumn: '1/2', gridRow: '1/2' }}>Liner Size</div>
                        <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerSize5 : 0}</div>
                        <div style={{ gridColumn: '3/4', gridRow: '1/2', textAlign: 'right' }}>in</div>
                        <div style={{ gridColumn: '1/2', gridRow: '2/3' }}>Liner ID</div>
                        <div style={{ gridColumn: '2/3', gridRow: '2/3', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.LinerId5 : 0}</div>
                        <div style={{ gridColumn: '3/4', gridRow: '2/3', textAlign: 'right' }}>in</div>
                        <div style={{ gridColumn: '1/2', gridRow: '3/4' }}>Liner Hanger MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '3/4', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolMd5 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '3/4', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '4/5' }}>Liner Hanger TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '4/5', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerTolTvd5 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '4/5', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '5/6' }}>Liner Shoe MD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '5/6', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeMd5 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '5/6', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      <div style={{ gridColumn: '1/2', gridRow: '6/7' }}>Liner Shoe TVD</div>
                      <div style={{ gridColumn: '2/3', gridRow: '6/7', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', WellM ? WellM!.LinerShoeTvd5 : 0, 15).toFixed(1)}</div>
                      <div style={{ gridColumn: '3/4', gridRow: '6/7', textAlign: 'right' }}>{getLabel('lengthMedium', locationState)}</div>
                      </div>
                    </div>) : null}

                  </div>

                  <div style={{ gridColumn: '1/3', gridRow: '12/13', fontWeight: 700, color: '#000000' }}>HOLE DATA</div>

                  <div style={{ gridColumn: '1/2', gridRow: '13/14' }}>Hole Size</div>
                  <div style={{ gridColumn: '2/3', gridRow: '13/14', textAlign: 'right', marginRight: '6px' }}>{WellM ? WellM!.BhaSize : 0}</div>
                  <div style={{ gridColumn: '3/4', gridRow: '13/14' }}>in</div>

                  <div style={{ gridColumn: '1/2', gridRow: '14/15' }}>Hole MD</div>
                  <div style={{ gridColumn: '2/3', gridRow: '14/15', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', getRealHoleDepth(), 15).toFixed(1)}</div>
                  <div style={{ gridColumn: '3/4', gridRow: '14/15' }}>{getLabel('lengthMedium', locationState)}</div>

                  <div style={{ gridColumn: '1/2', gridRow: '15/16' }}>Hole TVD</div>
                  <div style={{ gridColumn: '2/3', gridRow: '15/16', textAlign: 'right', marginRight: '6px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', getRealHoleTVD(), 15).toFixed(1)}</div>
                  <div style={{ gridColumn: '3/4', gridRow: '15/16' }}>{getLabel('lengthMedium', locationState)}</div>

                </div>
                <div style={{
                  width: '155px',
                  backgroundColor: 'transparent',
                  color: '#737496',
                  marginLeft: '10px',
                }}>
                  <img
                    alt="kill"
                    src={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? kill_clear : kill_land}
                    style={{ height: '700px', marginTop: '10px' }}
                    className="theme_black"
                  />
                </div>
              </div>
            </div>

            <div id="killSheetDataTable" className="" style={{ gridColumn: '1/3', gridRow: '4/5', backgroundColor: '#E5E4E2', borderRadius: '8px', border: '1px solid #515270', margin: 'auto', padding: '8px', width: '100%', height: '100%', marginTop: '10px' }}>

              {/* header */}
              <div style={{ borderTop: '1px solid transparent', borderLeft: '1px solid transparent', borderRight: '1px solid transparent', padding: '10px', color: '#fff', fontWeight: 700, backgroundColor: '#6495ED', marginBottom: '10px', marginTop: '-8px', marginLeft: '-8px', marginRight: '-8px', width: '101.5%', borderRadius: '8px 8px 0px 0px', textTransform: 'capitalize' }}>Pre-recorded Volume Data</div>

              <div style={{ display: 'flex', flexDirection: 'row', color: '#000000', borderBottom: '1px solid #2b2c40' }}>
                <div className="killSheetNumericCell" style={{ flex: 3, textAlign: 'left', fontWeight: 700, width: '300px', minWidth: '300px', maxWidth: '300px' }}><br/></div>
                <div className="killSheetNumericCell" style={{ flex: 2, fontWeight: 700, width: '131px', minWidth: '131px', maxWidth: '131px' }}><span className="killSheetTableUnitHeader">{getLabel('lengthMedium', locationState)}</span><br />Length</div>
                <div className="killSheetNumericCell" style={{ flex: 2, fontWeight: 700 }}><span className="killSheetTableUnitHeader">{getLabel('volumeDistance', locationState) === 'm3/m' ? <>m&sup3;/m</> : getLabel('volumeDistance', locationState)}</span><br />Capacity</div>
                <div className="killSheetNumericCell" style={{ flex: 2, fontWeight: 700 }}><span className="killSheetTableUnitHeader">{getLabel('volume', locationState) === 'm3' ? <>m&sup3;</> : getLabel('volume', locationState)}</span><br />Volume</div>
                <div className="killSheetNumericCell" style={{ flex: 2, fontWeight: 700 }}><span className="killSheetTableUnitHeader">stks</span><br />Pump Strokes</div>
                <div className="killSheetNumericCell" style={{ flex: 2, fontWeight: 700 }}><span className="killSheetTableUnitHeader">minutes</span><br />Time</div>
              </div>

              <div className="sectionHeader">
                <div className="sectionHeader"
                  style={{ flex: 13, height: '25px', marginLeft: '-3px', paddingTop: '2px', textAlign: 'left', fontWeight: 600, color: '#000000' }}>
                  Drill String
                </div>
              </div>

              {/* section 1 - string-block */}
              {killSheetMatrix?.filter(item => item[1] === 'string-block').map((row, index) => {
                return (
                  row[5] === '' || row[5] === 0 || ((row[5] > -0.01) && (row[5] < 0.01)) || row[5] === null || row[5] === undefined || row[5] === 'NaN' ? null :
                  <div
                    key={index}
                    className={row[9] === 'bold' ? 'totalRowContainer' : ''}
                    style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #899499' }}
                  >
                    <div className="killSheetNumericCell" style={{ flex: 3, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                      {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                      {row[8] === 'pipe' ?
                        row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                        : null}
                      {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                      {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                      {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                      {row[9] === 'bold' ?
                        <span className="dataTooltipHost" style={{ fontWeight: 'normal' }}>{trimLongLabel(row[2], 35, '')}</span> :
                        <span data-tooltip={row[2]} data-tooltip-position="right" className="dataTooltipHost">{trimLongLabel(row[2], 35, '')}</span>
                      }
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2, width: '131px', minWidth: '131px', maxWidth: '131px' }}>
                      {isNaN(parseFloat(row[3])) ?
                        ''
                        : (Number(getStatistic('hybridizeLengthImpToMet', locationState)) * parseFloat(row[3])).toFixed(2)
                      }
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>
                      {isNaN(parseFloat(row[4])) ?
                        ''
                        : (Number(getStatistic('hybridizeVolume', locationState)) * parseFloat(row[4])).toFixed(5)
                        }
                      </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>
                      {row[2] === 'Drill String Volume' ? <span className="blueCircularEquationLabel rightMargin10">D</span> : null}
                      {isNaN(parseFloat(row[5])) ? '' : parseFloat(row[5]).toFixed(1)}
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>
                      {row[2] === 'Drill String Volume' ? <span className="blueCircularEquationLabel rightMargin10">E</span> : null}
                      {isNaN(parseFloat(row[6])) ? '' : parseFloat(row[6]).toFixed(1)}
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[7])) ? '' : parseFloat(row[7]).toFixed(1)}</div>
                  </div>
                )
              })}

              <div className="sectionHeader">
                <div className="sectionHeader"
                  style={{ flex: 13, height: '25px', marginLeft: '-3px', paddingTop: '2px', textAlign: 'left', fontWeight: 600, color: '#000000' }}>
                  Open Hole
                </div>
              </div>

              {/* section 2 - openhole block */}
              {killSheetMatrix?.filter(item => item[1] === 'openhole-block').map((row, index) => {
                return (
                  row[5] === '' || row[5] === 0 || ((row[5] > -0.01) && (row[5] < 0.01)) || row[5] === null || row[5] === undefined || isNaN(row[5]) ? null :
                  <div
                    className={row[9] === 'bold' ? 'totalRowContainer' : ''}
                    key={index}
                    style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #899499' }}
                  >
                    {row[9] === 'bold' ?
                      <div className="killSheetNumericCell" style={{ whiteSpace: 'nowrap', flex: 2, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                        {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                        {/* {row[8] === 'pipe' ? <span className="source source6">Pipe</span>: null} */}
                        {row[8] === 'pipe' ?
                          row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                          : null}
                        {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                        {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                        {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                        <span className="dataTooltipHost" style={{ fontWeight: 'normal' }}>
                          {row[2]}
                          <span className="killSheetNumericCell" style={{ flex: 1, color: 'transparent', textAlign: 'left', width: '1px !important' }}>
                            {getOpenHoleMdLength().toFixed(2)}
                          </span>
                        </span>
                      </div> :
                      <div className="killSheetNumericCell" style={{ whiteSpace: 'nowrap', flex: 3, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                        {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                        {/* {row[8] === 'pipe' ? <span className="source source6">Pipe</span>: null} */}
                        {row[8] === 'pipe' ?
                          row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                          : null}
                        {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                        {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                        {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                        <span data-tooltip={row[2]} data-tooltip-position="right" className="dataTooltipHost">{trimLongLabel(row[2], 35, '')}</span>
                      </div>
                    }
                    <div className="killSheetNumericCell" style={{ flex: 2, width: '131px', minWidth: '131px', maxWidth: '131px' }}>
                      {isNaN(parseFloat(row[3])) ?
                        ''
                        : (Number(getStatistic('hybridizeLengthImpToMet', locationState)) * parseFloat(row[3])).toFixed(2)
                      }
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>
                      {isNaN(parseFloat(row[4])) ?
                        ''
                        : (Number(getStatistic('hybridizeVolume', locationState)) * parseFloat(row[4])).toFixed(5)
                      }
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>
                      {row[2] === 'Open Hole Volume' ? <span className="blueCircularEquationLabel rightMargin10">F</span> : null}
                      {isNaN(parseFloat(row[5])) ? '' : parseFloat(row[5]).toFixed(1)}
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[6])) ? '' : parseFloat(row[6]).toFixed(1)}</div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[7])) ? '' : parseFloat(row[7]).toFixed(1)}</div>
                  </div>
                )
              })}

              <div className="sectionHeader">
                <div className="sectionHeader"
                  style={{ flex: 13, height: '25px', marginLeft: '-3px', paddingTop: '2px', textAlign: 'left', fontWeight: 600, color: '#000000' }}>
                  Casing / Liner
                </div>
              </div>

              {/* section 3 - casing block */}
              {killSheetMatrix?.filter(item => item[1] === 'casing-block').map((row, index) => {
                return (
                  row[5] === '' || row[5] === 0 || ((row[5] > -0.01) && (row[5] < 0.01)) || row[5] === null || row[5] === undefined || isNaN(row[5]) ? null :
                  <div
                    className={row[11] === 'subtotal' ? 'killSheetDataTableSubTotal' : row[9] === 'bold' ? 'killSheetDataTableTotal' : ''}
                    key={index}
                    style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #899499' }}
                  >
                    {row[9] === 'bold' ?
                      <div className="killSheetNumericCell" style={{ whiteSpace: 'nowrap',flex: 3, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                        {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                        {/* {row[8] === 'pipe' ? <span className="source source6">Pipe</span>: null} */}
                        {row[8] === 'pipe' ?
                          row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                          : null}
                        {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                        {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                        {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                        <span className="dataTooltipHost" style={{ fontWeight: 'normal' }}>{row[2]}</span>
                      </div> :
                      <div className="killSheetNumericCell" style={{ whiteSpace: 'nowrap',flex: 3, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                        {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                        {/* {row[8] === 'pipe' ? <span className="source source6">Pipe</span>: null} */}
                        {row[8] === 'pipe' ?
                          row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                          : null}
                        {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                        {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                        {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                        <span data-tooltip={row[2]} data-tooltip-position="right" className="dataTooltipHost">{trimLongLabel(row[2], 35, '')}</span>
                      </div>
                    }
                    <div className="killSheetNumericCell" style={{ flex: 2, width: '131px', minWidth: '131px', maxWidth: '131px' }}>{
                      isNaN(parseFloat(row[3])) ?
                        ''
                        : (Number(getStatistic('hybridizeLengthImpToMet', locationState)) * parseFloat(row[3])).toFixed(2)
                      }
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>
                      {isNaN(parseFloat(row[4])) ?
                        <span className="blueCircularEquationLabel">G</span>
                        : (Number(getStatistic('hybridizeVolume', locationState)) * parseFloat(row[4])).toFixed(5)
                      }
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[5])) ? '' : parseFloat(row[5]).toFixed(1)}</div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[6])) ? '' : parseFloat(row[6]).toFixed(1)}</div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[7])) ? '' : parseFloat(row[7]).toFixed(1)}</div>
                  </div>
                )
              })}

              {/* section 4 - liner block */}
              {killSheetMatrix?.filter(item => item[1] === 'casingliner-block').map((row, index) => {
                return (
                  row[5] === '' || row[5] === 0 || ((row[5] > -0.01) && (row[5] < 0.01)) || row[5] === null || row[5] === undefined || isNaN(row[5]) ? null :
                  <div
                    className={row[11] === 'subtotal' ? 'killSheetDataTableSubTotal' : row[9] === 'bold' ? 'killSheetDataTableTotal' : ''}
                    key={index}
                    style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #899499' }}
                  >
                    {row[9] === 'bold' ?
                      <div className="killSheetNumericCell" style={{ whiteSpace: 'nowrap',flex: 3, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                        {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                        {row[8] === 'pipe' ?
                          row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                          : null}
                        {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                        {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                        {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                        <span className="dataTooltipHost" style={{ fontWeight: 'bold' }}>{row[2]}</span>
                      </div> :
                      <div className="killSheetNumericCell" style={{ whiteSpace: 'nowrap',flex: 3, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                        {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                        {row[8] === 'pipe' ?
                          row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                          : null}
                        {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                        {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                        {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                        <span data-tooltip={row[2]} data-tooltip-position="right" className="dataTooltipHost">{trimLongLabel(row[2], 35, '')}</span>
                      </div>
                    }
                    <div className="killSheetNumericCell" style={{ flex: 2, width: '131px', minWidth: '131px', maxWidth: '131px' }}>
                      {isNaN(parseFloat(row[3])) ?
                        ''
                        : (Number(getStatistic('hybridizeLengthImpToMet', locationState)) * parseFloat(row[3])).toFixed(2)
                      }
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>
                      {isNaN(parseFloat(row[4])) ?
                        <span className="blueCircularEquationLabel">F</span>
                        : (Number(getStatistic('hybridizeVolume', locationState)) * parseFloat(row[4])).toFixed(5)
                      }
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[5])) ? '' : parseFloat(row[5]).toFixed(1)}</div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[6])) ? '' : parseFloat(row[6]).toFixed(1)}</div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[7])) ? '' : parseFloat(row[7]).toFixed(1)}</div>
                  </div>
                )
              })}

              {/* summary */}
              {killSheetMatrix?.filter(item => item[1] === 'total-block').map((row, index) => {
                return (
                  <div
                    className={row[9] === 'bold' ? 'totalRowContainer' : ''}
                    key={index}
                    style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #899499' }}
                  >
                    {row[9] === 'bold' ?
                      <div className="killSheetNumericCell" style={{ whiteSpace: 'nowrap',flex: 3, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                        {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                        {row[8] === 'pipe' ?
                          row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                          : null}
                        {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                        {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                        {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                        <span className="dataTooltipHost" style={{ fontWeight: 'normal' }}>{row[2]}</span>
                      </div> :
                      <div className="killSheetNumericCell" style={{ whiteSpace: 'nowrap',flex: 3, textAlign: 'left', width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                        {row[8] === 'bha' ? <span className="source source5">BHA</span>: null}
                        {row[8] === 'pipe' ?
                          row[9] === 'HWDP' || row[9] === 'DC' ? <span className="source source5">BHA</span> : <span className="source source6">Pipe</span>
                          : null}
                        {row[8] === 'casing' ? <span className="source source7">Case</span>: null}
                        {row[8] === 'liner' ? <span className="source source8">Liner</span>: null}
                        {row[8] === '' ? <span className="source sourceN">..........</span>: null}
                        <span data-tooltip={row[2]} data-tooltip-position="right" className="dataTooltipHost">{trimLongLabel(row[2], 35, '')}</span>
                      </div>
                    }
                    <div className="killSheetNumericCell" style={{ flex: 2, width: '131px', minWidth: '131px', maxWidth: '131px' }}>{isNaN(parseFloat(row[3])) ? '' : parseFloat(row[3]).toFixed(2)}</div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[4])) ? '' : parseFloat(row[4]).toFixed(5)}</div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>
                      {row[2] === 'DP/HWDP/DC x Casing/Liner Volume' ? <span className="blueCircularEquationLabel rightMargin10">G</span> : null}
                      {isNaN(parseFloat(row[5])) ? '' : parseFloat(row[5]).toFixed(1)}
                    </div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[6])) ? '' : parseFloat(row[6]).toFixed(1)}</div>
                    <div className="killSheetNumericCell" style={{ flex: 2 }}>{isNaN(parseFloat(row[7])) ? '' : parseFloat(row[7]).toFixed(1)}</div>
                  </div>
                )
              })}

              <div className="sectionHeader">
                <div className="sectionHeader"
                  style={{ flex: 13, height: '25px', marginLeft: '-3px', paddingTop: '2px', textAlign: 'left', fontWeight: 600, color: '#000000' }}>
                  Summary
                </div>
              </div>

              {/* statistics */}
              <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : 'metricTableRowContainer'}>
                <div className="killSheetNumericCell" style={{ flex: 2, textAlign: 'left', color: '#000000', minWidth: '175px', maxWidth: '175px' }}>Surface Line Volume</div>
                <div className="killSheetNumericCell" style={{ flex: 1, whiteSpace: 'nowrap', minWidth: '85px', maxWidth: '85px' }}>
                  <input type="number"
                    defaultValue={KillSheet?.surfaceLineVolume || 0}
                    style={{ marginLeft: '11px', width: '60px', height: '20px'}}
                    onFocus={e => e.target.select()}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'surfaceLineVolume', 'number', e.target.value)
                    })}
                    /> {getLabel('volume', locationState) === 'm3' ? <>m&sup3;</> : getLabel('volume', locationState)}
                  </div>
                <div className="killSheetNumericCell" style={{ flex: 2, marginLeft: '20px', minWidth: '170px', maxWidth: '170px', whiteSpace: 'nowrap' }}>
                  <div style={{ backgroundColor: '#D1D0CE', color: 'black', marginBottom: '15px', width: '376px',  fontWeight: 500, borderRadius: '8px', marginLeft: '100px', paddingLeft: '10px', paddingRight: '10px' }}>Surface Line Volume is not included in calculations below</div>
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}></div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}></div>
                <div className="killSheetNumericCell" style={{ flex: 2, marginLeft: '-17px' }}>{(checkIsNumber(KillSheet!.surfaceLineVolume) / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2)).toFixed(0)}</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>{(checkIsNumber(KillSheet!.surfaceLineVolume) / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2)  / KillSheet!.spm).toFixed(0)}</div>
              </div>

              <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : 'metricTableRowContainer'}>
                <div className="killSheetNumericCell" style={{ flex: 2, textAlign: 'left', color: '#000000', minWidth: '175px', maxWidth: '175px' }}>Choke Line Volume (ID)</div>
                <div className="killSheetNumericCell" style={{ flex: 1, whiteSpace: 'nowrap', minWidth: '85px', maxWidth: '85px' }}>
                  <input type="number"
                    defaultValue={KillSheet?.chokelineVolume || 0}
                    style={{ width: '60px', height: '20px'}}
                    onFocus={e => e.target.select()}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'chokelineVolume', 'number', e.target.value)
                    })}
                    /> in
                  </div>
                <div className="killSheetNumericCell" style={{ flex: 2, minWidth: '170px', maxWidth: '170px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', KillSheet?.chokelineLength || 0, 15).toFixed(2)}</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>{unitConversion('lengthMedium', locationState?.units, 'in', matrixMetric(((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt)), 15).toFixed(5)}</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                <span style={{
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      // -moz-border-radius: '50%',
                      borderRadius: '50%',
                      border: 'solid 1px #1464F4',
                      backgroundColor: 'dodgerblue',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'inline-block',
                      fontWeight: 800,
                      marginRight: '10px'
                    }}>
                      H
                    </span>
                  {((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    matrixMetric(((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength).toFixed(1)
                    : 0
                  }
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  {
                    checkIsNumber(matrixMetric(((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0) > 0 ?
                      (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                      (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0) /
                      (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2) : 0))).toFixed(0)
                  }
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  {
                    checkIsNumber(matrixMetric((((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0) > 0 ?
                      (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                      (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0) /
                      (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2) : 0) / KillSheet!.spm))).toFixed(0)
                  }
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', height: '26px', paddingTop: '2px', borderBottom: '1px solid #899499' }}>
                <div className="killSheetNumericCell" style={{ flex: 5, textAlign: 'left', color: '#000000', minWidth: '435px', maxWidth: '435px' }}>Total Annulus/Chokeline Volume</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  <span className="hollowLetter">F</span> +
                  <span className="hollowLetter">G</span>
                  {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? '' : '+'}
                  <span className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : 'hollowLetter'}>
                    H
                  </span>
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                <span className="blueLetter" style={{marginRight: '10px'}}>
                    I
                  </span>
                  {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ?
                    checkIsNumber(((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) + (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)))).toFixed(1)
                    :
                    checkIsNumber(((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) + (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)) +
                    matrixMetric((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0)))).toFixed(1)}
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ?
                    checkIsNumber(((((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)))
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2)))).toFixed(0)

                  :
                    checkIsNumber(((((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)) +
                    matrixMetric((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0)))
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2)))).toFixed(0)}
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ?
                    checkIsNumber(((((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)))
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2)) / KillSheet!.spm)).toFixed(0)
                  :
                    checkIsNumber(((((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)) +
                    matrixMetric((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0)))
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2)) / KillSheet!.spm)).toFixed(0)}
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', height: '26px', paddingTop: '2px', borderBottom: '1px solid #899499' }}>
                <div className="killSheetNumericCell" style={{ flex: 5, textAlign: 'left', color: '#000000', minWidth: '435px', maxWidth: '435px' }}>Total Well System Volume</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  <span className="hollowLetter">D</span> +
                  <span className="hollowLetter">I</span>
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  <span className="blueLetter" style={{marginRight: '10px'}}>J</span>
                  {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ?
                   checkIsNumber((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5))))).toFixed(1)
                  :
                  checkIsNumber((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)) +
                    matrixMetric((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0))))).toFixed(1)
                  }
                  </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ?
                    checkIsNumber(((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5))))
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2))).toFixed(0)
                  :
                  checkIsNumber(((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)) +
                    matrixMetric((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0))))
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2))).toFixed(0)
                  }
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ?
                    checkIsNumber((((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5))))
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2)) / KillSheet!.spm)).toFixed(0)

                  :
                  checkIsNumber((((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)) +
                    matrixMetric((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0))))
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2)) / KillSheet!.spm)).toFixed(0)
                  }
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', height: '26px', paddingTop: '2px', borderBottom: '1px solid #899499' }}>
                <div className="killSheetNumericCell" style={{ flex: 5, textAlign: 'left', color: '#000000', minWidth: '435px', maxWidth: '435px' }}>Active Surface Volume</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>&nbsp;</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  <div>
                  <span className="blueLetter" style={{marginRight: '10px'}}>K</span>
                  <input type="number"
                    defaultValue={KillSheet?.activeSurfaceVolume || 0}
                    style={{ width: '80px', height: '20px'}}
                    onFocus={e => e.target.select()}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'activeSurfaceVolume', 'number', e.target.value)
                    })}
                    />
                  </div>
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>&nbsp;</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>&nbsp;</div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', height: '26px', paddingTop: '2px', borderBottom: '1px solid #899499' }}>
                <div className="killSheetNumericCell" style={{ flex: 5, textAlign: 'left', color: '#000000', minWidth: '435px', maxWidth: '435px' }}>Total Active Fluid System</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  <span className="hollowLetter">J</span> +
                  <span className="hollowLetter">K</span>
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ?
                    checkIsNumber(((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5))))
                    + KillSheet?.activeSurfaceVolume
                    )).toFixed(1)

                  :
                  checkIsNumber(((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)) +
                    matrixMetric((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0))))
                    + KillSheet?.activeSurfaceVolume
                    )).toFixed(1)
                  }
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>
                  {locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ?
                    checkIsNumber((((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5))))
                    + KillSheet?.activeSurfaceVolume)
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2))).toFixed(0)
                  :
                  checkIsNumber((((getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 5) +
                    ((getMatrixValue(killSheetMatrix, 'openhole-block', 'Open Hole Volume', 5)) +
                    (getMatrixValue(killSheetMatrix, 'total-block', 'DP/HWDP/DC x Casing/Liner Volume', 5)) +
                    matrixMetric((((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) > 0 ?
                    (((KillSheet!.chokelineVolume * KillSheet!.chokelineVolume) / sqInchToBblFt) * KillSheet?.chokelineLength) : 0))))
                    + KillSheet?.activeSurfaceVolume)
                    / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2))).toFixed(0)
                  }
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>&nbsp;</div>
              </div>

              <div  className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : 'metricTableRowContainer'}
              >
                <div className="killSheetNumericCell" style={{ flex: 2, textAlign: 'left', fontSize: '14px', color: '#000000', minWidth: '175px', maxWidth: '175px' }}>Riser x DP Volume (ID)</div>
                <div className="killSheetNumericCell" style={{ flex: 1, whiteSpace: 'nowrap', minWidth: '85px', maxWidth: '85px' }}>
                  <input type="number" style={{ width: '60px', height: '20px'}} defaultValue={KillSheet?.marineRiserDpVolume || 0}
                    onFocus={e => e.target.select()}
                    onChange={((e) => {
                      KillSheet?.updateKillSheetData(KillSheet.id, 'marineRiserDpVolume', 'number', e.target.value)
                    })}
                  /> in
                </div>
                <div className="killSheetNumericCell" style={{ flex: 2, minWidth: '170px', maxWidth: '170px' }}>{unitConversion('lengthMedium', locationState?.units, 'out', getMarineRiserLength(), 15).toFixed(2)}</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>{unitConversion('lengthMedium', locationState?.units, 'in', matrixMetric((((KillSheet!.marineRiserDpVolume * KillSheet!.marineRiserDpVolume) - (getLastStringOd() * getLastStringOd())) / sqInchToBblFt)), 15).toFixed(5)}</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>{(matrixMetric(getMarineRiserLength() * (((KillSheet!.marineRiserDpVolume * KillSheet!.marineRiserDpVolume) - (getLastStringOd() * getLastStringOd())) / sqInchToBblFt))).toFixed(1)}</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>{
                    (
                      checkIsNumber(matrixMetric((getMarineRiserLength()
                      * (((KillSheet!.marineRiserDpVolume * KillSheet!.marineRiserDpVolume) - (getLastStringOd() * getLastStringOd())) / sqInchToBblFt)
                      / (KillSheet!.pump === 1 ? KillSheet!.displacementPump1 : KillSheet!.displacementPump2))
                    ))).toFixed(0)
                  }</div>
                <div className="killSheetNumericCell" style={{ flex: 2 }}>&nbsp;</div>
              </div>

            </div>

            <div id="killSheetSummary" style={{ gridColumn: '1/3', gridRow: '5/6', backgroundColor: '#E5E4E2', borderRadius: '8px', border: '1px solid #515270', margin: 'auto', padding: '8px', width: '100%', height: '100%', marginTop: '10px' }}>

              <div>
                {/* <div style={{ backgroundColor: 'transparent', width: '100%', padding: '10px', color: '#000000', fontWeight: 700 }}>Kick Data</div> */}
                <div style={{ borderTop: '1px solid transparent', borderLeft: '1px solid transparent', borderRight: '1px solid transparent', padding: '10px', color: '#fff', fontWeight: 700, backgroundColor: '#6495ED', marginBottom: '10px', marginTop: '-8px', marginLeft: '-8px', marginRight: '-8px', width: '101.5%', borderRadius: '8px 8px 0px 0px', textTransform: 'capitalize' }}>Kick Data</div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '10px', alignContent: 'center', justifyContent: 'center', alignItems: 'center', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '40px', fontSize: '13px', borderBottom: '1px solid #899499' }}>
                  <div style={{ width: '30%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginLeft: '100px', marginRight: '10px', textAlign: 'left' }}>SIDPP</div>
                    <div style={{textAlign: 'right', marginRight: '6px', color: '#000000' }}>
                    <input id="sidpp" type="number" style={{ width: '100px' }} defaultValue={KillSheet?.sidpp || 0}
                      onFocus={e => e.target.select()}
                      onChange={((e) => {
                        // KillSheet?.updateKillSheetData(KillSheet.id, 'sidpp', 'number', e.target.value)
                        doubleInputUpdater(e.target.value)
                      })}
                    />
                    </div>
                    <div style={{ textAlign: 'left' }}>{getLabel('pressure', locationState)}</div>
                  </div>
                  <div style={{ width:'5%' }}>&nbsp;</div>
                  <div style={{ width: '30%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginLeft: '0px', marginRight: '10px', textAlign: 'right' }}>SICP</div>
                    <div style={{ textAlign: 'right', marginRight: '6px', color: '#000000' }}>
                    <input id="sicp" type="number" style={{ width: '100px' }} defaultValue={KillSheet?.sicp || 0}
                      onFocus={e => e.target.select()}
                      onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'sicp', 'number', e.target.value)
                      })}
                    />
                    </div>
                    <div style={{ textAlign: 'left', backgroundColor: 'transparent' }}>{getLabel('pressure', locationState)}</div>
                  </div>
                  <div style={{ width:'5%' }}>&nbsp;</div>
                  <div style={{ width: '30%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginLeft: '0px', marginRight: '10px', textAlign: 'right' }}>PIT GAIN</div>
                    <div style={{ textAlign: 'right', marginRight: '6px', color: '#000000' }}>
                    <input id="pitgain" type="number" style={{ width: '100px' }} defaultValue={KillSheet?.pitgain || 0}
                      onFocus={e => e.target.select()}
                      onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'pitgain', 'number', e.target.value)
                      })}
                    />
                    </div>
                    <div style={{ textAlign: 'left', backgroundColor: 'transparent' }}>{getLabel('volume', locationState) === 'm3' ? <>m&sup3;</> : getLabel('volume', locationState)}</div>
                  </div>
                </div>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '32fr 46fr 10fr 3fr 4fr', gridGap: '1px', color: '#737496', fontSize: '14px', paddingBottom: '10px', borderBottom: '1px solid #899499' }}>
                <div style={{ gridColumn: '1/2', gridRow: '1/3', textAlign: 'left', marginLeft: '10px', fontSize: '14px', minWidth: '342px', maxWidth: '342px', borderRight: '1px solid #899499' }}><span style={{ color: '#000000', fontWeight: 700 }}>Kill Fluid Density</span><br /><br />KMD</div>
                <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'left', marginLeft: '20px', marginRight: '10px', fontSize: '14px', color: 'dodgerblue', fontStyle: 'italic' }}>
                  Current Drilling Fluid Density + SIDPP / <span className="redBrackets">(</span> TVD x {(locationState?.units === 'metric' ? '0.0981' : '0.052')} <span className="redBrackets">)</span>
                </div>
                <div style={{ gridColumn: '2/3', gridRow: '2/3', display: 'grid', gridTemplateColumns: '5fr 1fr 5fr 10fr', marginLeft: '20px' }}>

                  <div style={{ gridColumn: '1/2', gridRow: '2/3', marginLeft: '10px'}}>{getLastTrendSheetRow()?.mwOut}</div>
                  <div style={{ gridColumn: '2/3', gridRow: '2/3' }}>+</div>
                  <div style={{ gridColumn: '3/4', gridRow: '1/3', display: 'grid', gridTemplateColumns: '1fr', textAlign: 'center', maxWidth: '150px', marginLeft: '10px' }}>
                    <div style={{ gridColumn: '1/1', gridRow: '1/2', width: '115px', textAlign: 'center', maxWidth: '150px', borderBottom: '1px solid #899499' }}>
                      {KillSheet?.sidpp || 0}
                    </div>
                    <div style={{ gridColumn: '1/1', gridRow: '2/2', width: '115px', textAlign: 'center', maxWidth: '150px' }}>
                      {getRealHoleTVD().toFixed(2)} x {locationState?.units === 'metric' ? '0.0981' : '0.052'}
                    </div>
                  </div>
                  <div style={{ gridColumn: '4/5', gridRow: '2/3' }}>&nbsp;</div>

                </div>
                <div style={{ gridColumn: '3/4', gridRow: '2/3'}}>&nbsp;</div>
                <div style={{ gridColumn: '4/5', gridRow: '2/3', textAlign: 'right', marginRight: '8px', color: '#000000', fontWeight: 700 }}>
                  {Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10).toFixed(2)}
                </div>
                <div style={{ gridColumn: '5/6', gridRow: '2/3', width: '120px' }}>{getLabel('weightVolumeSg', locationState)}</div>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '32fr 46fr 10fr 3fr 4fr', gridGap: '1px', color: '#737496', fontSize: '14px', paddingBottom: '10px', borderBottom: '1px solid #899499' }}>
                <div style={{ gridColumn: '1/2', gridRow: '1/3', textAlign: 'left', marginLeft: '10px', fontSize: '14px', minWidth: '342px', maxWidth: '342px', borderRight: '1px solid #899499' }}><span style={{ color: '#000000', fontWeight: 700 }}>Initial Circulating Pressure</span></div>
                <div style={{ gridColumn: '1/2', gridRow: '3/3', textAlign: 'left', marginLeft: '10px', fontSize: '14px', minWidth: '342px', maxWidth: '342px', borderRight: '1px solid #899499' }}><span style={{ color: '#899499', fontWeight: 700 }}>ICP</span></div>
                <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'left', marginLeft: '20px', marginRight: '10px', fontSize: '14px', color: 'dodgerblue', fontStyle: 'italic' }}>
                  Dynamic Pressure Loss + SIDPP
                </div>
                <div style={{ gridColumn: '2/3', gridRow: '2/3', marginLeft: '20px' }}>{getRiserSpm().toFixed(2)} + {(KillSheet!.sidpp || 0).toFixed(2) || 0}</div>
                <div style={{ gridColumn: '3/4', gridRow: '1/3' }}>&nbsp;</div>
                <div style={{ gridColumn: '4/5', gridRow: '2/3', textAlign: 'right', marginRight: '8px', color: '#000000', fontWeight: 700 }}>
                  <input id="icpOverride" type="number" defaultValue={(getRiserSpm() + KillSheet!.sidpp || 0).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onChange={((e) => {
                        KillSheet?.updateKillSheetData(KillSheet.id, 'icpOverride', 'number', e.target.value)
                      })}
                    />
                </div>
                <div style={{ gridColumn: '5/6', gridRow: '2/3', width: '120px' }}>{getLabel('pressure', locationState)}</div>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '32fr 46fr 10fr 3fr 4fr', gridGap: '1px', color: '#737496', fontSize: '14px', paddingBottom: '10px', borderBottom: '1px solid #899499' }}>
                <div style={{ gridColumn: '1/2', gridRow: '1/3', textAlign: 'left', marginLeft: '10px', fontSize: '14px', minWidth: '342px', maxWidth: '342px', borderRight: '1px solid #899499' }}><span style={{ color: '#000000', fontWeight: 700 }}>Final Circulating Pressure</span><br /><br />FCP</div>
                <div style={{ gridColumn: '2/3', gridRow: '1/3', display: 'grid', gridTemplateColumns: '5fr 1fr 5fr 8fr', height: '85%' }}>
                  <div style={{ gridColumn: '1/5', gridRow: '1/2', textAlign: 'left', marginLeft: '20px', marginRight: '10px', fontSize: '14px', color: 'dodgerblue', fontStyle: 'italic' }}>
                    <span className="redBrackets">(</span> Kill Fluid Density / Current Fluid Density <span className="redBrackets">)</span> x Dynamic Pressure Loss
                  </div>
                  <div style={{ gridColumn: '1/2', gridRow: '2/3', maxWidth: '100px', textAlign: 'center', marginLeft: '20px', width: '80px', borderBottom: '1px solid #899499' }}>
                    {parseFloat((Math.ceil(getKillFluidDensityMinutes() * 10) / 10).toFixed(1))}
                  </div>
                  <div style={{ gridColumn: '1/2', gridRow: '3/3', maxWidth: '100px', textAlign: 'center', marginLeft: '20px', width: '80px' }}>{getLastTrendSheetRow()?.mwOut.toFixed(2)}</div>
                  <div style={{ gridColumn: '2/3', gridRow: '2/3', textAlign: 'left', maxWidth: '10px' }}>x</div>
                  <div style={{ gridColumn: '3/3', gridRow: '2/3' }}>{getRiserSpm().toFixed(2)}</div>
                  <div style={{ gridColumn: '4/5', gridRow: '2/3' }}>&nbsp;</div>
                </div>
                <div style={{ gridColumn: '3/4', gridRow: '2/3'}}>&nbsp;</div>
                <div style={{ gridColumn: '4/5', gridRow: '2/3', textAlign: 'right', marginRight: '8px', color: '#000000', fontWeight: 700 }}>
                  {
                    checkIsNumber(((parseFloat((Math.ceil(getKillFluidDensityMinutes() * 10) / 10).toFixed(1))
                    / (getLastTrendSheetRow()?.mwOut || 0))
                    * getRiserSpm())).toFixed(1)
                    }
                </div>
                <div style={{ gridColumn: '5/6', gridRow: '2/3', width: '120px' }}>{getLabel('pressure', locationState)}</div>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '32fr 46fr 10fr 3fr 4fr', gridGap: '1px', color: '#737496', fontSize: '14px', paddingBottom: '10px', borderBottom: '1px solid #899499' }}>
                <div style={{ gridColumn: '1/2', gridRow: '1/4', textAlign: 'left', marginLeft: '10px', fontSize: '14px', minWidth: '342px', maxWidth: '342px', borderRight: '1px solid #899499' }}><span style={{ color: '#000000', fontWeight: 700 }}>L = ICP - FCP</span><br/><br/>
                  <span className="blueLetter" style={{marginRight: '4px'}}>L</span>
                  = {(getRiserSpm() + KillSheet!.sidpp || 0).toFixed(2)} - {((((getLastTrendSheetRow()?.mwOut || 0) + (KillSheet?.sidpp || 0) / (getRealHoleTVD() * (locationState?.units === 'metric' ?
                        parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                        parseFloat(getConversionStatistic('densityPressure')!.impRate)
                    )))
                    / (getLastTrendSheetRow()?.mwOut || 0))
                    * getRiserSpm()).toFixed(2)}
                  =
                  <span style={{ color: '#000000', marginLeft: '8px'  }}>
                    {
                      ((getRiserSpm() + KillSheet!.sidpp || 0)
                      - ((((getLastTrendSheetRow()?.mwOut || 0) + (KillSheet?.sidpp || 0) / (getRealHoleTVD() * (locationState?.units === 'metric' ?
                          parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                          parseFloat(getConversionStatistic('densityPressure')!.impRate)
                        )))
                      / (getLastTrendSheetRow()?.mwOut || 0))
                      * getRiserSpm())).toFixed(0)
                    }
                  </span> {getLabel('pressure', locationState)}
                </div>
                <div style={{ gridColumn: '2/3', gridRow: '1/3', display: 'grid', marginLeft: '20px',  gridTemplateColumns: '5fr 1fr 5fr 3fr 5fr 1fr 5fr' }}>
                  <div style={{ gridColumn: '1/2', gridRow: '1/2', width: '80px', textAlign: 'center', marginLeft: '10px', borderBottom: '1px solid #899499' }}>
                    <span className="hollowLetter">L</span> x 100
                  </div>
                  <div style={{ gridColumn: '1/2', gridRow: '2/3', width: '80px', textAlign: 'center', marginLeft: '10px'  }}>
                    <span className="hollowLetter">E</span>
                  </div>
                  <div style={{ gridColumn: '2/3', gridRow: '2/3', minWidth: '60px', textAlign: 'center' }}>=</div>
                  <div style={{ gridColumn: '3/4', gridRow: '1/2', maxWidth: '100px', textAlign: 'center', borderBottom: '1px solid #899499' }}>
                    {checkIsNumber(((getRiserSpm() + KillSheet!.sidpp || 0)
                        - ((((getLastTrendSheetRow()?.mwOut || 0) + (KillSheet?.sidpp || 0) / (getRealHoleTVD() * (locationState?.units === 'metric' ?
                        parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                        parseFloat(getConversionStatistic('densityPressure')!.impRate)
                    )))
                        / (getLastTrendSheetRow()?.mwOut || 0))
                        * getRiserSpm()))).toFixed(0)
                    } x 100
                  </div>
                  <div style={{ gridColumn: '3/4', gridRow: '2/3', textAlign: 'center', maxWidth: '100px'  }}>
                    {getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 6).toFixed(0)}
                  </div>
                </div>
                <div style={{ gridColumn: '3/4', gridRow: '1/3' }}>&nbsp;</div>
                <div style={{ gridColumn: '4/5', gridRow: '2/3', textAlign: 'right', marginRight: '8px', color: '#000000', fontWeight: 700 }}>
                  {
                    checkIsNumber((((((getRiserSpm() + KillSheet!.sidpp || 0)
                    - ((((getLastTrendSheetRow()?.mwOut || 0) + (KillSheet?.sidpp || 0) / (getRealHoleTVD() * (locationState?.units === 'metric' ?
                    parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                    parseFloat(getConversionStatistic('densityPressure')!.impRate)
                )))
                    / (getLastTrendSheetRow()?.mwOut || 0))
                    * getRiserSpm()))) * 100) /
                    getMatrixValue(killSheetMatrix, 'string-block', 'Drill String Volume', 6))).toFixed(1)
                  }
                </div>
                <div style={{ gridColumn: '5/6', gridRow: '2/3', width: '120px' }}>{getLabel('pressure', locationState)}/100 strokes</div>
              </div>

              <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''}>
                <div style={{ display: 'grid', gridTemplateColumns: '32fr 46fr 10fr 3fr 4fr', gridGap: '1px', color: '#737496', fontSize: '14px', paddingBottom: '10px', borderBottom: '1px solid #899499' }}>
                  <div style={{ gridColumn: '1/2', gridRow: '1/3', textAlign: 'left', marginLeft: '10px', fontSize: '14px', minWidth: '342px', maxWidth: '342px', borderRight: '1px solid #899499' }}><span style={{ color: '#000000', fontWeight: 700 }}>Initial Dynamic Casing Pressure at Kill Pump Rate</span></div>
                  <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'left', marginLeft: '20px', marginRight: '10px', fontSize: '14px', color: 'dodgerblue', fontStyle: 'italic' }}>SICP - Choke Line Friction</div>
                  <div style={{ gridColumn: '2/3', gridRow: '2/3', marginLeft: '20px' }}>{(KillSheet?.sicp || 0).toFixed(2)} - {getChokeSpm().toFixed(2)}</div>
                  <div style={{ gridColumn: '3/4', gridRow: '1/3' }}>&nbsp;</div>
                  <div style={{ gridColumn: '4/5', gridRow: '2/3', textAlign: 'right', marginRight: '8px', color: '#000000', fontWeight: 700 }}>
                  {((KillSheet?.sicp || 0) - getChokeSpm()).toFixed(1)}
                  </div>
                  <div style={{ gridColumn: '5/6', gridRow: '2/3', width: '120px' }}>{getLabel('pressure', locationState)}</div>
                </div>
              </div>

              <div className={locationState?.rigType === 'land' || locationState?.rigType === 'jackUp' ? 'invisible' : ''}>
                <div style={{ display: 'grid', gridTemplateColumns: '32fr 46fr 10fr 3fr 4fr', gridGap: '1px', color: '#737496', fontSize: '14px', paddingBottom: '10px' }}>
                  <div style={{ gridColumn: '1/2', gridRow: '1/3', textAlign: 'left', marginLeft: '10px', fontSize: '14px', minWidth: '342px', maxWidth: '342px', borderRight: '1px solid #899499' }}><span style={{ color: '#000000', fontWeight: 700 }}>New MAASP with KMW</span></div>
                  <div style={{ gridColumn: '2/3', gridRow: '1/2', textAlign: 'left', marginTop: '6px', marginLeft: '20px', marginRight: '10px', fontSize: '14px', color: 'dodgerblue', fontStyle: 'italic' }}><span className="redBrackets">(</span> <span className="hollowLetter">C</span> - Kill Fluid Density <span className="redBrackets">)</span> * Shoe T.V. Depth * Multiplier</div>
                  <div style={{ gridColumn: '2/3', gridRow: '2/3', marginLeft: '20px' }}>
                    (
                    {getMaximumAllowableDrillFluidDensity(locationState?.units || '').toFixed((locationState?.units || '') === 'metric' || 'hybrid' ? 2 : 1)}
                    &nbsp;&nbsp; - &nbsp;&nbsp;
                    {Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10).toFixed(2)}
                    )
                    &nbsp;&nbsp; * &nbsp;&nbsp;
                    {
                      (
                          parseFloat(getStatistic('hybridizeLengthImpToMet', locationState) || '0')
                          *
                          (getMaxLinerShoeTvd() > (WellM?.casingShoeTvd || 0) ? getMaxLinerShoeTvd() : (WellM?.casingShoeTvd || 0))
                      ).toFixed(2)
                    }
                    &nbsp;&nbsp; * &nbsp;&nbsp;
                    {getDensityMultiplier(locationState?.units || '')}
                  </div>
                  <div style={{ gridColumn: '3/4', gridRow: '1/3' }}>&nbsp;</div>
                  <div style={{ gridColumn: '4/5', gridRow: '2/3', textAlign: 'right', marginRight: '8px', color: '#000000', fontWeight: 700 }}>
                  {
                    (
                      (getMaximumAllowableDrillFluidDensity(locationState?.units || '') - Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10))
                      * (parseFloat(getStatistic('hybridizeLengthImpToMet', locationState) || '0') * (getMaxLinerShoeTvd() > (WellM?.casingShoeTvd || 0) ? getMaxLinerShoeTvd() : (WellM?.casingShoeTvd || 0)))
                      * (parseFloat(getDensityMultiplier(locationState?.units || '')))
                    ).toFixed(0)
                  }
                  </div>
                  <div style={{ gridColumn: '5/6', gridRow: '2/3', width: '120px' }}>{getLabel('pressure', locationState)}</div>
                </div>
              </div>

            </div>

            <div
              id="killSheetKickTolerance"
              style={{ gridColumn: '1/3', gridRow: '6/7', backgroundColor: '#E5E4E2', borderRadius: '8px', border: '1px solid #515270', margin: 'auto', padding: '8px', width: '100%', height: '100%', marginTop: '10px' }}
            >

              <div>
                  <div style={{ borderTop: '1px solid transparent', borderLeft: '1px solid transparent', borderRight: '1px solid transparent', padding: '10px', color: '#fff', fontWeight: 700, backgroundColor: '#6495ED', marginBottom: '10px', marginTop: '-8px', marginLeft: '-8px', marginRight: '-8px', width: '101.5%', borderRadius: '8px 8px 0px 0px', textTransform: 'capitalize' }}>Kick Tolerance</div>

                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    // margin: 'auto',
                    paddingBottom: '10px',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#737596',
                    backgroundColor: 'transparent',
                    width: '100%',
                    lineHeight: '40px',
                    fontSize: '13px',
                    borderBottom: '1px solid #899499'
                  }}>
                    <div style={{ width: '100%%', marginLeft: '-90px', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                      <div data-tooltip="If swabbed kick, kick intensity equals zero" data-tooltip-position="right" className="dataTooltipHost" style={{ marginTop: '7px' }}>
                        <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px' }}/>
                      </div>
                      <div style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                        Kick Intensity
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ textAlign: 'right', marginRight: '6px', color: '#000000' }}>
                          <input id="kickIntensity" type="number" style={{ width: '100px' }} defaultValue={KillSheet?.kickIntensity || 0}
                            onFocus={e => e.target.select()}
                            onChange={((e) => {
                              KillSheet?.updateKillSheetData(KillSheet.id, 'kickIntensity', 'number', e.target.value)
                            })}
                          />
                        </div>
                        <div style={{ textAlign: 'left' }}>{getLabel('weightVolume', locationState)}</div>
                        <div style={{ marginLeft: '10px' }}>
                          &nbsp;
                          <span style={{
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            // -moz-border-radius: '50%',
                            borderRadius: '50%',
                            border: 'solid 1px #1464F4',
                            backgroundColor: 'dodgerblue',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'inline-block',
                            fontWeight: 800,
                            marginRight: '10px'
                          }}>
                            O
                          </span>
                        </div>
                        {/* <div style={{ marginLeft: '50px', color: 'dodgerblue', fontStyle: 'italic' }}>
                          Possible maximum formation pressure - Planned TD mud weight ({getLastTrendSheetRow()?.mwOut || 0})
                        </div> */}
                      </div>
                    </div>

                    <div style={{ marginLeft: '0px', width: '100%%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                      <div style={{ whiteSpace: 'nowrap', marginLeft: '100px', marginRight: '10px', textAlign: 'left' }}>Influx Gradient (gas)</div>
                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{textAlign: 'right', marginRight: '6px', color: '#000000' }}>
                          <input id="influxGradientGas" type="number" style={{ width: '100px' }} defaultValue={KillSheet?.influxGradientGas || 0}
                            onFocus={e => e.target.select()}
                            onChange={((e) => {
                              KillSheet?.updateKillSheetData(KillSheet.id, 'influxGradientGas', 'number', e.target.value)
                            })}
                          />
                        </div>
                        <div style={{ textAlign: 'left' }}>{getLabel('pressureDistance', locationState)}</div>
                        <div style={{ marginLeft: '10px' }}>
                          &nbsp;
                          <span style={{
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            // -moz-border-radius: '50%',
                            borderRadius: '50%',
                            border: 'solid 1px #1464F4',
                            backgroundColor: 'dodgerblue',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'inline-block',
                            fontWeight: 800,
                            marginRight: '10px'
                          }}>
                            P
                          </span>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginLeft: '0px', width: '100%%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                      <div style={{ whiteSpace: 'nowrap', marginLeft: '100px', marginRight: '10px', textAlign: 'left' }}>Hole TVD</div>
                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{textAlign: 'right', marginRight: '6px', color: '#000000' }}>
                          <input id="holeTvd" type="number" style={{ width: '100px' }} defaultValue={unitConversion('lengthMedium', locationState?.units, 'out', KillSheet?.holeTvd || 0)}
                            onFocus={e => e.target.select()}
                            onChange={((e) => {
                              KillSheet?.updateKillSheetData(KillSheet.id, 'holeTvd', 'number', unitConversion('lengthMedium', locationState?.units, 'in', parseFloat(e.target.value), 15).toString())
                            })}
                          />
                        </div>
                        <div style={{ textAlign: 'left' }}>{getLabel('lengthMedium', locationState)}</div>
                        <div style={{ marginLeft: '10px' }}>
                          &nbsp;
                          <span style={{
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            // -moz-border-radius: '50%',
                            borderRadius: '50%',
                            border: 'solid 1px #1464F4',
                            backgroundColor: 'dodgerblue',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'inline-block',
                            fontWeight: 800,
                            marginRight: '10px'
                          }}>
                            Z
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: ' 12fr 17fr 1fr 7fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '40px', fontSize: '13px', borderBottom: '1px solid #899499'}}>
                    {/* description */}
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '10px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                      <div style={{ marginTop: '-5px' }}>Max Allow. Shut in Casing Pressure (MASICP)</div>
                      <div style={{ marginRight: '6px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', width: '100%'}}>
                        <span data-tooltip="SICP where the Shoe will likely break down" data-tooltip-position="right" className="dataTooltipHost">
                          <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px' }}/>
                        </span>
                      </div>
                    </div>
                    {/* equation */}
                    <div className="systemFont" style={{ display: 'flex', flexDirection: 'column', fontSize: '14px', lineHeight: '24px', marginTop: '6px', whiteSpace: 'nowrap', marginLeft: '10px', color: 'dodgerblue' }}>
                      <div className="systemFont" style={{ color: 'dodgerblue', fontStyle: 'italic' }}>
                        <span className="redBrackets">(</span>&nbsp;<span className="hollowLetter">C</span> – <span className="hollowLetter">M</span>&nbsp;<span className="redBrackets">)</span> x {Number(getStatistic('constants', locationState))} x Shoe T.V. Depth
                      </div>
                      <div className="systemFont" style={{ color: 'dimgray' }}>
                        ({(getMaximumAllowableDrillFluidDensity(locationState?.units || '')).toFixed(2)} &nbsp;
                          - {(getLastTrendSheetRow()?.mwOut || 0).toFixed(2)})
                          * {Number(getStatistic('constants', locationState))} &nbsp;
                          {/* * {(getMaxLinerShoeTvd() > (WellM?.casingShoeTvd || 0) ? getMaxLinerShoeTvd() : (WellM?.casingShoeTvd || 0)).toFixed(2)} */}
                          * {unitConversion('lengthMedium', locationState?.units, 'out', (getMaxLinerShoeTvd() > (WellM?.casingShoeTvd || 0) ? getMaxLinerShoeTvd() : (WellM?.casingShoeTvd || 0)), 15).toFixed(2)}
                      </div>
                    </div>
                    {/* = */}
                    <div>
                    &nbsp;
                    </div>
                    {/* result */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                        {getInitialMaasp(locationState?.units || '').toFixed(0).toString()}
                      </div>
                      <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('pressure', locationState)}</div>
                      <div style={{ marginLeft: '10px' }}>
                        &nbsp;
                        <span style={{
                          width: '20px',
                          height: '20px',
                          lineHeight: '20px',
                          // -moz-border-radius: '50%',
                          borderRadius: '50%',
                          border: 'solid 1px #1464F4',
                          backgroundColor: 'dodgerblue',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'inline-block',
                          fontWeight: 800,
                          marginRight: '10px'
                        }}>
                          Q
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: ' 12fr 17fr 1fr 7fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '25px', fontSize: '13px', borderBottom: '1px solid #899499'}}>
                    {/* description */}
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '10px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                      <div style={{ marginTop: '-5px' }}>Influx Height</div>
                      <div style={{ marginRight: '6px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', width: '100%'}}>
                        <span data-tooltip="Maximum height of Influx before shoe breaks down" data-tooltip-position="right" className="dataTooltipHost">
                          <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px' }}/>
                        </span>
                      </div>
                    </div>
                    {/* equation */}
                    <div className="systemFont" style={{ display: 'flex', flexDirection: 'column', lineHeight: '24px', marginTop: '6px', whiteSpace: 'nowrap', marginLeft: '10px', color: 'dodgerblue', fontSize: '14px' }}>
                      <div>
                        <div className="systemFont" style={{ color: 'dodgerblue', fontStyle: 'italic' }}>
                          <span className="hollowLetter">Q</span> – <span className="redBrackets">(</span>&nbsp;<span className="hollowLetter">O</span> x {Number(getStatistic('constants', locationState))} x <span className="hollowLetter">Z</span>&nbsp;<span className="redBrackets">)</span>
                          / <span className="redBrackets">(</span>&nbsp;<span className="hollowLetter">M</span> x {Number(getStatistic('constants', locationState))}&nbsp;<span className="redBrackets">)</span> – <span className="hollowLetter">P</span>
                        </div>
                        <div className="systemFont" style={{ color: 'dimgray' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: '14px' }}>
                            <div style={{ marginLeft: '0px', color: 'dimgray' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '6px', marginBottom: '0px', paddingBottom: '0px', borderBottom: '1px solid dodgerblue' }}>
                                  <div className="systemFont" style={{ marginRight: '4px' }}>
                                    {getInitialMaasp(locationState?.units || '').toFixed(0)}
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className="systemFont" style={{ marginTop: '0px' }}>
                                      - &nbsp; ( {(KillSheet?.kickIntensity || 0).toFixed(2)} &nbsp;
                                    </div>
                                    <div className="systemFont" >
                                      * &nbsp;{Number(getStatistic('constants', locationState))} &nbsp;
                                      * &nbsp;{unitConversion('lengthMedium', locationState?.units, 'out', (KillSheet?.holeTvd || 0), 15).toFixed(1)})
                                    </div>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                  <div className="systemFont" >
                                    (
                                      {(getLastTrendSheetRow()?.mwOut || 0).toFixed(2)} &nbsp;
                                      * {Number(getStatistic('constants', locationState))} &nbsp;
                                    ) -
                                  </div>
                                  <div className="systemFont" style={{ marginTop: '0px', marginLeft: '4px' }}>
                                    {(KillSheet?.influxGradientGas || 0).toFixed(2)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* = */}
                    <div>
                    &nbsp;
                    </div>
                    {/* result */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px' }}>
                      <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                        {getInfluxHeight().toFixed(2)}
                      </div>
                      <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('lengthMedium', locationState)}</div>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: ' 12fr 17fr 1fr 7fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '40px', fontSize: '13px', borderBottom: '1px solid #899499'}}>
                    {/* description */}
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '10px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                      <div style={{ marginTop: '-5px' }}>Influx Volume at BHA</div>
                      <div style={{ marginRight: '6px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', width: '100%'}}>
                        <span data-tooltip="Maximum height of Influx calculated to a volume at the bottom/BHA" data-tooltip-position="right" className="dataTooltipHost">
                          <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px' }}/>
                        </span>
                      </div>
                    </div>
                    {/* equation */}
                    <div className="systemFont" style={{ marginTop: '5px', marginLeft: '10px', color: 'dodgerblue', fontStyle: 'italic', lineHeight: '24px', fontSize: '14px' }}>
                      <span className="redBrackets">(</span>&nbsp;Annular capacity from TD and up to Influx Height for each OD of BHA/String&nbsp;<span className="redBrackets">)</span> x <span className="redBrackets">(&nbsp;</span>height of different ODs within Influx Height&nbsp;<span className="redBrackets">)</span>
                    </div>
                    {/* = */}
                    <div>
                    &nbsp;
                    </div>
                    {/* result */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                        {calculatePipeInfluxVolumeAtBottomOfHole().toFixed(2)}
                      </div>
                      <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('volume', locationState)}</div>
                      <div style={{ marginLeft: '10px' }}>
                        &nbsp;
                        <span style={{
                          width: '20px',
                          height: '20px',
                          lineHeight: '20px',
                          // -moz-border-radius: '50%',
                          borderRadius: '50%',
                          border: 'solid 1px #1464F4',
                          backgroundColor: 'dodgerblue',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'inline-block',
                          fontWeight: 800,
                          marginRight: '10px'
                        }}>
                          R
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: ' 12fr 17fr 1fr 7fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '40px', fontSize: '13px', borderBottom: '1px solid #899499'}}>
                    {/* description */}
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '10px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                      <div style={{ marginTop: '-5px' }}>Influx Volume at Shoe Base</div>
                      <div style={{ marginRight: '6px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', width: '100%'}}>
                        <span data-tooltip="Maximum height of Influx calculated to a volume from the Shoe and down" data-tooltip-position="right" className="dataTooltipHost">
                          <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px' }}/>
                        </span>
                      </div>
                    </div>
                    {/* equation */}
                    <div className="systemFont" style={{ marginTop: '5px', marginLeft: '10px', color: 'dodgerblue', fontStyle: 'italic', lineHeight: '24px', fontSize: '14px' }}>
                      <span className="redBrackets">(</span>&nbsp;Annular capacity from Shoe and down to Influx Height for each OD of BHA/String&nbsp;<span className="redBrackets">)</span> x <span className="redBrackets">(</span>&nbsp;height of different ODs within Influx Height&nbsp;<span className="redBrackets">)</span>
                    </div>
                    {/* = */}
                    <div>
                    &nbsp;
                    </div>
                    {/* result */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                        {calculatePipeInfluxVolumeAtShoeBase().toFixed(2)}
                      </div>
                      <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('volume', locationState)}</div>
                      <div style={{ marginLeft: '10px' }}>
                        &nbsp;
                        <span style={{
                          width: '20px',
                          height: '20px',
                          lineHeight: '20px',
                          // -moz-border-radius: '50%',
                          borderRadius: '50%',
                          border: 'solid 1px #1464F4',
                          backgroundColor: 'dodgerblue',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'inline-block',
                          fontWeight: 800,
                          marginRight: '10px'
                        }}>
                          S
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: ' 12fr 17fr 1fr 7fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '25px', fontSize: '13px', borderBottom: '1px solid #899499'}}>
                    {/* description */}
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '10px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                      <div style={{ marginTop: '-5px' }}>Influx Volume at Bottom</div>
                      <div style={{ marginRight: '6px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', width: '100%'}}>
                        <span data-tooltip="Influx volume at shoe calculated with Boyles Law (simplified) to a volume at the bottom of well" data-tooltip-position="right" className="dataTooltipHost">
                          <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px' }}/>
                        </span>
                      </div>
                    </div>
                    {/* equation */}
                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '14px' }}>
                      <div style={{ marginLeft: '10px', color: 'dodgerblue' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div className="systemFont" style={{ color: 'dodgerblue', fontStyle: 'italic', marginTop: '5px' }}>
                            <span className="redBrackets">(</span>&nbsp;<span className="hollowLetter">S</span> x <span className="hollowLetter">C</span> x Shoe T.V. Depth&nbsp;<span className="redBrackets">)</span> &nbsp;/&nbsp; <span className="redBrackets">(</span>&nbsp;(&nbsp;<span className="hollowLetter">M</span> + <span className="hollowLetter">O</span>&nbsp;) x <span className="hollowLetter">Z</span>&nbsp;<span className="redBrackets">)</span>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '6px', marginBottom: '0px', width: '155px',  color: 'dimgray', paddingBottom: '0px', borderBottom: '1px solid dimgray' }}>
                            <div className="systemFont" style={{ marginTop: '1px', marginRight: '4px' }}>
                              {calculatePipeInfluxVolumeAtShoeBase().toFixed(2)}
                            </div>
                            <div className="systemFont" >
                            * {(getMaximumAllowableDrillFluidDensity(locationState?.units || '')).toFixed(1).toString()} * {(unitConversion('lengthMedium', locationState?.units, 'out', getMaxLinerShoeTvd() > (WellM?.casingShoeTvd || 0) ? getMaxLinerShoeTvd() : (WellM?.casingShoeTvd || 0), 15) || 0).toFixed(1).toString()}
                            </div>
                          </div>
                          <div className="systemFont" style={{ display: 'flex', flexDirection: 'row', color: 'dimgray', width: '155px' }}>
                            ({(getLastTrendSheetRow()?.mwOut || 0).toFixed(1).toString()} + {((KillSheet?.kickIntensity || 0).toFixed(1).toString())}) * {
                            (unitConversion('lengthMedium', locationState?.units, 'out', (KillSheet?.holeTvd || 0), 15) || 0).toFixed(1).toString()}
                          </div>
                        </div>
                      </div>
                      <div>&nbsp;</div>
                    </div>
                    {/* = */}
                    <div>
                    &nbsp;
                    </div>
                    {/* result */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px' }}>
                      <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                        {calculateInfluxVolumeAtBottom().toFixed(2)}
                      </div>
                      <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('volume', locationState)}</div>
                      <div style={{ marginLeft: '10px' }}>
                        &nbsp;
                        <span style={{
                          width: '20px',
                          height: '20px',
                          lineHeight: '20px',
                          // -moz-border-radius: '50%',
                          borderRadius: '50%',
                          border: 'solid 1px #1464F4',
                          backgroundColor: 'dodgerblue',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'inline-block',
                          fontWeight: 800,
                          marginRight: '10px'
                        }}>
                          T
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: ' 12fr 17fr 1fr 7fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '40px', fontSize: '13px' }}>
                    {/* description */}
                    <div style={{ fontSize: '14px', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499' }}>Kick Tolerance</div>
                    {/* equation */}
                    <div className="systemFont" style={{ marginLeft: '10px', color: 'dodgerblue', fontStyle: 'italic', fontSize: '14px' }}>
                      Lesser of <span className="hollowLetter">R</span> and <span className="hollowLetter">T</span><br/>
                      (Or infinity if Kick Tolerance is greater than or equal to Open Hole Volume)
                    </div>
                    {/* = */}
                    <div>
                    &nbsp;
                    </div>
                    {/* result */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                        {
                          (
                            getInfluxHeight() > getOpenHoleLength()
                              ? Infinity
                              :
                                calculatePipeInfluxVolumeAtBottomOfHole() < calculateInfluxVolumeAtBottom() ?
                                calculatePipeInfluxVolumeAtBottomOfHole()
                                : calculateInfluxVolumeAtBottom()
                          )
                          .toFixed(2)
                        }
                      </div>
                      <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('volume', locationState)}</div>
                    </div>
                  </div>

                </div>
              </div>

              <div
                id="mpdData"
                style={{ gridColumn: '1/3', gridRow: '7/8', backgroundColor: '#E5E4E2', borderRadius: '8px', border: '1px solid #515270', margin: 'auto', padding: '8px', width: '100%', height: '100%', marginTop: '10px' }}
              >
                <div>
                  <div style={{ borderTop: '1px solid transparent', borderLeft: '1px solid transparent', borderRight: '1px solid transparent', padding: '10px', color: '#fff', fontWeight: 700, backgroundColor: '#6495ED', marginBottom: '10px', marginTop: '-8px', marginLeft: '-8px', marginRight: '-8px', width: '101.5%', borderRadius: '8px 8px 0px 0px', textTransform: 'capitalize' }}>MPD</div>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto',  paddingBottom: '10px', alignContent: 'center', justifyContent: 'center', alignItems: 'center', color: '#737596', backgroundColor: 'transparent',  width: '100%', lineHeight: '40px', fontSize: '13px' }}>

                      <div style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'row', margin: 'auto',  paddingBottom: '10px', alignContent: 'center', justifyContent: 'center', alignItems: 'center', color: '#737596', width: '100%', lineHeight: '40px', fontSize: '13px', borderBottom: '1px solid #899499' }}>
                        <div style={{ width: '100%%', marginLeft: '-90px', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', marginLeft: '100px', marginRight: '10px', textAlign: 'left' }}>
                            <div data-tooltip="Desired SBP static after Mud Rollover/Displacement" data-tooltip-position="right" className="dataTooltipHost" style={{ marginTop: '7px' }}>
                              <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px' }}/>
                            </div>
                            <div>
                              Target SBP Static
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ textAlign: 'right', marginRight: '6px', color: '#000000' }}>
                              <input
                                id="targetSbp"
                                type="number"
                                title="Target SBP Static"
                                style={{ width: '100px' }}
                                defaultValue={KillSheet?.targetSbp || 0}
                                onFocus={e => e.target.select()}
                                onChange={((e) => {
                                  KillSheet?.updateKillSheetData(KillSheet.id, 'targetSbp', 'number', e.target.value)
                                })}
                              />
                            </div>
                            <div style={{ textAlign: 'left' }}>{getLabel('pressure', locationState)}</div>
                            <div style={{ marginLeft: '10px' }}>
                              &nbsp;
                              <span style={{
                                width: '20px',
                                height: '20px',
                                lineHeight: '20px',
                                // -moz-border-radius: '50%',
                                borderRadius: '50%',
                                border: 'solid 1px #1464F4',
                                backgroundColor: 'dodgerblue',
                                color: '#fff',
                                textAlign: 'center',
                                display: 'inline-block',
                                fontWeight: 800,
                                marginRight: '10px'
                              }}>
                                U
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{ marginLeft: '50px', width: '100%%', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', marginLeft: '100px', marginRight: '10px', textAlign: 'left' }}>
                            <div data-tooltip="Current SBP static. Identical to SIDPP if entered above" data-tooltip-position="right" className="dataTooltipHost" style={{ marginTop: '7px' }}>
                              <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px' }}/>
                            </div>
                            <div>
                              Current SBP Static
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{textAlign: 'right', marginRight: '6px', color: '#000000' }}>
                              <input
                                id="currentSbp"
                                title="Current SBP Static"
                                type="number"
                                style={{ width: '100px' }}
                                defaultValue={KillSheet?.sidpp || 0}
                                onFocus={e => e.target.select()}
                                onChange={((e) => {
                                  doubleInputUpdater(e.target.value)
                                })}
                              />
                            </div>
                            <div style={{ textAlign: 'left' }}>{getLabel('pressure', locationState)}</div>
                            <div style={{ marginLeft: '10px' }}>
                              &nbsp;
                              <span style={{
                                width: '20px',
                                height: '20px',
                                lineHeight: '20px',
                                // -moz-border-radius: '50%',
                                borderRadius: '50%',
                                border: 'solid 1px #1464F4',
                                backgroundColor: 'dodgerblue',
                                color: '#fff',
                                textAlign: 'center',
                                display: 'inline-block',
                                fontWeight: 800,
                                marginRight: '10px'
                              }}>
                                Y
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ backgroundColor: 'transparent', display: 'grid', gridTemplateColumns: '19fr 27fr 8fr 5fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', width: '100%', lineHeight: '25px', fontSize: '13px', borderBottom: '1px solid #899499' }}>
                        {/* description */}
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '0px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                          <div style={{ marginTop: '7px' }}>
                            Current MW
                          </div>
                          <div data-tooltip="Last mud weight out recorded in Trend Sheet" data-tooltip-position="right" className="dataTooltipHost" style={{ display: 'flex', width: '100%', marginTop: '0px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                            <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px', marginTop: '0px' }}/>
                          </div>
                        </div>
                        {/* equation */}
                        <div className="systemFont" style={{ marginLeft: '10px', color: 'dodgerblue', fontStyle: 'italic', fontWeight: 400, marginTop: '7px', fontSize: '14px' }}>
                          Current Drill Fluid Density &nbsp;<span className="hollowLetter">M</span>
                        </div>
                        {/* result */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '4px', marginBottom: '-4px', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>{(getLastTrendSheetRow()?.mwOut || 0).toFixed(2)}</div>
                          <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('weightVolumeSg', locationState)}</div>
                        </div>
                        <div style={{ marginTop: '4px', marginBottom: '-4px' }}>
                          <span style={{
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            // -moz-border-radius: '50%',
                            borderRadius: '50%',
                            border: 'solid 1px #1464F4',
                            backgroundColor: 'dodgerblue',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'inline-block',
                            fontWeight: 800,
                            marginRight: '10px',
                            marginTop: '7px'
                          }}>
                            V
                          </span>
                        </div>
                      </div>

                      <div style={{ backgroundColor: 'transparent', display: 'grid', gridTemplateColumns: '19fr 27fr 8fr 5fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', width: '100%', lineHeight: '25px', fontSize: '13px', borderBottom: '1px solid #899499' }}>
                        {/* description */}
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '0px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                          <div style={{ marginTop: '7px' }}>
                            Max MW
                          </div>
                          <div data-tooltip="From formation strength data (c)" data-tooltip-position="right" className="dataTooltipHost" style={{ display: 'flex', width: '100%', marginTop: '0px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                            <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px', marginTop: '0px' }}/>
                          </div>
                        </div>
                        {/* equation */}
                        <div className="systemFont" style={{ marginLeft: '10px', color: 'dodgerblue', fontStyle: 'italic', fontWeight: 400, marginTop: '7px', fontSize: '14px' }}>
                          Maximum Allowable Drill Fluid Density
                        </div>
                        {/* result */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '4px', marginBottom: '-4px', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>{getMaximumAllowableDrillFluidDensity(locationState?.units || '').toFixed(2)}</div>
                          <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('weightVolumeSg', locationState)}</div>
                        </div>
                        <div style={{ marginTop: '4px', marginBottom: '-4px' }}>
                          {/* <span style={{
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            // -moz-border-radius: '50%',
                            borderRadius: '50%',
                            border: 'solid 1px #1464F4',
                            backgroundColor: 'dodgerblue',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'inline-block',
                            fontWeight: 800,
                            marginRight: '10px',
                            marginTop: '7px'
                          }}>
                            V
                          </span> */}
                        </div>
                      </div>

                      <div style={{ backgroundColor: 'transparent', display: 'grid', gridTemplateColumns: '19fr 27fr 8fr 5fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', width: '100%', lineHeight: '25px', fontSize: '13px', borderBottom: '1px solid #899499' }}>
                        {/* description */}
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '0px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                          <div style={{ marginTop: '7px' }}>
                            KMW
                          </div>
                          <div data-tooltip="KMW to kill the well i.e. SBP zero pressure" data-tooltip-position="right" className="dataTooltipHost" style={{ display: 'flex', width: '100%', marginTop: '0px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                            <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px', marginTop: '0px' }}/>
                          </div>
                        </div>
                        {/* equation */}
                        <div className="systemFont" style={{ marginLeft: '10px', color: 'dodgerblue', fontStyle: 'italic', fontWeight: 400, marginTop: '7px', fontSize: '14px' }}>
                          Kill Fluid Density
                        </div>
                        {/* result */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '4px', marginBottom: '-4px', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>{Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10).toFixed(2)}</div>
                          <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('weightVolumeSg', locationState)}</div>
                        </div>
                        <div style={{ marginTop: '4px', marginBottom: '-4px' }}>
                          <span style={{
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            // -moz-border-radius: '50%',
                            borderRadius: '50%',
                            border: 'solid 1px #1464F4',
                            backgroundColor: 'dodgerblue',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'inline-block',
                            fontWeight: 800,
                            marginRight: '10px',
                            marginTop: '7px'
                          }}>
                            W
                          </span>
                        </div>
                      </div>

                      <div style={{ backgroundColor: 'transparent', display: 'grid', gridTemplateColumns: '19fr 27fr 8fr 5fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', width: '100%', lineHeight: '25px', fontSize: '13px', borderBottom: '1px solid #899499' }}>
                        {/* description */}
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '0px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                          <div style={{ marginTop: '7px' }}>
                            MPD Target MW
                          </div>
                          <div data-tooltip="Mud Weight to reach the target SBP static (U)" data-tooltip-position="right" className="dataTooltipHost" style={{ display: 'block', width: '100%', marginTop: '10px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                            <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px', marginTop: '0px' }}/>
                          </div>
                        </div>
                        {/* equation */}
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', color: 'dodgerblue', fontWeight: 400, marginTop: '7px', fontSize: '14px' }}>
                          <div className="systemFont" style={{ marginLeft: '0px', color: 'dodgerblue', fontStyle: 'italic', fontWeight: 400 }}>
                            <span className="hollowLetter">W</span> - <span className="redBrackets">(</span>&nbsp;<span className="hollowLetter">U</span> / (Shoe T.V. Depth * {Number(getStatistic('constants', locationState))})&nbsp;<span className="redBrackets">)</span>
                          </div>
                          <div className="systemFont" style={{ marginLeft: '0px', color: 'dimgray', fontWeight: 400 }}>
                            {(Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10))}
                            - ({(KillSheet?.targetSbp || 0)}
                            / ({(unitConversion('lengthMedium', locationState?.units, 'out', getRealHoleTVD(), 15) || 0)}
                            * {Number(getStatistic('constants', locationState))}))
                          </div>
                        </div>
                        {/* result */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '-17px', marginBottom: '-4px', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                              {
                                ((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10))
                                - (KillSheet?.targetSbp || 0)
                                / ((unitConversion('lengthMedium', locationState?.units, 'out', getRealHoleTVD(), 15) || 0)
                                * Number(getStatistic('constants', locationState)))).toFixed(2)
                              }
                          </div>
                          <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('weightVolumeSg', locationState)}</div>
                        </div>
                        <div style={{ marginTop: '4px', marginBottom: '-4px' }}>
                          <span style={{
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            // -moz-border-radius: '50%',
                            borderRadius: '50%',
                            border: 'solid 1px #1464F4',
                            backgroundColor: 'dodgerblue',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'inline-block',
                            fontWeight: 800,
                            marginRight: '10px',
                            marginTop: '7px'
                          }}>
                            X
                          </span>
                        </div>
                      </div>

                      <div style={{ backgroundColor: 'transparent', display: 'grid', gridTemplateColumns: '19fr 27fr 8fr 5fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', width: '100%', lineHeight: '25px', fontSize: '13px', borderBottom: '1px solid #899499' }}>
                        {/* description */}
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '0px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                          <div style={{ marginTop: '7px' }}>
                            MPD ICP
                          </div>
                          <div data-tooltip="Initial Circulating Pressure" data-tooltip-position="right" className="dataTooltipHost" style={{ display: 'block', width: '100%', marginTop: '10px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                            <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px', marginTop: '0px' }}/>
                          </div>
                        </div>
                        {/* equation */}
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', color: 'dodgerblue', fontWeight: 400, marginTop: '7px', fontSize: '14px' }}>
                          <div className="systemFont" style={{ marginLeft: '0px', color: 'dodgerblue', fontStyle: 'italic', fontWeight: 400 }}>
                            Dynamic Pressure Loss + <span className="hollowLetter">Y</span>
                          </div>
                          <div className="systemFont" style={{ marginLeft: '0px', color: 'dimgray', fontWeight: 400 }}>
                            {getRiserSpm().toFixed(2)} + {(KillSheet?.sidpp || 0).toFixed(2)}
                          </div>
                        </div>
                        {/* result */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '-20px', marginBottom: '-4px', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>{(getRiserSpm() + (KillSheet?.sidpp || 0)).toFixed(2)}</div>
                          <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('pressure', locationState)}</div>
                        </div>
                      </div>

                      <div style={{ backgroundColor: 'transparent', display: 'grid', gridTemplateColumns: '19fr 27fr 8fr 5fr', gridGap: '1px', paddingBottom: '10px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', width: '100%', lineHeight: '25px', fontSize: '13px', borderBottom: '1px solid #899499' }}>
                        {/* description */}
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '0px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                          <div style={{ marginTop: '7px' }}>
                            MPD FCP
                          </div>
                          <div data-tooltip="Final Circulating Pressure once target SBP has been reached" data-tooltip-position="right" className="dataTooltipHost" style={{ display: 'block', width: '100%', marginTop: '10px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                            <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px', marginTop: '0px' }}/>
                          </div>
                        </div>
                        {/* equation */}
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', color: 'dodgerblue', fontWeight: 400, marginTop: '7px', fontSize: '14px' }}>
                          <div className="systemFont" style={{ marginLeft: '0px', color: 'dodgerblue', fontStyle: 'italic', fontWeight: 400 }}>
                            <span className="redBrackets">(</span>&nbsp;<span className="hollowLetter">X</span> / <span className="hollowLetter">V</span>&nbsp;<span className="redBrackets">)</span> * Riser SPM + <span className="hollowLetter">U</span>
                          </div>
                          <div className="systemFont" style={{ marginLeft: '0px', color: 'dimgray', fontWeight: 400 }}>
                            (
                            {((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10))
                            - (KillSheet?.targetSbp || 0)
                            / ((unitConversion('lengthMedium', locationState?.units, 'out', getRealHoleTVD(), 15) || 0)
                            * Number(getStatistic('constants', locationState)))).toFixed(2)}
                              / {(getLastTrendSheetRow()?.mwOut || 0).toFixed(2)})
                              * {getRiserSpm().toFixed(2)}
                              + {(KillSheet?.targetSbp || 0)}
                          </div>
                        </div>
                        {/* result */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '-20px', marginBottom: '-4px', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                            {
                              (((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10))
                              - (KillSheet?.targetSbp || 0)
                              / ((unitConversion('lengthMedium', locationState?.units, 'out', getRealHoleTVD(), 15) || 0)
                              * Number(getStatistic('constants', locationState))))
                              / (getLastTrendSheetRow()?.mwOut || 0)
                              * getRiserSpm()
                              + (KillSheet?.targetSbp || 0)).toFixed(2)
                            }
                          </div>
                          <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('pressure', locationState)}</div>
                        </div>
                        <div>&nbsp;</div>
                      </div>

                      <div style={{ backgroundColor: 'transparent', display: 'grid', gridTemplateColumns: '19fr 27fr 8fr 5fr', gridGap: '1px', paddingBottom: '0px', alignContent: 'left', justifyContent: 'left', alignItems: 'left', color: '#737596', width: '100%', lineHeight: '25px', fontSize: '13px', marginBottom: '-3px' }}>
                        {/* description */}
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, color: '#000000', whiteSpace: 'nowrap', paddingTop: '0px', marginLeft: '10px', marginRight: '10px', textAlign: 'left', borderRight: 'solid 1px #899499', fontSize: '14px' }}>
                          <div style={{ marginTop: '7px' }}>
                            MPD Final PL
                          </div>
                          <div data-tooltip="Calculated pressure loss in the system after Mud Rollover/Displacement" data-tooltip-position="right" className="dataTooltipHost" style={{ display: 'block', width: '100%', marginTop: '10px', textAlign: 'right', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                            <img alt="info" src={icon_tooltip} style={{ height: '20px', width: '20px', marginRight: '10px', marginTop: '0px' }}/>
                          </div>
                        </div>
                        {/* equation */}
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', color: 'dodgerblue', fontWeight: 400, marginTop: '7px', fontSize: '14px' }}>
                          <div className="systemFont" style={{ marginLeft: '0px', color: 'dodgerblue', fontStyle: 'italic', fontWeight: 400 }}>
                            Riser SPM * <span className="hollowLetter">X</span> / <span className="hollowLetter">V</span>
                          </div>
                          <div className="systemFont" style={{ marginLeft: '0px', color: 'dimgray',  fontWeight: 400 }}>
                            {getRiserSpm().toFixed(2)}
                            * &nbsp;{((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10))
                                - (KillSheet?.targetSbp || 0)
                                / ((unitConversion('lengthMedium', locationState?.units, 'out', getRealHoleTVD(), 15) || 0)
                                * Number(getStatistic('constants', locationState)))).toFixed(2)}
                             / {(getLastTrendSheetRow()?.mwOut || 0)}
                          </div>
                        </div>
                        {/* result */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '-20px', marginBottom: '-4px', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '80px', textAlign: 'right', fontSize: '14px', color: '#000000', fontWeight: 700, marginRight: '5px !important' }}>
                            {
                              (getRiserSpm()
                              * ((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10))
                                - ((KillSheet?.targetSbp || 0)
                                / ((unitConversion('lengthMedium', locationState?.units, 'out', getRealHoleTVD(), 15) || 0)
                                * Number(getStatistic('constants', locationState)))))
                              / (getLastTrendSheetRow()?.mwOut || 0)).toFixed(2)
                            }
                          </div>
                          <div style={{ marginLeft: '6px', textAlign: 'left' }}>{getLabel('pressure', locationState)}</div>
                        </div>
                        <div>&nbsp;</div>
                      </div>

                    </div>
                </div>
              </div>

            </div>
            <div style={{ color: 'transparent', marginTop: '20px', marginBottom: '25px'}}>
              PT: {PipeTally?.strings.length} - {pipeTallyLength} |
              CT: {CasingTally?.strings.length} - {casingTallyLength} |
              LT: {LinerTally?.strings.length} - {linerTallyLength} |
              BHA: {BHA?.parts.length} - {bhaTotalLength}
            </div>
          </div> :
      <>
        <SectionTabs />
        <div style={{ color: '#fff', padding: '20px', display: 'flex', flexDirection: 'row' }}>
          <div><img alt="warning" src={warning} className="filter-blue" style={{ marginTop: '10px', width: '25px', height: '25px', marginRight: '20px' }} /></div>
          <div style={{ marginTop: '11px', color: '#0096FF' }}>A well section must have a Pipe Tally and either a BHA or Casing/Liner Tally to view a Kill Sheet</div>
        </div>
      </>
    )
})

export default KillSheetUi
