import { useEffect } from 'react'
import { PartTypesEnum } from 'app/models/BHA/BHAPart'
import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import {
  ChangeEventHandler,
  useState,
  useCallback,
  Fragment,
  useContext,
} from 'react'
import { RouterContext } from '../Router'
import Dialog from '../kit/Dialog'
import Input from '../kit/Input'
import Select from '../kit/Select'
import { Button } from '../kit/SmallWidthButton'
import { getLabel, unitConversion } from '../../../utils/helpers'
//import { useMutation, gql } from '@apollo/client'

const initialState = {
  id: '',
  serialNumber: '',
  length: '',
  partType: 'DP' as PartTypesEnum,
  OD: '',
  ID: '',
  weight: '',
  maxOD: '',
  topType: '',
  botType: '',
  description: '',
  liftSubTJOD: '',
  elevatorID: '',
  elevatorMarking: '',
  insertSize: '',
  comment: '',
  info: '',
  bitNumber: '',
  bitNozzles: '',
  bitTFA: '',
  bitManufacturer: '',
  bitDullGradeIn: '',
  bitDullGradeOut: '',
}

type StateKeys = keyof typeof initialState

const EditPart = observer(({ open, partId, partType, historyIndex, partDescription, onClose: oc }: EditPartProps) => {
  const {
    store: { BHA, PipeTally },
  } = useMst()
  const [form, setForm] = useState(initialState)
  const [tallyString, setTallyString] = useState(0)
  const { locationState } = useContext(RouterContext)

  const onClose = useCallback(() => {
    oc()
    setTimeout(() => {
      setForm(initialState)
      setTallyString(0)
    }, 250)
  }, [oc])

  const handleInputs = (
    key: StateKeys
    ): ChangeEventHandler<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement> =>
    function (e) {
      setForm({ ...form, [key]: e.target.value })
    }

  const handleString: ChangeEventHandler<HTMLInputElement & HTMLSelectElement> =
    (e) => {
      setTallyString(Number(e.target.value))
    }

  //const oldSerial = BHA?.tally.find(part => part.id === partId)?.serialNumber

  const handleSubmit = () => {

    if (form.partType !== 'DP' && historyIndex === 0 ) {
      BHA?.editPart({
        id: form.id,
        OD: Number(form.OD),
        ID: Number(form.ID),
        description: form.description,
        serialNumber: form.serialNumber || undefined,
        length: unitConversion('lengthMedium', locationState?.units, 'in', Number(form.length), 15),
        weight: unitConversion('lengthMedium', locationState?.units, 'out', Number(form.weight), 15),  // length is ok, only going lbs/m to lbs/ft
        maxOD: Number(form.maxOD),
        partType: form.partType,
        topType: form.topType,
        botType: form.botType,
        liftSubTJOD: form.liftSubTJOD ? Number(form.liftSubTJOD) : undefined,
        elevatorID: form.elevatorID ? Number(form.elevatorID) : undefined,
        elevatorMarking: form.elevatorMarking,
        insertSize: form.insertSize ? Number(form.insertSize) : undefined,
        comment: form.comment,
        info: form.info,
        bitNumber: form.bitNumber,
        bitNozzles: form.bitNozzles,
        bitTFA: form.bitTFA,
        bitManufacturer: form.bitManufacturer,
        bitDullGradeIn: form.bitDullGradeIn,
        bitDullGradeOut: form.bitDullGradeOut,
      })
    }

  
    partType = 'DP'
    onClose()
  }

  
    useEffect(() => {
        handleInputs('partType')({ target: { value: partType } } as any)
        populateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        handleInputs('partType')({ target: { value: partType } } as any)
        populateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partType])

    function populateForm() {
        const editingPart = BHA?.tally(historyIndex).find(part => part.id === partId)
        form.partType = editingPart?.partType ? editingPart.partType as PartTypesEnum : 'DP'
        const Length = unitConversion('lengthMedium', locationState?.units, 'out', editingPart?.length || 0, 15).toFixed(2).toString()
        const Weight = unitConversion('lengthMedium', locationState?.units, 'in', editingPart?.weight || 0, 15).toFixed(2).toString()

        setForm({ ...form,
            'id': partId,
            'partType': partType ,
            'description': editingPart?.description || '',
            'serialNumber': editingPart?.serialNumber || '',
            'length': Length,
            'OD': (editingPart?.OD)?.toString() || '',
            'ID': (editingPart?.ID)?.toString() || '',
            'weight': Weight,
            'maxOD': (editingPart?.maxOD)?.toString() || '',
            'topType': (editingPart?.topType)?.toString() || '',
            'botType': (editingPart?.botType)?.toString() || '',
            'liftSubTJOD': (editingPart?.liftSubTJOD)?.toString() || '',
            'elevatorID': (editingPart?.elevatorID)?.toString() || '',
            'elevatorMarking': editingPart?.elevatorMarking || '',
            'insertSize': (editingPart?.insertSize)?.toString() || '',
            'comment': editingPart?.comment || '',
            'info': editingPart?.info || '',
            'bitNumber': editingPart?.bitNumber || '',
            'bitNozzles': editingPart?.bitNozzles || '',
            'bitTFA': editingPart?.bitTFA || '',
            'bitManufacturer': editingPart?.bitManufacturer || '',
            'bitDullGradeIn': editingPart?.bitDullGradeIn || '',
            'bitDullGradeOut': editingPart?.bitDullGradeOut || '',
        })
    }


  return (
    <Dialog
      style={{ padding: '8px', maxWidth: 725, border: '1px solid #29465B' }}
      open={open}
      onClose={onClose}
    >
      <div style={{ backgroundColor: '#5B5C7455', borderRadius: '16px', padding: '10px', textAlign: 'center' }}>
        <div style={{ border: 'none' }}>
        {(BHA?.parts.length || 0) === 0 ? (
          <h4 style={{ textAlign: 'left', padding: '0px 10px 10px 0px' }}>
              <span className="uiContainerStatusWarning" style={{ marginRight: '15px' }}>CREATE &nbsp; BHA</span>
              <span style={{ fontWeight: 500, color: '#E5E4E2' }}>I wish to add a new joint to begin to <span style={{ color: 'cornflowerblue', textDecoration: 'none', fontWeight: 900, marginLeft: '2px', marginRight: '2px' }}>create</span> a new BHA from scratch</span>
          </h4>
          ) : ( <span></span> )
      }
        <h3 style={{ color: 'lightslategray', textAlign: 'center' }}>Edit BHA Part</h3>
        <div style={{ color: '#FFF', fontWeight: 800 }}>{partType}</div><br/>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'baseline',
          columnGap: '2%',
        }}
      >
        {form.partType !== 'DP' ? (
          <Fragment>
            <Input
              style={{ width: '32%' }}
              id="part-serial-number-input"
              title="Serial number"
              type="text"
              defaultValue={form.serialNumber}
              onChange={handleInputs('serialNumber')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-desc-input"
              title="Desc."
              type="text"
              defaultValue={form.description}
              onChange={handleInputs('description')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-length-input"
              title={"Length *(" + getLabel('lengthMedium', locationState) + ")"}
              type="number"
              defaultValue={unitConversion('lengthMedium', locationState?.units, 'out', BHA?.tally(historyIndex).find(part => part.id === partId)?.length || 0, 15).toFixed(2).toString()}
              onChange={handleInputs('length')}
              step="0.1"
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-weight-input"
              title={"Weight/Length *(" + getLabel('weightDistance', locationState) + ")"}
              type="number"
              defaultValue={unitConversion('lengthMedium', locationState?.units, 'in', BHA?.tally(historyIndex).find(part => part.id === partId)?.weight || 0, 15).toFixed(2).toString()}
              onChange={handleInputs('weight')}
              step="0.1"
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-od-input"
              title="F/N OD"
              type="number"
              defaultValue={form.OD}
              onChange={handleInputs('OD')}
              step="0.1"
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-id-input"
              title="ID"
              type="number"
              defaultValue={form.ID}
              onChange={handleInputs('ID')}
              step="0.1"
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-max-od-input"
              title="Max OD"
              type="number"
              defaultValue={form.maxOD}
              onChange={handleInputs('maxOD')}
              step="0.1"
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-top-type-input"
              title="Top Type"
              type="text"
              defaultValue={form.topType}
              onChange={handleInputs('topType')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-bot-type-input"
              title="Bottom Type"
              type="text"
              defaultValue={form.botType}
              onChange={handleInputs('botType')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-liftsub-input"
              title="Lift Sub TJ O.D."
              type="text"
              value={form.liftSubTJOD}
              onChange={handleInputs('liftSubTJOD')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-elevatorid-input"
              title="Elevator/Insert I.D."
              type="text"
              value={form.elevatorID}
              onChange={handleInputs('elevatorID')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-elevatormarking-input"
              title="Elev. Marking/Colour Code"
              type="text"
              value={form.elevatorMarking}
              onChange={handleInputs('elevatorMarking')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '32%' }}
              id="part-insertsize-input"
              title="Slip/Insert Size"
              type="text"
              value={form.insertSize}
              onChange={handleInputs('insertSize')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            <Input
              style={{ width: '66%' }}
              id="part-comment-input"
              title="Comment"
              type="text"
              value={form.comment}
              onChange={handleInputs('comment')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
                />
            {(form.partType === 'PDC' || form.partType === 'RollerCone') ? (
              <Fragment>
                <Input
                  style={{ width: '32%' }}
                  id="part-bit-number-input"
                  title="Bit Number"
                  type="text"
                  value={form.bitNumber}
                  onChange={handleInputs('bitNumber')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  style={{ width: '32%' }}
                  id="part-bit-nozzles-input"
                  title="Nozzles"
                  type="text"
                  value={form.bitNozzles}
                  onChange={handleInputs('bitNozzles')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  style={{ width: '32%' }}
                  id="part-bit-tfa-input"
                  title="TFA"
                  type="text"
                  value={form.bitTFA}
                  onChange={handleInputs('bitTFA')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  style={{ width: '32%' }}
                  id="part-bit-manufacturer-input"
                  title="Manufacturer"
                  type="text"
                  value={form.bitManufacturer}
                  onChange={handleInputs('bitManufacturer')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  style={{ width: '32%' }}
                  id="part-bit-dull-grade-in-input"
                  title="Dull Grade In"
                  type="text"
                  value={form.bitDullGradeIn}
                  onChange={handleInputs('bitDullGradeIn')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  style={{ width: '32%' }}
                  id="part-bit-dull-grade-out-input"
                  title="Dull Grade Out"
                  type="text"
                  value={form.bitDullGradeOut}
                  onChange={handleInputs('bitDullGradeOut')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
              </Fragment>
            ) : (null)}
            <div className={'field'} style={{ width: '100%' }}>
              <textarea
                id={'part-info-input'}
                className={'inputModalField'}
                style={{ width: '100%', resize: 'none'}}
                value={form.info}
                placeholder={''}
                onChange={handleInputs('info')}
                rows={2}
              />
              <label
                htmlFor={'part-info-input'}
                className={'placeholder'}
                style={{ pointerEvents: 'none' }}
              >
                {'Information'}
              </label>
            </div>
            <Input
                style={{ width: 0, marginRight: 0, display: 'none' }}
                id="part-type-input"
                title="Part Type"
                type="text"
                defaultValue={form.partType}
                onChange={handleInputs('partType')}
                className="inputModalField"
                labelClassName="placeholder"
                wrapperClassName="field"
                />
          </Fragment>
        ) : (
          <Select
            value={tallyString}
            title="Select Tally String"
            onChange={handleString}
            style={{ display: 'none', width: 200 }}
          >
            {PipeTally?.strings.map(({ number, id, pipeTypeId }, i) => {
              return (
                <option key={id} value={i}>
                  String {number}  - {pipeTypeId}
                </option>
              )
            })}
          </Select>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginTop: '15px'
        }}
      >
        { historyIndex === 0 ? (<Button small onClick={handleSubmit}>
          Update
         </Button>) : <></>
        }
        <Button
          small
          secondary
          style={{ marginRight: '8px' }}
          onClick={onClose}
        >
          Cancel
        </Button>
        </div>
      </div>
    </Dialog>
  )
})

type EditPartProps = {
  open: boolean
  partId: string
  partType: PartTypesEnum
  historyIndex: number
  partDescription: string
  onClose: () => void
}

export default EditPart
