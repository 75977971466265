import { useContext, useEffect } from 'react';
import { RouterContext } from '../Router'
import Clock from '../../components/Well/Clock'
import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import Units from '../../store/Units.json'
import { SectionTabs } from '../WellSection/SectionTabs'
import PrintInfo from 'app/components/kit/PrintInfo'
import { TrendSheetRowInstance } from 'app/models/TrendSheet';
import ApexCharts from 'apexcharts'
import { CSVLink } from 'react-csv'
import { cleanUpRogueCharacters, getStatistic, isNumeric } from '../../../utils/helpers'
import '../KillSheet/KillSheet.css'
import warning from '../../../assets/images/icons/icon_warning.svg'

const KillChart = observer(() => {
    const {
        store: { WellM, KillSheet, TrendSheet, BHA, PipeTally, CasingTally, LinerTally },
    } = useMst()
    const { locationState } = useContext(RouterContext)

    let pipeTallyLength = 0
    PipeTally?.strings.forEach((string:any, index) => {
      pipeTallyLength += parseFloat(string.totalLength)
    })

    let casingTallyLength = 0
    CasingTally?.strings.forEach((string:any, index) => {
      casingTallyLength += parseFloat(string.totalLength)
    })

    let linerTallyLength = 0
    LinerTally?.strings.forEach((string:any, index) => {
      linerTallyLength += parseFloat(string.totalLength)
    })

    let bhaTotalLength = 0
    BHA?.tally(0).map((part:any, i) => {
      return bhaTotalLength += parseFloat(part.length)
    })

    let preReqs = bhaTotalLength + casingTallyLength + linerTallyLength > 0 ? true : false
    preReqs = pipeTallyLength === 0 ? false : preReqs
    preReqs = (TrendSheet?.rows.length || 0) < 1 ? false : preReqs
    preReqs = ((KillSheet?.sidpp || 0) <= 0) || ((KillSheet?.pitgain || 0) <= 0) ? false : preReqs

    useEffect(() => {
      try {
      if (preReqs && !document.querySelector('.killGraph')) {
          var chartContainer = document.createElement('div');
          chartContainer.id = 'killGraph';
          document.getElementById('killGraphContainer')?.appendChild(chartContainer);
          var kill_chart = new ApexCharts(document.querySelector("#killGraph"), killGraph);
          kill_chart.render()
      }
    } catch (error) {
      console.log(error)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function getConversionStatistic(key: string) {
      const conversion = Units[Units.findIndex(({ handle }) => handle === key)]
      if (conversion && locationState) {
        return conversion
      }
    }

    function checkIsNumber(val: number) {
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getLastTrendSheetRow() {
      if (TrendSheet) {
          if (TrendSheet.rows.length > 0) {
              return TrendSheet.rows[TrendSheet.rows.length - 1]
          }
      }
      return null
    }

    function getRiserSpm() {
      if (KillSheet) {
        if (KillSheet.pump === 1) {
          if (KillSheet.spm === 20) {
            return KillSheet.riser120
          } else if (KillSheet.spm === 30) {
            return KillSheet.riser130
          } else if (KillSheet.spm === 40) {
            return KillSheet.riser140
          } else if (KillSheet.spm === 50) {
            return KillSheet.riser150
          }
        } else if (KillSheet.pump === 2) {
          if (KillSheet.spm === 20) {
            return KillSheet.riser220
          } else if (KillSheet.spm === 30) {
            return KillSheet.riser230
          } else if (KillSheet.spm === 40) {
            return KillSheet.riser240
          } else if (KillSheet.spm === 50) {
            return KillSheet.riser250
          }
        }
      }
      return 0
    }

    function getKillFluidDensityMinutes() {
      let val = 0
      val = (getLastTrendSheetRow()?.mwOut || 0) + (KillSheet?.sidpp || 0) / ((getLastTrendSheetRow()?.depthTvd || 0) * (locationState?.units === 'metric' ? 0.0981 : 0.052))
      if (isNaN(val)) { val = 0 }
      if (!isFinite(val)) { val = 0 }
      return val
    }

    function getCirculatingStpp(row: TrendSheetRowInstance, inDepth?: number, inDepthTvd?: number) {
      if (!inDepth) { inDepth = row.depth }
      if (!inDepthTvd) { inDepthTvd = row.depthTvd }

      if (KillSheet && row.depth !== 0) {
        return getRiserSpm()
          + (
            (checkIsNumber(((parseFloat((Math.ceil(getKillFluidDensityMinutes() * 10) / 10).toFixed(1)) / (getLastTrendSheetRow()?.mwOut || 0)) * getRiserSpm())))
            - getRiserSpm()
            ) * ((inDepth || 0) / (getLastTrendSheetRow()?.depth || 0))
          + (KillSheet?.sidpp || 0)
          - (((inDepthTvd || 0) / (getLastTrendSheetRow()?.depthTvd || 0)) * (KillSheet?.sidpp || 0))
      }
      return 0
    }

    function getBhaTotalLengthCapacity() {
      if (BHA && BHA.parts.length > 0 && WellM) {
        let bhaTotalLengthCapacity = 0
        // eslint-disable-next-line array-callback-return
        BHA?.tally(0).map((part, i) => {
          bhaTotalLengthCapacity += part.length * (part.ID * part.ID) / 1029.4   // part.capacity
          if (i === BHA.parts.length - 1) {
            return bhaTotalLengthCapacity
          }
        })
        return bhaTotalLengthCapacity
      }
      return 0
    }

    function getBhaTotalLength() {
      if (BHA && BHA.parts.length > 0) {
        let bhaTotalLength = 0
        // eslint-disable-next-line array-callback-return
        BHA?.tally(0).map((part: any, i) => {
          bhaTotalLength += part.length
          if (i === BHA.parts.length - 1) {
            return bhaTotalLength
          }
        })
        return bhaTotalLength
      }
      return 0
    }

    function getSecondLastPipeTallyStringResidualLength() {
      let val = 0
      let totalTallyLength = 0

      if (WellM) {
        if (PipeTally && PipeTally.strings.length > 0) {
          for (let i = 0; i < PipeTally.strings.length - 1; i++) {
            totalTallyLength += parseFloat(PipeTally.strings[i].totalLength)
          }
        }

        if (locationState!.wellSectionType === 'OH') {
          if (BHA && BHA.parts.length > 0) {
            for (let i = 0; i < BHA.tally(0).length; i++) {
              totalTallyLength += Number(BHA.tally(0)[i].length)
            }
          }
        } else if (locationState!.wellSectionType === 'CSG') {
          if (CasingTally && CasingTally?.currentString.list.data.length > 0) {
            for (let i = 0; i < CasingTally.strings.length; i++) {
              totalTallyLength += parseFloat(CasingTally.strings[i].totalLength)
            }
          }
          if (LinerTally && LinerTally?.currentString.list.data.length > 0) {
            for (let i = 0; i < LinerTally.strings.length; i++) {
              totalTallyLength += parseFloat(LinerTally.strings[i].totalLength)
            }
          }
      }
      }

      val = (getLastTrendSheetRow()?.depth || 0) - totalTallyLength
      return val
    }

    function createFeetPerStrokeMatrix(): any[][] | undefined {
      let indexCounter = 0
      const BhaExists = (BHA?.parts.length || 0) > 0 ? 1 : 0
      if (preReqs && WellM && KillSheet && TrendSheet && BHA && PipeTally && PipeTally.strings.length > 0) {
        let feetPerStrokeMatrix = new Array(PipeTally.strings.length + BhaExists).fill(0).map(() => new Array(6).fill(0))
        feetPerStrokeMatrix.splice(0, feetPerStrokeMatrix.length)

        const pumpDisplacement = KillSheet.pump === 1 ? KillSheet.displacementPump1 : KillSheet.displacementPump2

        if (BhaExists) {
          indexCounter++
          feetPerStrokeMatrix.push([
            indexCounter,
            'BHA',
            pumpDisplacement / (getBhaTotalLengthCapacity() / getBhaTotalLength()),
            getBhaTotalLength(),
            getBhaTotalLength() / (pumpDisplacement / (getBhaTotalLengthCapacity() / getBhaTotalLength())),
            false
          ])
        }

        // add in casing and liner tallys
        if (!BhaExists) {
          if (CasingTally && CasingTally.strings[0].tally.length > 0) {
            for (let i = 0; i < CasingTally.strings.length; i++) {
              indexCounter++
              feetPerStrokeMatrix.push([
                // index
                // CasingTally.strings.length - i,
                indexCounter,
                // label
                'Casing-' + (CasingTally.strings.length - i),
                // ft/stk
                pumpDisplacement / ((CasingTally.strings[i].getPipe(CasingTally.strings[i].name || '').cap ? CasingTally.strings[i].getPipe(CasingTally.strings[i].name || '').cap : 0) * (locationState?.units === 'metric' ? parseFloat(getConversionStatistic('volumeDistance')!.impToMet) : 1)),
                // length
                parseFloat(CasingTally.strings[i].totalLength),
                // length/ft/stk
                parseFloat(CasingTally.strings[i].totalLength) / (pumpDisplacement / ((CasingTally.strings[i].getPipe(CasingTally.strings[i].name || '').cap ? CasingTally.strings[i].getPipe(CasingTally.strings[i].name || '').cap : 0) * (locationState?.units === 'metric' ? parseFloat(getConversionStatistic('volumeDistance')!.impToMet) : 1))),
                false
              ])
            }
          }
          if (LinerTally && LinerTally.strings[0].tally.length > 0) {
            for (let i = 0; i < LinerTally.strings.length; i++) {
              indexCounter++
              feetPerStrokeMatrix.push([
                indexCounter,
                'Liner-' + (LinerTally.strings.length - i),
                pumpDisplacement / ((LinerTally.strings[i].getPipe(LinerTally.strings[i].name || '').cap ? LinerTally.strings[i].getPipe(LinerTally.strings[i].name || '').cap : 0) * (locationState?.units === 'metric' ? parseFloat(getConversionStatistic('volumeDistance')!.impToMet) : 1)),
                parseFloat(LinerTally.strings[i].totalLength),
                parseFloat(LinerTally.strings[i].totalLength) / (pumpDisplacement / ((LinerTally.strings[i].getPipe(LinerTally.strings[i].name || '').cap ? LinerTally.strings[i].getPipe(LinerTally.strings[i].name || '').cap : 0) * (locationState?.units === 'metric' ? parseFloat(getConversionStatistic('volumeDistance')!.impToMet) : 1))),
                false
              ])
            }
          }
        }

        for (let i = 0; i < PipeTally.strings.length; i++) {
          indexCounter++
          feetPerStrokeMatrix.push([
            indexCounter,
            'Pipe-' + (PipeTally.strings.length - i),
            pumpDisplacement / ((PipeTally.strings[i].getPipe(PipeTally.strings[i].name || '').cap ? PipeTally.strings[i].getPipe(PipeTally.strings[i].name || '').cap : 0) * (locationState?.units === 'metric' ? parseFloat(getConversionStatistic('volumeDistance')!.impToMet) : 1)),
            i === PipeTally.strings.length - 1 ? getSecondLastPipeTallyStringResidualLength() : parseFloat(PipeTally.strings[i].totalLength),
            (i === PipeTally.strings.length - 1 ? getSecondLastPipeTallyStringResidualLength() : parseFloat(PipeTally.strings[i].totalLength)) / (pumpDisplacement / ((PipeTally.strings[i].getPipe(PipeTally.strings[i].name || '').cap ? PipeTally.strings[i].getPipe(PipeTally.strings[i].name || '').cap : 0) * (locationState?.units === 'metric' ? parseFloat(getConversionStatistic('volumeDistance')!.impToMet) : 1))),
            false
          ])
        }

        // flip index key
        for (let i = 0; i < feetPerStrokeMatrix.length; i++) {
          feetPerStrokeMatrix[i][0] = feetPerStrokeMatrix.length - i
        }

        return feetPerStrokeMatrix
      }
    }

    function getPipeChangesAtThisReading(depth: number) {
      if (feetPerStrokeMatrix) {
        let pipeChanges = []
        let currentDepth = 0
        for (let i = feetPerStrokeMatrix.length - 1; i >= 0; i--) {
          currentDepth += feetPerStrokeMatrix[i][3]
          if (currentDepth <= depth) {
            pipeChanges.push(feetPerStrokeMatrix[i][1])
          }
        }
        return pipeChanges
      }
      return []
    }

    function getDepthAtPipe(pipeName: string) {
      if (feetPerStrokeMatrix) {
        let depth = 0
        for (let i = feetPerStrokeMatrix.length - 1; i >= 0; i--) {
          depth += feetPerStrokeMatrix[i][3]
          if (feetPerStrokeMatrix[i][1] === pipeName) {
            return depth
          }
        }
      }
      return 0
    }

    function getStrokesInInterval(mdSum: number, thisRowMdReading: number, priorRowMdReading: number) {
      let acc = 0
      if (feetPerStrokeMatrix) {
        if (thisRowMdReading !== 0) {
          for (let j = 0; j < feetPerStrokeMatrix.length; j++) {
            acc += feetPerStrokeMatrix[j][3]
            if (mdSum <= acc) {
              return (thisRowMdReading - priorRowMdReading) / feetPerStrokeMatrix[j][2]
            }
          }
        } else {
         return 0
        }
      }
      return 0
    }

    function createKillChartMatrix(): any[][] | undefined {
      if (preReqs && WellM && KillSheet && TrendSheet && BHA && PipeTally && PipeTally.strings.length > 0) {
        let priorMdReading = 0
        let priorTvdReading = 0
        let pipeChangesAtThisReading = []

        let killChartMatrix = new Array(TrendSheet.rows.length).fill(0).map(() => new Array(17).fill(0))

        killChartMatrix.splice(0, killChartMatrix.length)

        /*
        feetPerStrokeMatrix schema
          0 - index
          1 - label
          2 - FT/STK
          3 - length
          4 - length/FT/STK

        killChartMatrix schema
          0 - index
          1 - TD MD
          2 - TD TVD
          3 - MD Reading
          4 - TVD Reading
          5 - dMd
          6 - dTvd
          7 - MD Sum
          8 - TVD Sum
          9 - Strokes in Interval
          10 - Sum Strokes
          11 - Remaining SIDPP
          12 - Circulating STPP
          13 - PL
          14 - MPD PL
          15 - MPD SIDPP
          16 - MPD CIrculating STPP
        */

        // create the base row of the matrix (0)
        killChartMatrix.push([
          0,
          getLastTrendSheetRow()?.depth || 0,
          getLastTrendSheetRow()?.depthTvd || 0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          (KillSheet?.sidpp || 0),
          getRiserSpm() + (KillSheet?.sidpp || 0),
          getRiserSpm(),
          getRiserSpm()
          + (
            (getRiserSpm() * (
              (Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10) || 0)
              - (((KillSheet?.targetSbp || 0) / (getLastTrendSheetRow()?.depthTvd || 0)) * Number(getStatistic('constants', locationState)))
              ) / (getLastTrendSheetRow()?.mwOut || 0)) - getRiserSpm()
          )
          * (getLastTrendSheetRow()?.depth || 0)
          / (getLastTrendSheetRow()?.depth || 0),
          0,
          0
        ])

        TrendSheet.rows.map((row, index) => (
          // loop over all trendsheet rows:
          //    if the row has a pipe(s) change, inject row(s) at the pipe change depth using averaging for calculations, then
          //        insert a "normal" row which takes into account the inserted rows
          //    if the row has no pipe change(s) just insert a regular row into the matrix

          // eslint-disable-next-line no-sequences
          pipeChangesAtThisReading = getPipeChangesAtThisReading(row.depth).filter(n => !getPipeChangesAtThisReading(priorMdReading).includes(n)),
          // console.log('processing row depth', row.depth, 'which has', pipeChangesAtThisReading.length, 'pipe changes:', pipeChangesAtThisReading),
          pipeChangesAtThisReading.length > 0 ? (
            pipeChangesAtThisReading.map((pipe, i) => (
              // insert a row at the pipe depth
              getDepthAtPipe(pipe) !== getLastTrendSheetRow()?.depth ? (

              // eslint-disable-next-line no-sequences
              killChartMatrix.push([
                index + (1 + i)/10,
                getLastTrendSheetRow()?.depth || 0,
                getLastTrendSheetRow()?.depthTvd || 0,
                getDepthAtPipe(pipe),
                priorTvdReading + ((getDepthAtPipe(pipe) - priorMdReading) / (row.depth - priorMdReading)) * (row.depthTvd - priorTvdReading),
                row.depth !== 0 ? getDepthAtPipe(pipe) - priorMdReading : 0,
                (priorTvdReading + ((getDepthAtPipe(pipe) - priorMdReading) / (row.depth - priorMdReading)) * (row.depthTvd - priorTvdReading)) - priorTvdReading,
                0,
                0,
                0,
                0,
                0,
                getCirculatingStpp(
                  row,
                  getDepthAtPipe(pipe),
                  priorTvdReading + ((getDepthAtPipe(pipe) - priorMdReading) / (row.depth - priorMdReading)) * (row.depthTvd - priorTvdReading)
                ),
                0,
                getRiserSpm()
                  + ((getRiserSpm() * ((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10) || 0)
                  - ((KillSheet?.targetSbp || 0) / ((getLastTrendSheetRow()?.depthTvd || 0) * Number(getStatistic('constants', locationState)))))
                  / (getLastTrendSheetRow()?.mwOut || 0)) - getRiserSpm()) * (getDepthAtPipe(pipe) / (getLastTrendSheetRow()?.depth || 0)),
                0,
                0
              ]),
              priorTvdReading = priorTvdReading + ((getDepthAtPipe(pipe) - priorMdReading) / (row.depth - priorMdReading)) * (row.depthTvd - priorTvdReading),
              priorMdReading = getDepthAtPipe(pipe)
              ) : null
            )),
            // insert the "normal" row, adjusted for the inserted row(s)

            killChartMatrix.push([
              index + 1,
              getLastTrendSheetRow()?.depth || 0,
              getLastTrendSheetRow()?.depthTvd || 0,
              row.depth,
              row.depthTvd,
              row.depth !== 0 ?((row.depth - priorMdReading) || 0) : 0,
              row.depthTvd !== 0 ? ((row.depthTvd - priorTvdReading) || 0) : 0,
              0,
              0,
              0,
              0,
              0,
              getCirculatingStpp(row),
              0,
              getRiserSpm()
                + ((getRiserSpm() * ((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10) || 0)
                - ((KillSheet?.targetSbp || 0) / ((getLastTrendSheetRow()?.depthTvd || 0) * Number(getStatistic('constants', locationState)))))
                / (getLastTrendSheetRow()?.mwOut || 0)) - getRiserSpm()) * (row.depth / (getLastTrendSheetRow()?.depth || 0)),
              0,
              0
            ]),
            priorTvdReading = row.depthTvd,  // Math.ceil((priorTvdReading + ((row.depth - priorMdReading) / (row.depth - priorNormalMd)) * (row.depthTvd - priorNormalTvd)) * 10 / 10),
            priorMdReading = row.depth
            // eslint-disable-next-line no-sequences
          ) : (

            // if no pipe changes at this tripsheet reading, insert a  normal row
            killChartMatrix.push([
              index + 1,
              getLastTrendSheetRow()?.depth || 0,
              getLastTrendSheetRow()?.depthTvd || 0,
              row.depth || 0,
              row.depthTvd || 0,
              row.depth !== 0 ?((row.depth - priorMdReading) || 0) : 0,
              row.depthTvd !== 0 ? ((row.depthTvd - priorTvdReading) || 0) : 0,
              0,
              0,
              0,
              0,
              0,
              getCirculatingStpp(row),
              0,
              getRiserSpm()
                + ((getRiserSpm() * ((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10) || 0)
                - ((KillSheet?.targetSbp || 0) / ((getLastTrendSheetRow()?.depthTvd || 0) * Number(getStatistic('constants', locationState)))))
                / (getLastTrendSheetRow()?.mwOut || 0)) - getRiserSpm()) * (row.depth / (getLastTrendSheetRow()?.depth || 0)),
              0,
              0
              // eslint-disable-next-line no-sequences
            ]),
            priorMdReading = row.depth,
            priorTvdReading = row.depthTvd
          )
        ))

        // flip matrix
        killChartMatrix = killChartMatrix.reverse()

        let dMdPrior = 0
        let dTvdPrior = 0
        let dMdAcc = 0
        let dTvdAcc = 0
        let sumStrokes = 0

        for (let i = 0; i < killChartMatrix.length - 1; i++) {
          killChartMatrix[i][7] = dMdPrior !== 0 ? dMdAcc + killChartMatrix[i][5] : killChartMatrix[i][5]
          killChartMatrix[i][8] = dTvdPrior !== 0 ? dTvdAcc + killChartMatrix[i][6] : killChartMatrix[i][6]
          killChartMatrix[i][11] = killChartMatrix[i][3] !== 0 ? (KillSheet?.sidpp || 0) - (killChartMatrix[i][4] / (getLastTrendSheetRow()?.depthTvd || 0) * (KillSheet?.sidpp || 0)) : 0

          dMdPrior = killChartMatrix[i][5]
          dTvdPrior = killChartMatrix[i][6]
          dMdAcc += dMdPrior
          dTvdAcc += dTvdPrior
        }

        // flip matrix back
        killChartMatrix = killChartMatrix.reverse()

        for (let i = 0; i < killChartMatrix.length; i++) {
          // calc pl
          killChartMatrix[i][13] = killChartMatrix[i][12] - killChartMatrix[i][11]

          // calc strokes in interval
          killChartMatrix[i][9] = i > 0 ? getStrokesInInterval(killChartMatrix[i][7], killChartMatrix[i][3], killChartMatrix[i-1][3]) : 0
          sumStrokes += i > 0 ? getStrokesInInterval(killChartMatrix[i][7], killChartMatrix[i][3], killChartMatrix[i-1][3]) : 0

          // calc sum strokes
          killChartMatrix[i][10] = sumStrokes

          // mpd calculations
          killChartMatrix[i][15] = killChartMatrix[0][11]
            + (((KillSheet?.targetSbp || 0) - (KillSheet?.sidpp || 0)) / (getLastTrendSheetRow()?.depthTvd || 0))
            * killChartMatrix[i][4]
          killChartMatrix[i][16] = killChartMatrix[i][14] + killChartMatrix[i][15]
        }

        // correct first row
        killChartMatrix[0][14] = getRiserSpm()
          + ((getRiserSpm() * ((Number(Math.ceil(getKillFluidDensityMinutes() * 10) / 10) || 0)
          - ((KillSheet?.targetSbp || 0) / ((getLastTrendSheetRow()?.depthTvd || 0) * Number(getStatistic('constants', locationState)))))
          / (getLastTrendSheetRow()?.mwOut || 0)) - getRiserSpm()) * (killChartMatrix[0][3]  / (getLastTrendSheetRow()?.depth || 0))
        killChartMatrix[0][16] = killChartMatrix[0][14] + killChartMatrix[0][15]

        return killChartMatrix
      }
    }

    function generateChartMatrixDownload() {
      if (killChartMatrix) {
        let bundle = []
          bundle = [...killChartMatrix]
          bundle.unshift([
            'Index',
            'Trend MD',
            'Trend TVD',
            'MD Reading',
            'TVD Reading',
            'MD Change',
            'TVD Change',
            'MD Sum',
            'TVD Sum',
            'Strokes/Int',
            'Strokes Sum',
            'Remaining SIDPP',
            'Circulating STPP',
            'PL',
            'MPD PL',
            'MPD SIDPP',
            'MPD Circulating STPP'
          ])
        let download = JSON.parse(JSON.stringify(bundle))
        return download
      }
    }

    function generateStrokesFootDownload() {
      if (feetPerStrokeMatrix) {
        const bundle = [...feetPerStrokeMatrix]
        bundle.unshift(['Index', 'Label', 'Feet/Stroke', 'Length', 'Length/Ft/Stroke', 'N/A'])
        let download = JSON.parse(JSON.stringify(bundle))
        return download
      }
    }

    let feetPerStrokeMatrix = createFeetPerStrokeMatrix()
    // console.log('feetPerStrokeMatrix', feetPerStrokeMatrix)

    let killChartMatrix = createKillChartMatrix()
    // if (killChartMatrix) {
    //   console.log('killChartMatrix', killChartMatrix)
    // }

    let yAxisLabel = locationState?.units === 'metric' ? 'BAR' : 'PSI'

    let arrPl = new Array([] as any)
    let arrRs = new Array([] as any)
    let arrCs = new Array([] as any)
    let arrMp = new Array([] as any)
    let arrMs = new Array([] as any)
    let arrMc = new Array([] as any)

    if (killChartMatrix) {
      for (let i = 0; i < killChartMatrix?.length; i++) {
        if (i === 1) {
          if ((killChartMatrix[i][3] !== 0) && (killChartMatrix[i][4] !== 0)) {
            arrPl.push({
              x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round((killChartMatrix[i][12] - killChartMatrix[i][11]) * 10) / 10,
            })
            arrRs.push({
              x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][11] * 10) / 10
            })
            arrCs.push({
              x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][12] * 10) / 10
            })
            arrMp.push({
              x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][14] * 10) / 10
            })
            arrMs.push({
              x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][15] * 10) / 10
            })
            arrMc.push({
              x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][16] * 10) / 10
            })
          }
        } else {
          arrPl.push({
            x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][13] * 10) / 10
          })
          arrRs.push({
            x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][11] * 10) / 10
          })
          arrCs.push({
            x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][12] * 10) / 10
          })
          arrMp.push({
            x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][14] * 10) / 10
          })
          arrMs.push({
            x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][15] * 10) / 10
          })
          arrMc.push({
            x: Math.round(killChartMatrix[i][10] * 10) / 10, y: Math.round(killChartMatrix[i][16] * 10) / 10
          })
        }
      }
      arrPl.shift()
      arrRs.shift()
      arrCs.shift()
      arrMp.shift()
      arrMs.shift()
      arrMc.shift()

      // console.log('PL', arrPl)
    }

    let series = []
      series = [
        {
          name: "PL",
          type: "line",
          data: arrPl,
        },
        {
          name: "Remaining SIDPP",
          type: "line",
          data: arrRs,
        },
        {
          name: "Circulating STPP",
          type: "line",
          data: arrCs,
        },
        {
          name: "MPD PL",
          type: "line",
          data: arrMp,
        },
        {
          name: "MPD SIDPP",
          type: "line",
          data: arrMs,
        },
        {
          name: "MPD Circulating STPP",
          type: "line",
          data: arrMc,
        }
      ]

    var killGraph = {
      series: series,
      chart: {
        height: 850,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight' , // "straight" or "smooth"
      },
      title: {
        text: 'Kill Chart',
        align: 'center',
        style: {
          color: 'rgb(30,144,255)',
          fontSize: '28px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          // cssClass: 'apexcharts-xaxis-title',
        },
      },
      // grid: {
      //   row: {
      //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      //     opacity: 0.5
      //   },
      // },
      fill: {
        type: "solid",
      },
      markers: {
        size: [0, 0],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      theme: {
        mode: 'dark',
        palette: 'palette1',
        monochrome: {
            enabled: false,
            color: '#255aee',
            shadeTo: 'light',
            shadeIntensity: 0.65
        },
      },
      xaxis: {
        type: "numeric",
        min: 0,
        // max: 9000,
        tickAmount: 12,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
              colors: '#a9a9a9',
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
        },
        title: {
          text: 'Strokes',
          offsetX: 0,
          offsetY: 10,
          style: {
            color: '#ffffff',
            fontSize: '18px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
          },
        },
      },
      yaxis: {
        type: "numeric",
        min: 0,
        // max: 9000,
        tickAmount: 8,
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
              colors: ['#a9a9a9'],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: function (val: any) {
            if (isNumeric(val)) {
              return val.toFixed(0)
            } else {
              return val
            }
          }
        },
        title: {
          text: yAxisLabel,
          offsetX: -10,
          offsetY: 0,
          style: {
              color: '#ffffff',
              fontSize: '18px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'top',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 600,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: -10,
        labels: {
            colors: ['#a9a9a9'],
            useSeriesColors: false
        },
        markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
        },
        itemMargin: {
            horizontal: 5,
            vertical: 0
        },
        onItemClick: {
            toggleDataSeries: true
        },
        onItemHover: {
            highlightDataSeries: true
        },
      }
    }

    return (
      preReqs ?
        <div>
          <SectionTabs />
          <span className="doNotPrint">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="tripStatsData" style={{ width: '100%', textAlign: 'right', marginTop: '0px', marginRight: '10px', marginBottom: '10px', fontSize: '13px' }}>
              <div className="killSheetTimeClockWrapper" style={{ textAlign: 'right', flexDirection: 'row-reverse', marginTop: '-40px',  }}>
                <div id="timeclock" className="timeclock"></div>
                <div className="timeclock wellInfoTimeClock"><Clock /></div>
              </div>
            </div>
          </div>
          </span>
          <PrintInfo />
          <div id="killGraphContainer">
            <div id="killGraph"></div>
          </div>
          <div className="downloadButtonWrapper" style={{ marginBottom: '25px' }}>
            <CSVLink data={generateChartMatrixDownload()} filename={`${cleanUpRogueCharacters(window.location.href.split('/')[5])}-KillChart-${new Date().toISOString()}.csv`}>CSV</CSVLink>
            <CSVLink data={generateStrokesFootDownload()} filename={`${cleanUpRogueCharacters(window.location.href.split('/')[5])}-FeetPerStroke-${new Date().toISOString()}.csv`}>Strokes</CSVLink>
          </div>
        </div>
      : <>
          <SectionTabs />
          <div style={{ color: '#fff', padding: '20px', display: 'flex', flexDirection: 'row' }}>
            <div><img alt="warning" src={warning} className="filter-blue" style={{ marginTop: '10px', width: '25px', height: '25px', marginRight: '20px' }} /></div>
            <div style={{ marginTop: '11px', color: '#0096FF' }}>A well section must have a Kill Sheet, Pipe Tally, Trend Sheet and either a BHA or Casing/Liner Tally to view a Kill Chart</div>
          </div>
        </>
      )
    }
  )

export default KillChart
