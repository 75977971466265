import { ReactElement } from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { getCompanyName, getRigName } from '../../../utils/helpers'

export function PrintInfo(): ReactElement {

const breadcrumbs = useBreadcrumbs()
const wellName = breadcrumbs[2]?.match.pathname.split('/')[2]
const wellSectionName = breadcrumbs[3]?.match.pathname.split('/')[3]


  return (
    <div id="printOnlyContent">
        <div style={{
            backgroundColor: '#000000',
            color: '#fff',
            borderRadius: '8px',
            border: '1px dashed #000000',
            padding: '8px',
            width: '100%',
            fontSize: '10pt',
            marginLeft: '1px',
            marginBottom: '25px',
        }}>
        <b><u>Company</u></b>: {getCompanyName()}
        &nbsp; / <b><u>Rig</u></b>: {getRigName()}
        &nbsp; / <b><u>Well</u></b>: {wellName}
        &nbsp; / <b><u>Section</u></b>: {wellSectionName}
        </div>
    </div>
  )
}

export default PrintInfo
