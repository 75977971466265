export const STAND_LITERALS = ['S', 'D', 'T']
export const LAST_STAND = 1000
export const FEEDBACK_LINK = "mailto:toolbox@endeavortech.com?subject=Radar7 feedback&body=Please include your comments, questions, etc. Your feedback is greatly appreciated."
export const RESET_PASSWORD_LINK = "mailto:toolbox@endeavortech.com?subject=Radar7 support request&body=Please include your company, full name, username and email address."
export const MUIDATAGRIDX = 'c2f87861b3d555c7e580b86d8d8e3a58Tz02OTQ4MyxFPTE3MTk0MjM0NzQ3NzUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
export const HEALTH_CHECK_INTERVAL = 15000
export const HEALTH_CHECK = false
export const STATUS_INTERVAL = 60000
export const STATUS_CHECK = true
export const siralav = ['Acme', 'InterWell', 'Valaris']
