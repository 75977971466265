import { useQuery, gql, useMutation } from '@apollo/client'
import { produce } from 'immer'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Dialog from './kit/Dialog'
import Input from './kit/Input'
import { Button, ImportButton } from './kit/SmallWidthButton'
import { RadioButton } from './kit/RadioButton'
import { Paper } from './Layout'
import PrintInfo from 'app/components/kit/PrintInfo'
import { spaceToUnderscore, underscoreToSpace, removeNonAlphaNumericExceptSpacePeriodHyphen, getCompany, getRig } from 'utils/helpers'
import { withStyles } from '@mui/styles'
import Metrics from './Well/Metrics'
import Units from './Well/Units'
import DataTable from './Well/DataTable'
import Clock from './Well/Clock'
import Badges from './Well/Badges'
import LastSection from './Well/LastSection'
import KickCalculatorTool from './Tools/KickCalculatorTool'
// import { RouterContext } from './Router'
import './Wells.css'
import './PipeTally/pipeTally.css'

const Head = () => (
  <thead style={{ display: 'table', tableLayout: 'fixed' }}>
    <tr>
      <th className="wellSectionsTableCell" style={{ width: '50%' }}>Name</th>
      <th className="wellSectionsTableCell" style={{ width: '25%' }}>Type</th>
      <th className="wellSectionsTableCell" style={{ width: '25%' }}>Date</th>
      {/* <th className="wellSectionsTableCell" style={{ width: '15%' }}>Units</th> */}
    </tr>
  </thead>
)
const CreateWellSection = ({ refetch, open, onClose, wellName, companyId }) => {
  const [form, setForm] = useState({ name: '', type: '', base: 'new', copy: '' })

  const { data, loading } = useQuery(
    gql`
      query getWellSections($companyId: String!, $rigId: String!, $take: String!, $name: String!) {
        getWellSections(
          companyId: $companyId,
          rigId: $rigId,
          take: $take,
          name: $name,
        ) {
          id
          name
          type
          updatedAt
          createdAt
        }
      }
    `,
    {
      variables: {
        name: underscoreToSpace(wellName),
        companyId: getCompany(),
        rigId: getRig,
        take: '500',
      },
      onCompleted(data) {
        // data.getWellSections.length === 0 ? setOtherSections(false) : setOtherSections(true)
        // setUnitGridVisibility();
      },
    }
  )

  const [createWellSection] = useMutation(
    gql`
      mutation createWellSection(
        $name: String!
        $type: String!
        $wellName: String!
        $companyId: String!
        $rigId: String!
      ) {
        createWellSection(
            name: $name
            type: $type
            rig: $rigId
            company: $companyId
            wellName: $wellName
        ) {
          id
        }
      }
    `,
        {
          variables: {
            rigId: JSON.parse(localStorage.getItem('context')).rig.id,
        },
      onCompleted() {
        refetch()
        setForm({ name: '', type: '' })
        onClose()
      },
    }
  )

  const [copyWellSection] = useMutation(
    gql`
      mutation copyWellSection(
        $name: String!
        $copy: String!
        $type: String!
        $wellName: String!
        $companyId: String!
        $rigId: String!
      ) {
        copyWellSection(
            name: $name
            copy: $copy
            type: $type
            rig: $rigId
            company: $companyId
            wellName: $wellName
        ) {
          id
        }
      }
    `,
        {
          variables: {
            rigId: JSON.parse(localStorage.getItem('context')).rig.id,
        },
      onCompleted() {
        refetch()
        setForm({ name: '', type: '' })
        onClose()
      },
    }
  )

  const handleName = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.name = sanitizeInput(e.target.value)
      })
    )
  }

  const handleType = {
    openHole() {
      setForm(
        produce(form, (draft) => {
          if (draft.type !== 'OH') draft.type = 'OH'
        })
      )
    },
    casing() {
      setForm(
        produce(form, (draft) => {
          if (draft.type !== 'CSG') draft.type = 'CSG'
        })
      )
    },
  }

  const handleBase = {
    new() {
      setForm(
        produce(form, (draft) => {
          if (draft.base !== 'new') draft.base = 'new'
        })
      )
    },
    copy() {
      setForm(
        produce(form, (draft) => {
          if (draft.base !== 'copy') draft.base = 'copy'
        })
      )
    },
  }


  function sanitizeInput(str) {
    return removeNonAlphaNumericExceptSpacePeriodHyphen(str)
  }


  const hasWellSectionData = !loading && data?.getWellSections?.length > 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="createWellSectionWrapper">
        <h3>Create well section</h3>

        <h4 className="createWellSectionTitle" style={{ color: 'dodgerBlue', marginLeft: '40px' }}>
          Create New or Copy Existing
        </h4>
        <div id="createWellSectionContainer" className="createWellSectionContainer" style={{ marginLeft: '50px' }}>

          <div className="direction-picker">
            <RadioButton
              id="NewWellSection"
              checked={form.base === 'new'}
              onChange={() => {
                document.getElementById('existingWellSections').style.display = 'none'
                handleBase.new()
                document.getElementById('wellSectionName').focus()
              }}
            />
            <label style={{ marginLeft: '25px', fontSize: '14px', fontWeight: 400, cursor: 'pointer', color: '#818290' }} htmlFor="NewWellSection">New Well Section</label>
          </div>

          <div className={!hasWellSectionData ? 'invisibleSelection' : 'direction-picker'}>
            <RadioButton
              id="ExistingWellSection"
              style={{ marginLeft: '30px' }}
              className={!hasWellSectionData ? 'invisible' : 'indentLeft'}
              checked={(form.base === 'copy' && hasWellSectionData)}
              onChange={() => {
                !hasWellSectionData ? document.getElementById('existingWellSections').style.display = 'none' : document.getElementById('existingWellSections').style.display = 'block'
                handleBase.copy()
              }}
            />
            <label style={{ marginLeft: '25px', fontSize: '14px', fontWeight: 400, cursor: 'pointer', color: '#818290' }} htmlFor="ExistingWellSection">Copy Existing Section</label>
            <br/><br/>
          </div>

        </div>

        <div id="existingWellSections" style={{
          backgroundColor: '#2b2c41',
          overflowY: 'scroll',
          display: 'none'
        }}>
          <select
            id="ExistingWellSections"
            size={5}
            className="selectWellSection"
            style={{
              marginLeft: 0,
              width: 350,
              padding: '10px',
              color: 'dimgray',
              backgroundColor: '#2b2c41',
              borderRadius: '8px',
              // overflowY: 'scroll',
            }}
            onChange={(e) => {
              setForm(
                produce(form, (draft) => {
                  draft.copy = e.target.value
                })
              )
            }}
          >
            {!loading && data?.getWellSections.slice().sort(function(a, b) {
              return a.name.localeCompare(b.name); }
            ).map(({ id, name, type, updatedAt }) => (
              <option
                key={id}
                value={id}
                name={name}
                className="selectWellSectionItem"
                onClick={() => {
                  document.getElementById('wellSectionName').focus()
                }}
              >
                {name}
              </option>
            ))}
          </select>
        </div>

        <h4 className="createWellSectionTitle" style={{ color: 'dodgerBlue', marginLeft: '40px', marginBottom: '-40px' }}>
          Section Name
        </h4>
        <div style={{ margin: 16 }}>
          <Input
            id="wellSectionName"
            title=""
            onChange={handleName}
            onKeyUp={(e) => {
              e.target.value = sanitizeInput(e.target.value)
            }}
            className="inputModalField"
            labelClassName="placeholder"
            wrapperClassName="field"
            style={{ width: 400 }}
          />
        </div>
        <div style={{ fontSize: '8pt', color: 'silver', display: 'flex', justifyContent: 'flex-start', alignSelf: 'flex-start', alignItems: 'flex-start', textAlign: 'left', marginLeft: '20px', marginTop: '-20px', marginBottom: '0px' }}>* Alphanumeric, period, hyphen and spaces only</div>

        <h4 className="createWellSectionTitle" style={{ color: 'dodgerBlue', marginLeft: '40px' }}>
          Section Type
        </h4>
        <div id="createWellSectionContainer" className="createWellSectionContainer" style={{ marginLeft: '50px' }}>
          <div className="direction-picker" onClick={handleType.openHole}>
            <RadioButton
              id="select-before"
              checked={form.type === 'OH'}
              onChange={() => {}}
            />
            <p className="direction-label">Drilling Operations</p>
          </div>
          <div className="direction-picker" onClick={handleType.casing}>
            <RadioButton
              id="select-after"
              style={{ marginLeft: 30 }}
              checked={form.type === 'CSG'}
              onChange={() => {}}
            />
            <p className="direction-label">Run Casing/Liner</p>
          </div>
        </div>
        <div className="createWellSectionBtnWrapper">
          <Button
            id="btnCreateWellSection"
            small
            disabled={!form.name || form.type.length === 0 || form.base.length === 0 || (form.base === 'copy' && document.getElementById('ExistingWellSections').value === '')}
            onClick={() => {
              form.base === 'copy' ?
              copyWellSection({
                variables: { ...form, wellName, companyId },
              })
              :
              createWellSection({
                variables: { ...form, wellName, companyId },
              })
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const WellSections = () => {
  const [open, setOpen] = useState(false)
  const router = useNavigate()
  // const { locationState } = useContext(RouterContext)
  const { wellName } = useParams()
  const { data, loading, refetch } = useQuery(
    gql`
      query getWellSections($companyId: String!, $rigId: String!, $take: String!, $name: String!) {
        getWellSections(
          companyId: $companyId,
          rigId: $rigId,
          take: $take,
          name: $name,
        ) {
          id
          name
          type
          updatedAt
          createdAt
        }
      }
    `,
    {
      variables: {
        name: underscoreToSpace(wellName),
        companyId: getCompany(),
        rigId: getRig(),
        take: '500',
      },
      onCompleted(data) {
        // console.log('wells', data);
        // setUnitGridVisibility();
      },
    }
  )

  // function setUnitGridVisibility() {
    // const unitSelection = document.getElementById('unitSelection');
    // const unitEditingGrid = document.getElementById('unitConversions');

    // unitSelection.blur();

    // if (unitSelection.value === 'Custom') {
    //   unitEditingGrid.style.display = 'block';
    //   return;
    // }
    // unitEditingGrid.style.display = 'none';
  // }

  const StyledTab = withStyles({
    root: {
      textTransform: 'capitalize',
      '&.Mui-selected > span': { color: '#fff !important', cursor: 'default' },
      '&.MuiTab-textColorInherit > span:hover': { color: '#5c85f5 !important' },
      '&.Mui-selected > span:hover': { color: '#fff !important' },
      padding: '5px',
      minWidth: 0,
      marginRight: 28,
      marginBottom: '0px',
      fontSize: '14px',
      color: 'silver !important',
    },
  })(Tab)

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div {...other}>
        {value === index && <div p={3}>{children}</div>}
      </div>
    );
  }
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <PrintInfo />
      <div id="wellTabs" style={{ display: 'flex', flexDirection: 'row' }}>
        <Tabs value={value} onChange={handleChange}>
          <StyledTab label="Summary" />
          <StyledTab label="Well Sections" />
          <StyledTab label="Kick Tolerance" />
        </Tabs>
      </div>
      <div className="wellInfoWrapper">
        <TabPanel value={value} index={0}>
          <div className="wellTabsWrapper">
            <div className="wellTabsContainer">
              <Badges />
              <div className="wellInfoTimeClockWrapper">
                <div className="timeclock wellInfoTimeClock"><Clock /></div>
                <div id="timeclock" className="timeclock"></div>
              </div>
              <LastSection />
            </div>
          </div>
          <Metrics />
          <div className="unitSelectionWrapper">
            <div className="unitSelectionContainer">
              <div id="unitConversions" className="unitSelectionPanel">
                <Units />
              </div>
            </div>
          </div>
          <DataTable host="well" well={wellName} />
        </TabPanel>
      </div>
      <TabPanel value={value} index={1}>
        <CreateWellSection
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          refetch={refetch}
          wellName={underscoreToSpace(wellName)}
          companyId={JSON.parse(localStorage.getItem('context')).company.id}
        />
        <div
          style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}
        >
          <ImportButton
            style={{ width: 150, margin: 8 }}
            title="Create well section"
            onClick={() => setOpen(true)}
          />
        </div>
        <Paper style={{ padding: '8px 16px' }}>
          <div style={{ width: '100%' }}>
            <table className="table well" style={{
                display: 'table !important',
                position: 'relative !important',
                tableLayout: 'fixed',
                width: '100%',
                overflowWrap: 'break-word',
              }}>
              <Head />
              <tbody style={{ tableLayout: 'fixed', display: 'table', width: '100%', boxSizing: 'border-box' }}>
                {!loading &&
                  data?.getWellSections.map(({ id, name, type, updatedAt }) => (
                    <tr
                      key={id}
                      onClick={() => {
                        router(
                          `/all-wells/${spaceToUnderscore(
                            wellName
                          // )}/${spaceToUnderscore(name)}/pipe-tally`
                          )}/${spaceToUnderscore(name)}`
                        )
                      }}
                    >
                      <td className="hl wellSectionsTableCell" style={{ width: '40%' }}>
                        {name}
                      </td>
                      <td className="hl wellSectionsTableCell" style={{ width: '20%' }}>
                        {type === 'CSG' ? 'Casing' : 'Open hole'}
                      </td>
                      <td className="hl wellSectionsTableCell" style={{ width: '25%' }}>
                        {new Date(updatedAt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})}
                        <span style={{ marginLeft: '9px', color: '#67687e' }}>
                          {new Date(updatedAt).toLocaleTimeString()}
                        </span>
                      </td>
                      {/* <td className="hl wellSectionsTableCell" style={{ width: '15%' }}>
                        {units}
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="wellTabsWrapper">
          <div className="wellTabsContainer">
            <KickCalculatorTool />
          </div>
        </div>
      </TabPanel>
    </div>
  )
}

export default WellSections
