import React, { FC, Component } from 'react'

import {
  ApolloClient,
  InMemoryCache,
  from,
  ApolloProvider as Provider,
} from '@apollo/client'

import {
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
} from '@apollo/client/core'
import { print, GraphQLError } from 'graphql'
import { createClient, ClientOptions, Client } from 'graphql-ws'
import { RetryLink } from '@apollo/client/link/retry';

class WebSocketLink extends ApolloLink {
  private client: Client

  constructor(options: ClientOptions) {
    super()
    this.client = createClient(options)
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err)
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`
                )
              )
            }

            const errAsGQLArray = err as GraphQLError[];
            if(errAsGQLArray && Array.isArray(errAsGQLArray)) {
              return sink.error(
                new Error(
                  errAsGQLArray.map(({ message }) => message).join(', ')
                )
              )  
            }

            if(err) {
              return sink.error(
                new Error(err.toString())
              )  
            }

            return sink.error(
              new Error("Unknown error")
            )  
          },
        }
      )
    })
  }
}

const sessionId = window.localStorage.getItem('session')

if (!process.env.REACT_APP_SERVER_URL)
  throw new Error('set .env REACT_APP_SERVER_URL')

  const retryLink = new RetryLink({
    delay: {
      initial: 2000,
      max: 2000,
      jitter: false
    }
  });

const link = new WebSocketLink({
  url: process.env.REACT_APP_SERVER_URL,
  keepAlive: 30000,                             // ping server every 30 seconds
  lazyCloseTimeout: 3600000,                    // close connection after 1 hour of inactivity
  retryAttempts: 1800,                          // keep retrying until connection is established, every 2 seconds for up to an hour
  retryWait: async function retryWait(attempt) {
      await new Promise((resolve) =>
      setTimeout(resolve, 3000),                // system stability reset grace period upon network reconnection
    )
  },
  on: {
    //connected: () => console.log('graphql-ws connected'),
    error: (err) => console.log('gql_socket_err: ', err),
    closed: (e:any) => {
      console.log('Socket closed.', e.code, e.reason, e.wasClean);
    }
  },
  connectionParams: () => {
    return {
      session: sessionId ?? undefined,
    }
  },
})

export const client = new ApolloClient({
  // link,
  link: from([retryLink, link]),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== 'production',
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
    query: { errorPolicy: 'all' },
  },
})

function withApollo(App: FC) {
  return class extends Component {
    render() {
      return (
        <Provider client={client}>
          <App />
        </Provider>
      )
    }
  }
}

export default withApollo
