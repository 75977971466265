import Clock from '../Well/Clock'
import { underscoreToSpace } from '../../../utils/helpers'
import { getCompanyName, getRigName } from '../../../utils/helpers'

interface SettingsProps {
    company: string | null
    firstName: string | null
    lastName: string | null
}

const Settings = (props: SettingsProps) => {

    return (
        <div className="" style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div style={{ backgroundColor: 'transparent', width: '100%' }}>
                <div className="tripSettings">
                    <div className="tripContext">
                        <div style={{ marginBottom: '10px' }}>Company</div>
                        {/* <div>Client</div> */}
                        <div>Driller</div>
                    </div>
                    <div className="tripContextData">
                        <div style={{ marginBottom: '10px' }}>{getCompanyName() ?? ''}</div>
                        {/* <div>_____________</div> */}
                        <div>{props.firstName ?? ''} {props.lastName ?? ''}</div>
                    </div>
                    <div className="tripLocation">
                        <div style={{ marginBottom: '10px' }}>Rig</div>
                        <div style={{ marginBottom: '10px' }}>Well</div>
                        <div>Well Section</div>
                    </div>
                    <div className="tripLocationData">
                        <div style={{ marginBottom: '10px' }}>{getRigName()}</div>
                        <div style={{ marginBottom: '10px' }}>{underscoreToSpace(window.location.href.split('/')[4])}</div>
                        <div>{underscoreToSpace(window.location.href.split('/')[5])}</div>
                    </div>
                    <div className="tripStats">
                        <div>Date: </div>
                    </div>
                    <div className="tripStatsData">
                        {/* {new Date(Date.now()).toString()} */}
                        <div className="wellInfoTimeClockWrapper"
                            style={{ marginTop: '-23px' }}
                        >
                            <div style={{ fontSize: '14px', color: 'silver' }} className="timeclock wellInfoTimeClock"><Clock /></div>
                            <div style={{ fontSize: '14px', color: 'silver' }} id="timeclock" className="timeclock"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings