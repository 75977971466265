import { HTMLProps } from 'react'
import './Input.css'

const Input = ({
  title,
  helperText,
  placeholder,
  errorHelperText,
  id,
  style,
  autofocus,
  wrapperClassName,
  labelClassName,
  className,
  ...props
}: InputProps) => {
  return (
    <div className={wrapperClassName} style={style}>
      <input 
        id={id} {...props} 
        className={className ?? ''} 
        placeholder={placeholder ?? ''} 
        autoFocus={autofocus} 
      />
      <label
        htmlFor={id}
        className={labelClassName ?? ''}
        style={{ pointerEvents: 'none' }}
      >
        {title}
      </label>
    </div>
  )
}

type InputProps = {
  title?: string
  helperText?: string
  placeholder?: string
  errorHelperText?: string
  autofocus?: boolean
  wrapperClassName?: string
  className?: string
  labelClassName?: string
} & HTMLProps<HTMLInputElement>

export default Input
