import { useState, useEffect } from 'react'
//import { useMst } from 'app/store'
import {
    DataGridPro,
    GridColDef,
    GridFooterContainer,
    gridPageCountSelector,
    gridPageSelector,
    GridPagination,
    GridToolbarContainer,
    GridToolbarExport,
    GridValueFormatterParams,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid-pro"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box'
import { MUIDATAGRIDX } from '../../constants/common'
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client'
import { insertString, numberFormatter, removeNonAlphaNumericExceptPercentAndHyphen, removeSingleQuotes, trimString, underscoreToSpace, } from 'utils/helpers'
import Pagination from "@mui/material/Pagination"
import { styled } from '@mui/material/styles'
//import { UserContext } from 'App'
import { LicenseInfo } from '@mui/x-license-pro'
import iconReset from '../../../assets/images/icons/icon_reset_rh.png'
import { Toast } from '../kit/Toast'
import { AlertProps } from '@mui/material/Alert';
import warning from '../../../assets/images/icons/icon_warning.svg'
//import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
//import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import { confirm } from "react-confirm-box"

LicenseInfo.setLicenseKey(MUIDATAGRIDX)

const allKinds = new Set<string>(['PT', 'BHA', 'SP']);

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
    //   color="primary"
      sx={{button:{color: 'dodgerblue', fontWeight: 800}}}
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
const RotatingHours = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [searchWellName, setSearchWellName] = useState('')
  const [searchJoint, setSearchJoint] = useState('')
  const [searchDescription, setSearchDescription] = useState('')
  const [searchWellId, setSearchWellId] = useState('')
  const [searchWellSectionId, setSearchWellSectionId] = useState('')
  const [activeJoint, setActiveJoint] = useState({serial: '', kind: '', ttype: '', tserial: ''})
  const [searchSource, setSearchSource] = useState(allKinds)
  const [msg, setMsg]= useState('')
  const [showMsg, setShowMsg] = useState(false)
  const [variant, setVariant] = useState<AlertProps["color"]>("success")
  //const { user } = useContext(UserContext)
  //const [scope, setScope] = useState('')
  //const {
  //  store: { logRotatingHours },
  //} = useMst()

  const overrideTo = {newTotal: 0, comment: ''}

  const [loadRotatingHours, { data, loading, refetch }] = useLazyQuery(
      gql`
          query getRotatingHoursV2(
              $companyId: String!,
              $rigId: String!,
              $kinds: [String!]!,
              $ttype: String,
              $serial: String,
              $wellId: String,
              $wellSectionId: String
              ) {
                getRotatingHoursV2(
                  companyId: $companyId,
                  rigId: $rigId,
                  kinds: $kinds,
                  ttype: $ttype,
                  serial: $serial,
                  wellId: $wellId,
                  wellSectionId: $wellSectionId
                  ) { ... on rotatingHoursSummaryV2 { rigId, kind, targetType, targetSerial, ttype, serial, total } }
          }
      `,
      {
        fetchPolicy: 'network-only',
        onCompleted(data) {
            //console.log('data', data)
            setIsLoading(false)
          },
        onError(err) {
          //console.log(err.message)
        }
      }
  )

  const [loadJointDetail, { data: dataDetail, loading: loadingDetail, refetch: refetchDetail  }] = useLazyQuery(
    gql`
      query getRotatingHoursDetailsV2(
        $companyId: String!
        $rigId: String!
        $kind: String!
        $ttype: String!
        $serial: String!
      ) {
        getRotatingHoursDetailsV2(
          companyId: $companyId
          rigId: $rigId
          kind: $kind
          ttype: $ttype
          serial: $serial
          ) {  
            summaries 
                { ... on rotatingHoursSummaryDetailsV2 { wellName, wellSectionName, total, startDate, endDate} } , 
            resets { ... on rotatingHoursResetDetailsV2 { oldTotal, newTotal, comment, createdAt, loggedBy } } 
             
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
          //console.log('data', data)
      },
      onError(err) {
        //console.log(err.message)
      }
    }
  )

  const {data: wellData } = useQuery(
    gql`
      query getWells($companyId: String!, $rigId: String!, $take: String!) {
        getWells(
            companyId: $companyId,
            rigId: $rigId,
            take: $take,
          ) {
          id
          rigId
          name
          loacation
          units
          status
          createdAt
        }
      }
    `,
    {
      variables: {
        companyId: JSON.parse(window.localStorage.getItem("context") || "").company.id,
        rigId: JSON.parse(window.localStorage.getItem("context") || "").rig.id,
        take: '500',
      },
      onCompleted(data) {
      },
    }
  )

  const {data: wellSectionData } = useQuery(
    gql`
      query getWellSections($companyId: String!, $rigId: String!, $take: String!, $name: String!) {
        getWellSections(
          companyId: $companyId,
          rigId: $rigId,
          take: $take,
          name: $name,
        ) {
          id
          name
          type
          updatedAt
          createdAt
        }
      }
    `,
    {
      variables: {
        name: searchWellName === undefined ? '' : searchWellName.length === 0 ? searchWellName :underscoreToSpace(searchWellName),
        companyId: JSON.parse(window.localStorage.getItem("context") || "").company.id,
        rigId: JSON.parse(window.localStorage.getItem("context") || "").rig.id,
        take: '500',
      },
      onCompleted(data) {
      },
    }
  )

  const [overrideRotatingHours] = useMutation(
    gql`
      mutation resetRotatingHours(
        $rigId: String!,
        $kind: String!, 
        $type: String!,
        $serial: String!,
        $newTotal: Float!,
        $comment: String!
        ) {
          resetRotatingHours(
          # data: {
            rigId: $rigId,
            kind: $kind, 
            type: $type,
            serial: $serial,
            newTotal: $newTotal,
            comment: $comment
              # }
        )
      }
    `,
    {
      onCompleted: () => {
        setVariant('success')
        setMsg('Rotating Hours for the joint have been reset')
        setShowMsg(true)
        setTimeout(() => {
          setShowMsg(false)
        }, 3000)
        refetch()
        refetchDetail()
      },
      onError: (err)  => {
        setVariant('error')
        setMsg(err.toString())
        setShowMsg(true)
        setTimeout(() => {
          setShowMsg(false)
        }, 3000)
      }
    }
  )

  const columns: GridColDef[] = [
      { field: 'pipeType', headerName: 'Type', width: 100, editable: false, headerClassName: 'rh--header' },
      { field: 'pipeDesc', headerName: 'Pipe Description', flex: 1, editable: false, headerClassName: 'rh--header',
        valueGetter: (params) => {
          return params.value;
        },
        valueFormatter: (params: GridValueFormatterParams<string>) => {
          if (params.value == null) {
            return '';
          }
          return params.value.toString()
        },
      },
      { field: 'serial',
          headerName: 'Serial Number',
          flex: 1,
          editable: false,
          headerClassName: 'rh--header',
          valueGetter: (params) => {
            return params.value;
          },
          valueFormatter: (params: GridValueFormatterParams<string>) => {
            if (params.value == null) {
              return '';
            }
            return params.value.toString()
          },
      },
      { field: 'summary',
          headerName: 'Hours Logged',
          width: 120,
          editable: false,
          type: 'number',
          valueGetter: (params) => {
            return params.value;
          },
          valueFormatter: (params: GridValueFormatterParams<number>) => {
            if (params.value == null) {
              return 0.0;
            }
            return numberFormatter((params.value).toString(),2)
          },
          headerClassName: 'rh--header'
      },
      {
        field: 'joint',
        headerName: 'Reset',
        sortable: false,
        renderCell: (params) => {
          return (
            <img
              alt="reset"
              className="iconReset"
              src={iconReset}
              onClick={(e) => {
                resetPointer(e, params)
              }
            } />
          )
        },
      },
  ];

  const rows = isLoading || !data ? [] : data.getRotatingHoursV2.map((item: any) => {
      return {
          rigId: item.rigId,
          pipeType: item.kind,
          pipeDesc: item.ttype,
          kind: item.kind,
          targetType: item.targetType,
          targetSerial: item.targetSerial,
          serial: item.serial,
          summary: item.total,
      }
  })

  const rowDetail = loadingDetail || !dataDetail ? [] : dataDetail.getRotatingHoursDetailsV2.summaries.map((item: any) => {
    return {
      wellName: item.wellName,
      wellSectionName: item.wellSectionName,
      total: item.total,
      dateTimeStart: item.startDate,
      dateTimeEnd: item.endDate,
    }
  }).concat(dataDetail.getRotatingHoursDetailsV2.resets.map((item: any) => {
    return {
      wellName: 'Reset',
      wellSectionName: item.comment ? item.comment : '',
      newTotal: item.newTotal,
      total: item.oldTotal,
      dateTimeEnd: item.createdAt,
      loggedBy: item.loggedBy,
    }
  }))

  const columnDetail: GridColDef[] = [
    { field: 'wellName', headerName: 'Well', flex: 1, editable: false, headerClassName: 'rh--header' },
    { field: 'wellSectionName', headerName: 'Well Section', flex: 1, editable: false, headerClassName: 'rh--header' },
    { field: 'dateTimeStart',
        headerName: 'From',
        width: 170,
        editable: false,
        headerClassName: 'rh--header',
        valueGetter: (params) => {
          return params.value;
        },
        valueFormatter: (params: GridValueFormatterParams<string>) => {
          const theDate = new Date(params.value);
          if (isNaN(theDate.getTime()) || theDate.getTime() <= 0) {
            return '';
          }
          return insertString(theDate.toString().substring(0, 21), 16, ' at ').substring(4,25)
        },
    },
    { field: 'dateTimeEnd',
        headerName: 'To',
        width: 170,
        editable: false,
        headerClassName: 'rh--header',
        valueGetter: (params) => {
          return params.value;
        },
        valueFormatter: (params: GridValueFormatterParams<string>) => {
          const theDate = new Date(params.value);
          if (isNaN(theDate.getTime()) || theDate.getTime() <= 0) {
            return '';
          }
          return insertString(theDate.toString().substring(0, 21), 16, ' at ').substring(4,25)
        },
    },
    { field: 'total',
      headerName: 'Hours',
      width: 90,
      editable: false,
      headerClassName: 'rh--header',
      type: 'number',
      valueGetter: (params) => {
        return params.value;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (isNaN(params.value)) {
          return '';
        }
        return numberFormatter(params.value.toString(),2)
      },
    },
    { field: 'newTotal',
      headerName: 'Reset to',
      width: 90,
      editable: false,
      headerClassName: 'rh--header',
      type: 'number',
      valueGetter: (params) => {
        return params.value;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (isNaN(params.value)) {
          return '';
        }
        return numberFormatter(params.value.toString(),2)
      },
    },
    { field: 'loggedBy', headerName: 'Recorder', flex: 1, editable: false, headerClassName: 'rh--header' },
  ];

  const onClick = async (options: any, serial: string, kind: string, ttype: string, tserial: string) => {
    const result = await confirm(serial, options)

    if (result) {
      if (!isNaN(overrideTo.newTotal)) {
          // await resetRotatingHours(joint)

          const rigId = JSON.parse(window.localStorage.getItem("context") || "").rig.id

          await overrideRotatingHours({
            variables: {
              rigId,
              kind,
              type: ttype,
              serial: tserial,
              newTotal: overrideTo.newTotal,
              comment: overrideTo.comment,
            },
          })          
      }
      else {
        setVariant('error')
        setMsg('Entered value is not valid')
        setShowMsg(true)
        setTimeout(() => {
          setShowMsg(false)
        }, 3000)
      }
      return;
    } else {
      setVariant('error')
      setMsg('Rotating Hours reset was cancelled and not performed')
      setShowMsg(true)
      setTimeout(() => {
        setShowMsg(false)
      }, 3000)
    }
  }

  function setNewOverride(newVal: string) {
    overrideTo.newTotal = parseFloat(newVal)
  }

  const overrideJointHours = {
      closeOnOverlayClick: true,
      render: (message: string, onConfirm: any, onCancel: any) => {
          return (
              <div className="centeredDiv" style={{ backgroundColor: 'firebrick' }}>
                  <div style={{ width: '600px', margin: '0px 20px 20px 20px', padding: '10px 20px 20px 20px', marginBottom: '15px' }}>
                      <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                          <img alt="warning" src={warning} style={{ marginTop: '10px', width: '75px', height: '75px', marginRight: '10px' }} />
                      </div>
                      <div style={{ color: '#FFFFFF', fontWeight: 500, fontSize: '14pt', marginBottom: '15px' }}>
                          Are you sure you want to override Rotating Hours for this joint?<br /><br />
                          <div style={{ margin: 'auto', padding: '10px', backgroundColor: '#000000', borderRadius: '6px', fontWeight: 700, color: '#fff' }}>{message}</div>
                      </div>
                      <div style={{ color: '#fff', fontWeight: 600, marginBottom: '25px', marginTop: '15px' }}>
                          Overriding a joint will
                          <span style={{ textDecoration: 'none', backgroundColor: 'transparent', color: '#fff', borderRadius: '4px', padding: '6px 4px 0px 4px', fontWeight: 800 }}>archive</span>
                          the previous hours logged and reset the logged hours for the joint to <span style={{ color: '#FFFFFF', fontWeight: 800 }}>the value entered below</span>.
                      </div>
                      <div style={{ paddingBottom: '25px', color: '#fff', fontWeight: 700 }}>
                        Override this joint to
                        <input
                          id="jointHoursOverride"
                          type="number"
                          onFocus={e => e.target.select()}
                          style={{ marginLeft: '10px', marginRight: '10px', textAlign: 'right', width: '60px', height: '30px', borderRadius: '4px', backgroundColor: '#fff', color: '#000000', fontWeight: 700, border: '1px solid #000000', padding: '0px 5px 0px 5px' }}
                          defaultValue={0}
                          onBlur={(e) => setNewOverride(e.target.value)}
                          />
                        hours
                      </div>
                      <div style={{ paddingBottom: '25px', color: '#fff', fontWeight: 700 }}>
                        Comment:
                        <input
                          id="jointHoursOverrideComment"
                          onFocus={e => e.target.select()}
                          style={{ marginLeft: '10px', marginRight: '10px', height: '30px', borderRadius: '4px', backgroundColor: '#fff', color: '#000000', fontWeight: 700, border: '1px solid #000000', padding: '0px 5px 0px 5px' }}
                          onBlur={(e) => overrideTo.comment = e.target.value}
                          />
                      </div>
                      <button onClick={onConfirm} className="popupAlertButton" style={{ marginRight: '15px' }}> Confirm </button>
                      <button onClick={onCancel} className="popupAlertButton"> Cancel </button>
                  </div>
              </div>
          )
      }
  }

  useEffect(() => {
    // setNewOverride('0')
    loadRotatingHours( {
      variables: {
        companyId: JSON.parse(window.localStorage.getItem("context") || "").company.id,
        rigId: JSON.parse(window.localStorage.getItem("context") || "").rig.id,
        wellId: searchWellId,
        wellSectionId: searchWellSectionId,
        serial: searchJoint,
        ttype: searchDescription,
        kinds: Array.from(searchSource),
      }
    } );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadRotatingHours( {
      variables: {
        companyId: JSON.parse(window.localStorage.getItem("context") || "").company.id,
        rigId: JSON.parse(window.localStorage.getItem("context") || "").rig.id,
        wellId: searchWellId,
        wellSectionId: searchWellSectionId,
        serial: searchJoint,
        ttype: searchDescription,
        kinds: Array.from(searchSource),
      }
    } );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWellId, searchWellSectionId, searchJoint, searchDescription, searchSource]);

  useEffect(() => {
    if(activeJoint.tserial) loadJointDetail( {
      variables: {
        companyId: JSON.parse(window.localStorage.getItem("context") || "").company.id,
        rigId: JSON.parse(window.localStorage.getItem("context") || "").rig.id,
        serial: activeJoint.tserial,
        kind: activeJoint.kind,
        ttype: activeJoint.ttype
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(activeJoint.tserial) loadJointDetail( {
      variables: {
        companyId: JSON.parse(window.localStorage.getItem("context") || "").company.id,
        rigId: JSON.parse(window.localStorage.getItem("context") || "").rig.id,
        serial: activeJoint.tserial,
        kind: activeJoint.kind,
        ttype: activeJoint.ttype
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeJoint])

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
          <div><GridToolbarExport /></div>
          <div style={{ color: '#8D918D', marginTop: '4px' }}>Maximum 1000 joints returned</div>
        </div>
      </GridToolbarContainer>
    );
  }

  function CustomToolbarDetail() {
    return (
      <div>
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
      </div>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer>
        <CustomPagination />
        <GridPagination />
      </GridFooterContainer>
    );
  }


  function handleRowClick(params: any, event: any, details:any) {
    //setSearchRigId(params.row.rigId)
    if(!(activeJoint.tserial === params.row.targetSerial && activeJoint.kind === params.row.kind && activeJoint.ttype === params.row.targetType))
      {
        setActiveJoint({serial: params.row.serial, kind: params.row.kind, ttype: params.row.targetType, tserial: params.row.targetSerial})
      }
  }

  function handleWellChange(wellId: string) {
    const sId = wellId.split('|')[0]
    const sName = wellId.split('|')[1]
    setSearchWellName(sName)
    setSearchWellId(sId)
    setSearchWellSectionId('')
    //setSearchJoint('')
    //setSearchSource(allKinds)
  }

  function handleWellSectionChange(wellSectionId: string) {
    setSearchWellSectionId(wellSectionId)
    //setSearchJoint('')
    //setSearchSource(allKinds)
  }

  function handleJointChange(joint?: string) {
    let jointSearchKey = ''
    if (!joint || joint.length === 0) {
      const elem = document.getElementById('currentJoint') as HTMLInputElement
      jointSearchKey = removeSingleQuotes(removeNonAlphaNumericExceptPercentAndHyphen(elem.value))
    } else {
      jointSearchKey = removeSingleQuotes(removeNonAlphaNumericExceptPercentAndHyphen(joint))
    }
    setSearchJoint(jointSearchKey)
    //setSearchWellId('')
    //setSearchWellSectionId('')
  }

  function handleDescriptionChange(desc?: string) {
    let descSearchKey = ''
    if (!desc || desc.length === 0) {
      const elem = document.getElementById('currentDesc') as HTMLInputElement
      descSearchKey = removeSingleQuotes(removeNonAlphaNumericExceptPercentAndHyphen(elem.value))
    } else {
      descSearchKey = removeSingleQuotes(removeNonAlphaNumericExceptPercentAndHyphen(desc))
    }
    setSearchDescription(descSearchKey)
    //setSearchWellId('')
    //setSearchWellSectionId('')
  }

  function handleSourceChange(source: string, sourceState: boolean) {
    if(sourceState) {
      searchSource.add(source)      
    } else {
      searchSource.delete(source)
    }
    setSearchSource(new Set(searchSource))
  }

  function resetPointer(event: any, rowValues: any) {
    overrideTo.newTotal = 0
    overrideTo.comment = '';
    onClick(overrideJointHours, rowValues.row.serial, rowValues.row.kind, rowValues.row.targetType, rowValues.row.targetSerial)
    return true
  }

  return !loading && !loadingDetail ? (
      <div style={{ marginTop: '35px' }}>
          <h2 style={{ color: '#fff', marginBottom: '10px' }}>Filter</h2>
          <div id="gridFilter" style={{ marginBottom: '25px', minWidth: '1156px' }}>
            <Box sx={{ padding: '15px', borderRadius: '8px', backgroundColor: '#4a4b68', color: '#000000', height: 85, width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                <div style={{ width: '21%', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ color: '#fff', fontSize: '14px', marginLeft: '3px', marginBottom: '4px' }}>Well</div>
                  <div>
                    <select
                        id="currentWell"
                        onChange={(e) => {
                          handleWellChange(e.target.value)
                          e.target.blur()
                      }}
                        defaultValue={`${searchWellId}|${searchWellName}`}
                        title="Select a Well"
                        className="rhSelect"
                        style={{ width: '90%' }}
                        >
                          <option key={''} value={''}>{'--- Select Well ---'}</option>
                          {wellData?.getWells.slice().sort((a: any, b: any) => a.name.localeCompare(b.name)).map((wellData: any) => (
                            <option key={wellData.id} value={`${wellData.id}|${wellData.name}`}>
                              {trimString(wellData.name, 30)}
                            </option>
                        ))}
                      </select>
                    </div>
                </div>
                <div style={{ width: '24%', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ color: '#fff', fontSize: '14px', marginLeft: '3px', marginBottom: '4px' }}>Well Section</div>
                  <div>
                    <select
                      id="currentWellSection"
                      onChange={(e) => {
                        handleWellSectionChange(e.target.value)
                        e.target.blur()
                    }}
                      defaultValue={searchWellSectionId}
                      title="Select a Well Section"
                      className="rhSelect"
                      style={{ width: '90%' }}
                    >
                      <option key={''} value={''}>{'--- Select Well Section ---'}</option>
                      {searchWellId.length > 0 && wellSectionData?.getWellSections.slice().sort((a: any, b: any) => a.name.localeCompare(b.name)).map((wellSectionData: any) => (
                        <option key={wellSectionData.id} value={wellSectionData.id}>
                            {trimString(wellSectionData.name, 30)}
                          </option>
                      ))}
                    </select>
                    </div>
                </div>
                <div style={{ width: '10%', display: 'flex', flexDirection: 'column' }}>
                  <div id="rhCbScopeWrapper" style={{ marginLeft: '8px' }}>
                    <FormGroup sx={{ marginTop: '8px' }}>
                      <FormControlLabel sx={{ color: 'white' }} control={
                        <Checkbox
                          defaultChecked={searchSource.has('BHA')}
                          size="small"
                          onChange={(e) => {
                            handleSourceChange('BHA', e.target.checked)
                            e.target.blur()
                          }}
                          sx={{
                            marginBottom: '-14px',
                            color: '#d6d0cb',
                            borderRadius: '8px',
                            '&.Mui-checked': {
                              color: '#d6d0cb',
                            },
                          }}
                        />
                      } label="BHA" />
                      <FormControlLabel sx={{ color: 'white' }} control={
                        <Checkbox
                        defaultChecked={searchSource.has('PT')}
                        size="small"
                        onChange={(e) => {
                          handleSourceChange('PT', e.target.checked)
                          e.target.blur()
                        }}
                        sx={{
                          marginBottom: '-14px',
                          color: '#d6d0cb',
                          borderRadius: '8px',
                          '&.Mui-checked': {
                            color: '#d6d0cb',
                          },
                        }}
                      />
                    } label="Pipes" />
                      <FormControlLabel sx={{ color: 'white' }} control={
                        <Checkbox
                        defaultChecked={searchSource.has('SP')}
                        size="small"
                        onChange={(e) => {
                          handleSourceChange('SP', e.target.checked)
                          e.target.blur()
                        }}
                        sx={{
                          marginBottom: '-14px',
                          color: '#d6d0cb',
                          borderRadius: '8px',
                          '&.Mui-checked': {
                            color: '#d6d0cb',
                          },
                        }}
                      />
                    } label="SP" />
                  </FormGroup>
                </div>
                </div>
                <div style={{ width: '19%', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ color: '#fff', fontSize: '14px', marginLeft: '3px', marginBottom: '4px' }}>Description</div>
                  <div style={{ minWidth: '140px'}}>
                  <input
                      id="currentDesc"
                      type="string"
                      defaultValue={searchDescription}
                      className="rhSelect"
                      style={{ backgroundColor: '#fff', color: '#000000', marginRight: '6px', width: '125px' }}
                      onFocus={e => e.target.select()}
                      onBlur={((e) => {
                        handleDescriptionChange(e.target.value)
                      })}
                    />
                  </div>
                </div>
                <div style={{ width: '26%', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ color: '#fff', fontSize: '14px', marginLeft: '3px', marginBottom: '4px' }}>Joint</div>
                  <div style={{ minWidth: '200px'}}>
                    <input
                      id="currentJoint"
                      type="string"
                      defaultValue={searchJoint}
                      className="rhSelect"
                      style={{ backgroundColor: '#fff', color: '#000000', marginRight: '6px', width: '125px' }}
                      onFocus={e => e.target.select()}
                      onBlur={((e) => {
                        handleJointChange(e.target.value)
                      })}
                    />
                    <button
                      className="rhSelect"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleJointChange()
                        handleDescriptionChange()
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
          <h2 style={{ color: '#fff', marginBottom: '0px' }}>Hours Logged</h2>
          <div id="gridSummary">
            <Box sx={{ borderRadius: '18px', backgroundColor: 'transparent', color: '#000000', height: 410, width: '100%' }}>
                <DataGridPro
                    getRowId={(row) => row.targetType + row.targetSerial + row.kind}
                    rows={rows}
                    onRowClick={(params, event, details) => {
                      handleRowClick(params, event, details)
                    }}
                    columns={columns}
                    initialState={{
                        columns: {
                          columnVisibilityModel: {
                            wellsectionid: false,
                            occurances: false,
                            rigId: false,
                          },
                        },
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                    }}
                    localeText={{
                        footerRowSelected: CustomPagination
                    }}
                    pageSizeOptions={[10]}
                    checkboxSelection={false}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        footer: CustomFooter
                    }}
                    rowHeight={25}
                    style={{ color: 'silver', border: 'none' }}
                    sx={{
                      "& .MuiDataGrid-sortIcon": {
                        opacity: 1,
                        color: "white",
                      },
                      '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                      },
                      '&.MuiDataGrid-root': {
                        border: 'none',
                      },
                      '&>.MuiDataGrid-main': {
                        '&>.MuiDataGrid-columnHeaders': {
                            borderBottom: '1px solid dimgray'
                        },
                        '& div div div div >.MuiDataGrid-cell': {
                            borderBottom: '1px solid dimgray'
                        }
                      },
                      '& .MuiDataGrid-row:hover': {
                        backgroundColor: '#1565b3',
                      },
                      '& .MuiDataGrid-cell:focus': {
                        outline: ' none'
                      },
                      "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none",
                      }
                    }}
                />
            </Box>
          </div>
          <h2 style={{ color: '#fff', marginTop: '10px', marginBottom: '2px' }}>Joint History - <span style={{ color: 'dodgerblue' }}>{activeJoint.serial ? activeJoint.serial : ''}</span></h2>
          <div id="gridDetails">
            <Box sx={{ borderRadius: '18px', backgroundColor: 'transparent', color: '#000000', height: 400, width: '100%' }}>
              <DataGridPro
                getRowId={(row) => row.wellName + row.wellSectionName + row.dateTimeEnd.toString() }
                rows={activeJoint.serial ? rowDetail : []}
                columns={columnDetail}
                getDetailPanelHeight={({ row }) => 100}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                      source: false,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                rowHeight={25}
                style={{ color: 'silver', border: 'none' }}
                slots={{
                  toolbar: CustomToolbarDetail,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                sx={{
                  "& .MuiDataGrid-sortIcon": {
                    opacity: 1,
                    color: "white",
                  },
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none',
                  },
                  '&>.MuiDataGrid-main': {
                    '&>.MuiDataGrid-columnHeaders': {
                      borderBottom: '1px solid #c3c3c3'
                    },
                    '& div div div div >.MuiDataGrid-cell': {
                        borderBottom: '1px solid #c3c3c3'
                    }
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none",
                  }
                }}
              />
            </Box>
          </div>
          <Toast
              variant={variant}
              message={`${msg}`}
              open={showMsg}
            />
      </div>
  ) : (<></>)
}

export default RotatingHours
