import { observer } from 'mobx-react'

type KickCalculatorToolColumnProps = {
    kc: any
    id: string
    index: number
    A1: number
    A2: number
    A3: number
    A4: number
    A5: number
    A6: number
    A7: number
    A8: number
    A9: number
    A10: number
    B1: number
    B2: number
    B3: number
    B4: number
    B5: number
    B6: number
    B7: number
    B8: number
    B9: number
    C1: number
    C2: number
    C3: number
    C4: number
    C5: number
    C6: number
    C7: number
    C8: number
    C9: number
    D1: number
    D2: number
    D3: number
    D4: number
    DD1: number
    DD2: number
    DD3: number
    DD4: number
    E: number
    wellType: string
    units: string
}

const KickCalculatorToolColumn = observer(({ ...props }: KickCalculatorToolColumnProps): JSX.Element => {
    const {
        kc,
        id,
        index,
        A1,
        A2,
        A3,
        A4,
        A5,
        A6,
        A7,
        A8,
        A9,
        A10,
        B1,
        B2,
        B3,
        B4,
        // B5,
        B6,
        B7,
        B8,
        B9,
        C1,
        C2,
        C3,
        C4,
        C5,
        C6,
        C7,
        C8,
        C9,
        D1,
        D2,
        D3,
        D4,
        DD1,
        DD2,
        DD3,
        DD4,
        E,
        wellType,
        units
    } = props

    function processKickCalculatorColumnUpdate(id='', column=0, fieldName='A1', fieldType='string', value=1) {
        if (value === null) { value = 0 }
        if (kc) {
            kc.updateKickCalculatorModel(id, fieldName, fieldType, value.toString(), units)
        }
    }

    function unfocus() {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }

    function updateModel() {
        // for future use
    }

    function getTableCellStyle(set: string, val: number) {
        if ((set === 'C8') || (set === 'C9')) {
            if (wellType === 'floater') {
                if (kc.ramBop >= val) {
                    return 'kickCalculatorInputTableView bgGreen'
                }
                else {
                    return 'kickCalculatorInputTableView bgRed'
                }
            }
            else {
                if (kc.ramBop >= val) {
                    return 'kickCalculatorInputTableView bgGreen'
                }
                else {
                    return 'kickCalculatorInputTableView bgRed'
                }
            }
        }
        else if ((set ==='D4')  || (set ==='DD4')) {
            if (units === 'metric') {
                if (val < 0) return 'bgRed kickCalculatorInputTableView'
                else if ( val >= 0 && val <= 4) return 'bgYellow kickCalculatorInputTableView'
                else if ( val > 4) return 'bgGreen kickCalculatorInputTableView'
                else return 'kickCalculatorInputTableView'
            }
            else {
                if (val < 0) return 'bgRed kickCalculatorInputTableView'
                else if ( val >= 0 && val <= 25) return 'bgYellow kickCalculatorInputTableView'
                else if ( val > 25) return 'bgGreen kickCalculatorInputTableView'
                else return 'kickCalculatorInputTableView'
            }
        }
        else if (set === 'E') {
            if (val < 0) return 'bgRed kickCalculatorInputTableView'
            else if ( val >= 0 && val < 0.50001) return 'bgYellow kickCalculatorInputTableView'
            else if (val >= 0.50001) return 'bgGreen kickCalculatorInputTableView'
            else return 'kickCalculatorInputTableView'
        }
    }

    function nan(value: number) {
        return isNaN(value) ? 0 : Number(value)
    }

    return (
        (kc) ? (
        <div>
            <div style={{
                display: 'flex', flexDirection: 'column',
                minWidth: '85px', maxWidth: '85px',
                color: 'silver', margin: '0px',
                columnGap: '0px',
                alignContent: 'flex-start',
                flexWrap: 'wrap',
            }}>
                <div className="kickCalculatorSpacer">Section {index}</div>
                <div className="kickCalculatorSubHeader">&nbsp;</div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A1_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A1)}
                        // onBlur={(e) => {
                        //     e.target.value = "" ? e.target.value = "0" : e.target.value
                        // }}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A1',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A2_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A2)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A2',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A3_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A3)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A3',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A4_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A4)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A4',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A5_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A5)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A5',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A6_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A6)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A6',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A7_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A7)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A7',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A8_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A8)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A8',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A9_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A9)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A9',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'A10_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(A10)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'A10',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>

                <div className="kickCalculatorSubHeader">&nbsp;</div>
                <div className="toolsInput kickCalculatorColumn">
                    <select
                        id={'B1_' + index}
                        className="formFieldSelect wellSectionHeaderDropdown kickCalculatorSelect"
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'B1',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                        style={{
                        marginTop: '0px',
                        textAlign: "center",
                        fontSize: "12pt",
                        whiteSpace: "nowrap",
                        fontFamily: "sans-serif",
                        fontWeight: 400,
                        }}
                        value={nan(B1)}
                        onClick={(e) => {e.stopPropagation()}}
                        onBlur={(e) => {unfocus()}}
                    >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'B2_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(B2)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'B2',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'B3_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(B3)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'B3',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'B4_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(B4)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'B4',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'B5_' + index}
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(kc.columns[index-1].B5).toFixed(1)}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        onChange={(e) => {e.stopPropagation()}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'B6_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(B6)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'B6',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'B7_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(B7)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'B7',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'B8_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(B8)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'B8',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        key={id}
                        id={'B9_' + index}
                        onFocus={e => e.target.select()}
                        onClick={(e) => {e.stopPropagation()}}
                        className="kickCalculatorInputTableEdit"
                        type="number"
                        value={nan(B9)}
                        onChange={(e) => {processKickCalculatorColumnUpdate(
                            kc.columns[index - 1].id,
                            index,
                            'B9',
                            'number',
                            e.target.value === "" ? 0 : parseFloat(e.target.value)
                        )}}
                    />
                </div>

                <div className="kickCalculatorSpacer" style={{ marginTop: '10px' }}>&nbsp;</div>
                <div className="kickCalculatorSubHeader">&nbsp;</div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(C1).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className={kc.type === 'floater' ? 'invisible' : 'toolsInput kickCalculatorColumn'} >
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(C2).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className={kc.type === 'floater' ? 'invisible' : 'toolsInput kickCalculatorColumn'} >
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(C3).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className={kc.type === 'floater' ? 'invisible' : 'toolsInput kickCalculatorColumn'} >
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(C4).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className={kc.type === 'floater' ? 'toolsInput kickCalculatorColumn' : 'invisible'} >
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(C5).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className={kc.type === 'floater' ? 'toolsInput kickCalculatorColumn' : 'invisible'} >
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(C6).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className={kc.type === 'floater' ? 'toolsInput kickCalculatorColumn' : 'invisible'} >
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(C7).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className={kc.type === 'floater' ? 'invisible' : 'toolsInput kickCalculatorColumn'} >
                    <input
                        // className={C8 < kc.ramBop ? 'kickCalculatorInputTableView bgGreen' : 'kickCalculatorInputTableView'}
                        className={getTableCellStyle('C8', C8)}
                        type="number"
                        value={nan(C8).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className={kc.type === 'floater' ? 'toolsInput kickCalculatorColumn' : 'invisible'} >
                    <input
                        // className={C9 < kc.ramBop ? 'kickCalculatorInputTableView bgGreen' : 'kickCalculatorInputTableView'}
                        className={getTableCellStyle('C9', C9)}
                        type="number"
                        value={nan(C9).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>

                <div className="kickCalculatorSubHeader">&nbsp;</div>
                <div className="kickCalculatorSpacer">&nbsp;</div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(D1).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(D2).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(D3).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className={getTableCellStyle('D4', D4)}
                        type="number"
                        value={nan(D4).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>

                {/* <div className="kickCalculatorSpacer">&nbsp;</div> */}

                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(B2).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(DD1).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={(nan(DD2) >= 0 ? nan(DD2) : 0).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={(nan(DD3) >= 0 ? nan(DD3) : 0).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className={getTableCellStyle('DD4', DD4)}
                        type="number"
                        value={(nan(DD4) >= 0 ? nan(DD4) : 0).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>

                <div className="kickCalculatorSpacer">&nbsp;</div>
                <div className="toolsInput kickCalculatorColumn">
                    <input
                        className="kickCalculatorInputTableView"
                        type="number"
                        value={nan(E).toFixed(1)}
                        onChange={updateModel}
                    />
                </div>
            </div>
        </div>
        ) : ( <></> )
    )
})

export default KickCalculatorToolColumn