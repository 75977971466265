import { FC, DetailedHTMLProps, HTMLAttributes } from 'react'
import { ReactComponent as AddIcon } from './icons/PlusCircle.svg'
import { ReactComponent as StashIcon } from './icons/Stash.svg'
import { ReactComponent as TrashIcon } from './icons/Delete.svg'
import './IconButtons.css'

export const AddActionButton: FC<JSXButtonProps> = (props) => {
  return (
    <button disabled={props.disabled} {...props} title='Add Pipe' className='icon-button add-icon'>
      <AddIcon />
    </button>
  )
}

export const StashActionButton: FC<JSXButtonProps> = (props) => {
  return (
    <button disabled={props.disabled} {...props} title='Store Pipe' className='icon-button stash-icon' style={{ display: 'none' }}>
      <StashIcon />
    </button>
  )
}

export const TrashActionButton: FC<JSXButtonProps> = (props) => {
  return (
    <button disabled={props.disabled} {...props} title='Delete Pipe' className='icon-button trash-icon'>
      <TrashIcon />
    </button>
  )
}

type JSXButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { disabled?: boolean }
