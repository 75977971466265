// import { Instance, types, SnapshotIn } from 'mobx-state-tree'
import { Instance, types } from 'mobx-state-tree'
import Tubular from './Tubular'
// import { undoManager } from 'app/store'

export type TubularsInstance = Instance<typeof Tubulars>

const Tubulars = types
  .model('Tubulars', {
    tubulars: types.optional(types.array(Tubular), [{
      __typename: '',
      id: '',
      type: '',
      name: '',
      connection: '',
      OD: 0,
      ID: 0,
      weight: 0,
      disp: 0,
      cap: 0,
      range: 0,
      mutMin: 0,
      mutMax: 0,
      tenStrength: 0,
      torStrength: 0,
      standard: false,
      rigId: '',
    }]),
  })
  // .views((self) => ({
  //   get currentString() {
  //     return self.strings[self.currentStringIndex]
  //   },
  // }))
  // .actions((self) => {
  //   return {

  //   }
  // })

export default Tubulars
