import { useState } from 'react';
import Help from './Help'
import Profile from './Profile'
import Links from './Links'
import Summary from './Summary'
import Activity from './Activity.jsx'
// import Feedback from './Feedback'
import { observer } from 'mobx-react-lite'

const DashboardLayout = observer(() => {
    const [currentCompanyId, setCurrentCompanyId] = useState('')
    const [currentRigId, setCurrentRigId] = useState('')

    return (
        <div className="dashboardWrapper">
            <div className="dashboardContainer">
                <Summary
                    currentCompanyId={currentCompanyId}
                    setCurrentCompanyId={setCurrentCompanyId}
                    currentRigId={currentRigId}
                    setCurrentRigId={setCurrentRigId}
                />
                <Links />
                <Profile />
                <Activity currentRigId={currentRigId} currentCompanyId={currentCompanyId} />
                <Help />
                {/* <Feedback /> */}
            </div>
        </div>
    );
})

export default DashboardLayout
