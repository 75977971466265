import { useQuery, gql, useMutation } from '@apollo/client'
// import { UserContext } from 'App'
import { useState, useEffect, useCallback } from 'react'
import { useMst } from 'app/store'
import Dialog from '../kit/Dialog'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Input from '../kit/Input'
// import Select from '../kit/Select'
import { Button, ImportButton } from '../kit/SmallWidthButton'
import { Paper } from '../Layout'
import './Tubulars.css'
import { makeStyles } from '@mui/styles'


const Head = ({pipeType}) => (
  <thead>
    {
            pipeType === 'SP' ? (
              <tr>
                <th style={{ width: 275 }}>Description</th>
                <th style={{ width: 110 }}>Type</th>
                <th style={{ width: 110 }}>Serial</th>
                <th style={{ width: 70 }}>In use</th>
              </tr>
            ) 
            :             
      (
        <tr>
          <th style={{ width: 275 }}>Description</th>
          <th style={{ width: 110 }}>Connection</th>
          <th style={{ width: 70 }}>OD</th>
          <th style={{ width: 70 }}>ID</th>
          <th style={{ width: 70 }}>MUT Min</th>
          <th style={{ width: 70 }}>MUT Max</th>
          <th style={{ width: 77 }}>Adj. Weight</th>
          <th style={{ width: 71 }}>Adj. Disp.</th>
          <th style={{ width: 71 }}>Adj. Cap.</th>
          <th style={{ width: 71 }}>Range</th>
          <th style={{ width: 100 }}>Tensile<br />strength</th>
          <th style={{ width: 100 }}>Torsional<br />strength</th>
        </tr>
            )
    }
  </thead>
)

const formState = {
  name: '',
  type: '',
  ttype: '',
  serial: '',
  connection: '',
  OD: '',
  ID: '',
  weight: '',
  disp: '',
  cap: '',
  range: '',
  mutMin: '',
  mutMax: '',
  tenStrength: '',
  torStrength: '',
  standard: '',
  rigId: '',
}

const CreatePipe = ({ refetch, refetchSP, open, onClose: oc, pipeType }) => {
  const {
    store: { getTubulars },
  } = useMst()
  formState.type = pipeType;
  const onClose = useCallback(() => {
    for(const key in formState) { formState[key] = '';}
    oc()
  }, [oc])

  const [createPipe] = useMutation(
    gql`
      mutation createPipe(
        $pipeType: String!
        $name: String!
        $connection: String!
        $OD: Float!
        $ID: Float!
        $weight: Float!
        $disp: Float!
        $cap: Float!
        $range: Int!
        $mutMin: Int!
        $mutMax: Int!
        $tenStrength: Int!
        $torStrength: Int!
        $standard: Boolean!
        $rigId: String!
      ) {
        createPipe(
          # data: {
            pipeType: $pipeType
            name: $name
            connection: $connection
            OD: $OD
            ID: $ID
            weight: $weight
            disp: $disp
            cap: $cap
            range: $range
            mutMin: $mutMin
            mutMax: $mutMax
            tenStrength: $tenStrength
            torStrength: $torStrength
            standard: $standard
            rigId: $rigId
          # }
        ) {
          id
        }
      }
    `,
    {
      onCompleted() {
        refetch()
        getTubulars({ rigId: JSON.parse(localStorage.getItem('context') || '').rig.id })
        onClose()
      },
    }
  )

  const [createSP] = useMutation(
    gql`
      mutation createSwivelPack(
        $name: String!
        $type: String!
        $serial: String!
        $rigId: String!
      ) {
        createSwivelPack(
            name: $name
            type: $type
            serial: $serial
            rigId: $rigId
        ) {
          id
        }
      }
    `,
    {
      onCompleted() {
        refetchSP()
        onClose()
      },
    }
  )

  const handleInputs = (key) =>
    function (e) {
      formState[key] = e.target.value;
    }


  const handleSubmit = () => {
    if(isDisabled()) return;
    if(formState.type === 'SP') {
      createSP({
        variables: {
          name: formState.name,
          type: formState.ttype,
          serial: formState.serial,
          rigId: JSON.parse(localStorage.getItem('context')).rig.id
        },
      })  
    } else {
      createPipe({
        variables: {
          // pipeType,
          pipeType: formState.type,
          name: formState.name,
          connection: formState.connection,
          OD: Number(formState.OD),
          ID: Number(formState.ID),
          weight: Number(formState.weight),
          disp: Number(formState.disp),
          cap: Number(formState.cap),
          range: Number(Number(formState.range).toFixed(0)),
          mutMin: Number(formState.mutMin),
          mutMax: Number(formState.mutMax),
          tenStrength: Number(formState.tenStrength),
          torStrength: Number(formState.torStrength),
          standard: false,
          rigId: JSON.parse(localStorage.getItem('context')).rig.id
        },
      })  
    }
  }

  const isDisabled = () =>
    formState.type === 'SP' ? ( 
      !formState.name ||
      !formState.ttype ||
      !formState.serial
    )
    : (
    !formState.name ||
    !formState.connection ||
    !formState.OD ||
    !formState.ID ||
    !formState.weight ||
    !formState.disp ||
    !formState.cap ||
    !formState.range ||
    !formState.mutMin ||
    !formState.mutMax ||
    !formState.tenStrength ||
    !formState.torStrength
    )

  return (
    <Dialog open={open} onClose={onClose} style={{ maxWidth: 464 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 32,
          marginTop: 12,
          marginLeft: 20,
          marginRight: 20,
          flexDirection: 'column',
        }}
      >
        <h3>Create tubular</h3>
        <div style={{
          textAlign: 'center',
          fontSize: '13px',
          color: 'dimgray',
          // border: '1px solid dimgray',
          padding: '5px 0px',
          marginLeft: 0,
          marginRight: 0,
          marginBottom: '10px',
          // borderRadius: '8px',
          width: '110%',
          boxSizing: 'border-box',
          backgroundColor: '#393a4c',
          fontWeight: 500,
        }}>
          <div style={{ color: '#fff', padding: '20px' }}>
            You are about to create a new tubular.
            Make sure it is not already existing and that the data is correct.
            Only a Support Administrator can modify and remove tubulars from the library.
          </div>
          <span style={{ fontWeight: 800, color: 'red' }}>Imperial Measurements Only</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
            marginRight: -16,
            marginBottom: 2,
          }}
        >
        {pipeType === 'SP' ? 
        (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: 16, width: 416 }}>
          <Input
              style={{ width: 416 }}
              title="Description"
              id="create-lib-pipe-name"
              type="text"
              defaultValue={formState.name}
              onChange={handleInputs('name')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: 16, width: 416 }}>
            <Input
            style={{ marginLeft: 16, width: 200 }}
              title="Type"
              id="create-lib-pipe-ttype"
              type="text"
              defaultValue={formState.ttype}
              onChange={handleInputs('ttype')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: 16, width: 416 }}>
            <Input
            style={{ marginLeft: 16, width: 200 }}
              title="Serial"
              id="create-lib-pipe-serial"
              type="text"
              defaultValue={formState.serial}
              onChange={handleInputs('serial')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
        </div>
        ) 
        : 
        (
          <div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: 16, width: 416 }}>
          <Input
              style={{ width: 416 }}
              title="Description"
              id="create-lib-pipe-name"
              type="text"
              defaultValue={formState.name}
              onChange={handleInputs('name')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: 16, width: 416 }}>
            <Input
            style={{ marginLeft: 16, width: 200 }}
              title="Connection"
              id="create-lib-pipe-connection"
              type="text"
              defaultValue={formState.connection}
              onChange={handleInputs('connection')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
            marginRight: -16,
            marginBottom: 32,
          }}
        >
          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="OD"
              id="create-lib-pipe-od"
              type="number"
              step="0.1"
              defaultValue={formState.OD}
              onChange={handleInputs('OD')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="ID"
              id="create-lib-pipe-id"
              type="number"
              step="0.1"
              defaultValue={formState.ID}
              onChange={handleInputs('ID')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="Adj. Weight"
              id="create-lib-pipe-weight"
              type="number"
              step="0.1"
              defaultValue={formState.weight}
              onChange={handleInputs('weight')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="Adj. Disp."
              id="create-lib-pipe-disp"
              type="number"
              step="0.1"
              defaultValue={formState.disp}
              onChange={handleInputs('disp')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="Adj. Cap."
              id="create-lib-pipe-cap"
              type="number"
              step="0.1"
              defaultValue={formState.cap}
              onChange={handleInputs('cap')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="Range"
              id="create-lib-pipe-range"
              type="number"
              step="1"
              defaultValue={formState.range}
              onChange={handleInputs('range')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>



          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="MUT Min"
              id="create-lib-pipe-mutmin"
              type="number"
              step="1"
              defaultValue={formState.mutMin}
              onChange={handleInputs('mutMin')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="MUT Max"
              id="create-lib-pipe-mut_max"
              type="number"
              step="1"
              defaultValue={formState.mutMax}
              onChange={handleInputs('mutMax')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>




          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="Tens. Strength"
              id="create-lib-pipe-ten-str"
              type="number"
              step="0.1"
              defaultValue={formState.tenStrength}
              onChange={handleInputs('tenStrength')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
          <div style={{ marginRight: 16, width: 200 }}>
            <Input
              title="Tors. Strength"
              id="create-lib-pipe-tor-str"
              type="number"
              step="0.1"
              defaultValue={formState.torStrength}
              onChange={handleInputs('torStrength')}
              className="inputModalField"
              labelClassName="placeholder"
              wrapperClassName="field"
            />
          </div>
        </div>
        </div>
        )

        }
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            marginRight: 8,
          }}
        >
          <Button small secondary onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button small onClick={handleSubmit}>
            Create
          </Button>
        </div>
      </div>
      </div>
    </Dialog>
  )
}

  const useStyles = makeStyles({
  StyledTab: {
    textTransform: 'none',
    color: '#737496 !important',
    '&.Mui-selected > span': { color: '#fff !important' },
    paddingLeft: 4,
    paddingRight: 4,
    minWidth: 0,
    marginRight: 32,
  },
})

const PipesLibrary = () => {
  const [open, setOpen] = useState(false)
  const [pipeType, setPipeType] = useState('DP')
  const { data, loading, refetch } = useQuery(
    gql`
    query getPipes($type: String!, $rigId: String!) {
      getPipes(
            type: $type,
            rigId: $rigId
      ) {
        id
        type
        name
        connection
        OD
        ID
        weight
        disp
        cap
        range
        mutMin
        mutMax
        tenStrength
        torStrength
        standard
        rigId
      }
    }
    `,
    {
      variables: {
        type: pipeType,
        rigId: localStorage.getItem('context') ? JSON.parse(localStorage.getItem('context') || '').rig.id : ''
      },
      onCompleted(data) {
        // console.log('wells', data)
      },
    }
  )

  const { data: dataSP, loading: loadingSP, refetch: refetchSP } = useQuery(
    gql`
    query getSwivelPacks($rigId: String!) {
      getSwivelPacks(
            rigId: $rigId
      ) {
        id
        type
        name
        serial
        inUse
        rigId
      }
    }
    `,
    {
      variables: {
        rigId: localStorage.getItem('context') ? JSON.parse(localStorage.getItem('context') || '').rig.id : ''
      },
      onCompleted(data) {
        // console.log('wells', data)
      },
      fetchPolicy: 'network-only',
    }
  )

  const [setCurrentSwivelPack] = useMutation(
    gql`
    mutation setCurrentSwivelPack($rigId: String!, $swivelPackId: String!) {
      setCurrentSwivelPack(rigId: $rigId, swivelPackId: $swivelPackId)
    }
    `, 
    {
       onCompleted: () => {refetchSP();}
    }
  );


  useEffect(() => {
    if(pipeType === 'SP') refetchSP()
    else refetch({ type: pipeType })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeType])

  const classes = useStyles();

  return (
    <>
      <CreatePipe
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        refetch={refetch}
        refetchSP={refetchSP}
        pipeType={pipeType}
      />
      <div
        id="tubularsTabs"
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
        }}
      >
        <ImportButton
          style={{ width: 100, margin: 8 }}
          title="Create tubular"
          onClick={() => setOpen(true)}
        />
        <Tabs
          value={pipeType}
          onChange={(_, value) => {
            setPipeType(value)
          }}
          style={{ marginBottom: 16 }}
        >
          <Tab className={classes.StyledTab} disableRipple value="DP" label="Drill Pipes" />
          <Tab className={classes.StyledTab} disableRipple value="HWDP" label="Heavy Weight Drill Pipes" />
          <Tab className={classes.StyledTab} disableRipple value="DC" label="Drill Collars" />
          <Tab className={classes.StyledTab} disableRipple value="LS" label="Landing Strings" />
          <Tab className={classes.StyledTab} disableRipple value="CSG" label="Casing Strings" />
          <Tab className={classes.StyledTab} disableRipple value="LIN" label="Liner Strings" />
          <Tab className={classes.StyledTab} disableRipple value="SP" label="Swivel Packs" />
        </Tabs>
      </div>

      <Paper style={{ padding: '8px 16px' }}>
        <table className="table pipe">
          <Head pipeType={pipeType} />
          <tbody>
            {
            pipeType === 'SP' ?
              !loadingSP &&
              dataSP?.getSwivelPacks.map((sp) => (
                <tr key={sp.id}>
                  <td style={{ width: 275 }} className="hl">
                    {sp.name}
                  </td>
                  <td style={{ width: 110 }} className="hl">{sp.type}</td>
                  <td style={{ width: 110 }} className="hl">
                    {sp.serial}
                  </td>
                  <td style={{ width: 70 }} className="hl">
                    <input type="radio" value={sp.id} name="inUse" id={sp.id}
                      defaultChecked={sp.inUse}
                      onClick={((e) => {
                         if(e.target.defaultChecked) {
                          e.target.checked = false;
                          setCurrentSwivelPack({variables: { rigId: sp.rigId, swivelPackId: ""}})
                         } else {
                          setCurrentSwivelPack({variables: { rigId: sp.rigId, swivelPackId: e.target.value}})
                         }
                      })}
                    />
                  </td>
                </tr>
              ))
            : !loading &&
              data?.getPipes.map((pipe) => (
                <tr key={pipe.id}>
                  <td style={{ width: 275 }} className="hl">
                    {pipe.name}
                  </td>
                  <td style={{ width: 110 }}>{pipe.connection}</td>
                  <td style={{ width: 70 }} className="hl">
                    {pipe.OD.toFixed(3)}
                  </td>
                  <td style={{ width: 70 }} className="hl">
                    {pipe.ID.toFixed(3)}
                  </td>
                  <td style={{ width: 70 }} className="hl">
                    {pipe.mutMin.toString()}
                  </td>
                  <td style={{ width: 70 }} className="hl">
                    {pipe.mutMax.toString()}
                  </td>
                  <td style={{ width: 77 }} className="hl">
                    {pipe.weight.toFixed(3)}
                  </td>
                  <td style={{ width: 71 }} className="hl">
                    {pipe.disp.toFixed(4)}
                  </td>
                  <td style={{ width: 71 }} className="hl">
                    {pipe.cap.toFixed(4)}
                  </td>
                  <td style={{ width: 71, textAlign: 'center' }} className="hl">
                    {pipe.range.toString()}
                  </td>
                  <td style={{ width: 100 }}>{pipe.tenStrength.toString()}</td>
                  <td style={{ width: 100 }}>{pipe.torStrength.toString()}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Paper>
    </>
  )
}

export default PipesLibrary
