import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router'
import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom';
import { spaceToUnderscore, underscoreToSpace } from 'utils/helpers'
import iconGoto from '../../../assets/images/icons/icon_goto.svg'

const LastSection = () => {
  const [thisWellName, setThisWellName] = useState('')
  const [lastSection, setLastSection] = useState('')
  const [isLoading, setIsLoading] = useState('')
  const [showLink, setShowLink] = useState(false)

  const router = useNavigate()
  const { wellName } = useParams()

  const { data, loading } = useQuery(
    gql`
      query getWellSections($companyId: String!, $rigId: String!, $take: String!, $name: String!) {
        getWellSections(
          companyId: $companyId,
          rigId: $rigId,
          take: $take,
          name: $name,
        ) {
          id
            name
            type
            createdAt
            updatedAt
            well {
              id
              name
            }
        }
      }
    `,
    {
      variables: {
          name: underscoreToSpace(wellName),
          companyId: JSON.parse(localStorage.getItem('context')).company.id,
          rigId: JSON.parse(localStorage.getItem('context')).rig.id,
          take: '1',
        },
        onCompleted(data) {
          setIsLoading(true)
        },
        onError(err) {
          console.error(err)
      }
    }
  )

  useEffect(() => {
    if (isLoading) {
      if (data.getWellSections.length > 0) {
        setLastSection(data.getWellSections[0].name)
        setThisWellName(data.getWellSections[0].well.name)
        setShowLink(true)
        return
      }
    }

    return () => {
      setIsLoading(false)
    }
  }, [isLoading, data])

  return !loading && showLink ? (
      <Link to={`/all-wells/${spaceToUnderscore(thisWellName)}/${spaceToUnderscore(lastSection)}`}>
      <div className="importantLink importantLinkProminent">
        <img alt="last section" className="importantLinkProminentIcon" src={iconGoto} />
        <span className="importantLinkText"
            onClick={() => {
              router(`/all-wells/${spaceToUnderscore(thisWellName)}/${spaceToUnderscore(lastSection)}`)
          }}
        >
          Last Section
        </span>
      </div>
    </Link>
  ) : ('')
}

export default LastSection