import { makeStyles } from '@mui/styles'
import {
  ReactNode,
  DetailedHTMLProps,
  HTMLAttributes,
} from 'react'

const useStyles = makeStyles({
  chipTab: ({ active, disabled }: JSSProps) => ({
    all: 'unset',
    padding: '8px 10px 6px',
    borderRadius: '8px',
    backgroundColor: active ? '#5C85F5' : '#3A3C56',
    color: active ? '#fff' : '#737496',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    transition: 'opacity ease-out 0.1s',
    opacity: !disabled ? 1 : 0.15,
    pointerEvents: !disabled ? 'auto' : 'none',
    '&:hover': {
      opacity: '0.8',
    },
    margin: '0px 6px',
  }),
})

export const ChipTab = (props: ChipTabProps) => {
  const { children, active = false, disabled = false, ...p } = props

  const cn = useStyles({ active, disabled })

  return (
    <button {...p} className={cn.chipTab}>
      {children}
    </button>
  )
}

type JSSProps = {
  active: boolean
  disabled: boolean
}

type JSXButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

interface ChipTabProps extends JSXButtonProps {
  children?: ReactNode
  active?: boolean
  disabled?: boolean
}
