import { ReactElement, useContext } from 'react';
import { useEffect, useState, } from 'react';
import { RouterContext } from '../Router'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { makeStyles } from '@mui/styles'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
// import logoEquinor from '../../../assets/images/logos/logoEquinor.svg'

export function SectionTabs(): ReactElement {
    const location = useLocation()
    const uri = location.pathname
    const breadcrumbs = useBreadcrumbs()
    const path = breadcrumbs[3]?.match.pathname
    const router = useNavigate()
    const [value, setValue] = useState(0);
    const { locationState } = useContext(RouterContext)
    const isOpenHole = locationState?.wellSectionType === 'OH'

    const useStyles = makeStyles({
        StyledTab: {
            textTransform: 'capitalize',
            '&.Mui-selected > span': { color: '#fff !important', cursor: 'default' },
            '&.MuiTab-textColorInherit > span:hover': { color: '#5c85f5 !important' },
            '&.Mui-selected > span:hover': { color: '#fff !important' },
            padding: '5px',
            minWidth: 0,
            marginRight: 28,
            marginBottom: '0px',
            fontSize: '14px',
            color: 'silver !important',
        },
        ExternalTab: {
            textTransform: 'capitalize',
            '&.Mui-selected > span': { color: '#fff !important', cursor: 'default' },
            '&.MuiTab-textColorInherit > span:hover': { color: '#5c85f5 !important' },
            '&.Mui-selected > span:hover': { color: '#fff !important' },
            padding: '5px',
            minWidth: 0,
            marginRight: 28,
            marginBottom: '0px',
            fontSize: '14px',
            color: 'cornflowerblue !important',
        },
    });

    const classes = useStyles();

    const handleClick = (value: string) => {
        let newPathArray = path.split('/')
        newPathArray.push(value)
        if (value === '') {
            router(newPathArray.join('/').substring(0, newPathArray.join('/').length - 1))
        }
        if (value.includes('overpull-sheet')
            ) {
                router(`${newPathArray.splice(0, newPathArray.length - 1).join('/')}#${value}`)
        }
        else if (value.includes('library')) {
            router('/library/tubulars')
        }
        else {
            router(newPathArray.join('/'))
        }
    }

    useEffect(() => {
        const thisTab = uri.split('/')[uri.split('/').length - 1]
        if (isOpenHole) {
            switch (thisTab) {
                case 'pipe-tally':
                    setValue(1);
                    break;
                case 'bha':
                    setValue(2);
                    break;
                case 'trip-sheet':
                    setValue(3);
                    break;
                case 'trend-sheet':
                    setValue(4);
                    break;
                case 'kill-sheet':
                    setValue(5);
                    break;
                case 'kill-graph':
                    setValue(6);
                    break;
                case 'bop':
                    setValue(7);
                    break;
                case 'library':
                    setValue(8);
                    break;
                    // case 'overpull-sheet':
                //     setValue(7);
                //     break;
                default:
                    setValue(0);
                    break;
            }
        }
        if (!isOpenHole) {
            switch (thisTab) {
                case 'pipe-tally':
                    setValue(1);
                    break;
                case 'casing-tally':
                    setValue(2);
                    break;
                case 'liner-tally':
                    setValue(3);
                    break;
                case 'trip-sheet':
                    setValue(4);
                    break;
                case 'trend-sheet':
                    setValue(5);
                    break;
                case 'kill-sheet':
                    setValue(6);
                    break;
                case 'kill-graph':
                    setValue(7);
                    break;
                case 'bop':
                    setValue(8);
                    break;   
                case 'library':
                    setValue(9);
                    break;
                    // case 'overpull-sheet':
                //     setValue(8);
                //     break;
                default:
                    setValue(0);
                    break;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uri])

    return (
        <div id="sectionTabs" className="sectionTabs" style={{ width: '100%', backgroundColor: 'transparent', maxHeight: '48px' }}>

            {/* <span style={{ color: 'lime' }}>{locationState?.wellId}</span><br/>
            <span style={{ color: 'hotpink' }}>{locationState?.wellSectionId}</span> */}

            {isOpenHole ? (
                <Tabs
                    value={value}
                    style={{ marginBottom: '30px'}}
                >
                    <Tab className={`${classes.StyledTab} ${value === 0 ? 'activeTab' : ''}`} label="Summary" onClick={() => handleClick('')} />
                    <Tab className={`${classes.StyledTab} ${value === 1 ? 'activeTab' : ''}`} label="Pipe Tally" onClick={() => handleClick('pipe-tally')} />
                    <Tab className={`${classes.StyledTab} ${value === 2 ? 'activeTab' : ''}`} label="BHA" onClick={() => handleClick('bha')} />
                    <Tab className={`${classes.StyledTab} ${value === 3 ? 'activeTab' : ''}`} label="Trip Sheet" onClick={() => handleClick('trip-sheet')} />
                    <Tab className={`${classes.StyledTab} ${value === 4 ? 'activeTab' : ''}`} label="Trend Sheet" onClick={() => handleClick('trend-sheet')} />
                    <Tab className={`${classes.StyledTab} ${value === 5 ? 'activeTab' : ''}`} label="Kill Sheet" onClick={() => handleClick('kill-sheet')} />
                    <Tab className={`${classes.StyledTab} ${value === 6 ? 'activeTab' : ''}`} label="Kill Chart" onClick={() => handleClick('kill-graph')} />
                    <Tab className={`${classes.StyledTab} ${value === 7 ? 'activeTab' : ''}`} label="BOP" onClick={() => handleClick('bop')} />
                    <Tab className={`${classes.ExternalTab} ${value === 8 ? 'activeTab' : ''}`} label="Tubulars" onClick={() => handleClick('library')} />
                    {/* <StyledTab className={value === 3 ? 'activeTab' : ''} label="BOP" onClick={() => handleClick('bop')} /> */}
                    {/* <DisabledTab className={value === 7 ? 'activeTab' : ''} label="Overpull Sheet" onClick={() => handleClick('overpull-sheet')} /> */}
                    </Tabs>
                ) : (
                <Tabs
                    value={value}
                    style={{ marginBottom: '30px'}}
                >
                    <Tab className={`${classes.StyledTab} ${value === 0 ? 'activeTab' : ''}`} label="Summary" onClick={() => handleClick('')} />
                    <Tab className={`${classes.StyledTab} ${value === 1 ? 'activeTab' : ''}`} label="Pipe Tally" onClick={() => handleClick('pipe-tally')} />
                    <Tab className={`${classes.StyledTab} ${value === 2 ? 'activeTab' : ''}`} label="Casing Tally" onClick={() => handleClick('casing-tally')} />
                    <Tab className={`${classes.StyledTab} ${value === 3 ? 'activeTab' : ''}`} label="Liner Tally" onClick={() => handleClick('liner-tally')} />
                    <Tab className={`${classes.StyledTab} ${value === 4 ? 'activeTab' : ''}`} label="Trip Sheet" onClick={() => handleClick('trip-sheet')} />
                    <Tab className={`${classes.StyledTab} ${value === 5 ? 'activeTab' : ''}`} label="Trend Sheet" onClick={() => handleClick('trend-sheet')} />
                    <Tab className={`${classes.StyledTab} ${value === 6 ? 'activeTab' : ''}`} label="Kill Sheet" onClick={() => handleClick('kill-sheet')} />
                    <Tab className={`${classes.StyledTab} ${value === 7 ? 'activeTab' : ''}`} label="Kill Chart" onClick={() => handleClick('kill-graph')} />
                    <Tab className={`${classes.StyledTab} ${value === 8 ? 'activeTab' : ''}`} label="BOP" onClick={() => handleClick('bop')} />
                    <Tab className={`${classes.ExternalTab} ${value === 9 ? 'activeTab' : ''}`} label="Tubulars" onClick={() => handleClick('library')} />
                    {/* <StyledTab className={value === 4 ? 'activeTab' : ''} label="BOP" onClick={() => handleClick('bop')} /> */}
                    {/* <DisabledTab className={value === 8 ? 'activeTab' : ''} label="Overpull Sheet" onClick={() => handleClick('overpull-sheet')} /> */}
                    </Tabs>
                )
            }
            {/* <div style={{ marginLeft: 'auto'}}>
                <img alt="Equinor" style={{ paddingRight: '55px', height: '70px' }} src={logoEquinor} />
            </div> */}
        </div>
    );
}
