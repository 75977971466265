import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import ApexCharts from 'apexcharts'
import Chart from 'react-apexcharts'

const ChartSelect = observer(({ chartNumber }) => {
    const [option1, setOption1] = useState('depth')
    const [option2, setOption2] = useState('depthTvd')
    const [chartOneOptions, setChartOneOptions] = useState({})
    const [chartTwoOptions, setChartTwoOptions] = useState({})
    const [chartOneData, setChartOneData] = useState([{name: "Date",data: [{x:"abc",y:0}]}])
    const [chartTwoData, setChartTwoData] = useState([{name: "Date",data: [{x:"abc",y:0}]}])
    const {
        store: { TrendSheet },
      } = useMst()

    const options = [
    'active|Active',
    'azimuth|Azimuth',
    'depth|Depth',
    'depthTvd|Depth TVD',
    'ecd|ECD',
    'flowIn|Flow In',
    'flowOut|Flow Out',
    'wiFreeRot|Free Rotation',
    'gas|Gas',
    'inclination|Inclination',
    'mwIn|Mud Weight In',
    'mwOut|Mud Weight Out',
    'pressureOff|Pressure Off',
    'pressureOn|Pressure On',
    'rop|ROP',
    'rpm|RPM',
    'spm|SPM',
    'torque|Torque',
    'wiDown|Weight Down',
    'wiUp|Weight Up',
    'wob|WOB'
    ]

    useEffect(() => {
        if (document.querySelector('#chart_1')) {
            setChartOneData([{name: "Date",data: getChartData(option1)}])
        }
    }, [option1, TrendSheet])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (document.querySelector('#chart_2')) {
            setChartTwoData([{name: "Date",data: getChartData(option2)}])
        }
    }, [option2, TrendSheet])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(chartOneOptions).length === 0) {
            setChartOneOptions({
                chart: {
                    type: 'area',
                    animations: {
                            enabled: true
                    },
                    zoom: {
                        enabled: false
                    },
                },
                series: [{
                    name: option1.split('|')[1],
                    data: chartOneData
                }],
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 5,
                    tickPlacement: 'between',
                    position: 'bottom',
                    labels: {
                        show: true,
                        rotateAlways: false,
                        rotate: 0,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        minHeight: undefined,
                        maxHeight: 120,
                        maxWidth: 200,
                        align: 'right',
                        offsetX: 0,
                        offsetY: 0,
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM \'yy',
                            day: 'dd MMM',
                            hour: 'HH:mm'
                        },
                        style: {
                            colors: 'Gray',
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-yaxis-label',
                        }
                    },
                    axisBorder: {
                        show: true,
                        height: 1,
                        width: '100%',
                        offsetX: 0,
                        offsetY: 0
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 160,
                        style: {
                            colors: ['Gray'],
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-yaxis-label',
                            },
                        offsetX: 0,
                        offsetY: 0,
                        rotate: 0,
                        formatter: function (val) {
                            return val.toFixed(1);
                        },
                    },
                    axisBorder: {
                        show: true,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    }
                }
            })
        }

        if (Object.keys(chartTwoOptions).length === 0) {
            setChartTwoOptions({
                chart: {
                    type: 'area',
                    animations: {
                            enabled: true
                    },
                    zoom: {
                        enabled: false
                    },
                },
                series: [{
                    name: option2.split('|')[1],
                    data: chartTwoData
                }],
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 5,
                    tickPlacement: 'between',
                    position: 'bottom',
                    labels: {
                        show: true,
                        rotateAlways: false,
                        rotate: 0,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        minHeight: undefined,
                        maxHeight: 120,
                        maxWidth: 200,
                        align: 'right',
                        offsetX: 0,
                        offsetY: 0,
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM \'yy',
                            day: 'dd MMM',
                            hour: 'HH:mm'
                        },
                        style: {
                            colors: 'Gray',
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-yaxis-label',
                        }
                    },
                    axisBorder: {
                        show: true,
                        height: 1,
                        width: '100%',
                        offsetX: 0,
                        offsetY: 0
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 160,
                        style: {
                            colors: ['Gray'],
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-yaxis-label',
                            },
                        offsetX: 0,
                        offsetY: 0,
                        rotate: 0,
                        formatter: function (val) {
                            return val.toFixed(1);
                        },
                    },
                    axisBorder: {
                        show: true,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    }
                }
            })
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ApexCharts.exec('chart_1', 'updateSeries', chartOneData)
    }, [chartOneData])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ApexCharts.exec('chart_2', 'updateSeries', chartTwoData)
    }, [chartTwoData])  // eslint-disable-line react-hooks/exhaustive-deps

    function addZero(i) {
        if (i < 10) {i = "0" + i}
        return i;
      }

    function getChartData(option) {
        let chartDataSet = []
        if (option !== '') {
            if (TrendSheet && TrendSheet.rows.length > 0) {
                TrendSheet.rows.forEach((row) => {
                    if (row[option] !== null && row[option] !== undefined) {
                        chartDataSet.push({x: `${addZero(row['dateTime'].getMonth()+1)}/${addZero(row['dateTime'].getDate())} ${addZero(row['dateTime'].getHours())}:${addZero(row['dateTime'].getMinutes())}`,  y:  row[option]})
                    }
                })
            }
        }
        if (chartDataSet.length === 0) {
            chartDataSet.push({x: 'abc', y: 0})
        }
        return chartDataSet
    }

    function optionChange(e) {
        if (e.target.id === 'c1') {
            setOption1(e.target.value)

        } else if (e.target.id === 'c2') {
            setOption2(e.target.value)
        }
    }

    return (
        <div>
            <div>
                <select
                    id={`c${chartNumber.toString()}`}
                    value={chartNumber === 1 ? option1 : option2}
                    onChange={((e) => {
                        optionChange(e)
                    })}
                    className="hpChart"
                    title={'Chart ' + chartNumber.toString()}
                >
                    {options.map(option =>
                        <option key={option.split('|')[0]} value={option.split('|')[0]}>{option.split('|')[1].toUpperCase()}</option>
                    )}
                </select>
            </div>
            <div>
                <Chart
                    id={'chart_' + chartNumber.toString()}
                    options={chartNumber === 1 ? chartOneOptions : chartTwoOptions}
                    series={chartNumber === 1 ? chartOneData : chartTwoData}
                    width="500px"
                    height="200px"
                    type='area'
                />
            </div>
        </div>
    );
})

export default ChartSelect
