import { Instance, types, SnapshotIn, destroy, isAlive } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import TrendSheetRow from './TrendSheetRow'

export type TrendSheetInstance = Instance<typeof TrendSheet>
export type TrendSheetSetAttributes = SnapshotIn<typeof TrendSheet>
export type TrendSheetRowCreationArgs = SnapshotIn<typeof TrendSheetRow>

export type CreateTrendSheetInput = {
  name?: string;
  diameter?: string
}

const TrendSheet = types
  .model('TrendSheet', {
    id: types.optional(types.identifier, uuid),
    rows: types.optional(types.array(TrendSheetRow), [{ id: '' }]),
  })
  .views((self) => ({
    getTrendSheetRow(id: string) {
      return self.rows.find(({ id: ID }) => ID === id)
    },
    getFirstTrendSheetRow() {
      return self.rows[0]
    },
    // getLastCheckedRow() {
    //   self.rows.filter(e => e['emptyFill'] === 'true')
    //   return 0
    // }
  }))
  .actions((self) => {
    return {
      createTrendSheetRow(args: TrendSheetRowCreationArgs) {
        self.rows.push(TrendSheetRow.create(args))
      },

      markDeleted(id: string) {
        const row = self.rows.find(({ id: ID }) => ID === id)
        if (row) {
          if (isAlive(row)) {
            row.deleted = true
          }
        }
      },

      // updateTrendSheetRow(
      //   id: string,
      //   index: number,
      //   tt1: number,
      //   tt2: number,
      //   openClosed: string,
      //   tsComment: string,
      // ) {
      //   const row = self.rows.find(({ id: ID }) => ID === id)
      //   if (row) {
      //     row.id = id
      //     row.index = index
      //     row.tt1 = tt1
      //     row.tt2 = tt2
      //     row.openClosed = openClosed
      //     row.tsComment = tsComment
      //   }
      // },

      updateTrendSheetCell(id: string, field: any, fieldType: string, value: string) {
        const row: any = self.rows.find(({ id: ID }) => ID === id)
        if (row) {

          // Object.getOwnPropertyNames(row).forEach((key) => {
          const updateFieldType: string = fieldType
          const updateField: typeof updateFieldType = field
          const updateValue = value

          if (updateFieldType === 'string') {
            row[updateField] = updateValue
          } else if (updateFieldType === 'number') {
            row[updateField] = Number(updateValue)
          } else if (updateFieldType === 'boolean') {
            row[updateField] = updateValue === 'true'
          } else if (updateFieldType === 'array') {
            row[updateField] = updateValue.split(',')
          } else if (updateFieldType === 'object') {
            row[updateField] = JSON.parse(updateValue)
          } else if (updateFieldType === 'date') {
            row[updateField] = new Date(updateValue)
          } else if (updateFieldType === 'enum') {
            row[updateField] = updateValue
          } else if (updateFieldType === 'reference') {
            row[updateField] = updateValue
          } else {
            row[updateField] = updateValue
          }
        }
      },

      deleteTrendSheetRow(id: string) {
        const deleteKey = self.rows.findIndex(({ id: ID }) => ID === id)
        if (deleteKey !== -1) {
          self.rows.splice(deleteKey, 1)
        } else {
          console.log('deleteTrendSheetRow: row not found')
        }
      },

      clearTrendSheetRows() {
        destroy(self.rows)
      },

      createEmptyRow(currentLoggedInUser: string) {
        self.rows.push(
          TrendSheetRow.create()
        )
        const lastRow: any = self.rows[ self.rows.length - 1]
        lastRow.dateTime = new Date()
        lastRow.user = currentLoggedInUser
      },

      setComment(id: string, comment: string) {
        const row = self.rows.find(({ id: ID }) => ID === id)
        if (row) {
          row.comment = comment
        }
      },

      reverseTrendSheet() {
        self.rows.reverse()
      },
    }
  })

export type TrendSheetRowInstance = Instance<typeof TrendSheetRow>

export default TrendSheet
