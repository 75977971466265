import { Fragment, useEffect, useRef, useState } from 'react'
import { ChildrenProps, JSXElementProps } from 'utils/helpers'
import { Paper } from '../Layout'
import { makeStyles } from '@mui/styles'
import Portal from '../Portal'

const useStyles = makeStyles({
  dialog: {
    zIndex: 1000,
  },
  dialogBg: ({ open }: UseStylesProps) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgb(20, 18, 30, 0.8)',
    backdropFilter: 'blur(4px)',
    zIndex: 900,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    opacity: open ? 1 : 0,
  }),
})

const Dialog = ({ onClose, open, children, ...props }: DialogProps) => {
  const backdrop = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState(false)

  const cn = useStyles({ open: open && active })

  useEffect(() => {
    const { current } = backdrop

    const transitionEnd = () => setActive(open)
    const clickHandler = (e: MouseEvent) => e.target === current && onClose(e)

    if (current) {
      current.addEventListener('transitionend', transitionEnd)
      current.addEventListener('click', clickHandler)
    }

    if (open) {
      //console.log("Scheduling dialog open in 10 sec");
      //window.setTimeout(() => {
        ;(document.activeElement as HTMLDivElement).blur()
        setActive(open)
        ;(document.querySelector('#root') as HTMLDivElement).setAttribute(
          'inert',
          'true'
        )
      //  console.log("Dialog open");
      //}, 10)
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd)
        current.removeEventListener('click', clickHandler)
      }

      //console.log("Dialog closed");
      ;(document.querySelector('#root') as HTMLDivElement).removeAttribute(
        'inert'
      )
    }
  }, [open, onClose])

  return (
    <Fragment>
      {(open || active) && (
        <Portal className={'modal-portal'}>
          <div ref={backdrop} className={cn.dialogBg}>
            <Paper
              {...(({ ref, ...props }) => props)(props)}
              className={cn.dialog}
            >
              {children}
            </Paper>
          </div>
        </Portal>
      )}
    </Fragment>
  )
}

type DialogProps = {
  onClose: EventListener
  open: boolean
} & JSXElementProps<HTMLDivElement> &
  ChildrenProps

type UseStylesProps = {
  open: boolean
}

export default Dialog
