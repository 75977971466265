import { Instance, types, SnapshotIn } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

export type KillSheetInstance = Instance<typeof KillSheet>
export type KillSheetSetAttributes = SnapshotIn<typeof KillSheet>

// export type CreateKillSheetInput = {
//   name?: string;
//   diameter?: string
// }

const KillSheet = types
  .model('KillSheet', {
    id: types.optional(types.identifier, uuid),
    surfaceLeakOffPressure: types.optional(types.number, 0),
    drillingFluidDensity: types.optional(types.number, 0),
    chokelineLength: types.optional(types.number, 0),
    displacementPump1: types.optional(types.number, 0),
    displacementPump2: types.optional(types.number, 0),
    chokelineVolume: types.optional(types.number, 0),
    surfaceLineVolume: types.optional(types.number, 0),
    kickIntensity: types.optional(types.number, 0),
    influxGradientGas: types.optional(types.number, 0),
    targetSbp: types.optional(types.number, 0),
    currentSbp: types.optional(types.number, 0),
    holeTvd: types.optional(types.number, 0),
    marineRiserDpVolume: types.optional(types.number, 0),
    activeSurfaceVolume: types.optional(types.number, 0),
    pressureLossDate: types.optional(types.string, ''),
    pressureLossDepth: types.optional(types.number, 0),
    pump: types.optional(types.number, 0),
    spm: types.optional(types.number, 0),
    spmLabel1: types.optional(types.string, ''),
    spmLabel2: types.optional(types.string, ''),
    spmLabel3: types.optional(types.string, ''),
    spmLabel4: types.optional(types.string, ''),
    slowPumpRate1: types.optional(types.string, ''),
    slowPumpRate2: types.optional(types.string, ''),
    slowPumpRate3: types.optional(types.string, ''),
    slowPumpRate4: types.optional(types.string, ''),
    riser120: types.optional(types.number, 0),
    riser130: types.optional(types.number, 0),
    riser140: types.optional(types.number, 0),
    riser150: types.optional(types.number, 0),
    riser220: types.optional(types.number, 0),
    riser230: types.optional(types.number, 0),
    riser240: types.optional(types.number, 0),
    riser250: types.optional(types.number, 0),
    choke120: types.optional(types.number, 0),
    choke130: types.optional(types.number, 0),
    choke140: types.optional(types.number, 0),
    choke150: types.optional(types.number, 0),
    choke220: types.optional(types.number, 0),
    choke230: types.optional(types.number, 0),
    choke240: types.optional(types.number, 0),
    choke250: types.optional(types.number, 0),
    sidpp: types.optional(types.number, 0),
    icpOverride: types.optional(types.number, 0),
    sicp: types.optional(types.number, 0),
    pitgain: types.optional(types.number, 0),
  })
  .views((self) => ({
    // getKillSheetRow(id: string) {
    //   return self.rows.find(({ id: ID }) => ID === id)
    // },
    // getFirstKillSheetRow() {
    //   return self.rows[0]
    // },
    // getLastCheckedRow() {
    //   self.rows.filter(e => e['emptyFill'] === 'true')
    //   return 0
    // }
  }))
  .actions((self) => {
    return {
      updateKillSheetData(id: string, field: any, fieldType: string, value: string) {
        const updateFieldType: string = fieldType
        const updateField: typeof updateFieldType = field
        const updateValue = value

        if (updateField === 'surfaceLeakOffPressure') {
          self.surfaceLeakOffPressure = parseFloat(updateValue)
        } else if (updateField === 'drillingFluidDensity') {
          self.drillingFluidDensity = parseFloat(updateValue)
        } else if (updateField === 'chokelineLength') {
          self.chokelineLength = parseFloat(updateValue)
        } else if (updateField === 'displacementPump1') {
          self.displacementPump1= parseFloat(updateValue)
        } else if (updateField === 'displacementPump2') {
          self.displacementPump2 = parseFloat(updateValue)
        } else if (updateField === 'surfaceLineVolume') {
          self.surfaceLineVolume = parseFloat(updateValue)
        } else if (updateField === 'kickIntensity') {
          self.kickIntensity = parseFloat(updateValue)
        } else if (updateField === 'influxGradientGas') {
          self.influxGradientGas = parseFloat(updateValue)
        } else if (updateField === 'targetSbp') {
          self.targetSbp = parseFloat(updateValue)
        } else if (updateField === 'currentSbp') {
          self.currentSbp = parseFloat(updateValue)
        } else if (updateField === 'holeTvd') {
          self.holeTvd = parseFloat(updateValue)
        } else if (updateField === 'chokelineVolume') {
          self.chokelineVolume= parseFloat(updateValue)
        } else if (updateField === 'marineRiserDpVolume') {
          self.marineRiserDpVolume = parseFloat(updateValue)
        } else if (updateField === 'activeSurfaceVolume') {
          self.activeSurfaceVolume = parseFloat(updateValue)
        } else if (updateField === 'pump') {
          self.pump = parseFloat(updateValue)
        } else if (updateField === 'spm') {
          self.spm = parseFloat(updateValue)
        } else if (updateField === 'riser120') {
          self.riser120 = parseFloat(updateValue)
        } else if (updateField === 'riser130') {
          self.riser130 = parseFloat(updateValue)
        } else if (updateField === 'riser140') {
          self.riser140 = parseFloat(updateValue)
        } else if (updateField === 'riser150') {
          self.riser150 = parseFloat(updateValue)
        } else if (updateField === 'riser220') {
          self.riser220 = parseFloat(updateValue)
        } else if (updateField === 'riser230') {
          self.riser230 = parseFloat(updateValue)
        } else if (updateField === 'riser240') {
          self.riser240 = parseFloat(updateValue)
        } else if (updateField === 'riser250') {
          self.riser250 = parseFloat(updateValue)
        } else if (updateField === 'choke120') {
          self.choke120 = parseFloat(updateValue)
        } else if (updateField === 'choke130') {
          self.choke130 = parseFloat(updateValue)
        } else if (updateField === 'choke140') {
          self.choke140 = parseFloat(updateValue)
        } else if (updateField === 'choke150') {
          self.choke150 = parseFloat(updateValue)
        } else if (updateField === 'choke220') {
          self.choke220 = parseFloat(updateValue)
        } else if (updateField === 'choke230') {
          self.choke230 = parseFloat(updateValue)
        } else if (updateField === 'choke240') {
          self.choke240 = parseFloat(updateValue)
        } else if (updateField === 'choke250') {
          self.choke250 = parseFloat(updateValue)
        } else if (updateField === 'sidpp') {
          self.sidpp = parseFloat(updateValue)
        } else if (updateField === 'icpOverride') {
          self.icpOverride = parseFloat(updateValue)
        } else if (updateField === 'sicp') {
          self.sicp = parseFloat(updateValue)
        } else if (updateField === 'pitgain') {
          self.pitgain = parseFloat(updateValue)
        } else if (updateField === 'spmLabel1') {
          self.spmLabel1 = updateValue
        } else if (updateField === 'spmLabel2') {
          self.spmLabel2 = updateValue
        } else if (updateField === 'spmLabel3') {
          self.spmLabel3 = updateValue
        } else if (updateField === 'spmLabel4') {
          self.spmLabel4 = updateValue
        } else if (updateField === 'slowPumpRate1') {
          self.slowPumpRate1 = updateValue
        } else if (updateField === 'slowPumpRate2') {
          self.slowPumpRate2 = updateValue
        } else if (updateField === 'slowPumpRate3') {
          self.slowPumpRate3 = updateValue
        } else if (updateField === 'slowPumpRate4') {
          self.slowPumpRate4 = updateValue
        } else if (updateField === 'pressureLossDate') {
          self.pressureLossDate = updateValue
        } else if (updateField === 'pressureLossDepth') {
          self.pressureLossDepth = parseFloat(updateValue)
        }
      }
    }
  })

export default KillSheet
