import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'App'
import { useQuery, gql } from '@apollo/client'
import iconCaretDown from '../../../assets/images/icons/icon_caretDown.svg'
import { getCompany, getRig, getRigName, getRigType } from '../../../utils/helpers'

const Rig = ({currentCompanyId, currentRigId, setCurrentRigId}) => {
    const { user } = useContext(UserContext)
    const [scope, setScope] = useState('')

    const { data, loading, refetch } = useQuery(
        gql`
            query Rig($companyId: String!) {
                rigsByCompanyId(companyId: $companyId) {
                    id
                    name
                    type
                }
            }
        `,
        {
            variables: {
                companyId: currentCompanyId,
            },
            onCompleted(data) {
                ensureRigSelected()
            },
            onError(err) {
                console.error(err)
            }
        }
    )

    useEffect(() => {
        const operator = ['rig']
        const shorebased = ['rig', 'company']
        const support = ['rig', 'company', 'global']

            switch (JSON.stringify(user?.scope)) {
            case JSON.stringify(operator):
                setScope('operator')
                break
            case JSON.stringify(shorebased):
                setScope('shorebased')
                break
            case JSON.stringify(support):
                setScope('support')
                break
            default:
                setScope('operator')
                break
        }
    }, [user?.scope])

    useEffect(() => {
        setCurrentRigId(getCompany())
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        refetch()
        if (scope !== 'operator') {
            ensureRigSelected()
        }
    }, [currentCompanyId, refetch]) // eslint-disable-line react-hooks/exhaustive-deps

    function updateCurrentRigId() {
        const rigSelect = document.getElementById('currentRig')
        setCurrentRigId(rigSelect.options[0].value.split('|')[0])

        const context = JSON.parse(window.localStorage.getItem('context'))
        context.rig.id = rigSelect.options[0].value.split('|')[0]
        context.rig.name = rigSelect.options[0].value.split('|')[1]
        context.rig.type = rigSelect.options[0].value.split('|')[2]
        window.localStorage.setItem('context', JSON.stringify(context))
    }

    function handleChange(event) {
        setCurrentRigId(event.target.value.split('|')[0])

        const context = JSON.parse(window.localStorage.getItem('context'))
        context.rig.id = event.target.value.split('|')[0]
        context.rig.name = event.target.value.split('|')[1]
        context.rig.type = event.target.value.split('|')[2]
        window.localStorage.setItem('context', JSON.stringify(context))

        return currentRigId // to silence unused warning
    }

    function selectContainsOption(select) {
        for (let i = 0; i < select.options.length; i++) {
            if (select.options[i].value.split('|')[0] === currentRigId) {
                return true
            }
        }
        return false
    }

    function selectFirstOption(select) {
        if (select.options.length > 0) {
            select.value = select.options[0].value
        }
    }

    function ensureRigSelected() {
        const rigSelect = document.getElementById('currentRig')

        if (rigSelect !== null) {
            if (rigSelect.options.length > 0) {
                if (getRig() === null) {
                    updateCurrentRigId()
                }
                else {
                    if (selectContainsOption(rigSelect) === false) {
                        selectFirstOption(rigSelect)
                        const newRigId = rigSelect.options[0].value.split('|')[0]
                        setCurrentRigId(newRigId)

                        const context = JSON.parse(window.localStorage.getItem('context'))
                        context.rig.id = rigSelect.options[0].value.split('|')[0]
                        context.rig.name = rigSelect.options[0].value.split('|')[1]
                        context.rig.type = rigSelect.options[0].value.split('|')[2]
                        window.localStorage.setItem('context', JSON.stringify(context))
                    }
                }
            }
        }
    }

    return (
        <>
            {!loading && ((scope === 'shorebased') || (scope === 'support')) ? (
                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <img alt="Rig" src={iconCaretDown} />
                    </div>
                    <div style={{ marginLeft: '-8px' }}>
                        <select
                            id="currentRig"
                            onChange={handleChange}
                            value={
                                getRig() + '|' +
                                getRigName() + '|' +
                                getRigType()
                            }
                            title="Select a Rig"
                            className="dashboardSelect"
                            style={{ height: '30px !important', backgroundColor: 'transparent' }}
                        >
                            {data?.rigsByCompanyId.map(({ id, name, type }) => (
                                <option key={id} value={`${id}|${name}|${type}`}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            ) : (
                <div className="dashboardSelect">
                    {getRigName()}
                </div>
            )}
        </>
    );
}

export default Rig
