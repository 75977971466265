// import { Instance, types, SnapshotIn } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
// import { undoManager } from 'app/store'

const Tubular = types
  .model('Tubular', {
    __typename: types.optional(types.string, ''),
    id: types.optional(types.identifier, uuid),
    type: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    connection: types.optional(types.string, ''),
    OD: types.optional(types.number, 0),
    ID: types.optional(types.number, 0),
    weight: types.optional(types.number, 0),
    disp: types.optional(types.number, 0),
    cap: types.optional(types.number, 0),
    range: types.optional(types.number, 0),
    mutMin: types.optional(types.number, 0),
    mutMax: types.optional(types.number, 0),
    tenStrength: types.optional(types.number, 0),
    torStrength: types.optional(types.number, 0),
    standard: types.optional(types.boolean, false),
    rigId: types.optional(types.string, ''),
  })
  .views((self) => ({
    // get currentString() {
    //   return self.strings[self.currentStringIndex]
    // },
  }))
  .actions((self) => {
    return {
      //
    }
  })

export default Tubular
